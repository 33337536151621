import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import { useContactTags } from "src/hooks/useTags";
import { useConversationTags } from "src/hooks/useTags";
import { useTeams } from "src/hooks/useTeam";
import { userAvatar } from "src/Helpers";
import { FlowStepTeamConversationMeta } from "./Meta";

export default function FlowStepTeamConversation({ formik, index }) {
  const { t } = useTranslation();
  const teams = useTeams();
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();

  const formatOptionLabel = ({ value, label, team }) => (
    <div className="flex items-center justify-between">
      <div>
        <h4 className="font-medium">{label}</h4>
      </div>
      <div className="ml-2 flex justify-end -space-x-1 overflow-hidden">
        {team &&
          team.users.map((user) => (
            <Fragment key={user.id}>
              {userAvatar(
                user,
                "h-6 w-6 text-xs ring-2 ring-white group-hover:ring-gray-300"
              )}
            </Fragment>
          ))}
      </div>
    </div>
  );

  return (
    <>
      <SelectorField
        isMulti
        isCreatable={false}
        label={FlowStepTeamConversationMeta.FIELDS.assign_to_teams.label}
        name={`steps.${index}.${FlowStepTeamConversationMeta.FIELDS.assign_to_teams.key}`}
        help={FlowStepTeamConversationMeta.FIELDS.assign_to_teams.help}
        // @ts-ignore
        formatOptionLabel={formatOptionLabel}
        options={teams.map((team) => ({
          label: team.name,
          value: team.id,
          team: team,
        }))}
        placeholder={t("inbox:search-teams", "Type to search teams")}
      />
      <SwitchField
        name={`steps.${index}.${FlowStepTeamConversationMeta.FIELDS.append_tags.key}`}
        label={FlowStepTeamConversationMeta.FIELDS.append_tags.label}
        help={FlowStepTeamConversationMeta.FIELDS.append_tags.help}
      />
      <FlowTag
        title="Add conversation tags"
        availableTags={availableConversationTags}
        name={`steps.${index}.${FlowStepTeamConversationMeta.FIELDS.conversation_tags.key}`}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        title="Add contact tags"
        availableTags={availableContactTags}
        name={`steps.${index}.${FlowStepTeamConversationMeta.FIELDS.contact_tags.key}`}
        {...contactTagsColorClasses}
      />
    </>
  );
}
