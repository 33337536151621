import { useMemo } from "react";
import { ExclamationIcon, GlobeAltIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { classNames } from "../../../../../Helpers";
import TextInputField from "../../../../../components/Form/TextInputField";
import FlowStepAddVariable from "../../../FlowStepAddVariable";
import { FlowStepMessageMeta } from "./Meta";

interface FlowStepMessageMediaURLProps {
  formik: FormikProps<any>;
  index: number;
  mediaType: MediaType;
}

interface MediaType {
  label: string;
  value: string;
  icon: JSX.Element;
  help: string;
  accept?: string;
  inputHelp?: string;
}

export default function FlowStepMessageMediaURL({
  formik,
  index,
  mediaType,
}: FlowStepMessageMediaURLProps) {
  const addBodyURLVariable = (variable: string) => {
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file_url.key}`,
      variable
    );
  };

  const shouldShowPreview = useMemo(() => {
    if (
      !formik.values?.steps[index]["body_file_url"] ||
      formik.values?.steps[index]["body_type"] === "document"
    ) {
      return false;
    } else if (formik.values?.steps[index]["body_file_url"].includes("{{")) {
      return false;
    }
    return true;
  }, [formik.values, index]);

  if (!mediaType) {
    return null;
  }

  return (
    <div>
      <div className="grid grid-cols-3 gap-3">
        <div
          className={classNames(
            shouldShowPreview ? "col-span-2" : "col-span-3",
            "space-y-2"
          )}
        >
          <TextInputField
            label={FlowStepMessageMeta.FIELDS.body_file_url.label}
            placeholder={FlowStepMessageMeta.FIELDS.body_file_url.placeholder}
            help={mediaType.inputHelp}
            icon={GlobeAltIcon}
            name={`steps.${index}.${FlowStepMessageMeta.FIELDS.body_file_url.key}`}
            type="url"
          />

          <div className="mt-2 flex flex-wrap-reverse items-start justify-between">
            <div className="">
              <FlowStepAddVariable
                formik={formik}
                addVariable={addBodyURLVariable}
                currentStepIndex={index}
              />
            </div>
          </div>
        </div>
        <div>
          {shouldShowPreview && (
            <div>
              {mediaType.value === "image" && (
                <img
                  alt={formik.values.steps[index].name}
                  src={formik.values.steps[index]["body_file_url"]}
                />
              )}
              {mediaType.value === "video" && (
                <video controls className="mr-2 self-center">
                  <source
                    src={formik.values.steps[index]["body_file_url"]}
                    type="video/mp4"
                  />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              )}
            </div>
          )}
        </div>
      </div>
      {shouldShowPreview && (
        <div className="mt-2 border-l-4 border-indigo-400 bg-indigo-50 p-3">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-indigo-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <p className="text-sm text-indigo-700">
                If you can't see your {mediaType.label.toLowerCase()} on the
                right, it's not publicly accessible and won't work for your
                flow.
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
