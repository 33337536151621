import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { buildRoute } from "src/router/router";

interface FlowDetailInboundRunInstructionsProps {
  flowId: string;
}

export default function FlowDetailInboundRunInstructions({
  flowId,
}: FlowDetailInboundRunInstructionsProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-2 text-sm text-gray-900 space-y-3">
        <Trans i18nKey="flow-executions:inbound.run-flow-instructions">
          <div className="space-y-2">
            <p>Your flow is not yet running.</p>
            <p>
              Set what text we should match in every inbound message in order to
              trigger this flow.
            </p>
          </div>
        </Trans>

        <div className="flex items-center justify-between">
          <Link
            to={buildRoute("flow-execution-create", {
              ":id": flowId,
            })}
            className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
          >
            {t("flow-executions:inbound.run", "Set trigger text")}
            <ArrowSmRightIcon className="h-5 w-5 text-white mr-1" />
          </Link>
        </div>
      </div>
    </>
  );
}
