import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";

interface FlowFromStepOutboundNoticeProps {
  formik: FormikProps<any>;
  index: number;
}

export default function FlowFromStepOutboundNotice({
  formik,
  index,
}: FlowFromStepOutboundNoticeProps) {
  const { t } = useTranslation();
  const showNotice = useMemo(() => {
    return (
      formik.values.execution_type === "OUTBOUND" &&
      index === 0 &&
      formik.values.steps[index].step_type !== "TEMPLATE"
    );
  }, [formik.values, index]);
  return (
    <>
      {showNotice && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700">
                <p>
                  {t(
                    "flows:outbound-template-notice",
                    "For outbound flows you must first send a template message to open the conversation."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
