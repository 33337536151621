import { CalendarIcon } from "@heroicons/react/outline";
import DatePickerField from "../../../../../components/Form/DatePickerField";
import SwitchField from "../../../../../components/Form/SwitchField";
import TextInputField from "../../../../../components/Form/TextInputField";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionDate({ formik, index }) {
  return (
    <>
      <div>
        <SwitchField
          label={FlowStepQuestionMeta.FIELDS.answer_has_range.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_has_range.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_has_range.help}
        />
      </div>
      {formik.values.steps[index].answer_has_range && (
        <div className="grid grid-cols-2 items-start space-x-4">
          <div>
            <DatePickerField
              label={FlowStepQuestionMeta.FIELDS.answer_range_min.label}
              name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_range_min.key}`}
              help={FlowStepQuestionMeta.FIELDS.answer_range_min.help}
              dateFormat="dd/MM/yyyy"
              icon={CalendarIcon}
            />
          </div>
          <div>
            <DatePickerField
              label={FlowStepQuestionMeta.FIELDS.answer_range_max.label}
              name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_range_max.key}`}
              help={FlowStepQuestionMeta.FIELDS.answer_range_max.help}
              dateFormat="dd/MM/yyyy"
              icon={CalendarIcon}
            />
          </div>
        </div>
      )}
      <div>
        <TextInputField
          label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
          placeholder={
            FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
          }
          type="text"
        />
      </div>
    </>
  );
}
