import { useCallback, useMemo } from "react";
import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  ChevronDownIcon,
  DuplicateIcon,
  ExclamationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { classNames } from "../../Helpers";
import DropdownMenu from "../../components/DropdownMenu";
import StepInputField from "../../components/Form/StepInputField";
import { FlowStepMessageMeta } from "./form/steps/message/Meta";

export default function FlowFormStepToggle({
  formik,
  index,
  step,
  open,
  setOpen,
}) {
  const stepHasErrors = useMemo(() => {
    return formik.errors.steps
      ? formik.errors.steps[index] &&
          Object.keys(formik.errors.steps[index]).length > 0
      : false;
  }, [formik.errors, index]);

  const moveStepUp = useCallback(
    (stepIdx) => {
      if (stepIdx === 0) {
        return;
      }
      const steps = [...formik.values.steps];
      const prevStepDefaultIdx = steps[stepIdx - 1].next_step_default_idx;
      const prevStepAlternateIdx = steps[stepIdx - 1].next_step_alternate_idx;
      const step = steps.splice(stepIdx, 1)[0];

      steps.splice(stepIdx - 1, 0, step);

      // Restoring pointers
      const oldPrevStep = steps[stepIdx];
      oldPrevStep.next_step_default_idx = step.next_step_default_idx;
      // Check that the resulting alternate_idx is a step below the current one,
      // otherwise leave it blank
      if (oldPrevStep.step_type === "CONDITIONAL") {
        oldPrevStep.next_step_alternate_idx =
          step.next_step_alternate_idx > stepIdx + 1
            ? step.next_step_alternate_idx
            : "";
      }
      step.next_step_default_idx = prevStepDefaultIdx;
      // Check that the resulting alternate_idx is a step below the current one,
      // otherwise leave it blank
      if (step.step_type === "CONDITIONAL") {
        step.next_step_alternate_idx =
          prevStepAlternateIdx > stepIdx ? prevStepAlternateIdx : "";
      }

      formik.setFieldValue("steps", steps);
    },
    [formik]
  );

  const moveStepDown = useCallback(
    (stepIdx) => {
      if (stepIdx === formik.values.steps.length - 1) {
        return;
      }
      const steps = [...formik.values.steps];
      const nextStepDefaultIdx = steps[stepIdx + 1].next_step_default_idx;
      const nextStepAlternateIdx = steps[stepIdx + 1].next_step_alternate_idx;
      const step = steps.splice(stepIdx, 1)[0];

      if (stepIdx !== steps.length - 1) {
        steps.splice(stepIdx + 1, 0, step);
      } else {
        steps.push(step);
      }

      // Restoring pointers
      const oldNextStep = steps[stepIdx];
      oldNextStep.next_step_default_idx = step.next_step_default_idx;
      if (oldNextStep.step_type === "CONDITIONAL") {
        // Check that the resulting alternate_idx is a step below the current one,
        // otherwise leave it blank
        oldNextStep.next_step_alternate_idx =
          step.next_step_alternate_idx > stepIdx ? nextStepAlternateIdx : "";
      }
      step.next_step_default_idx = nextStepDefaultIdx;
      // Check that the resulting alternate_idx is a step below the current one,
      // otherwise leave it blank
      if (step.step_type === "CONDITIONAL") {
        step.next_step_alternate_idx =
          nextStepAlternateIdx > stepIdx + 1 ? nextStepAlternateIdx : "";
      }

      formik.setFieldValue("steps", steps);
    },
    [formik]
  );

  const duplicateStep = useCallback(
    (stepIdx) => {
      const newStep = { ...formik.values.steps[stepIdx] };
      delete newStep["id"];
      // When duplicating a step, create it without any links to other steps
      newStep.next_step_default_idx = "";
      newStep.next_step_alternate_idx = "";
      const steps = [...formik.values.steps];
      steps.splice(stepIdx + 1, 0, newStep);
      formik.setFieldValue("steps", steps);
    },
    [formik]
  );

  const removeStep = useCallback(
    (stepIdx) => {
      const steps = [...formik.values.steps];
      // Remove references to that step
      steps
        .filter((step) => step.next_step_default_idx === stepIdx)
        .forEach((step) => (step.next_step_default_idx = ""));

      steps
        .filter((step) => step.next_step_alternate_idx === stepIdx)
        .forEach((step) => (step.next_step_alternate_idx = ""));

      steps
        .filter(
          (step) =>
            step.next_steps_for_options_idx &&
            step.next_steps_for_options_idx.includes(stepIdx)
        )
        .forEach(
          (step) =>
            (step.next_steps_for_options_idx =
              step.next_steps_for_options_idx.map((stepOptionIdx) =>
                stepOptionIdx !== stepIdx ? stepOptionIdx : null
              ))
        );

      // Split steps into prev and next
      const prevSteps = steps.slice(0, stepIdx);
      const nextSteps = steps.slice(stepIdx + 1);

      // For all steps after that one, decrease next_step_idx by 1
      // TODO: eventually, mark variables referencing a non-existing step so
      // people know they don't exist anymore
      nextSteps.forEach((step) => {
        step.next_step_default_idx = step.next_step_default_idx
          ? step.next_step_default_idx - 1
          : "";
        step.next_step_alternate_idx = step.next_step_alternate_idx
          ? step.next_step_alternate_idx - 1
          : "";
        if (step.next_steps_for_options_idx) {
          step.next_steps_for_options_idx = step.next_steps_for_options_idx.map(
            (stepOptionIdx) => (stepOptionIdx ? stepOptionIdx - 1 : null)
          );
        }
      });
      formik.setFieldValue("steps", prevSteps.concat(nextSteps));
    },
    [formik]
  );

  const getDropdownMenuItems = useCallback(
    (index) => {
      const items = [];
      if (index > 0) {
        items.push((active) => (
          <button
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "flex w-full items-center px-4 py-2 text-sm"
            )}
            type="button"
            onClick={(_) => moveStepUp(index)}
          >
            <ArrowSmUpIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Move up
          </button>
        ));
      }
      if (index !== formik.values.steps.length - 1) {
        items.push((active) => (
          <button
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "flex w-full items-center px-4 py-2 text-sm"
            )}
            type="button"
            onClick={(_) => moveStepDown(index)}
          >
            <ArrowSmDownIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Move down
          </button>
        ));
      }
      const alwaysOn = [
        (active) => (
          <button
            className={classNames(
              active ? "bg-gray-100 text-gray-900" : "text-gray-700",
              "flex w-full items-center px-4 py-2 text-sm"
            )}
            type="button"
            onClick={(_) => duplicateStep(index)}
          >
            <DuplicateIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            Duplicate step
          </button>
        ),
        (active) => (
          <button
            className={classNames(
              active ? "bg-red-100 text-red-900" : "text-red-700",
              "flex w-full items-center px-4 py-2 text-sm"
            )}
            type="button"
            onClick={(_) => removeStep(index)}
          >
            <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Remove step
          </button>
        ),
      ];
      return items.concat(alwaysOn);
    },
    [
      duplicateStep,
      removeStep,
      moveStepUp,
      moveStepDown,
      formik.values.steps.length,
    ]
  );

  const cleanStepName = (value) => {
    let cleanedVal = value.replaceAll(/[^A-Za-zÀ-ÖØ-öø-ÿ_0-9]/g, " ");
    cleanedVal = cleanedVal.replace(/\s\s+/g, " ");
    return cleanedVal.trim();
  };

  return (
    <div className="flex cursor-pointer items-center justify-between px-4 py-2">
      <div className="flex grow items-center">
        <div className="pr-4 text-gray-500">
          <button
            className="h-8 w-8 rounded-full border border-gray-300"
            type="button"
            onClick={(_) => setOpen(!open)}
          >
            {index + 1}
          </button>
        </div>
        <StepInputField
          type="text"
          name={`steps.${index}.${FlowStepMessageMeta.FIELDS.name.key}`}
          placeholder={FlowStepMessageMeta.FIELDS.name.placeholder}
          onBlur={(ev) => {
            formik.setFieldValue(
              `steps.${index}.${FlowStepMessageMeta.FIELDS.name.key}`,
              cleanStepName(ev.target.value)
            );
          }}
        />
      </div>
      <div className="flex items-center">
        {stepHasErrors && (
          <div className="mr-2 text-right text-red-500">
            <ExclamationCircleIcon className="h-5 w-5" aria-hidden="true" />
          </div>
        )}
        <button
          className="flex h-8 w-8 items-center justify-center rounded-full hover:bg-gray-200"
          type="button"
          onClick={(_) => setOpen(!open)}
        >
          <ChevronDownIcon
            className={`${open ? "rotate-180 transform" : ""} h-4 w-4`}
          />
        </button>
        <DropdownMenu menuItems={getDropdownMenuItems(index)} />
      </div>
    </div>
  );
}
