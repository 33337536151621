import { Trans, useTranslation } from "react-i18next";
import { InboundTriggerWithEnum } from "@hilos/types/private-schema";

interface FlowExecutionInboundTriggerWithProps {
  inbound_trigger_with: InboundTriggerWithEnum;
  inbound_start_message_match_exact: boolean;
  inbound_start_message: string;
}
function FlowExecutionInboundTriggerWith({
  inbound_trigger_with,
  inbound_start_message_match_exact,
  inbound_start_message,
}: FlowExecutionInboundTriggerWithProps) {
  const { t } = useTranslation();
  if (!inbound_trigger_with || inbound_trigger_with === "TEXT") {
    return (
      <>
        {inbound_start_message_match_exact
          ? t(
              "flow-executions:inbound.start-message-match-exact-text",
              "Will start for messages that match"
            )
          : t(
              "flow-executions:inbound.start-message-match-contains-text",
              "Will start for messages that contain"
            )}{" "}
        "<code className="font-medium text-black">{inbound_start_message}</code>
        ".
      </>
    );
  }
  if (inbound_trigger_with === "ANY_MESSAGE") {
    return (
      <>
        {t(
          "flow-executions:inbound.trigger-any-message",
          "Will trigger anytime a customer messages you and there is no other conversation / flow active."
        )}
      </>
    );
  }
  if (inbound_trigger_with === "FILE") {
    return (
      <>
        {t(
          "flow-executions:inbound.start-message-file",
          "Will start for messages containing a File"
        )}
      </>
    );
  }
  if (inbound_trigger_with === "IMAGE") {
    return (
      <>
        {t(
          "flow-executions:inbound.start-message-image",
          "Will start for messages containing an Image"
        )}
      </>
    );
  }
  return (
    <>{t("flow-executions:inbound.start-message-unknown", "Unknown trigger")}</>
  );
}

export default FlowExecutionInboundTriggerWith;
