import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import useHilosStore from "src/hooks/useHilosStore";

const FIELDS = {
  has_max_answer_attempts: {
    key: "has_max_answer_attempts",
    label: "Keep track of answer attempts?",
    help: "Set how many attempts a user can make and what happens after.",
  },
  max_answer_attempts: {
    key: "max_answer_attempts",
    label: "Allow this many answer attempts",
  },
  max_wait_time_unit: {
    key: "max_wait_time_unit",
  },
  answer_failed_next_step_idx: {
    key: "answer_failed_next_step_idx",
    label:
      "If user didn't answer properly after this many attempts, go to this step",
  },
};

export default function MaxAnswerAttemptsComponent({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  const { session } = useHilosStore();

  if (!session?.account.feature_flags.includes("max_answer_attempts")) {
    return null;
  }
  return (
    <>
      <hr />

      <SwitchField
        name={`steps.${index}.${FIELDS.has_max_answer_attempts.key}`}
        label={FIELDS.has_max_answer_attempts.label}
        help={FIELDS.has_max_answer_attempts.help}
      />

      {formik.values.steps[index][FIELDS.has_max_answer_attempts.key] && (
        <>
          <div>
            <p className="block text-sm font-medium text-gray-700">
              {FIELDS.max_answer_attempts.label}
            </p>
            <TextInputField
              name={`steps.${index}.${FIELDS.max_answer_attempts.key}`}
              placeholder="2"
              type="number"
            />
          </div>

          <SelectorField
            label={FIELDS.answer_failed_next_step_idx.label}
            name={`steps.${index}.${FIELDS.answer_failed_next_step_idx.key}`}
            options={availableGroupedSteps(index)}
            formatGroupLabel={formatGroupLabel}
          />
        </>
      )}
    </>
  );
}
