import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import FlowStepInputAddVariable from "src/containers/flow/FlowStepInputAddVariable";
import FlowStepTextInputAddVariable from "src/containers/flow/FlowStepTextInputAddVariable";
import InputActionButton from "src/components/Form/InputActionButton";
import TextInputField from "src/components/Form/TextInputField";
import { FlowStepContactUpdateMeta } from "./Meta";

interface FlowStepContactUpdateDefaultProps {
  formik: FormikProps<any>;
  index: number;
}

export default function FlowStepContactUpdateDefault({
  formik,
  index,
}: FlowStepContactUpdateDefaultProps) {
  const { t } = useTranslation();
  const formPrefix = useMemo(() => {
    return `steps.${index}`;
  }, [index]);

  return (
    <div className="space-y-4">
      <FlowStepTextInputAddVariable
        formik={formik}
        name={`${formPrefix}.contact_first_name`}
        currentStepIndex={index}
        label={t(
          "flows:steps.contact-update.contact-first-name.label",
          "First name"
        )}
      />
      <FlowStepTextInputAddVariable
        formik={formik}
        name={`${formPrefix}.contact_last_name`}
        currentStepIndex={index}
        label={t(
          "flows:steps.contact-update.contact-last-name.label",
          "Last name"
        )}
      />
      <FlowStepTextInputAddVariable
        formik={formik}
        name={`${formPrefix}.contact_email`}
        currentStepIndex={index}
        label={t("flows:steps.contact-update.contact-email.label", "Email")}
      />
      <FlowStepTextInputAddVariable
        formik={formik}
        name={`${formPrefix}.contact_external_url`}
        currentStepIndex={index}
        label={t(
          "flows:steps.contact-update.contact-external-url.label",
          "External url"
        )}
        help={t(
          "flows:steps.contact-update.contact-external-url.help",
          "Use this field to set the URL to your CRM's contact detail. We'll show this in the Inbox sidebar so you can quickly move to your CRM from the conversation."
        )}
      />
    </div>
  );
}
