import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import FlowStepInputAddVariable from "src/containers/flow/FlowStepInputAddVariable";
import InputActionButton from "src/components/Form/InputActionButton";
import TextInputField from "src/components/Form/TextInputField";

interface FlowStepTextInputAddVariableProps {
  formik: FormikProps<any>;
  name: string;
  label?: string;
  help?: string;
  placeholder?: string;
  currentValue?: string;
  maxLength?: number;
  currentStepIndex: number;
}

export default function FlowStepTextInputAddVariable({
  formik,
  name,
  label,
  help,
  placeholder,
  currentStepIndex,
  currentValue = "",
  maxLength,
}: FlowStepTextInputAddVariableProps) {
  const { t } = useTranslation();
  return (
    <FlowStepInputAddVariable
      formik={formik}
      fieldName={name}
      currentStepIndex={currentStepIndex}
      currentValue={currentValue}
    >
      {(setShowVariableSelect) => (
        <TextInputField
          label={label}
          help={help}
          placeholder={placeholder}
          name={name}
          type="text"
          maxLength={maxLength}
          action={
            <InputActionButton
              onClick={() => setShowVariableSelect(true)}
              type="button"
            >
              {t("add-variable")}
            </InputActionButton>
          }
        />
      )}
    </FlowStepInputAddVariable>
  );
}
