import { InformationCircleIcon, ShareIcon } from "@heroicons/react/outline";
import { FIELDS } from "./FlowMeta";

export default function FlowFormStepNav({
  formik,
  submitted,
  currentStep,
  setCurrentStep,
}) {
  const stepHasErrors = (step) => {
    let errorFields = {
      attrs: [
        formik.errors[FIELDS.name.key],
        formik.errors[FIELDS.execution_type.key],
      ],
      steps: [formik.errors[FIELDS.steps.key]],
    };
    if (formik.errors && errorFields[step].some((x) => x)) {
      return true;
    }
    return false;
  };

  const steps = [
    {
      name: "Flow details",
      icon: <InformationCircleIcon className="mr-2 h-5 w-5" />,
      stepName: "attrs",
      current: currentStep === "attrs",
      hasErrors: stepHasErrors("attrs"),
    },
    {
      name: "Steps",
      icon: <ShareIcon className="mr-2 h-5 w-5 rotate-90" />,
      stepName: "steps",
      current: currentStep === "steps",
      hasErrors: stepHasErrors("steps"),
    },
  ];

  return (
    <nav aria-label="Progress">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-8">
        {steps.map((step) => (
          <li key={step.name} className="md:flex-1">
            {step.hasErrors ? (
              <button
                type="button"
                onClick={(_) => setCurrentStep(step.stepName)}
                className="group flex w-full flex-col border-l-4 border-yellow-500 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                aria-current="step"
              >
                <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-yellow-500">
                  {step.icon} {step.name}
                </span>
              </button>
            ) : (
              <>
                {step.current ? (
                  <button
                    type="button"
                    onClick={(_) => setCurrentStep(step.stepName)}
                    className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                    aria-current="step"
                  >
                    <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                      {step.icon} {step.name}
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    onClick={(_) => setCurrentStep(step.stepName)}
                    className="group flex w-full flex-col border-l-4 border-gray-200 pl-4 pb-2 hover:border-gray-300 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  >
                    <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-gray-600">
                      {step.icon} {step.name}
                    </span>
                  </button>
                )}
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
