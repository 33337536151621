import { useState } from "react";
import FlowAddVariableForm from "./FlowAddVariableForm";

export default function FlowStepAddVariable({
  formik,
  addVariable,
  currentStepIndex,
  listIndex = null,
}) {
  const [showVariableSelect, setShowVariableSelect] = useState(false);

  if (showVariableSelect) {
    return (
      <FlowAddVariableForm
        formik={formik}
        addVariable={addVariable}
        currentStepIndex={currentStepIndex}
        setShowVariableSelect={setShowVariableSelect}
        listIndex={listIndex}
      />
    );
  }

  return (
    <button
      className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
      type="button"
      onClick={(_) => setShowVariableSelect(true)}
    >
      Add variable
    </button>
  );
}
