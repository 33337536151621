import { Dispatch, SetStateAction, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon, VariableIcon } from "@heroicons/react/outline";
import { FieldArray, Formik, useFormikContext } from "formik";
import {
  ButtonComponents,
  URLButtonComponent,
  WhatsAppTemplate,
} from "@hilos/types/wa/templates";
import TextInputOrFileUploadField from "src/components/Form/TextInputOrFileUploadField";
import TextInputVariablesField from "src/components/Form/TextInputVariablesField";
import { getBaseUrl, getTemplateComponents } from "src/helpers/template";
import TextInputField from "../../components/Form/TextInputField";
import {
  getTemplateVarsFromString,
  getVariablesRequiredFromTemplate,
} from "./WhatsAppTemplateMeta";

export const initialTemplatePreviewValues = {
  headerURL: "",
  headerVar: "",
  bodyVars: [],
  buttonVars: [],
  locationHeaderVars: {
    latitude: "",
    longitude: "",
    name: "",
    address: "",
  },
};

export const initialTemplatePreviewAuthenticationValues = {
  locationHeaderVars: {
    name: "",
    address: "",
    latitude: "",
    longitude: "",
  },
  headerURL: "",
  headerVar: "",
  bodyVars: ["123456"],
  buttonVars: ["Copy code"],
};

const FormObserver = ({ onTemplateValuesChange }) => {
  const { values } = useFormikContext();
  useEffect(() => {
    if (!onTemplateValuesChange) {
      return;
    }
    onTemplateValuesChange(values);
  }, [values, onTemplateValuesChange]);
  return null;
};

interface WhatsAppTemplatePreviewFormProps {
  template: WhatsAppTemplate;
  onTemplateValuesChange: Dispatch<
    SetStateAction<{
      locationHeaderVars: {
        latitude: string;
        longitude: string;
        name: string;
        address: string;
      };
      headerURL: string;
      headerVar: string;
      bodyVars: never[];
      buttonVars: never[];
    }>
  >;
  initialValues?: {};
  enableVariables?: boolean;
  disableFileUpload?: boolean;
  isExample?: Boolean;
}

export default function WhatsAppTemplatePreviewForm({
  template,
  onTemplateValuesChange,
  initialValues,
  enableVariables,
  disableFileUpload,
  isExample = false,
}: WhatsAppTemplatePreviewFormProps) {
  const { t } = useTranslation();

  const { header, buttons } = getTemplateComponents(template);

  const {
    headerRequiresURL,
    isHeaderLocation,
    headerRequiresVariable,
    bodyVariables,
    buttonsVariables,
  } = getVariablesRequiredFromTemplate(template);

  return (
    <Formik
      initialValues={
        initialValues ? initialValues : initialTemplatePreviewValues
      }
      onSubmit={(_) => {}}
    >
      {(formik) => (
        <div className="space-y-4">
          <form noValidate onSubmit={formik.handleSubmit} className="space-y-4">
            {headerRequiresURL && header && header?.format !== "LOCATION" && (
              <TextInputOrFileUploadField
                media={header?.format}
                labelField={
                  <p className="text-sm font-medium text-gray-700">
                    {
                      // i18n.t("templates:header-file-text", "File for header text")
                      // i18n.t("templates:header-file-image", "File for header image")
                      // i18n.t("templates:header-file-video", "File for header video")
                      // i18n.t("templates:header-file-document", "File for header document")
                      t(`templates:header-file-${header?.format.toLowerCase()}`)
                    }
                  </p>
                }
                name="headerURL"
                placeholder={t("url", "URL")}
                label={
                  // i18n.t("templates:header-file-url-text", "URL for header text")
                  // i18n.t("templates:header-file-url-image", "URL for header image")
                  // i18n.t("templates:header-file-url-video", "URL for header video")
                  // i18n.t("templates:header-file-url-document", "URL for header document")
                  t(`templates:header-file-url-${header?.format.toLowerCase()}`)
                }
                type="url"
                icon={GlobeAltIcon}
                enableVariables={enableVariables}
                help={t(
                  "templates:images-aspect-ratio.help",
                  "Images taller than 1.91:1 aspect ratio are cropped vertically by WhatsApp (like in our preview). To communicate the crux in such images, plan to present the most important information at the center of the image."
                )}
                disableFileUpload={!!disableFileUpload}
              />
            )}
            {isHeaderLocation && !isExample && (
              <>
                <TextInputField
                  type="text"
                  name="locationHeaderVars.latitude"
                  placeholder={t(
                    "components.inputs.location.latitude.placeholder",
                    "37.483307"
                  )}
                  label={t("templates:header-location-latitude", "Latitude")}
                  icon={VariableIcon}
                />
                <TextInputField
                  type="text"
                  name="locationHeaderVars.longitude"
                  placeholder={t(
                    "components.inputs.location.longitude.placeholder",
                    "122.148981"
                  )}
                  label={t("templates:header-location-longitude", "Longitude")}
                  icon={VariableIcon}
                />
                <TextInputField
                  type="text"
                  name="locationHeaderVars.name"
                  placeholder={t(
                    "components.inputs.location.name.placeholder",
                    "Pablo Morales"
                  )}
                  label={t("templates:header-location-name", "Name")}
                  icon={VariableIcon}
                />
                <TextInputField
                  type="text"
                  name="locationHeaderVars.address"
                  placeholder={t(
                    "components.inputs.location.address.placeholder",
                    "1 Hacker Way, Menlo Park, CA 94025"
                  )}
                  label={t("templates:header-location-address", "Address")}
                  icon={VariableIcon}
                />
              </>
            )}

            {headerRequiresVariable &&
              (enableVariables ? (
                <TextInputVariablesField
                  placeholder={t(
                    "components.inputs.name.placeholder",
                    "John..."
                  )}
                  label={t(
                    "templates:header-value-for-variable",
                    "Header - Value for variable {{1}}"
                  )}
                  name="headerVar"
                  icon={VariableIcon}
                />
              ) : (
                <TextInputField
                  placeholder={t(
                    "components.inputs.name.placeholder",
                    "John..."
                  )}
                  label={t(
                    "templates:header-value-for-variable",
                    "Header - Value for variable {{1}}"
                  )}
                  name="headerVar"
                  type="text"
                  icon={VariableIcon}
                />
              ))}

            <FieldArray
              name="bodyVars"
              render={(_) => (
                <>
                  {bodyVariables.map((variable, idx) =>
                    enableVariables ? (
                      <TextInputVariablesField
                        key={idx}
                        placeholder={
                          template.category === "AUTHENTICATION"
                            ? "123456"
                            : t(
                                "components.inputs.fullname.placeholder",
                                "John Smith"
                              )
                        }
                        label={
                          template.category === "AUTHENTICATION"
                            ? t("templates:template-value-for-otp", "OTP Code")
                            : `${t(
                                "templates:template-value-for",
                                "Body - Value for variable"
                              )} ${variable}`
                        }
                        name={`bodyVars.${idx}`}
                        icon={VariableIcon}
                      />
                    ) : (
                      <TextInputField
                        key={idx}
                        placeholder={t(
                          "components.inputs.fullname.placeholder",
                          "John Smith"
                        )}
                        label={`${t(
                          "templates:template-value-for",
                          "Body - Value for variable"
                        )} ${variable}`}
                        name={`bodyVars.${idx}`}
                        type="text"
                        icon={VariableIcon}
                      />
                    )
                  )}
                </>
              )}
            ></FieldArray>
            <FieldArray
              name="buttonVars"
              render={(_) => (
                <>
                  {buttonsVariables.map((variable, idx) =>
                    enableVariables ? (
                      <TextInputVariablesField
                        key={idx}
                        placeholder="guide_es.pdf"
                        label={`${t(
                          "templates:button-variable-for-url",
                          "URL variable for button"
                        )} ${variable}`}
                        name={`buttonVars.${idx}`}
                        help={`${t("final-url", "Final URL:")} ${getBaseUrl(
                          idx,
                          buttons
                        )}${
                          formik.values[`buttonVars`][idx]
                            ? formik.values[`buttonVars`][idx]
                            : ""
                        }`}
                        icon={VariableIcon}
                      />
                    ) : (
                      <TextInputField
                        key={idx}
                        placeholder="guide_es.pdf"
                        label={`${t(
                          "templates:button-variable-for-url",
                          "URL variable for button"
                        )} "${variable}"`}
                        name={`buttonVars.${idx}`}
                        type="text"
                        help={`${t("final-url", "Final URL:")} ${getBaseUrl(
                          idx,
                          buttons
                        )}${
                          formik.values[`buttonVars`][idx]
                            ? formik.values[`buttonVars`][idx]
                            : ""
                        }`}
                        icon={VariableIcon}
                      />
                    )
                  )}
                </>
              )}
            ></FieldArray>

            <FormObserver onTemplateValuesChange={onTemplateValuesChange} />
          </form>
        </div>
      )}
    </Formik>
  );
}
