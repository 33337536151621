import { useTranslation } from "react-i18next";
import AvailableFlowSelector from "src/components/AvailableFlowSelector";
import { FlowStepTriggerFlowMeta } from "./Meta";

export default function FlowStepTriggerFlow({ formik, index }) {
  const { t } = useTranslation();
  return (
    <>
      <AvailableFlowSelector
        name={`steps.${index}.${FlowStepTriggerFlowMeta.FIELDS.next_flow.key}`}
        label={t("flows:steps.trigger-flow.label", "Next flow")}
        help={t("flows:steps.trigger-flow.help", "Select the flow to start.")}
        placeholder={t("type-to-search")}
      />
    </>
  );
}
