import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { WhatsAppMessage } from "@hilos/types/private-schema";
import { queryClient } from "../HilosProvider";
import { updateConversationContentData } from "../helpers/conversation";
import { getDataFromJSON } from "../helpers/utils";
import { API_ROUTES } from "../router/router";
import useHilosWebSockets from "./useHilosWebSockets";

interface UseInboxContactSyncParams {
  id?: string;
}

function useInboxContactSync({ id }: UseInboxContactSyncParams) {
  const navigate = useNavigate();

  const { lastJsonMessage } = useHilosWebSockets({
    path: API_ROUTES.WS_INBOX_CONTACT,
    params: { ":id": id },
  });

  useEffect(() => {
    if (lastJsonMessage) {
      const data = getDataFromJSON(lastJsonMessage.data);

      if (data) {
        if (lastJsonMessage.type === "conversation_content_data") {
          const event = data.event || null;
          const message = (data.message as WhatsAppMessage) || null;
          const inboxContactId = data.inbox_contact_id;

          if (inboxContactId) {
            const results = updateConversationContentData({
              id: data.id,
              timestamp: data.timestamp,
              content_type: data.content_type,
              inbox_contact_id: data.inbox_contact_id,
              event,
              message,
            });
            queryClient.setQueryData(
              ["conversation_content", inboxContactId],
              results
            );

            if (event?.event_type === "STATUS_CHANGE") {
              queryClient.invalidateQueries([
                "inbox_contact_details",
                inboxContactId,
              ]);
            }
          }
        }
      }
    }
  }, [lastJsonMessage, navigate]);
}

export default useInboxContactSync;
