import { API_ROUTES, buildAPIRoute, buildRoute } from "src/router/router";
import { ChatIcon, PlusSmIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";

import AccountStatusNotice from "src/components/Notice/AccountStatusNotice";
import BroadcastControlsButtons from "./BroadcastControlsButtons";
import BroadcastStatusBadge from "./BroadcastStatusBadge";
import ChannelBadge from "../config/channel/ChannelBadge";
import { DateTime } from "luxon";
import DeleteObject from "src/components/DeleteObject";
import EmptyStateComponent from "src/components/EmptyStates/EmptyStateComponent";
import HeaderNavigation from "src/components/HeaderNavigation";
import HelpDocsLink from "src/components/HelpDocsLink";
import { Link } from "react-router-dom";
import { MessageBlastRead } from "@hilos/types/private-schema";
import PermissionsChecker from "src/components/PermissionsCheck";
import Table from "src/components/Table/Table";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import useHilosStore from "src/hooks/useHilosStore";
import { useState } from "react";

export default function BroadcastList() {
  const { t } = useTranslation();
  const { session } = useHilosStore();

  const [triggerReload, setTriggerReload] = useState(false);
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestSubmitted, setRequestSubmitted] = useState(false);

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.MESSAGE_BLAST_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setRequestSubmitted(false);
      setRequestSuccess(true);
      setTriggerReload(!triggerReload);
    } catch (error) {
      const err = error as axiosErr;
      setRequestSuccess(false);
      if (err?.response?.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setTimeout(() => {
        setRequestSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setRequestSubmitted(false);
    }
  };

  return (
    <>
      <div className="h-screen overflow-y-auto bg-gray-50">
        <div className="shrink-0">
          <AccountStatusNotice />
        </div>
        {/* Header */}
        <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
          <HeaderNavigation
            navPages={[
              {
                name: "Home",
                url: buildRoute("dashboard"),
              },
              {
                name: "Broadcasts",
                url: buildRoute("broadcast-list"),
              },
            ]}
          />
          <div className="lg:flex lg:items-center lg:justify-between">
            <div className="min-w-0 flex-1">
              <Trans i18nKey="broadcasts:description">
                <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
                  <ChatIcon className="mr-2 h-7 w-7" aria-hidden="true" />
                  Broadcasts
                </h2>
                <p className="mt-1 text-sm text-gray-600">
                  Send a single template message to 1 or more phone numbers. Use
                  variables to personalize it!{" "}
                  <HelpDocsLink
                    href="https://hilos.io/docs/user/using-hilos/broadcasts/creating-a-broadcast"
                    className="ml-1 text-blue-400"
                  />
                </p>
              </Trans>
            </div>
            <div className="mt-2 flex md:mt-5 lg:mt-0 lg:ml-4">
              <span className="md:ml-3">
                <PermissionsChecker
                  permission="add_messageblast"
                  action={t(
                    "settings:permissions.add_messageblast",
                    "create new broadcasts"
                  )}
                >
                  <Link
                    to={buildRoute("broadcast-create")}
                    className="inline-flex items-center rounded-md border border-transparent bg-gradient-to-r from-hilos to-red-400 px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    New broadcast
                  </Link>
                </PermissionsChecker>
              </span>
            </div>
          </div>
        </div>

        <div className="min-h-screen bg-gray-50">
          <div className="h-full sm:px-6 sm:pt-8 lg:px-8">
            <Table<MessageBlastRead>
              baseURL={buildAPIRoute(API_ROUTES.MESSAGE_BLAST_CREATE_LIST)}
              paginated={true}
              triggerReload={triggerReload}
              filters={["channel"]}
              tableHead={
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("name")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("status")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("start-on")}
                    </th>
                    {session?.account.has_used_multiple_channels && (
                      <th
                        scope="col"
                        className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                      >
                        {t("channel", "Channel")}
                      </th>
                    )}
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("recipients")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("failed")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("sent")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("delivered")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    >
                      {t("read")}
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
                    ></th>
                  </tr>
                </thead>
              }
              tableRow={(broadcast) => (
                <>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
                    <Link
                      className="font-medium text-blue-600"
                      to={buildRoute("broadcast-detail", {
                        ":id": broadcast.id,
                      })}
                    >
                      {broadcast.name}
                    </Link>
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <BroadcastStatusBadge status={broadcast.status} />
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    <span>
                      <time className="item-created" dateTime="2020-03-22">
                        {DateTime.fromISO(
                          broadcast.start_on
                            ? (broadcast.start_on as string)
                            : (broadcast.created_on as string)
                        ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
                      </time>
                    </span>
                  </td>
                  {session?.account.has_used_multiple_channels && (
                    <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                      <ChannelBadge channel={broadcast.channel} />
                    </td>
                  )}
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {broadcast.recipients.length}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {broadcast.failed}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {broadcast.sent}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {broadcast.delivered}
                  </td>
                  <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-600">
                    {broadcast.read}
                  </td>
                  <td className="flex items-center px-4 py-3">
                    <div className="flex items-center space-x-2">
                      <BroadcastControlsButtons
                        broadcastId={broadcast.id.toString()}
                        broadcastStatus={broadcast.status}
                        onSuccess={() => setTriggerReload(!triggerReload)}
                      />
                      <DeleteObject
                        obj={broadcast}
                        objDescription={broadcast.name}
                        actionDescription="When deleting this broadcast, messages already accepted by WhatsApp but not yet delivered will still reach your recipients."
                        onDelete={onDelete}
                        deleteSuccess={requestSuccess}
                        deleteSubmitted={requestSubmitted}
                      />
                    </div>
                  </td>
                </>
              )}
              dataName={t("broadcasts", "Broadcasts")}
              noResults={
                <>
                  <h4>Couldn't find any broadcasts with these filters.</h4>
                  <p className="text-muted">Remove filters to try again.</p>
                </>
              }
              createObject={
                <EmptyStateComponent routeName="broadcasts">
                  <Link
                    to={buildRoute("broadcast-create")}
                    className="mt-2 inline-flex items-center rounded-md border border-transparent bg-hilos px-4 py-2 text-sm font-medium text-white shadow-sm  focus:ring-offset-2"
                  >
                    <PlusSmIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    New broadcast
                  </Link>
                </EmptyStateComponent>
              }
            ></Table>
          </div>
        </div>
      </div>
    </>
  );
}
