import { useState } from "react";
import { FormikProps } from "formik";
import FlowStepContactUpdateCustom from "src/containers/flow/form/steps/contact_update/FlowStepContactUpdateCustom";
import FlowStepContactUpdateDefault from "src/containers/flow/form/steps/contact_update/FlowStepContactUpdateDefault";
import SelectorField from "src/components/Form/SelectorField";
import FlowStepContactUpdateNav from "./FlowStepContactUpdateNav";
import { FlowStepContactUpdateMeta } from "./Meta";

interface FlowStepContactUpdateProps {
  formik: FormikProps<any>;
  index: number;
  availableGroupedSteps: (x: number) => { value: string; label: string }[];
}

export default function FlowStepContactUpdate({
  formik,
  index,
  availableGroupedSteps,
}: FlowStepContactUpdateProps) {
  const [currentStep, setCurrentStep] = useState("default");

  return (
    <>
      <FlowStepContactUpdateNav
        index={index}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        formik={formik}
      />

      {currentStep === "default" && (
        <FlowStepContactUpdateDefault formik={formik} index={index} />
      )}
      {currentStep === "additional" && (
        <FlowStepContactUpdateCustom formik={formik} index={index} />
      )}

      <SelectorField
        label={FlowStepContactUpdateMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepContactUpdateMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepContactUpdateMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
      />
    </>
  );
}
