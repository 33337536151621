// @flow
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  DocumentTextIcon,
  FilterIcon,
  PencilIcon,
  PhotographIcon,
  UploadIcon,
} from "@heroicons/react/outline";
import * as yup from "yup";
import { FlowExecutionSimple } from "@hilos/types/flows/FlowExecution";
import {
  FlowDetailRead,
  FlowExecutionExecutionTypeEnum,
  FlowExecutionReadDetail,
} from "@hilos/types/private-schema";
import RobotIcon from "src/components/icons/RobotIcon";
import { getTranslationPayload as t } from "src/i18n";
import { ERROR_MESSAGES } from "../../../constants/Form";
import * as comparisonMeta from "../ComparisonMeta";

export const getInitialValues = (flow: FlowDetailRead) => {
  return {
    start_on: "",
    execution_type: flow.current_version
      .execution_type as unknown as FlowExecutionExecutionTypeEnum,
    execute_for: flow.current_version.execution_type === "INBOUND" ? "ALL" : "",
    contact_filters: [],
    contact_list: [],
    inbound_trigger_with: "TEXT",
    inbound_start_message: "",
    inbound_start_message_match_exact: true,
    flow_execution_variables: Object.fromEntries(
      flow.flow_execution_variables?.map((variable) => [variable, ""]) ?? []
    ),
    source: "FRONTEND",
  } as Partial<FlowExecutionReadDetail>;
};

/**
 * This function is used in the `FlowExecutionUpdate.tsx` to handle when there
 * are new `flow_execution_variables` added to the `flow` so they are also added
 * to the `flowExecution` and the inputs appear and are required when they try
 * to update the `flowExecution`
 * @param flowExecution
 * @returns FlowExecutionReadDetail
 */
export const updateFlowExecutionVariables = (
  flowExecution: FlowExecutionReadDetail
): FlowExecutionReadDetail => {
  return {
    ...flowExecution,
    flow_execution_variables: {
      ...Object.fromEntries(
        flowExecution.flow.flow_execution_variables?.map((variable) => [
          variable,
          "",
        ]) ?? []
      ),
      ...(flowExecution.flow_execution_variables ?? {}),
    },
  };
};

export const getContactListInitialValues = () => {
  return {
    source: "",
    contact_list: [comparisonMeta.getInitialValues()],
    contact_file: {
      upload_group_id: "",
    },
  };
};

const contactListSchema = yup.object().shape({
  source: yup.string().required(ERROR_MESSAGES.required),
  contact_list: yup.mixed().when("source", {
    is: "LIST",
    then: yup.array().min(1, ERROR_MESSAGES.minNumber),
  }),
  contact_file: yup.mixed().when("source", {
    is: "FILE",
    then: yup.object().shape({
      upload_group_id: yup.string().required(ERROR_MESSAGES.required),
    }),
  }),
});

function getSchemaFlowExecutionVariables(flow_execution: FlowExecutionSimple) {
  if (flow_execution.flow_execution_variables) {
    return yup
      .object()
      .shape(
        Object.fromEntries(
          Object.keys(flow_execution.flow_execution_variables).map(
            (variableKey) => [variableKey, yup.string().required()]
          )
        )
      );
  }
  return yup.mixed().notRequired();
}

export const schema = yup.lazy((values: FlowExecutionSimple) => {
  return yup.object().shape({
    start_on: yup.date().min(Date(), ERROR_MESSAGES.minDate).nullable(),
    execution_type: yup.string().required(ERROR_MESSAGES.required),
    flow_execution_variables: getSchemaFlowExecutionVariables(values),
    execute_for: yup.string().required(ERROR_MESSAGES.required),
    inbound_trigger_with: yup.string().when("execution_type", {
      is: "INBOUND",
      then: yup.string().required(ERROR_MESSAGES.required),
    }),
    inbound_start_message: yup
      .string()
      .when(["inbound_trigger_with", "execution_type"], {
        is: (trigger, execution_type) =>
          trigger === "TEXT" && execution_type === "INBOUND",
        then: yup.string().required(ERROR_MESSAGES.required),
      }),
    inbound_start_message_match_exact: yup.bool(),
    contact_filters: yup.mixed().when("execution_type", {
      is: "OUTBOUND",
      then: yup.array().of(comparisonMeta.schema),
    }),
    contact_lists: yup.mixed().when("execution_type", {
      is: "OUTBOUND",
      then: yup.array().of(contactListSchema),
    }),
    has_priority: yup.bool(),
  });
});

export const FIELDS = {
  start_on: {
    key: "start_on",
    label: t("flow-executions:input.start_on.label", "Start on"),
    optional: true,
    placeholder: "",
    help: t(
      "flow-executions:input.start_on.help",
      "Schedule this flow to run on this date, if the flow is ready to run."
    ),
  },
  execution_type: {
    key: "execution_type",
    label: t(
      "flow-executions:input.execution_type.label",
      "How will this flow start?"
    ),
  },
  flow_execution_variables: {
    key: "flow_execution_variables",
    label: t(
      "flow-executions:input.flow_execution_variables.label",
      "Execution variables"
    ),
    help: t(
      "flow-executions:input.flow_execution_variables.help",
      "This variables were declared when the flow was created and they are required for it to function correctly"
    ),
  },
  execute_for: {
    key: "execute_for",
    label: t(
      "flow-executions:input.execute_for.label",
      "Who should this flow run with?"
    ),
    help: t(
      "flow-executions:input.execute_for.help",
      "Choose between uploading a .csv or filtering existing contacts."
    ),
  },
  inbound_trigger_with: {
    key: "inbound_trigger_with",
    label: t(
      "flow-executions:input.inbound_trigger_with.label",
      "How should we trigger this flow?"
    ),
  },
  inbound_start_message: {
    key: "inbound_start_message",
    label: t(
      "flow-executions:input.inbound_start_message.label",
      "Text to match"
    ),
    placeholder: "Welcome to ACME Corp, how can I help you?",
    help: t(
      "flow-executions:input.inbound_start_message.help",
      "Text to look for in inbound messages to start this flow"
    ),
  },
  inbound_start_message_match_exact: {
    key: "inbound_start_message_match_exact",
    label: t(
      "flow-executions:input.inbound_start_message_match_exact.label",
      "Match exact message?"
    ),
    help: t(
      "flow-executions:input.inbound_start_message_match_exact.help",
      "If not, we'll check if the message received contains the above text."
    ),
  },
  contact_filters: {
    key: "contact_filters",
    label: t("flow-executions:input.contact_filters.label", "Contact filters"),
  },
  contact_list: {
    key: "contact_list",
    label: t("flow-executions:input.contact_list.label", "Contact lists"),
  },
  contact_list_file: {
    key: "contact_list_file",
    label: t("flow-executions:input.contact_list_file.label", "Contact lists"),
  },
  has_priority: {
    key: "has_priority",
    label: t(
      "flow-executions:input.has_priority.label",
      "Run flow even if there are open conversations?"
    ),
    help: t(
      "flow-executions:input.has_priority.help",
      "If set, this flow will automatically close any open conversations for all contacts in this execution."
    ),
  },
};

export const FLOW_EXECUTION_TYPES = [
  {
    label: t(
      "flow-executions:flow-execution-type.inbound.label",
      "Inbound message"
    ),
    value: "INBOUND",
    icon: <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:flow-execution-type.inbound.help",
      "Triggers when we get a text containing a specific message from a customer."
    ),
  },
  {
    label: t(
      "flow-executions:flow-execution-type.outbound.label",
      "Outbound message"
    ),
    value: "OUTBOUND",
    icon: <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:flow-execution-type.outbound.help",
      "Can be triggered with existing contacts, a .csv upload, or via our API."
    ),
  },
];

export const FLOW_EXECUTION_FOR = [
  {
    label: t(
      "flow-executions:flow-execution-for.filter.label",
      "From existing contacts"
    ),
    value: "FILTERS",
    icon: <FilterIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:input.flow-execution-for.filter.help",
      "Filter from your contacts and save this segmentation."
    ),
  },
  {
    label: t("flow-executions:flow-execution-for.upload.label", "Upload .csv"),
    value: "LIST",
    icon: <UploadIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:input.flow-execution-for.upload.help",
      "Upload a file with contacts, and run this flow for them."
    ),
  },
];

export const INBOUND_TRIGGER_WITH = [
  {
    label: t("flow-executions:trigger-with.any-message.label", "Any message"),
    value: "ANY_MESSAGE",
    banner: (
      <span className="px-2 rounded-lg ml-2 text-xs py-1 flex bg-blue-50 text-blue-500">
        <RobotIcon className="w-3 h-3 self-center mr-1" />
        Chatbot
      </span>
    ),
    icon: <CheckIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:trigger-with.any-message.help",
      "Will trigger anytime a customer messages you and there is no other conversation / flow active."
    ),
  },
  {
    label: t(
      "flow-executions:trigger-with.specific-text.label",
      "Specific text"
    ),
    value: "TEXT",
    icon: <PencilIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:trigger-with.specific-text.help",
      "Trigger when the message contains a specific text"
    ),
  },
  {
    label: t("flow-executions:trigger-with.image.label", "Image"),
    value: "IMAGE",
    icon: <PhotographIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:trigger-with.image.help",
      "Trigger when the message contains an image"
    ),
  },
  {
    label: t("flow-executions:trigger-with.file.label", "File"),
    value: "FILE",
    icon: <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />,
    help: t(
      "flow-executions:trigger-with.file.help",
      "Trigger when the message contains a file"
    ),
  },
];
