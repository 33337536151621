import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { GlobeAltIcon, VariableIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import { URLButtonComponent } from "@hilos/types/wa/templates";
import TextInputOrFileUploadField from "src/components/Form/TextInputOrFileUploadField";
import { getBaseUrl, getTemplateComponents } from "src/helpers/template";
import InputActionButton from "../../../../../components/Form/InputActionButton";
import TextInputField from "../../../../../components/Form/TextInputField";
import {
  getTemplateVarsFromString,
  getVariablesRequiredFromTemplate,
} from "../../../../wa/WhatsAppTemplateMeta";
import FlowStepInputAddVariable from "../../../FlowStepInputAddVariable";

export default function FlowStepTemplateForm({
  template,
  formik,
  previewValues,
  formPrefix,
  index,
}) {
  const { header, buttons } = getTemplateComponents(template);
  const { t } = useTranslation();

  const {
    headerRequiresURL,
    headerRequiresVariable,
    bodyVariables,
    buttonsVariables,
  } = getVariablesRequiredFromTemplate(template);

  return (
    <div className="space-y-4">
      {headerRequiresURL && header && header?.format !== "LOCATION" && (
        <TextInputOrFileUploadField
          media={header?.format}
          labelField={
            <p className="text-sm font-medium text-gray-700">
              {
                // i18n.t("templates:header-file-text", "File for header text")
                // i18n.t("templates:header-file-image", "File for header image")
                // i18n.t("templates:header-file-video", "File for header video")
                // i18n.t("templates:header-file-document", "File for header document")
                t(`templates:header-file-${header?.format.toLowerCase()}`)
              }
            </p>
          }
          name={`${formPrefix}.headerURL`}
          placeholder="URL"
          label={
            // i18n.t("templates:header-file-url-text", "URL for header text")
            // i18n.t("templates:header-file-url-image", "URL for header image")
            // i18n.t("templates:header-file-url-video", "URL for header video")
            // i18n.t("templates:header-file-url-document", "URL for header document")
            t(`templates:header-file-url-${header?.format.toLowerCase()}`)
          }
          type="url"
          icon={GlobeAltIcon}
          enableVariables={true}
          help={t(
            "templates:images-aspect-ratio.help",
            "Images taller than 1.91:1 aspect ratio are cropped vertically by WhatsApp (like in our preview). To communicate the crux in such images, plan to present the most important information at the center of the image."
          )}
        />
      )}
      {headerRequiresVariable && (
        <FlowStepInputAddVariable
          formik={formik}
          currentStepIndex={index}
          fieldName={`${formPrefix}.headerVar`}
        >
          {(setShowVariableSelect) => (
            <TextInputField
              placeholder={t("components.inputs.name.placeholder", "John...")}
              label={t(
                "templates:header-value-for-variable",
                "Header - Value for variable {{1}}"
              )}
              name={`${formPrefix}.headerVar`}
              type="text"
              icon={VariableIcon}
              action={
                <InputActionButton
                  onClick={() => setShowVariableSelect(true)}
                  type="button"
                >
                  {t("add-variable")}
                </InputActionButton>
              }
            />
          )}
        </FlowStepInputAddVariable>
      )}
      <FieldArray
        name={`${formPrefix}.bodyVars.key`}
        render={(_) => (
          <>
            {bodyVariables.map((variable, idx) => (
              <Fragment key={idx}>
                <FlowStepInputAddVariable
                  formik={formik}
                  currentStepIndex={index}
                  fieldName={`${formPrefix}.bodyVars.${idx}`}
                >
                  {(setShowVariableSelect) => (
                    <TextInputField
                      placeholder={t(
                        "components.inputs.fullname.placeholder",
                        "John Smith"
                      )}
                      label={`${t(
                        "templates:template-value-for",
                        "Body - Value for variable"
                      )} ${variable}`}
                      name={`${formPrefix}.bodyVars.${idx}`}
                      type="text"
                      icon={VariableIcon}
                      action={
                        <InputActionButton
                          onClick={() => setShowVariableSelect(true)}
                          type="button"
                        >
                          {t("add-variable")}
                        </InputActionButton>
                      }
                    />
                  )}
                </FlowStepInputAddVariable>
              </Fragment>
            ))}
          </>
        )}
      ></FieldArray>
      <FieldArray
        name={`${formPrefix}.buttonVars.key`}
        render={(_) => (
          <>
            {buttonsVariables.map((variable, idx) => (
              <Fragment key={idx}>
                <FlowStepInputAddVariable
                  formik={formik}
                  currentStepIndex={index}
                  fieldName={`${formPrefix}.buttonVars.${idx}`}
                >
                  {(setShowVariableSelect) => (
                    <TextInputField
                      placeholder="guide_es.pdf"
                      label={`${t(
                        "templates:button-variable-for-url",
                        "URL variable for button"
                      )} ${variable}`}
                      name={`${formPrefix}.buttonVars.${idx}`}
                      type="url"
                      help={`Final URL: ${getBaseUrl(idx, buttons)}${
                        previewValues?.buttonVars[idx]
                      }`}
                      icon={VariableIcon}
                      action={
                        <InputActionButton
                          onClick={() => setShowVariableSelect(true)}
                          type="button"
                        >
                          {t("add-variable")}
                        </InputActionButton>
                      }
                    />
                  )}
                </FlowStepInputAddVariable>
              </Fragment>
            ))}
          </>
        )}
      ></FieldArray>
    </div>
  );
}
