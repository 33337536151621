import Select from "react-select";
import {
  CodeIcon,
  DotsHorizontalIcon,
  MenuIcon,
} from "@heroicons/react/outline";
import { FlowStepActionMeta } from "./Meta";

export default function FlowStepActionSelectBody({ setContentType, bodyType }) {
  const getAvailableOptions = () => {
    const structuredTypes = FlowStepActionMeta.BODY_TYPES.filter(
      (type) => type.type === "STRUCTURED"
    );
    const textTypes = FlowStepActionMeta.BODY_TYPES.filter(
      (type) => type.type === "TEXT"
    );
    const otherTypes = FlowStepActionMeta.BODY_TYPES.filter(
      (type) => type.type === "OTHER"
    );
    const groupedVars = [
      {
        label: "Structured",
        icon: MenuIcon,
        options: structuredTypes,
      },
      {
        label: "Text",
        icon: CodeIcon,
        options: textTypes,
      },
      {
        label: "Other",
        icon: DotsHorizontalIcon,
        options: otherTypes,
      },
    ];
    return groupedVars;
  };

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon className=" h-4 w-4 text-hilos" />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  const getDefaultValue = () => {
    let defaultValue = FlowStepActionMeta.BODY_TYPES.find(
      (type) => type.value === bodyType.value
    );
    if (!defaultValue) {
      return FlowStepActionMeta.BODY_TYPES.find(
        (type) => type.value === "NONE"
      );
    } else {
      return defaultValue;
    }
  };

  return (
    <>
      <div className="grow">
        <Select
          options={getAvailableOptions()}
          formatGroupLabel={formatGroupLabel}
          defaultValue={getDefaultValue}
          onChange={(option) => setContentType(option.value)}
          menuPortalTarget={document.body}
          menuPosition="fixed"
          menuPlacement="auto"
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (base) => ({
              ...base,
              borderColor: "#ddd",
              "&:hover": {
                borderColor: "#ddd",
              },
              boxShadow: "none",
              "*": {
                boxShadow: "none !important",
              },
            }),
            option: (base, { isFocused, isSelected }) => {
              return {
                ...base,
                wordBreak: "break-all",
                backgroundColor: isSelected
                  ? "#d54466"
                  : isFocused
                  ? "#d54466"
                  : undefined,
                color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
                fontSize: "small",
                paddingLeft: "30px",
              };
            },
          }}
        />
      </div>
    </>
  );
}
