import * as meta from "./Meta";

import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { FieldArray, Formik, FormikHelpers } from "formik";
import { PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { useEffect, useMemo, useState } from "react";

import APIErrors from "src/components/base/APIErrors";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { UserBulkInvitation } from "@hilos/types/private-schema";
import UserInvitationV2ConfirmationModal from "./UserInvitationV2ConfirmationModal";
import axios from "axios";
import { axiosErr } from "@hilos/types/axios";
import { classNames } from "src/Helpers";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { useRoles } from "src/hooks/useRole";
import { useTranslation } from "react-i18next";

export default function UserInvitationV2() {
  const { t } = useTranslation();
  const { session, reloadSession } = useHilosStore();
  const roles = useRoles();
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const timeout = useCleanTimeout();

  // Reload session on navigate
  useEffect(() => {
    reloadSession();
  }, [reloadSession]);

  const rolesOptions = useMemo(() => {
    return roles.map((role) => ({
      label: t(`settings:permissions.role.${role.name.toLowerCase()}`),
      value: role.id,
    }));
  }, [roles, t]);

  const onSubmit = async (
    formData,
    formikHelpers: FormikHelpers<UserBulkInvitation>
  ) => {
    formikHelpers.setSubmitting(true);
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.post(
        buildAPIRoute(API_ROUTES.USER_INVITATION_LIST_CREATE),
        formData
      );
      setSuccess(true);
      reloadSession();
      timeout.current = setTimeout(() => {
        setSubmitted(false);
        formikHelpers.resetForm();
      }, 1000 * 2);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:users.invite.error-creating-invitation",
            "An error occurred while sending the invitations. Please try again."
          )
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      formikHelpers.setSubmitting(false);
      setSubmitted(true);
    }
  };

  if (!session) {
    return null;
  }

  return (
    <Formik<UserBulkInvitation>
      validationSchema={meta.schema}
      onSubmit={(values, formikHelpers) => onSubmit(values, formikHelpers)}
      enableReinitialize={true}
      initialValues={meta.getInitialValues(roles)}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  {t("settings:users.invite.title", "Invite additional users")}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {session?.account.current_subscription ? (
                    <>
                      {t(
                        "settings:users.invite.subtitle",
                        "Your plan includes {{usersIncluded}} users. Inviting more users will add an extra charge to your subscription.",
                        {
                          usersIncluded:
                            session?.account.users_price?.included_items || 0,
                        }
                      )}
                    </>
                  ) : (
                    <>
                      {t(
                        "settings:users.invite.subtitle-no-subscription",
                        "You'll see any additional users to your plan when you start your subscription."
                      )}
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="shadow sm:overflow-hidden sm:rounded-md">
                <div className="space-y-4 bg-white px-4 py-5 sm:p-6">
                  <APIErrors
                    APIError={backendError}
                    APIValidationErrors={backendValidationErrors}
                    fieldTranslations={meta.FIELDS}
                  />

                  <FieldArray
                    name="invitations"
                    render={(arrayHelpers) => (
                      <>
                        <ul className="space-y-2">
                          {formik.values.invitations &&
                            formik.values.invitations.length > 0 &&
                            formik.values.invitations.map((_, idx) => (
                              <>
                                <div
                                  key={idx}
                                  className="flex items-start space-x-4"
                                >
                                  <TextInputField
                                    name={`invitations.${idx}.email`}
                                    label={
                                      idx === 0
                                        ? t(
                                            "settings:invitations.email.label",
                                            "Email"
                                          )
                                        : undefined
                                    }
                                    placeholder="tim@apple.com"
                                    type="email"
                                  />
                                  <div className="w-48">
                                    <SelectorField
                                      options={rolesOptions}
                                      name={`invitations.${idx}.role`}
                                      label={
                                        idx === 0
                                          ? t(
                                              "settings:invitations.role.label",
                                              "Role"
                                            )
                                          : undefined
                                      }
                                    />
                                  </div>

                                  <div className="ml-4 grow-0">
                                    <button
                                      type="button"
                                      className={classNames(
                                        idx === 0 ? "mt-6" : "",
                                        "inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                      )}
                                      onClick={(_) => arrayHelpers.remove(idx)}
                                    >
                                      <TrashIcon
                                        className="h-4 w-4"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </>
                            ))}
                        </ul>

                        <div>
                          <button
                            type="button"
                            className="inline-flex items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                            onClick={(_) => {
                              arrayHelpers.push({ key: "", value: "" });
                            }}
                          >
                            <PlusSmIcon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                            {t("add-more", "Add more")}
                          </button>
                        </div>
                      </>
                    )}
                  />

                  <UserInvitationV2ConfirmationModal
                    formik={formik}
                    success={success}
                    submitted={submitted}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
}
