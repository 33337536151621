import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import { ClipboardIcon, PlusIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import useHilosStore from "src/hooks/useHilosStore";
import { AccountStatus } from "src/types/account";

export default function ConnectToSandboxMenu() {
  const { t } = useTranslation();
  const QRSandboxCode = localStorage.getItem("qr_sandbox_code");
  const { session, reloadSession } = useHilosStore();
  const urlWhatsAppCode = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_SANDBOX_NUMBER}&text=${session?.account.generated_code}`;
  const [isCopied, setIsCopied] = useState(false);

  const generateQRCode = useCallback(() => {
    QRCode.toDataURL(
      urlWhatsAppCode,
      {
        width: 170,
        margin: 2,
        color: {
          dark: "#486581",
          light: "#ffffff",
        },
      },
      (err, url) => {
        if (err) return captureException(err);
        localStorage.setItem("qr_sandbox_code", url);
      }
    );
  }, [urlWhatsAppCode]);

  const handleCopyClick = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = urlWhatsAppCode;

      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  };

  useEffect(() => {
    if (session?.account.status === AccountStatus.NEW || !QRSandboxCode) {
      generateQRCode();
    }
  }, [session?.account.status]);

  useEffect(() => {
    const interval = setInterval(() => {
      reloadSession();
    }, 1000 * 3);
    return () => clearInterval(interval);
  }, [reloadSession]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <div className="flex flex-row justify-center">
          <Popover.Button>
            {
              <div className="rounded-md border border-gray-300 border-dashed flex items-center py-2 px-4 text-xs font-medium text-gray-400">
                <PlusIcon className="w-4 h-4 mr-2" />
                {t(
                  "dashboard:sandbox.add-new-number",
                  "Add new number to sandbox"
                )}
              </div>
            }
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute w-[300px] h-[300px] left-1/2 z-50 mt-8 -translate-x-1/2 translate-y-2 transform px-4">
              <div className="overflow-hidden rounded-lg bg-white p-2 shadow">
                <div className="flex-col">
                  <div>
                    <div className="font-bold my-2 text-center text-gray-800 justify-self-center">
                      {t(
                        "settings:onboarding.connect-new-number-sandbox",
                        "Connect a new number to Sandbox"
                      )}
                    </div>
                    <div className="text-xs mb-2 text-center text-gray-400 justify-self-center">
                      {t(
                        "settings:onboarding.scan-or-click-to-connect",
                        "Scan the QR or click in the button to connect it using a computer."
                      )}
                    </div>
                    <div className="inline-flex w-full items-center rounded-lg ">
                      {QRSandboxCode ? (
                        <img
                          className="mx-auto rounded-lg h-40 p-3"
                          src={QRSandboxCode}
                          alt="qr-code"
                        />
                      ) : (
                        <div className="m-2 flex justify-center h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                          {t("loading")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <div className="w-full flex justify-center">
                      <a
                        href={urlWhatsAppCode}
                        rel="noreferrer"
                        target="_blank"
                        className="spread-shadow-gree h-fit mt-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white self-center"
                      >
                        <FontAwesomeIcon
                          // @ts-ignore
                          icon={faWhatsapp}
                          aria-hidden="true"
                          className="mr-2"
                        />
                        {t("send-whatsapp", "Send WhatsApp")}
                      </a>
                    </div>
                    <div className="w-full flex justify-center mb-10 mt-4">
                      <button
                        onClick={handleCopyClick}
                        disabled={isCopied}
                        className=" border-dashed border-gray-700 border rounded-md px-2 py-1 flex text-xs text-gray-700 font-semibold"
                      >
                        <ClipboardIcon className="w-4 h-4 mr-2" />
                        {isCopied
                          ? t("dashboard:sandbox.copied-link", "Link copied!")
                          : t("dashboard:sandbox.copy-link", "Copy link")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </div>
      )}
    </Popover>
  );
}
