import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import PermissionsChecker from "src/components/PermissionsCheck";
import { transformNullsToUndefined } from "../../Helpers";
import HeaderNavigation from "../../components/HeaderNavigation";
import { getFlowData } from "../../helpers/utils";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import FlowForm from "./FlowForm";
import { massageFlow } from "./Helpers";

export default function FlowUpdate() {
  const navigate = useNavigate();
  const params = useParams();
  const { t } = useTranslation();
  const posthog = usePostHog();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [flow, setFlow] = useState(undefined);
  const [formSaveAction, setFormSaveAction] = useState("save&run");

  const getFlow = useCallback(async () => {
    try {
      const response = await axios.get(
        buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
          ":id": params.id,
        })
      );

      if (
        response.data &&
        (!response.data.is_legacy || response.data.upgrade_version)
      ) {
        navigate(
          buildRoute("flow-editor", {
            ":id": response.data.id,
          })
        );
      }

      transformNullsToUndefined(response.data);
      setFlow(massageFlow(response.data));
    } catch (err) {
      Sentry.captureException(err);
      console.log(err);
    }
  }, [params.id, navigate]);

  useEffect(() => {
    getFlow();
  }, [getFlow]);

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const response = await axios.put(
        buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
          ":id": params.id,
        }),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      posthog?.capture("flow updated", {
        flow_name: response?.data?.name,
        flow_id: response?.data?.id,
      });
      setTimeout(() => {
        if (formSaveAction === "save") {
          navigate(buildRoute("flow-list"));
        } else if (formSaveAction === "save&run") {
          navigate(
            buildRoute("flow-execution-create", { ":id": response.data.id })
          );
        }
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      posthog?.capture("flow update failed", {
        formData: formData,
        error: JSON.stringify(err),
      });
      if (err.response.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(err.response.data);
      } else {
        setBackendError(
          "An error occurred while updating your flow. Please try again."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:px-6 lg:px-8 ">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: "Flows",
              url: buildRoute("flow-list"),
            },
            {
              name: `Update flow ${flow?.name}`,
              url: buildRoute("flow-update", { ":id": flow?.id }),
            },
          ]}
        />
      </div>

      {flow && (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl">
            <PermissionsChecker
              permission="change_flow"
              action={t("settings:permissions.change_flow", "update flows")}
            >
              <FlowForm
                flow={getFlowData(flow)}
                formSubmit={formSubmit}
                success={success}
                submitted={submitted}
                formSaveAction={formSaveAction}
                setFormSaveAction={setFormSaveAction}
              />
            </PermissionsChecker>
          </div>
        </div>
      )}
    </div>
  );
}
