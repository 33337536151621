import { FlowSimple } from "@hilos/types/flows/Flow";
import { UserData } from "@hilos/types/hilos";

export enum FlowExecutionStatus {
  READY = "READY",
  RUNNING = "RUNNING",
  COMPLETED = "COMPLETED",
  CANCELED = "CANCELED",
  CONFIRMED = "CONFIRMED",
}

export enum ExecutionFor {
  FILTERS = "FILTERS",
  LIST = "LIST",
  ALL = "ALL",
}

export enum ExecutionType {
  INBOUND = "INBOUND",
  OUTBOUND = "OUTBOUND",
  API = "API",
}

export enum InboundTrigger {
  ANY_MESSAGE = "ANY_MESSAGE",
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  FILE = "FILE",
}

export interface FlowExecutionStatusPayload {
  status: FlowExecutionStatus;
}

export interface ContactFilter {
  field?: string;
  comparison?: string;
  value?: string;
  id: string;
}

export interface ContactList {
  file_contacts: unknown[];
  list_contacts: unknown[];
  source: string;
}

/**
 * `FlowExecution` structure from `FlowExecutionReadSimpleSerializer`
 * @see `api/serializers/flow_execution.py`
 */
export interface FlowExecutionSimple {
  status: FlowExecutionStatus;
  execute_for: ExecutionFor;
  execution_type: ExecutionType;
  created_by: UserData;
  inbound_start_message?: string;
  inbound_start_message_match_exact: boolean;
  contact_lists?: ContactList[];
  contact_executions?: unknown[];
  id: string;
  pending: number;
  expired: number;
  active: number;
  completed: number;
  inbound_trigger_with?: InboundTrigger;
  created_on: string;
  last_updated_on: string;
  /** Is it really sent? It doesn't appear in the Meta `fields` list */
  start_on?: string;
  flow_execution_variables?: Record<string, unknown>;
  num_contacts: number;
  last_run_on?: string;
}

/**
 * `FlowExecution` structure from `FlowExecutionReadDetailSerializer`
 * @see `api/serializers/flow_execution.py`
 */
export interface FlowExecution extends FlowExecutionSimple {
  contact_filters?: ContactFilter[];
  flow: FlowSimple;
}
