import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Switch } from "@headlessui/react";
import {
  CollectionIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
  PencilAltIcon,
  ReplyIcon,
  ShareIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/outline";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { DateTime } from "luxon";
import Tabs from "src/components/Tabs";
import RobotIcon from "src/components/icons/RobotIcon";
import useFlow from "src/hooks/useFlow";
import { useFlowExecutions } from "src/hooks/useFlowExecution";
import useHilosStore from "src/hooks/useHilosStore";
import { axiosErr } from "src/types/axios";
import { classNames, userToString } from "../../Helpers";
import DeleteObject from "../../components/DeleteObject";
import HeaderNavigation from "../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../router/router";
import ChannelBadge from "../config/channel/ChannelBadge";
import FlowAnalyticsSummary from "./FlowAnalyticsSummary";
import FlowDetailInboundRunInstructions from "./FlowDetailInboundRunInstructions";
import FlowDetailOutboundRunInstructions from "./FlowDetailOutboundRunInstructions";
import InactiveFlowNotice from "./InactiveFlowNotice";
import FlowExecutionList from "./flow_execution/FlowExecutionList";
import FlowExecutionSummaryInbound from "./flow_execution/FlowExecutionSummaryInbound";
import FlowExecutionContactList from "./flow_execution_contact/FlowExecutionContactList";
import FlowVersionList from "./flow_version/FlowVersionList";

export default function FlowDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const flowId = params.id as string;
  const { session } = useHilosStore();

  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteSubmitted, setDeleteSubmitted] = useState(false);

  const { flow, updateFlow, refetchFlow } = useFlow(flowId);
  const flowExecutions = useFlowExecutions(flowId);

  const onDelete = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
          ":id": formData.obj.id,
        })
      );
      setDeleteSubmitted(false);
      setDeleteSuccess(true);
      navigate(buildRoute("flow-list"));
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setDeleteSuccess(false);
      if (errorAxios?.response?.status === 400) {
        Sentry.captureException(errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError("An error occurred, please try again.");
      }
      setTimeout(() => {
        setDeleteSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setDeleteSubmitted(false);
    }
  };

  const discardUnpublishedChanges = useCallback(async () => {
    try {
      if (!flow) {
        return;
      }
      await axios.patch(
        buildAPIRoute(API_ROUTES.FLOW_AUTOSAVE, {
          ":id": flow?.id,
        }),
        { current_draft: null, upgrade_version: null }
      );
      refetchFlow();
    } catch (err) {
      console.log("error", err);
    }
  }, [flow, refetchFlow]);

  const activeInboundFlowExecutions = useMemo(() => {
    if (!flowExecutions.results) {
      return [];
    }
    return flowExecutions.results?.filter(
      (fe) =>
        fe.flow_version === flow?.current_version.id &&
        fe.execution_type === "INBOUND" &&
        ["READY", "RUNNING"].includes(fe.status as string)
    );
  }, [flowExecutions, flow?.current_version.id]);

  const handleToggleActive = useCallback(() => {
    if (!flow) {
      return;
    }
    updateFlow({ is_active: !flow.is_active });
  }, [flow, updateFlow]);

  if (!flow) {
    return null;
  }

  const flowHasBeenPublished =
    flow.status === "PUBLISHED" || flow.current_version;

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("flows:list-header", "Flow list"),
              url: buildRoute("flow-list"),
            },
            {
              name: flow?.name,
              url: buildRoute("flow-detail", { ":id": flow?.id }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ShareIcon
                className="mr-4 h-7 w-7 rotate-90"
                aria-hidden="true"
              />
              {t("flow", "Flow")} {flow.name}
              {flow.is_chatbot && (
                <span className="px-4 rounded-lg ml-2 text-sm font-semibold py-1 flex bg-blue-50 text-blue-500">
                  <RobotIcon className="w-4 h-4 self-center mr-1" />
                  Chatbot
                </span>
              )}
            </h2>
          </div>
        </div>
      </div>

      <div className="my-4 grid grid-cols-1 grid-rows-2 gap-4 px-6 md:grid-cols-4 md:grid-rows-1">
        <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t("summary", "Summary")}
          </h3>
          <div className="flex items-center justify-between text-sm">
            <div className="mr-4 w-full grow">
              <p className="text-base font-medium">{flow.name}</p>
              <div>
                {flowHasBeenPublished ? (
                  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
                    {t("flows:published", "Published")}
                  </span>
                ) : flow.needs_update ? (
                  <span className="inline-flex items-center rounded-full bg-yellow-100 px-3 py-0.5 text-xs font-semibold uppercase text-yellow-800">
                    {t("flows:required-changes", "Required changes")}
                  </span>
                ) : (
                  <span className="inline-flex items-center rounded-full bg-indigo-100 px-3 py-0.5 text-xs font-medium uppercase text-indigo-800">
                    {t("flows:status.draft", "Draft")}
                  </span>
                )}
              </div>

              {flow.status === "DRAFT" && (
                <div className="flex my-1 text-gray-500">
                  <div className="flex-shrink-0">
                    <InformationCircleIcon
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-1 text-xs">
                    {t("flows:unpublished-changes", "Unpublished changes")}
                  </div>
                </div>
              )}

              <p>{flow.current_version.execution_type}</p>
              {session?.account.has_used_multiple_channels && (
                <p className="whitespace-nowrap text-sm text-gray-600">
                  <ChannelBadge channel={flow.channel} />
                </p>
              )}
              <p className="text-gray-400">
                {t("created-by", "Created by")}{" "}
                <span className="font-medium text-gray-700">
                  {userToString(flow.created_by)}
                </span>
              </p>
              <p className="text-gray-400">
                {t("created-on", "Created on")}{" "}
                <span className="font-medium text-gray-700">
                  {flow.created_on &&
                    DateTime.fromISO(flow.created_on).toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}
                </span>
              </p>
            </div>
            <div className="grow-0">
              {!flow.needs_update && (
                <div className="mb-2">
                  <Switch.Group as="div" className="flex items-center">
                    <Switch
                      checked={flow.is_active}
                      onChange={handleToggleActive}
                      className={classNames(
                        flow.is_active ? "bg-indigo-600" : "bg-gray-200",
                        "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          flow.is_active ? "translate-x-5" : "translate-x-0",
                          "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3 text-sm">
                      <span className="font-medium text-gray-900">
                        {t("flows:is-active", "Can be triggered?")}
                      </span>{" "}
                    </Switch.Label>
                  </Switch.Group>
                  {!flow.is_active && (
                    <div className="mt-1 text-gray-500 text-xs">
                      {t(
                        "flows:inactive-notice",
                        "While inactive this flow won't be run."
                      )}
                    </div>
                  )}
                </div>
              )}
              <Link
                className="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                to={buildRoute(
                  flow.is_legacy && !flow.upgrade_version
                    ? "flow-update"
                    : "flow-editor",
                  {
                    ":id": flow.id,
                  }
                )}
              >
                <PencilAltIcon
                  className="-ml-0.5 mr-2 h-4 w-4"
                  aria-hidden="true"
                />
                {flow.status === "DRAFT"
                  ? t("update-draft", "Update draft")
                  : t("update", "Update")}
              </Link>

              {flow.status === "DRAFT" && (
                <button
                  type="button"
                  className="focus:ring-hilos-500 mb-2 inline-flex w-full items-center justify-center rounded-md border border-yellow-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-yellow-600 shadow-sm hover:bg-yellow-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-100"
                  onClick={discardUnpublishedChanges}
                >
                  <ReplyIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t(
                    "flows:unpublished-changes-discard-button",
                    "Discard draft"
                  )}
                </button>
              )}

              <DeleteObject
                obj={flow}
                objDescription={flow.name ?? t("flows:this-flow", "this flow")}
                actionDescription={t(
                  "flows:delete-object",
                  "When deleting this flow, messages already sent will still reach them."
                )}
                onDelete={onDelete}
                deleteSuccess={deleteSuccess}
                deleteSubmitted={deleteSubmitted}
                buttonClasses="inline-flex items-center justify-center w-full px-3 py-2 border border-red-600 shadow-sm text-sm leading-4 font-medium rounded-md text-red-600 bg-white hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                iconClasses=""
              />
            </div>
          </div>
        </div>
        <div className="col-span-2 rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
          {flow.needs_update ? (
            <>
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("flow-executions:details", "Execution details")}
              </h3>
              <div className="rounded-lg border-yellow-400 bg-yellow-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                      className="h-5 w-5 text-yellow-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm text-yellow-700">
                      {t(
                        "flow-executions:required-changes-notice",
                        "This flow must be updated in the flow builder and published in order to be triggered."
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : flow.current_version.execution_type === "INBOUND" ? (
            <>
              <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
                {t("flow-executions:details", "Execution details")}
              </h3>
              {!flow.is_active ? (
                <InactiveFlowNotice />
              ) : (
                <div className="justify-between flex items-center text-sm">
                  {flowExecutions && (flowExecutions.count || 0) > 0 ? (
                    <>
                      {activeInboundFlowExecutions.length > 0 ? (
                        <>
                          {activeInboundFlowExecutions.map((flowExecution) => (
                            <FlowExecutionSummaryInbound
                              flow={flow}
                              flowExecution={flowExecution}
                              key={flowExecution.id}
                            />
                          ))}
                        </>
                      ) : (
                        <FlowDetailInboundRunInstructions flowId={flow.id} />
                      )}
                    </>
                  ) : (
                    <FlowDetailInboundRunInstructions flowId={flow.id} />
                  )}
                </div>
              )}
            </>
          ) : (
            <FlowDetailOutboundRunInstructions flowId={flow.id} />
          )}
        </div>
      </div>

      <FlowAnalyticsSummary flowObject={flow} />

      <Tabs
        tabs={[
          {
            label: t("contacts", "Contacts"),
            icon: UserIcon,
          },
          {
            label: t("flow-executions", "Flow Executions"),
            icon: UserGroupIcon,
            hide: flow.current_version?.execution_type === "INBOUND",
          },
          {
            label: t("flow-versions", "Versions"),
            icon: CollectionIcon,
          },
        ]}
        className="px-4 sm:px-6"
      >
        <FlowExecutionContactList flowId={flowId} />
        <FlowExecutionList flowId={flowId} />
        <FlowVersionList flowId={flowId} />
      </Tabs>
    </div>
  );
}
