import { useCallback, useMemo, useState } from "react";
import {
  PlusSmIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FieldArray } from "formik";
import FlowOptionsFromVariable from "src/containers/flow/FlowOptionsFromVariable";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import listImg from "src/assets/img/flow/list.webp";
import { FlowStepQuestionMeta } from "./Meta";

function getMaxOptionsLength(answerType, answerOptionsRender) {
  if (answerType === "BOOL") {
    return 2;
  }
  if (answerType === "SINGLE_OPTION") {
    if (answerOptionsRender === "BUTTONS") {
      return 3;
    }
    if (answerOptionsRender === "LIST") {
      return 10;
    } else {
      return 15;
    }
  }
  return null;
}

const formatOptionLabel = ({ value, label, icon, help, ...props }) => {
  return (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
      {props.soon && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
            Coming soon!
          </span>
        </div>
      )}
    </div>
  );
};

function FlowStepQuestionSingleOption({
  formik,
  index,
  availableGroupedSteps,
}) {
  const [showListExample, setShowListExample] = useState(false);

  const toggleShowListExample = useCallback(() => {
    setShowListExample((s) => !s);
  }, [setShowListExample]);

  const handleClearDataSourceOptions = useCallback(() => {
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.missing_options_message.key}`,
      ""
    );
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.options_from_variable.key}`,
      ""
    );
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.option_from_variable_value.key}`,
      ""
    );
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.option_from_variable_title.key}`,
      ""
    );
  }, [formik.setFieldValue, index]);

  const maxOptionsLength = useMemo(
    () =>
      getMaxOptionsLength(
        formik.values.steps[index].answer_type,
        formik.values.steps[index].answer_options_render
      ),
    [formik.values.steps, index]
  );

  const onAnswerOptionsRenderChange = useCallback(
    (optionsRenderAs, index) => {
      const options = formik.values.steps[index].answer_options;
      const answerType = formik.values.steps[index].answer_type;
      const nextMaxOptionsLength = getMaxOptionsLength(
        answerType,
        optionsRenderAs
      );

      if (options && options.length > nextMaxOptionsLength) {
        const nextOptions = options.slice(0, nextMaxOptionsLength);
        formik.setFieldValue(`steps.${index}.answer_options`, nextOptions);
      }

      if (!["BUTTONS", "LIST"].includes(optionsRenderAs)) {
        formik.setFieldValue(`steps.${index}.has_options_from_variable`, false);
        handleClearDataSourceOptions();
      }
    },
    [formik.values, formik.setFieldValue, handleClearDataSourceOptions]
  );

  return (
    <>
      <div>
        <SelectorField
          label={FlowStepQuestionMeta.FIELDS.answer_options_render.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options_render.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_options_render.help}
          options={FlowStepQuestionMeta.ANSWER_OPTIONS_RENDER_TYPES}
          formatOptionLabel={formatOptionLabel}
          onSelect={(option) =>
            onAnswerOptionsRenderChange(option.value, index)
          }
        />
        {formik.values.steps[index].answer_options_render === "LIST" && (
          <button
            className="mt-1 flex items-center text-sm text-blue-500"
            type="button"
            onClick={toggleShowListExample}
          >
            <QuestionMarkCircleIcon className="mr-0.5 h-4 w-4" />
            {showListExample ? "Hide example" : "Show example"}
          </button>
        )}
      </div>

      {formik.values.steps[index].answer_options_render === "LIST" && (
        <div className="space-y-4">
          {showListExample && (
            <div className="text-center text-sm italic text-gray-500">
              <img src={listImg} alt="Message with list example" />
              <p>Example of a message with an interactive list</p>
            </div>
          )}
          <div>
            <TextInputField
              label={
                FlowStepQuestionMeta.FIELDS
                  .answer_options_render_list_button_title.label
              }
              name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options_render_list_button_title.key}`}
              placeholder={
                FlowStepQuestionMeta.FIELDS
                  .answer_options_render_list_button_title.placeholder
              }
              type="text"
              maxLength={20}
            />
          </div>
        </div>
      )}
      {["BUTTONS", "LIST"].includes(
        formik.values.steps[index].answer_options_render
      ) && (
        <div>
          <SwitchField
            name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.has_options_from_variable.key}`}
            label={FlowStepQuestionMeta.FIELDS.has_options_from_variable.label}
            help={FlowStepQuestionMeta.FIELDS.has_options_from_variable.help}
            onChange={() => {
              formik.setFieldValue(
                `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options.key}`,
                [""]
              );
              handleClearDataSourceOptions();
            }}
          />
        </div>
      )}

      <div>
        {!formik.values.steps[index].has_options_from_variable && (
          <p className="block text-sm font-medium text-gray-700">
            Which options can the user choose from?
          </p>
        )}

        {!["BUTTONS", "LIST"].includes(
          formik.values.steps[index].answer_options_render
        ) && (
          <p className="text-xs text-gray-500">
            We'll add these options to the question text along with numbers to
            let the customer know how to answer.
          </p>
        )}
        <div className="mt-2">
          {formik.values.steps[index].has_options_from_variable ? (
            <FlowOptionsFromVariable
              steps={formik.values.steps}
              currentStepIndex={index}
              maxOptionsLength={maxOptionsLength}
              availableGroupedSteps={availableGroupedSteps}
            />
          ) : (
            <FieldArray
              name={`steps.${index}.answer_options`}
              render={(arrayHelpers) => (
                <>
                  <div className="list-group list-group-flush">
                    {formik.values.steps &&
                      formik.values.steps[index].answer_options &&
                      formik.values.steps[index].answer_options.map(
                        (_, oIdx) => (
                          <div className="flex items-center py-1" key={oIdx}>
                            <div className="mr-4 grow-0">
                              <code>{oIdx + 1}</code>
                            </div>
                            <div className="grow">
                              <TextInputField
                                name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options.key}.${oIdx}`}
                                placeholder={
                                  FlowStepQuestionMeta.FIELDS.answer_options
                                    .placeholder
                                }
                                type="text"
                                maxLength={
                                  formik.values.steps[index]
                                    .answer_options_render === "BUTTONS"
                                    ? 20
                                    : formik.values.steps[index]
                                        .answer_options_render === "LIST"
                                    ? 24
                                    : null
                                }
                              />
                            </div>

                            <div className="ml-4 grow-0">
                              <button
                                type="button"
                                className="inline-flex w-full items-center justify-center rounded-md border border-red-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                                onClick={(_) => arrayHelpers.remove(oIdx)}
                              >
                                <XIcon className="h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        )
                      )}
                  </div>

                  {formik.errors &&
                    formik.errors.steps &&
                    formik.errors.steps[index] &&
                    formik.errors.steps[index].answer_options &&
                    typeof formik.errors.steps[index].answer_options ===
                      "string" && (
                      <p className="text-danger mb-2">
                        {formik.errors.steps[index].answer_options}
                      </p>
                    )}

                  {maxOptionsLength !== null &&
                    formik.values.steps[index].answer_options &&
                    formik.values.steps[index].answer_options.length <
                      maxOptionsLength && (
                      <button
                        type="button"
                        className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                        onClick={(_) => arrayHelpers.push("")}
                      >
                        <PlusSmIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Add option
                      </button>
                    )}
                </>
              )}
            />
          )}
        </div>
      </div>

      <div>
        <TextInputField
          label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
          placeholder={
            FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
          }
          type="text"
        />
      </div>
    </>
  );
}

export default FlowStepQuestionSingleOption;
