import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import MappedValuesField from "../MappedValuesField";
import { isStructuredContentType } from "./HTTPFormBody";

interface HTTPFormHeadersProps {
  path: string;
  fieldBodyName: string;
  fieldHeadersName: string;
  currentStepIndex: number | null;
}

function HTTPFormHeaders({
  path,
  fieldBodyName,
  fieldHeadersName,
  currentStepIndex,
}: HTTPFormHeadersProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const contentTypeRef = useRef<string | null>(null);

  const handleChangeValues = useCallback(
    (headers) => {
      let nextContentType = "";

      for (const header of headers) {
        if (header.key && header.key.toLowerCase() === "content-type") {
          nextContentType = header.value;
          break;
        }
      }

      if (contentTypeRef.current !== null) {
        if (
          isStructuredContentType(contentTypeRef.current) !==
            isStructuredContentType(nextContentType) ||
          contentTypeRef.current !== nextContentType
        ) {
          setFieldValue(`${path}.${fieldBodyName}`, null);
        }
      }

      contentTypeRef.current = nextContentType;
    },
    [fieldBodyName, path, setFieldValue]
  );

  return (
    <MappedValuesField
      path={path}
      name={fieldHeadersName}
      objectName={t("components:http-form.headers.header", "Header")}
      currentStepIndex={currentStepIndex}
      addButtonLabel={t(
        "components:http-form.headers.add-header",
        "Add header"
      )}
      onChangeValues={handleChangeValues}
    />
  );
}

export default HTTPFormHeaders;
