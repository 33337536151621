import { useCallback } from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { FlowDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "../router/router";

function useFlow(id: string | undefined) {
  const fetchFlow = useCallback(async () => {
    if (!id) {
      return null;
    }
    const { data } = await axios.get<FlowDetailRead>(
      buildAPIRoute(API_ROUTES.FLOW_DETAIL, {
        ":id": id,
      })
    );
    return data;
  }, [id]);

  const { data, refetch } = useQuery(["flow_detail", id], fetchFlow);

  const updateFlow = useCallback(
    async (params: Partial<FlowDetailRead>) => {
      const { data } = await axios.patch<FlowDetailRead>(
        API_ROUTES.FLOW_DETAIL.replace(":id", id as string),
        params
      );
      if (data) {
        refetch();
        return data;
      }
    },
    [id, refetch]
  );

  return {
    flow: data,
    updateFlow,
    refetchFlow: refetch,
  };
}

export default useFlow;
