import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, Position } from "reactflow";
import { FlowNodeData } from "@hilos/types/flow";
import useWrappedSteps from "src/hooks/useWrappedSteps";
import { classNames } from "src/Helpers";
import {
  CANCELED_COLORS_PALETTE,
  COMPLETED_COLORS_PALETTE,
  EXECUTION_STATUS_TYPES,
  EXPIRED_COLORS_PALETTE,
  FAILED_COLORS_PALETTE,
  RUNNING_COLORS_PALETTE,
} from "../../constants/flow";
import { STEP_TYPES_WITHOUT_NEXT } from "../../constants/steps";

const getExecutionStatusDetails = (
  currentStepStatus: string | null,
  currentExecutions: number,
  currentMaxExecutions: number
) => {
  if (currentStepStatus) {
    const colorIndex =
      Math.round(
        Math.min(
          Math.max((currentExecutions * 10) / currentMaxExecutions - 1, 0),
          9
        )
      ) || 0;

    switch (currentStepStatus) {
      case "READY":
        return RUNNING_COLORS_PALETTE[colorIndex];
      case "RUNNING":
        return RUNNING_COLORS_PALETTE[colorIndex];
      case "CANCELED":
        return CANCELED_COLORS_PALETTE[colorIndex];
      case "FAILED":
        return FAILED_COLORS_PALETTE[colorIndex];
      case "EXPIRED":
        return EXPIRED_COLORS_PALETTE[colorIndex];
      case "COMPLETED":
        return COMPLETED_COLORS_PALETTE[colorIndex];
      default:
        break;
    }
  }
  return null;
};

function StepNode({
  id,
  data,
  targetPosition,
  sourcePosition,
  selected,
}: NodeProps<FlowNodeData>) {
  const [t] = useTranslation();
  const { STEP_TYPES_DYNAMIC } = useWrappedSteps();

  const step = useMemo(
    () => (data.type && STEP_TYPES_DYNAMIC[data.type]) || null,
    [STEP_TYPES_DYNAMIC, data.type]
  );

  const label = data.label;

  const [
    currentStepStatus,
    currentExecutions,
    currentMaxExecutions,
    hasMultipleContactExecutions,
  ] = useMemo(() => {
    const nextHasMultipleContactExecutions =
      data.has_multiple_contact_executions || false;
    if (data.executions_by_status && data.max_executions_by_status) {
      for (const status of EXECUTION_STATUS_TYPES) {
        if (
          status.id in data.executions_by_status &&
          status.id in data.max_executions_by_status
        ) {
          return [
            status.id,
            data.executions_by_status[status.id] || 0,
            data.max_executions_by_status[status.id] || 0,
            nextHasMultipleContactExecutions,
          ];
        }
      }
    }
    return [null, 0, 0, nextHasMultipleContactExecutions];
  }, [data]);

  const currentExecutionsRate = useMemo(() => {
    if (currentExecutions && currentMaxExecutions) {
      return Math.round(
        Math.max((currentExecutions * 100) / currentMaxExecutions, 0)
      );
    }
    return 0;
  }, [currentExecutions, currentMaxExecutions]);

  const [contanierColor, titleColor, labelColor] = useMemo(() => {
    if (selected) {
      return ["#dbeafe", "#1e40af", "#1d4ed8"];
    }

    const colorByStatus = getExecutionStatusDetails(
      currentStepStatus,
      currentExecutions,
      currentMaxExecutions
    );

    if (colorByStatus) {
      return [colorByStatus.base, colorByStatus.tint, colorByStatus.tint];
    }

    return ["white", "#4b5563", "#374151"];
  }, [selected, currentStepStatus, currentExecutions, currentMaxExecutions]);

  if (!step) {
    return null;
  }

  return (
    <div
      draggable
      id={id}
      style={{ backgroundColor: contanierColor }}
      className={classNames("nopan group mx-10 w-40 rounded-lg p-2 shadow-md")}
    >
      <Handle
        className="pointer-events-none"
        type="target"
        position={targetPosition || Position.Top}
        isConnectable={false}
      />

      {data.options
        ? data.options.map((option) => (
            <Handle
              key={option.id}
              className="pointer-events-none"
              type="source"
              id={option.id}
              position={sourcePosition || Position.Bottom}
              isConnectable={false}
            />
          ))
        : data.type &&
          !STEP_TYPES_WITHOUT_NEXT.includes(data.type) && (
            <Handle
              className="pointer-events-none"
              type="source"
              position={sourcePosition || Position.Bottom}
              isConnectable={false}
            />
          )}
      <div className="pointer-events-none flex-1">
        {hasMultipleContactExecutions && (
          <div className="absolute -top-3 right-4 w-14 justify-center">
            <div className="flex px-1 rounded-full bg-white border-gray-500 border-2 justify-center items-center gap-1">
              <span className="font-bold text-sm text-gray-600">
                {currentExecutionsRate} %
              </span>
            </div>
          </div>
        )}
        <div className="flex flex-row items-center">
          <div className="flex flex-row flex-1 w-28 items-center">
            <step.icon
              style={{ color: titleColor }}
              className={classNames("shrink-0", label ? "h-5 w-5" : "h-6 w-6")}
            />
            <div className="flex flex-col px-1 line-clamp-2">
              <h1
                style={{ color: titleColor }}
                className={classNames(
                  "font-semibold truncate",
                  label ? "text-sm" : "text-md"
                )}
              >
                {t(step.name)}
              </h1>
            </div>
          </div>
        </div>
        <p
          style={{ color: labelColor }}
          className="text-md line-clamp-2 font-medium"
        >
          {label}
        </p>
      </div>
    </div>
  );
}

export default memo(StepNode);
