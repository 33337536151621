import * as yup from "yup1";

import {
  getCurrentStepVariables,
  getMappedItemsById,
  getRequiredVariablesFromStep,
} from "src/helpers/variables";

import { ERROR_MESSAGES } from "src/constants/errors";
import { FlowData } from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import { TFunction } from "i18next";
import { getStepFormat } from "./steps";
import { hasItems } from "src/helpers/utils";

export const FlowStepBaseSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t(ERROR_MESSAGES.REQUIRED))
      .test("uniqueName", t(ERROR_MESSAGES.DUPLICATED_NAME), function (name) {
        try {
          if (
            // @ts-ignore
            this.from[1].value.steps.some(
              (step) => step.id !== this.parent.id && step.name === name
            )
          ) {
            return false;
          }
        } catch {}
        return true;
      })
      .test(
        "dotsNotAllowed",
        t(ERROR_MESSAGES.DOTS_NOT_ALLOWED),
        (name) => !(name && name.includes("."))
      ),
    variables: yup.array().of(yup.string()),
    required_variables: yup.array().of(
      yup
        .string()
        .test("requiredVariableExist", function (variableId, { createError }) {
          try {
            if (!variableId || !this.from?.[1].value) {
              return createError({
                message: t(ERROR_MESSAGES.EMPTY_VARIABLE),
              });
            }

            if (
              !this.from[1].value.variables.some(
                (variable) => variable.id === variableId
              )
            ) {
              return createError({
                message: variableId,
              });
            }
            return true;
          } catch {
            return false;
          }
        })
    ),
  });

export const TimeWindowSchema = (t: TFunction) =>
  yup.object().shape({
    weekday: yup.number(),
    is_available: yup.bool(),
    start_at: yup
      .string()
      .nullable()
      .when("is_available", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    end_at: yup
      .string()
      .nullable()
      .when("is_available", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
  });

export const VariableMetadataSchema = (t: TFunction) =>
  yup.object().shape({
    id: yup.string(),
    name: yup.string(),
    source: yup.mixed().oneOf(["step", "contact"]),
    path: yup.string().notRequired(),
    data_type: yup
      .mixed()
      .oneOf([
        "text",
        "bool",
        "number",
        "list",
        "object",
        "null",
        "media",
        "date",
        "time",
        "datetime",
        "location",
      ]),
    transform: yup.string().notRequired(),
    flow_id: yup.string().notRequired(),
    step_id: yup.string().notRequired(),
  });

export const FlowStepAnswerSchema = (t: TFunction) =>
  FlowStepBaseSchema(t).shape({
    has_max_answer_attempts: yup.bool(),
    max_answer_attempts: yup
      .number()
      .nullable()
      .when("has_max_answer_attempts", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    answer_failed_next_step: yup
      .string()
      .nullable()
      .when("has_max_answer_attempts", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    has_max_wait_time: yup.bool(),
    max_wait_time_amount: yup
      .number()
      .nullable()
      .when("has_max_wait_time", {
        is: true,
        then: (schema) =>
          schema
            .required(t(ERROR_MESSAGES.REQUIRED))
            .min(0, t(ERROR_MESSAGES.MIN_NUMBER_VALUE)),
      }),
    max_wait_time_unit: yup
      .string()
      .oneOf(["HOUR", "MINUTE", "DAY"])
      .nullable()
      .when("has_max_wait_time", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    set_time_window: yup.bool(),
    time_window_type: yup
      .string()
      .nullable()
      .when("set_time_window", {
        is: true,
        then: (schema) => schema.required(t(ERROR_MESSAGES.REQUIRED)),
      }),
    time_windows: yup
      .array()
      .nullable()
      .when(["set_time_window", "time_window_type"], {
        is: (setTimeWindow, timeWindowType) =>
          setTimeWindow && timeWindowType === "CUSTOM",
        then: (schema) =>
          schema.of(TimeWindowSchema(t)).min(6, t(ERROR_MESSAGES.MIN_LENGTH)),
      }),
  });

export async function getFlowValuesWithUpdatedData(data: FlowData) {
  const nextSteps = data.steps || [];
  const nextVariables: HilosVariableData[] = data.variables.filter(
    (variable) => variable.source !== "step"
  );
  const variablesById = getMappedItemsById<HilosVariableData>(data.variables);

  for (const currentStepIndex in nextSteps) {
    const currentStepData = nextSteps[currentStepIndex];
    if (currentStepData) {
      const prevStepVariables = (currentStepData.variables || []).map(
        (variableId) => variablesById.get(variableId)
      );
      const nextStepVariables = getCurrentStepVariables(
        currentStepData,
        prevStepVariables
      );
      const nextStepVariableIds = nextStepVariables.map(
        (variable) => variable.id
      );

      const stepRequiredVariables = getRequiredVariablesFromStep(
        currentStepData,
        ["step.", "flow.", "trigger.", "flow_execution_variables."]
      );

      nextSteps[currentStepIndex].required_variables = stepRequiredVariables;
      nextSteps[currentStepIndex].variables = nextStepVariableIds;
      nextVariables.push(...nextStepVariables);
    }
  }

  return {
    ...data,
    steps: nextSteps,
    variables: nextVariables,
  };
}

export function getFlowData({
  name,
  channel,
  flow_execution_variables: flowExecutionVariables,
  ...data
}: FlowData) {
  const steps = {};
  const flowRequiredVariables: string[] = [];

  if (hasItems(data.steps)) {
    for (const index in data.steps) {
      const step = getStepFormat(data.steps[index]);
      if (step) {
        const requiredVariables = getRequiredVariablesFromStep(step, [
          "step.",
          "flow.",
          "trigger.",
          "flow_execution_variables.",
        ]);
        steps[step.id] = {
          ...step,
          required_variables: requiredVariables,
        };

        flowRequiredVariables.push(...requiredVariables);
      }
    }
  }

  const variables = (data.variables || []).reduce((nextVariables, variable) => {
    if (variable && flowRequiredVariables.includes(variable.id)) {
      nextVariables[variable.id] = { ...variable };
    }
    return nextVariables;
  }, {} as { [key: string]: HilosVariableData });

  return {
    name,
    channel,
    contact_can_run_multiple_times: data.contact_can_run_multiple_times,
    contact_execution_multiple_type: data.contact_execution_multiple_type,
    contact_execution_max_frequency_quantity:
      data.contact_execution_max_frequency_quantity,
    contact_execution_max_frequency_unit:
      data.contact_execution_max_frequency_unit,
    flow_execution_variables: flowExecutionVariables,
    should_trigger_webhook: data.should_trigger_webhook,
    current_version_data: {
      ...data,
      steps,
      variables,
    },
  };
}

export function countFieldsWithStringValue(data: any) {
  let total = 0;

  if (typeof data === "object") {
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (typeof data[key] === "string") {
          total++;
        }
        if (typeof data[key] === "object") {
          total += countFieldsWithStringValue(data[key]);
        }
      }
    }
  }

  return total;
}
