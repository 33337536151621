import { Fragment, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlusSmIcon, XCircleIcon } from "@heroicons/react/outline";
import { FieldArray, FormikProps } from "formik";
import FlowStepTextInputAddVariable from "src/containers/flow/FlowStepTextInputAddVariable";
import TextInputField from "src/components/Form/TextInputField";

interface FlowStepContactUpdateCustomProps {
  formik: FormikProps<any>;
  index: number;
}

export default function FlowStepContactUpdateCustom({
  formik,
  index,
}: FlowStepContactUpdateCustomProps) {
  const { t } = useTranslation();
  const contactCustomProperties = useMemo(
    () => formik.values.steps?.[index]?.contact_custom_properties || [],
    [formik.values.steps, index]
  );

  return (
    <div className="space-y-4">
      <FieldArray
        name={`steps.${index}.contact_custom_properties`}
        render={(arrayHelpers) => (
          <>
            <ul className=" space-y-4">
              {contactCustomProperties.map((property, currentPropertyIndex) => {
                const id = `steps.${index}.contact_custom_properties.${currentPropertyIndex}`;

                return (
                  <div key={id} className="relative space-y-2">
                    <XCircleIcon
                      className="mr-2 h-5 w-5 absolute -top-1 -right-1 cursor-pointer"
                      aria-hidden="true"
                      onClick={arrayHelpers.handleRemove(currentPropertyIndex)}
                    />
                    <TextInputField
                      label={t(
                        "flows:steps.contact-update.additional-property.key",
                        "Property name"
                      )}
                      name={`${id}.key`}
                      type="text"
                    />
                    <FlowStepTextInputAddVariable
                      formik={formik}
                      name={`${id}.value`}
                      currentStepIndex={index}
                      label={t(
                        "flows:steps.contact-update.additional-property.value",
                        "Property value"
                      )}
                    />
                    <hr className="my-5" />
                  </div>
                );
              })}
            </ul>
            <div className="mt-4">
              <button
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                onClick={arrayHelpers.handlePush({
                  key: "",
                  value: "",
                })}
              >
                <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                {t("flows:steps.contact-update.add-additional", "Add property")}
              </button>
            </div>
          </>
        )}
      />
    </div>
  );
}
