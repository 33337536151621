import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { SaveIcon, ShareIcon } from "@heroicons/react/outline";
import axios, { AxiosError } from "axios";
import { useFlowExecution } from "src/hooks/useFlowExecution";
import HeaderNavigation from "../../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import FlowExecutionForm from "./FlowExecutionForm";
import { updateFlowExecutionVariables } from "./FlowExecutionMeta";

export default function FlowExecutionUpdate() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const params = useParams();
  const flowExecutionId = params.id as string;

  const flowExecution = useFlowExecution(flowExecutionId);

  if (flowExecution && flowExecution?.execution_type !== "INBOUND") {
    // Only INBOUND flow executions are updatable
    navigate(buildRoute("flow-execution-detail", { ":id": flowExecution?.id }));
  }

  const formSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      await axios.put(
        buildAPIRoute(API_ROUTES.FLOW_EXECUTION_DETAIL, {
          ":id": params.id,
        }),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      setTimeout(() => {
        navigate(
          buildRoute("flow-detail", {
            ":id": flowExecution?.flow_version.flow.id,
          })
        );
      }, 1000 * 2);
    } catch (err) {
      type AxiosValidationError = AxiosError<{ status: number; data: unknown }>;
      const axiosErr: AxiosValidationError = err as AxiosValidationError;
      setSuccess(false);
      if (axiosErr?.response?.status === 400) {
        console.log("error", err);
        setBackendValidationErrors(axiosErr.response.data);
      } else {
        setBackendError(
          "An error occurred while running your flow. Please try again."
        );
      }
      setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: `Flow ${flowExecution?.flow.name}`,
              url: buildRoute("flow-detail", {
                ":id": flowExecution?.flow.id,
              }),
            },
            {
              name: "Update execution",
              url: buildRoute("flow-execution-update", {
                ":id": params.id,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ShareIcon
                className="mr-4 h-7 w-7 rotate-90"
                aria-hidden="true"
              />
              {t("flow-executions:update-execution", "Update execution of")}{" "}
              {flowExecution?.flow.name}
            </h2>
          </div>
        </div>
      </div>

      {flowExecution && (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mt-6 max-w-2xl">
                <div className="space-y-14">
                  <div className="row mt-10 text-center">
                    <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                      {t(
                        "flow-executions:update.title",
                        "Update this INBOUND flow"
                      )}
                    </h1>
                    <p className="mb-5 text-sm text-gray-500">
                      {t(
                        "flow-executions:update.subtitle",
                        "Set the trigger message for this flow to start."
                      )}
                    </p>
                  </div>
                  <FlowExecutionForm
                    flow={flowExecution.flow}
                    flowVersion={flowExecution.flow_version}
                    flowExecution={updateFlowExecutionVariables(flowExecution)}
                    formSubmit={formSubmit}
                    success={success}
                    submitted={submitted}
                    submittingText={t(
                      "flow-executions:run-on-button-updating",
                      "Updating..."
                    )}
                    successText={t(
                      "flow-executions:run-on-button-updated",
                      "Updated!"
                    )}
                    initialText={
                      <>
                        <SaveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                        {t("flow-executions:update-button", "Update flow")}
                      </>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
