import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { FormikProps, useField } from "formik";
import { AccountRead } from "@hilos/types/private-schema";
import { DAY_LABELS_LONG } from "src/containers/account/AccountMeta";
import CheckboxField from "src/components/Form/CheckboxField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import { hasItems } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";
import { FIELDS } from "./DelayUntilComponent";
import useTimeWindowsHelpers from "./useTimeWindowsHelpers";

interface TimeWindowsProps {
  formik: FormikProps<any>;
  index: number;
}

export default function TimeWindows({ formik, index }: TimeWindowsProps) {
  const { toggleTimeWindows, onAvailableDayChange, hourChoices } =
    useTimeWindowsHelpers({ formik, index });
  const nameTimeWindows = `steps.${index}.${FIELDS.time_windows.key}`;
  const nameSetTimeWindow = `steps.${index}.${FIELDS.set_time_window.key}`;
  const [setTimeWindow] = useField({ name: nameSetTimeWindow });
  const timeWindows = formik.values.steps[index][FIELDS.time_windows.key];
  const { t } = useTranslation();
  const { session } = useHilosStore();
  const account = session?.account as unknown as AccountRead;

  const formatOptionLabel = ({ value, label, disabled, ...props }) => (
    <div
      className={classNames(
        "flex items-center justify-between",
        disabled ? "text-gray-500" : ""
      )}
    >
      <h4 className="font-medium">{label}</h4>
      {disabled && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800">
            {t("unavailable", "Unavailable")}
          </span>
        </div>
      )}
    </div>
  );

  return (
    <>
      <SwitchField
        name={nameSetTimeWindow}
        label={t("flows:time-window.label", "Set a time window to continue?")}
        help={t(
          "flows:time-window.help",
          "Useful if you'd like to wait a day to continue but only if it's between Mon-Fri."
        )}
        onChange={toggleTimeWindows}
      />

      {setTimeWindow && hasItems(timeWindows) && (
        <>
          {!account.has_working_hours && (
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between">
                  <p className="text-sm text-blue-700">
                    {t(
                      "flows:time-window.set-working-hours",
                      "Set your account's working hours to be able to use them here."
                    )}
                  </p>
                  <p className="mt-3 text-sm md:ml-6 md:mt-0">
                    <Link
                      to={buildRoute("config-account-hours")}
                      className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                    >
                      Set
                      <span aria-hidden="true"> &rarr;</span>
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          )}
          <SelectorField
            name={`steps.${index}.${FIELDS.time_window_type.key}`}
            label={t(
              "flows:time-window-type.label",
              "Choose the time window type"
            )}
            help={t(
              "flows:time-window-type.help",
              "Use your account's working hours or set a custom time window."
            )}
            options={[
              {
                label: t("flows:time-window.working-hours", "In working hours"),
                value: "IN_WORKING_HOURS",
                disabled: !account.has_working_hours,
              },
              {
                label: t("flows:time-window.custom", "Custom"),
                value: "CUSTOM",
                disabled: false,
              },
            ]}
            formatOptionLabel={formatOptionLabel}
            isOptionDisabled={(option) => option.disabled}
          />

          {formik.values.steps[index][FIELDS.time_window_type.key] ===
            "CUSTOM" && (
            <div className="divide-y divide-gray-200">
              {timeWindows.map((wh, idx) => (
                <div
                  key={wh.weekday}
                  className="relative grid grid-cols-1 items-center justify-between py-4 sm:flex"
                >
                  <div className="flex items-center">
                    <CheckboxField
                      name={`${nameTimeWindows}.${idx}.is_available`}
                      onChange={(ev) => onAvailableDayChange(ev, idx)}
                    />
                    <div className="ml-2 min-w-0 flex-1 text-sm">
                      <label
                        htmlFor={`${nameTimeWindows}.${idx}.is_available`}
                        className="select-none font-medium text-gray-700"
                      >
                        {t(DAY_LABELS_LONG[wh.weekday])}
                      </label>
                    </div>
                  </div>
                  <div className="ml-3 mt-4 sm:mt-0">
                    {wh.is_available && (
                      <>
                        <div className="flex items-center space-x-6">
                          <div className="flex items-center">
                            <label
                              className="mr-2 text-sm text-gray-700"
                              htmlFor={`${nameTimeWindows}.${idx}.start_at`}
                            >
                              {t("flows:time-window.start_at", "From")}
                            </label>
                            <div className="min-w-[100px]">
                              <SelectorField
                                name={`${nameTimeWindows}.${idx}.start_at`}
                                options={hourChoices}
                              />
                            </div>
                          </div>
                          <div className="flex items-center">
                            <label
                              className="mr-2 text-sm text-gray-700"
                              htmlFor={`${nameTimeWindows}.${idx}.end_at`}
                            >
                              {t("flows:time-window.end_at", "Until")}
                            </label>
                            <div className="min-w-[100px]">
                              <SelectorField
                                name={`${nameTimeWindows}.${idx}.end_at`}
                                options={hourChoices}
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
}
