import { useTranslation } from "react-i18next";
import { TimeoutMeta } from "src/containers/flow/Helpers";
import TimeWindows from "src/containers/flow/form/steps/components/TimeWindows";
import FlowStepDelayNotice from "src/containers/flow/form/steps/delay/FlowStepDelayNotice";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import { FlowStepDelayMeta } from "./Meta";

export default function FlowStepDelay({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  const { t } = useTranslation();
  return (
    <>
      <hr />
      <div>
        <p className="block pb-2 text-sm font-medium text-gray-700">
          {t("flows:steps.delay.wait-time.label", "Wait for")}
        </p>
        <div className="grid grid-cols-3 items-center">
          <div className="col-span-2 w-full">
            <TextInputField
              name={`steps.${index}.${FlowStepDelayMeta.FIELDS.max_wait_time_amount.key}`}
              placeholder="24"
              type="number"
            />
          </div>
          <div className="ml-2 w-full">
            <SelectorField
              name={`steps.${index}.${FlowStepDelayMeta.FIELDS.max_wait_time_unit.key}`}
              options={TimeoutMeta.MAX_WAIT_TIME_OPTIONS}
            />
          </div>
        </div>
      </div>

      <TimeWindows formik={formik} index={index} />

      <FlowStepDelayNotice formik={formik} index={index} />

      <SelectorField
        label={t("flows:next-step.label", "Next step")}
        name={`steps.${index}.${FlowStepDelayMeta.FIELDS.next_step_default_idx.key}`}
        help={t(
          "flows:next-step.help",
          "If you can't find the step you'd like to go to next, check that it has a name."
        )}
        options={availableGroupedSteps(index)}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
}
