import { useMemo } from "react";
import { GlobeAltIcon, UploadIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { UploadedFile } from "@hilos/types/uploaded_file";
import { classNames } from "../../../../../Helpers";
import FlowStepMessageMediaFileUpload from "./FlowStepMessageMediaFileUpload";
import FlowStepMessageMediaURL from "./FlowStepMessageMediaURL";
import { FlowStepMessageMeta, MESSAGE_BODY_TYPES } from "./Meta";

interface FlowStepMessageMediaProps {
  formik: FormikProps<any>;
  index: number;
  deleteUploadedFile: () => void;
  uploadedFile: UploadedFile | null;
  setUploadedFile: (x: UploadedFile | null) => void;
}

const TABS = [
  {
    name: "From URL",
    value: "URL",
    icon: <GlobeAltIcon className="mr-2 h-4 w-4" />,
  },
  {
    name: "Upload File",
    value: "UPLOAD",
    icon: <UploadIcon className="mr-2 h-4 w-4" />,
  },
];

export default function FlowStepMessageMedia({
  formik,
  index,
  deleteUploadedFile,
  uploadedFile,
  setUploadedFile,
}: FlowStepMessageMediaProps) {
  const mediaSource: string =
    formik.values.steps[index][FlowStepMessageMeta.FIELDS.body_file_type.key];

  const switchTab = (newTab: string) => {
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file_type.key}`,
      newTab
    );
  };

  const MEDIA_TYPE = useMemo(
    () =>
      MESSAGE_BODY_TYPES.find(
        (el) =>
          el.value ===
          formik.values?.steps[index][FlowStepMessageMeta.FIELDS.body_type.key]
      ),
    [formik.values, index]
  );

  if (!MEDIA_TYPE) {
    return null;
  }

  return (
    <>
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue="From URL"
            onChange={(ev) => switchTab(ev.target.value)}
          >
            {TABS.map((tab) => (
              <option
                key={tab.name}
                className="flex items-center"
                value={tab.value}
              >
                {tab.icon} {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {TABS.map((tab) => (
                <button
                  type="button"
                  key={tab.name}
                  onClick={(_) => switchTab(tab.value)}
                  className={classNames(
                    tab.value === mediaSource
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "flex grow items-center border-b-2 py-3 px-1 text-center text-sm font-medium"
                  )}
                  aria-current={tab.value === mediaSource ? "page" : undefined}
                >
                  {tab.icon}
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      {mediaSource === "URL" && (
        <FlowStepMessageMediaURL
          formik={formik}
          index={index}
          mediaType={MEDIA_TYPE}
        />
      )}

      {mediaSource === "UPLOAD" && (
        <FlowStepMessageMediaFileUpload
          formik={formik}
          index={index}
          mediaType={MEDIA_TYPE}
          deleteUploadedFile={deleteUploadedFile}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}
      {formik.errors &&
        formik.errors.steps &&
        formik.errors.steps[index] &&
        formik.errors.steps[index].body_file_url && (
          <span className="mt-1.5 text-xs text-red-500">
            {formik.errors.steps[index].body_file_url}
          </span>
        )}
      {formik.errors &&
        formik.errors.steps &&
        formik.errors.steps[index] &&
        formik.errors.steps[index].body_file && (
          <span className="mt-1.5 text-xs text-red-500">
            {formik.errors.steps[index].body_file}
          </span>
        )}
    </>
  );
}
