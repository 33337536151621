import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { NetworkStatus, useQuery } from "@apollo/client";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { usePostHog } from "posthog-js/react";
import { InboxParamsValues } from "@hilos/containers/inbox/InboxParamsForm";
import { ChannelAvailabilityData } from "@hilos/types/channel";
import {
  InboxContactReadV2,
  InboxContactReadV2Query,
} from "@hilos/types/conversation";
import { InboxParams, SessionData } from "@hilos/types/hilos";
import {
  encodeParams,
  getLimitByCursor,
  getOrderBy,
  getQueryFilters,
  getQueryOrderBy,
  getSubscribeToMoreOptions,
  getVariablesWithCursor,
} from "src/helpers/inbox";
import {
  GET_INBOX_CONTACTS_QUERY,
  GET_INBOX_CONTACT_DATA_QUERY,
  getInboxContactCountQuery,
  getInboxContactCountSubscription,
} from "src/helpers/queries";
import { hasItems, isFeatureEnabled } from "src/helpers/utils";

interface useInboxContactsParams {
  session: SessionData | null;
  inboxParams: InboxParams;
  setInboxParams: (inboxParams: Partial<InboxParams>) => void;
  isPageVisible: boolean;
  currentAvailableChannels: ChannelAvailabilityData[];
  isLoadingAvailableChannels: boolean;
}

function useInboxContacts({
  session,
  inboxParams,
  setInboxParams,
  isPageVisible,
  currentAvailableChannels,
  isLoadingAvailableChannels,
}: useInboxContactsParams) {
  const posthog = usePostHog();
  const { inboxContactId } = useParams();
  const [cursor, setCursor] = useState(null);
  const [allowSubscriptions, setAllowSubscriptions] = useState(false);
  const [hasFetchMore, setHasFetchMore] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isUpdatingFilters, setIsUpdatingFilters] = useState(false);
  const hasPendingRefetch = useRef(false);
  const handleGetInboxContactRef = useRef<((id: string) => any) | null>(null);
  const dataRef = useRef<InboxContactReadV2Query>();
  const lastInboxContactCursor = useRef(null);
  const fetchMoreWithCursorRef = useRef<
    ((cursor: string | null) => void) | null
  >(null);

  const [
    allowQueryCountSubscription,
    allowQueryCountWithLimit,
    allowStreamingSubscription,
    allowLiveQueryWithDateLimit,
    allowQueriesWithSQLFunctions,
  ] = useMemo(
    () => [
      isFeatureEnabled({
        session,
        posthog,
        key: "inbox_contact_count_subscription",
      }) || !!process.env.REACT_APP_PUBLIC_INBOX_CONTACT_COUNT_SUBSCRIPTION,
      isFeatureEnabled({
        session,
        posthog,
        key: "inbox_contact_query_count_with_limit",
      }),
      isFeatureEnabled({
        session,
        posthog,
        key: "inbox_contact_streaming_subscription",
      }),
      isFeatureEnabled({
        session,
        posthog,
        key: "inbox_contact_livequery_with_date_limit",
      }),
      isFeatureEnabled({
        session,
        posthog,
        key: "inbox_contact_queries_with_sql_functions",
      }),
    ],
    [session, posthog]
  );

  const { filters, orderBy, extraParams } = useMemo(() => {
    const nextFilters: { _and?: object[] } = getQueryFilters({
      session,
      inboxParams,
      currentAvailableChannels,
      allowQueriesWithSQLFunctions,
    });

    const nextOrderBy = getQueryOrderBy(inboxParams);
    const nextSearch = inboxParams.search;
    const nextExtraParams: { [key: string]: any } = {};

    if (allowQueriesWithSQLFunctions && nextSearch) {
      nextExtraParams.search = nextSearch;
    }

    return {
      extraParams: nextExtraParams,
      filters: nextFilters,
      orderBy: nextOrderBy,
    };
  }, [
    session,
    inboxParams,
    currentAvailableChannels,
    allowQueriesWithSQLFunctions,
  ]);

  const {
    data,
    loading,
    error,
    fetchMore,
    refetch,
    networkStatus,
    subscribeToMore,
  } = useQuery<InboxContactReadV2Query>(
    allowQueriesWithSQLFunctions
      ? GET_INBOX_CONTACT_DATA_QUERY
      : GET_INBOX_CONTACTS_QUERY,
    {
      variables: { ...extraParams, filters, orderBy },
      fetchPolicy: "network-only",
      refetchWritePolicy: "overwrite",
      skip: isLoadingAvailableChannels,
      notifyOnNetworkStatusChange: true,
    }
  );

  const {
    data: inboxContactsCountSubscription,
    loading: loadingCounter,
    subscribeToMore: subscribeToMoreCount,
  } = useQuery(
    getInboxContactCountQuery(
      allowQueryCountWithLimit,
      allowQueriesWithSQLFunctions
    ),
    {
      variables: { ...extraParams, filters },
      fetchPolicy: "network-only",
      refetchWritePolicy: "overwrite",
      skip: isLoadingAvailableChannels,
      notifyOnNetworkStatusChange: true,
    }
  );

  const count = useMemo(
    () =>
      inboxContactsCountSubscription?.api_inboxcontact_aggregate?.aggregate
        ?.count || 0,
    [inboxContactsCountSubscription]
  );

  const hasMoreData = useMemo(
    () =>
      hasFetchMore ||
      Boolean(count && (data?.api_inboxcontact?.length ?? 0) < count),
    [count, data, hasFetchMore]
  );

  const updating = useMemo(
    () =>
      isUpdatingFilters ||
      [NetworkStatus.setVariables, NetworkStatus.refetch].includes(
        networkStatus
      ),
    [isUpdatingFilters, networkStatus]
  );

  const handleFetchMore = useCallback((nextCursor) => {
    fetchMoreWithCursorRef.current?.(nextCursor);
  }, []);

  const handleInboxParams = useCallback(
    (nextInboxParams: Partial<InboxParams>) => {
      hasPendingRefetch.current = true;
      setIsUpdatingFilters(true);
      let hasSelectedView = false;
      for (const name in nextInboxParams) {
        if (name) {
          let value = nextInboxParams[name];
          let data: string | null = null;

          if (value && ("filters" !== name || hasItems(value))) {
            if (name === "view" && value) {
              value = value.id;
              hasSelectedView = true;
            }

            data = encodeParams(value);
          }

          if (data) {
            searchParams.set(name, data);
          } else {
            if (searchParams.get(name)) {
              searchParams.delete(name);
            }
          }
        }
      }

      if (hasSelectedView) {
        if (searchParams.get("filters")) {
          searchParams.delete("filters");
        }
        if (searchParams.get("ordering")) {
          searchParams.delete("ordering");
        }
      }

      setSearchParams(searchParams);
      setInboxParams(nextInboxParams);
      setCursor(null);
    },
    [searchParams, setSearchParams, setInboxParams]
  );

  const handleChangeFilters = useCallback(
    async (values: InboxParamsValues) => {
      handleInboxParams({
        ordering: values.ordering,
        filters: values.filters,
        view: values.view,
      });
    },
    [handleInboxParams]
  );

  const handleChangeSearch = useCallback(
    debounce((value) => {
      handleInboxParams({ search: value });
    }, 300),
    [handleInboxParams]
  );

  const handleGetInboxContact = useCallback(
    (id) =>
      data?.api_inboxcontact?.find((inboxContact) => inboxContact.id === id) ||
      null,
    [data]
  );

  const handleInboxContactCursor = useCallback(
    debounce((id) => {
      if (lastInboxContactCursor.current !== id) {
        lastInboxContactCursor.current = id;

        if (id) {
          if (handleGetInboxContactRef.current) {
            const { field: orderByField } = getOrderBy(orderBy)[0];
            const inboxContact = handleGetInboxContactRef.current(id);
            setCursor(inboxContact?.[orderByField] || null);
          }
        } else {
          setCursor(null);
        }
      }
    }, 300),
    [orderBy]
  );

  useEffect(() => {
    handleGetInboxContactRef.current = handleGetInboxContact;
  }, [handleGetInboxContact]);

  useEffect(() => {
    fetchMoreWithCursorRef.current = (nextCursor: string | null) => {
      if (!isUpdatingFilters) {
        fetchMore({
          variables: getVariablesWithCursor({
            filters,
            orderBy,
            cursor: nextCursor,
            extraParams,
          }),
          updateQuery: (prev, { fetchMoreResult }) => {
            const { field: orderByField, direction: orderDirection } =
              getOrderBy(orderBy)[0];
            const nextComparatorField = get(
              fetchMoreResult,
              `api_inboxcontact[0].${orderByField}`
            );

            if (!nextComparatorField) {
              setHasFetchMore(false);
              return prev;
            }

            let nextHasFetchMore = false;
            const isOrderByDesc = orderDirection !== "asc";
            const nextInboxContacts: InboxContactReadV2[] = [];

            for (const inboxContact of prev.api_inboxcontact) {
              if (
                inboxContact[orderByField] && isOrderByDesc
                  ? inboxContact[orderByField] > nextComparatorField
                  : inboxContact[orderByField] < nextComparatorField
              ) {
                nextInboxContacts.push(inboxContact);
              }
            }

            for (const index in fetchMoreResult.api_inboxcontact) {
              if (Number(index) < 10) {
                nextInboxContacts.push(fetchMoreResult.api_inboxcontact[index]);
              } else {
                nextHasFetchMore = true;
                break;
              }
            }

            setHasFetchMore(nextHasFetchMore);

            return { api_inboxcontact: nextInboxContacts };
          },
        });
      }
    };
  }, [isUpdatingFilters, filters, orderBy, extraParams, fetchMore]);

  useEffect(() => {
    if (loading || isUpdatingFilters || error || !allowSubscriptions) {
      return;
    }

    let limitByCursor: string | null = null;

    if (allowLiveQueryWithDateLimit && dataRef.current) {
      limitByCursor = getLimitByCursor(
        dataRef.current,
        lastInboxContactCursor.current
      );
    }

    const subscription = subscribeToMore(
      getSubscribeToMoreOptions({
        allowStreamingSubscription,
        allowQueriesWithSQLFunctions,
        limitByCursor,
        filters,
        orderBy,
        cursor,
        extraParams,
      })
    );

    return () => {
      subscription();
    };
  }, [
    loading,
    isUpdatingFilters,
    allowSubscriptions,
    allowStreamingSubscription,
    allowQueriesWithSQLFunctions,
    allowLiveQueryWithDateLimit,
    error,
    cursor,
    filters,
    orderBy,
    extraParams,
    subscribeToMore,
  ]);

  useEffect(() => {
    if (
      loadingCounter ||
      isUpdatingFilters ||
      error ||
      !allowSubscriptions ||
      !allowQueryCountSubscription
    ) {
      return;
    }

    const subscription = subscribeToMoreCount({
      document: getInboxContactCountSubscription(
        allowQueryCountWithLimit,
        allowQueriesWithSQLFunctions
      ),
      variables: { ...extraParams, filters },
      updateQuery: (prev, { subscriptionData }) => subscriptionData,
    });

    return () => {
      subscription();
    };
  }, [
    loadingCounter,
    isUpdatingFilters,
    allowSubscriptions,
    allowQueryCountWithLimit,
    allowQueryCountSubscription,
    allowQueriesWithSQLFunctions,
    error,
    filters,
    extraParams,
    subscribeToMoreCount,
  ]);

  useEffect(() => {
    if (hasPendingRefetch.current) {
      refetch();
      setIsUpdatingFilters(false);
      hasPendingRefetch.current = false;
    }
  }, [filters, orderBy, refetch]);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    if (!isPageVisible) {
      const applyAllowSubscritionsUpdate = setTimeout(() => {
        setAllowSubscriptions(false);
      }, 5000);

      return () => clearTimeout(applyAllowSubscritionsUpdate);
    }

    setAllowSubscriptions(true);
  }, [isPageVisible]);

  const orderByField = useMemo(() => getOrderBy(orderBy)[0].field, [orderBy]);

  return {
    data,
    refetch,
    count,
    error,
    loading,
    updating,
    loadingCounter,
    hasMoreData,
    orderByField,
    inboxContactId,
    allowQueryCountWithLimit,
    allowQueriesWithSQLFunctions,
    handleFetchMore,
    handleChangeSearch,
    handleChangeFilters,
    handleInboxContactCursor,
  };
}

export default useInboxContacts;
