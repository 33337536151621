import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CheckIcon,
  PencilAltIcon,
} from "@heroicons/react/outline";
import { useFormikContext } from "formik";
import { FlowData } from "@hilos/types/flow";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import RadioButtonsField from "src/components/Form/RadioButtonsField";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import useHilosStore from "src/hooks/useHilosStore";
import { getTranslationPayload as tr } from "src/i18n";
import FlowAdvancedOptionsDisclosure from "./components/FlowBuilderAdvancedOptionsDisclosure";
import { EXECUTION_FREQUENCY_UNITS } from "./constants/flow";

const FLOW_EXECUTION_TYPES = [
  {
    label: tr("flows:execution-type.inbound.label", "Inbound message"),
    value: "INBOUND",
    icon: <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />,
    help: tr(
      "flows:execution-type.inbound.help",
      "Triggers when we get a text containing a specific message from a customer."
    ),
  },
  {
    label: tr("flows:execution-type.outbound.label", "Outbound message"),
    value: "OUTBOUND",
    icon: <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />,
    help: tr(
      "flows:execution-type.outbound.help",
      "Can be triggered with existing contacts, a .csv upload, or via our API."
    ),
  },
];

function FlowBuilderDetails() {
  const [t] = useTranslation();
  const { session } = useHilosStore();
  const { values, setFieldValue } = useFormikContext<FlowData>();

  const availableFlowExecutionVariables = useMemo(
    () =>
      values.flow_execution_variables?.map((variable) => ({
        label: variable,
        value: variable,
      })) ?? [],
    [values.flow_execution_variables]
  );

  const handleChangeChannel = useCallback(() => {
    if (values.steps) {
      for (const index in values.steps) {
        switch (values.steps[index].step_type) {
          case "TEMPLATE":
            setFieldValue(`steps.${index}.whatsapp_template`, null);
            setFieldValue(`steps.${index}.whatsapp_template_selected`, null);
            setFieldValue(`steps.${index}.whatsapp_template_variables`, null);
            break;
          default:
            break;
        }
      }
    }
  }, [values, setFieldValue]);

  const formatOptionLabel = useCallback(
    ({ value, label, icon, help }) => (
      <div className="flex items-center py-3">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{t(label)}</h4>
          {help && <p className="mb-0 text-xs">{t(help)}</p>}
        </div>
      </div>
    ),
    [t]
  );

  return (
    <div className="overflow-y-auto w-full">
      <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto mt-6 max-w-2xl">
          <div className="space-y-14">
            <div className="row mt-10 text-center">
              <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                {t("flows:start-with-basics", "Let's start with the basics.")}
              </h1>
              <p className="mb-5 text-sm text-gray-500">
                {t(
                  "flows:first-instruction",
                  "Give this flow a name and choose how it starts."
                )}
              </p>
            </div>

            <div className="space-y-4">
              <TextInputField
                type="text"
                name="name"
                label={t("flows:name.label", "Flow name")}
                placeholder={t("flows:name.placeholder", "Flow #1")}
              />

              {session?.account.has_multiple_active_channels && (
                <ChannelSelectField
                  name="channel"
                  label={t(
                    "flows:channel.label",
                    "Channel to use for this flow"
                  )}
                  onChange={handleChangeChannel}
                />
              )}

              <SelectorField
                name="execution_type"
                label={t(
                  "flows:execution-type.label",
                  "How will this flow start?"
                )}
                formatOptionLabel={formatOptionLabel}
                options={FLOW_EXECUTION_TYPES}
              />

              <FlowAdvancedOptionsDisclosure>
                <SwitchField
                  name="should_trigger_webhook"
                  label={t(
                    "flows:should_trigger_webhook.label",
                    "Trigger webhooks for this flow?"
                  )}
                  help={t(
                    "flows:should_trigger_webhook.help",
                    "Enable this to receive webhooks when a contact joins or finishes this flow."
                  )}
                />

                {session?.account.feature_flags.includes(
                  "flow_execution_variables"
                ) && (
                  <SelectorField
                    isMulti
                    isCreatable
                    name="flow_execution_variables"
                    label={t(
                      "flows:flow-execution-variables.label",
                      "What execution variables will this flow have?"
                    )}
                    help={t(
                      "flows:flow-execution-variables.help",
                      "The variables declared here will be available in the flow steps to use and you will provide them when creating a execution of this flow."
                    )}
                    options={availableFlowExecutionVariables}
                  />
                )}

                <SwitchField
                  name="contact_can_run_multiple_times"
                  label={t(
                    "flows:contact_can_run_multiple_times.label",
                    "Can a contact go through this flow multiple times?"
                  )}
                  help={t(
                    "flows:contact_can_run_multiple_times.help",
                    "Use this to avoid sending multiple messages to this contact."
                  )}
                />

                {values.contact_can_run_multiple_times && (
                  <>
                    <RadioButtonsField
                      name="contact_execution_multiple_type"
                      options={[
                        {
                          value: "UNLIMITED",
                          label: t(
                            "flows:contact_can_run_multiple_times.unlimited",
                            "Unlimited times"
                          ),
                          disabled: false,
                          icon: CheckIcon,
                        },
                        {
                          value: "CUSTOM",
                          label: t(
                            "flows:contact_can_run_multiple_times.custom",
                            "Custom"
                          ),
                          disabled: false,
                          icon: PencilAltIcon,
                        },
                      ]}
                    />

                    {values.contact_execution_multiple_type === "CUSTOM" && (
                      <div className="text-sm font-medium text-gray-700">
                        {t(
                          "flows:contact_execution_multiple_type.label",
                          "Allow a contact to go through the flow if at least this time has passed"
                        )}
                        :
                        <div className="flex items-center space-x-3 mt-1">
                          <TextInputField
                            name="contact_execution_max_frequency_quantity"
                            type="number"
                            placeholder="3"
                          />
                          <div className="w-full">
                            <SelectorField
                              name="contact_execution_max_frequency_unit"
                              options={EXECUTION_FREQUENCY_UNITS.map(
                                (unit) => ({
                                  label: t(unit.label),
                                  value: unit.value,
                                })
                              )}
                              placeholder={t(
                                "flows:contact_execution_max_frequency_unit.placeholder",
                                "Select a unit"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </FlowAdvancedOptionsDisclosure>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FlowBuilderDetails;
