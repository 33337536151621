import React, { createRef, useEffect, useState } from "react";
import { DataSet, Network } from "vis-network/standalone/esm/vis-network";

// import { STEP_TYPES } from "./FlowStepMeta"

export default function FlowFormStepStepsDiagram({ formik }) {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const diagramRef = createRef();
  const network = createRef();

  const getNodes = () => {
    return formik.values.steps.map((step, idx) => ({
      id: idx,
      widthConstraint: { maximum: 120 },
      label: `Step ${idx + 1}`,
    }));
  };

  const getEdges = () => {
    const menuEdges = formik.values.steps
      .map((step, idx) => {
        return step.next_steps_for_options_idx
          ? step.next_steps_for_options_idx.map((nextStep) => ({
              from: idx,
              to: nextStep,
              arrows: {
                to: {
                  enabled: true,
                  type: "arrow",
                },
              },
            }))
          : [];
      })
      .filter((x) => x.length > 0)
      .flat();

    const nextStepEdges = formik.values.steps
      .map((step, idx) => {
        return step.next_step_default_idx
          ? {
              from: idx,
              to: step.next_step_default_idx,
              arrows: {
                to: {
                  enabled: true,
                  type: "arrow",
                },
              },
            }
          : null;
      })
      .filter((x) => x);

    const alternateStepEdges = formik.values.steps
      .map((step, idx) => {
        return step.next_step_alternate_idx
          ? {
              from: idx,
              to: step.next_step_alternate_idx,
              arrows: {
                to: {
                  enabled: true,
                  type: "arrow",
                },
              },
            }
          : null;
      })
      .filter((x) => x);

    const answerMaxAttemptsEdges = formik.values.steps
      .map((step, idx) => {
        return step.answer_failed_next_step_idx
          ? {
              from: idx,
              to: step.answer_failed_next_step_idx,
              arrows: {
                to: {
                  enabled: true,
                  type: "arrow",
                },
              },
            }
          : null;
      })
      .filter((x) => x);

    return [
      ...nextStepEdges,
      ...alternateStepEdges,
      ...answerMaxAttemptsEdges,
      ...menuEdges,
    ];
  };

  useEffect(() => {
    const newNodes = getNodes();
    const newEdges = getEdges();
    if (newNodes.length !== nodes.length) {
      setNodes(new DataSet(newNodes));
    }
    const simplifiedNewEdges = newEdges.map((e) => [e.from, e.to]);
    const simplifiedEdges = edges.map((e) => [e.from, e.to]);
    const edgesEqual =
      simplifiedEdges.length === simplifiedNewEdges.length &&
      simplifiedEdges.every(
        (value, index) => value === simplifiedNewEdges[index]
      );
    if (!edgesEqual) {
      setEdges(new DataSet(newEdges));
    }
    // eslint-disable-next-line
  }, [formik.values]);

  useEffect(() => {
    if (nodes.length < 1 || edges.length < 1) {
      return;
    }
    const data = {
      nodes: nodes,
      edges: edges,
    };
    const options = {
      nodes: {
        shape: "box",
        margin: 10,
        widthConstraint: {
          maximum: 200,
        },
      },
      physics: {
        enabled: false,
      },
      layout: {
        improvedLayout: true,
        hierarchical: {
          direction: "UD",
          parentCentralization: false,
          sortMethod: "directed",
          shakeTowards: "roots",
        },
      },
    };

    network.current = new Network(diagramRef.current, data, options);

    return () => {
      network && network.current.destroy();
    };

    // eslint-disable-next-line
  }, [nodes, edges]);

  return (
    <>
      <div ref={diagramRef} style={{ height: 600 }}></div>
    </>
  );
}
