import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { RadioGroup } from "@headlessui/react";
import {
  CreditCardIcon,
  ExclamationCircleIcon,
  ExternalLinkIcon,
  GlobeAltIcon,
  InformationCircleIcon,
  PhoneIcon,
} from "@heroicons/react/outline";
import MetaIcon from "src/components/icons/MetaIcon";
import { classNames } from "src/Helpers";
import connect from "src/assets/img/channels/connect.png";
import cloudAPILogo from "src/assets/img/channels/meta-logo.png";

interface PhoneNumberOption {
  key: string;
  name: string;
  description: string;
}
const PHONE_NUMBER_OPTIONS: PhoneNumberOption[] = [
  {
    key: "NEW",
    // i18n.t("channel:tech-provider.new-number.title", "Use a new number")
    name: "channel:tech-provider.new-number.title",
    // i18n.t("channel:tech-provider.new-number.description", "The easiest way to get started.")
    description: "channel:tech-provider.new-number.description",
  },
  {
    key: "WA_BUSINESS",
    // i18n.t("channel:tech-provider.wa-business-migration.title", "Migrate a number in use on the WhatsApp Business App")
    name: "channel:tech-provider.wa-business-migration.title",
    description: "",
  },
  {
    key: "WA_API",
    // i18n.t("channel:tech-provider.bsp-migration.title", "Migrate a number already connected to the WhatsApp API with another provider")
    name: "channel:tech-provider.bsp-migration.title",
    description: "",
  },
];

export default function TechProviderOnboardingInstructions({
  launchWhatsAppSignup,
  submitting,
}) {
  const { t } = useTranslation();
  const [fields, setFields] = useState({
    phone_number_type: null as PhoneNumberOption | null,
    phone_number_migrate_confirmation: false,
    has_business_manager_access: false,
    has_website: false,
  });

  const handleInputChange = (field, nextValue) => {
    const nextFields = { ...fields };
    nextFields[field] = nextValue;
    setFields(nextFields);
  };

  const canLogin =
    fields.phone_number_type !== null &&
    [
      fields.phone_number_type.key === "NEW" ||
        fields.phone_number_migrate_confirmation,
      fields.has_business_manager_access,
      fields.has_website,
    ].every((x) => x);

  return (
    <div>
      <div className="sm:px-6 lg:px-8 grid grid-cols-1 grid-rows-2 gap-4 py-4 px-4 sm:grid-cols-3 sm:grid-rows-1">
        <div className="sm:col-span-2">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {t(
              "channel:channel-connect-instructions-title",
              "Before connecting your new number, make sure you have all the following:"
            )}
          </h3>
          <div className="mt-4">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-3 sm:px-0 flex items-start">
                <div className="shrink-0">
                  <PhoneIcon className="h-5 w-5 mr-3 pt-1 text-gray-500 font-normal" />
                </div>
                <div className="grow">
                  <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center justify-between">
                    <span>
                      {t(
                        "channel:channel-connect-instructions.phone-number.title",
                        "Phone number to use with WhatsApp"
                      )}
                    </span>
                    <a
                      href="https://hilos.io/docs/user/getting-started/prerequisites#choosing-a-phone-number"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-center text-blue-500 font-normal"
                    >
                      <span>{t("help-me-choose", "Help me choose")}</span>
                      <ExternalLinkIcon
                        className="h-4 w-4 ml-1"
                        aria-hidden="true"
                      />
                    </a>
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 space-y-4">
                    <RadioGroup
                      value={fields["phone_number_type"]}
                      onChange={(val) =>
                        handleInputChange("phone_number_type", val)
                      }
                    >
                      <RadioGroup.Label className="sr-only">
                        Phone Number Options
                      </RadioGroup.Label>
                      <div className="-space-y-px rounded-md bg-white">
                        {PHONE_NUMBER_OPTIONS.map((phone, phoneIdx) => (
                          <RadioGroup.Option
                            key={phone.name}
                            value={phone}
                            className={({ checked }) =>
                              classNames(
                                phoneIdx === 0
                                  ? "rounded-tl-md rounded-tr-md"
                                  : "",
                                phoneIdx === PHONE_NUMBER_OPTIONS.length - 1
                                  ? "rounded-bl-md rounded-br-md"
                                  : "",
                                checked
                                  ? "z-10 border-indigo-200 bg-indigo-50"
                                  : "border-gray-200",
                                "relative flex cursor-pointer border p-4 focus:outline-none"
                              )
                            }
                          >
                            {({ active, checked }) => (
                              <>
                                <span
                                  className={classNames(
                                    checked
                                      ? "bg-indigo-600 border-transparent"
                                      : "bg-white border-gray-300",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-600"
                                      : "",
                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                  )}
                                  aria-hidden="true"
                                >
                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                </span>
                                <span className="ml-3 flex flex-col">
                                  <RadioGroup.Label
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-900"
                                        : "text-gray-900",
                                      "block text-sm"
                                    )}
                                  >
                                    {t(phone.name)}
                                  </RadioGroup.Label>
                                  <RadioGroup.Description
                                    as="span"
                                    className={classNames(
                                      checked
                                        ? "text-indigo-700"
                                        : "text-gray-500",
                                      "block text-xs"
                                    )}
                                  >
                                    {t(phone.description)}
                                  </RadioGroup.Description>
                                </span>
                              </>
                            )}
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>

                    <div>
                      {fields.phone_number_type &&
                        ["WA_BUSINESS", "WA_API"].includes(
                          fields.phone_number_type.key
                        ) && (
                          <div className="rounded-md bg-yellow-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationCircleIcon
                                  className="h-5 w-5 text-yellow-400"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="ml-3">
                                <h3 className="text-sm font-medium text-yellow-800">
                                  {t(
                                    "channel:tech-provider.before-you-continue",
                                    "Before you continue"
                                  )}
                                </h3>
                                <div className="mt-2 text-sm text-yellow-700 space-y-2">
                                  {fields.phone_number_type.key ===
                                    "WA_BUSINESS" && (
                                    <Trans i18nKey="channel:channel-connect-instructions.phone-number.wa-business-migrate">
                                      <p>
                                        WhatsApp does not allow a phone number
                                        to be used both in the WhatsApp Business
                                        App and the WhatsApp API.
                                      </p>
                                      <p>
                                        If you'd like to migrate this number,
                                        you'll need to completely close your
                                        WhatsApp account. All your conversations
                                        will be lost.
                                      </p>
                                    </Trans>
                                  )}
                                  {fields.phone_number_type.key ===
                                    "WA_API" && (
                                    <Trans i18nKey="channel:channel-connect-instructions.phone-number.wa-api-migrate">
                                      <p>
                                        When migrating from another provider,
                                        the number will keep its templates,
                                        quality status and Official WhatsApp
                                        Account badge (if set).
                                      </p>
                                      <p>
                                        All your current conversations will
                                        remain with your current provider.
                                      </p>
                                    </Trans>
                                  )}
                                  <p>
                                    <Trans i18nKey="channel:channel-connect-instructions.phone-number.learn-more">
                                      Learn more about this on our{" "}
                                      <a
                                        href="https://hilos.io/docs/user/getting-started/prerequisites#choosing-a-phone-number"
                                        target="_blank"
                                        rel="noreferrer"
                                        className="inline-flex items-center text-blue-500 font-normal"
                                      >
                                        <span>docs</span>
                                        <ExternalLinkIcon
                                          className="h-4 w-4 ml-1"
                                          aria-hidden="true"
                                        />
                                      </a>
                                      .
                                    </Trans>
                                  </p>
                                  <div className="-space-y-px w-full rounded-md bg-white border-gray-200 relative flex cursor-pointer border p-4 focus:outline-none">
                                    <div className="relative flex items-start">
                                      <div className="flex h-6 items-center">
                                        <input
                                          id="phone_number_migrate_confirmation"
                                          aria-describedby="phone_number_migrate_confirmation-description"
                                          name="phone_number_migrate_confirmation"
                                          type="checkbox"
                                          checked={
                                            fields[
                                              "phone_number_migrate_confirmation"
                                            ]
                                          }
                                          onChange={(ev) =>
                                            handleInputChange(
                                              "phone_number_migrate_confirmation",
                                              ev.target.checked
                                            )
                                          }
                                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        />
                                      </div>
                                      <div className="ml-3 text-sm leading-6">
                                        <label
                                          htmlFor="phone_number_migrate_confirmation"
                                          className="text-gray-900"
                                        >
                                          {t(
                                            "channel:channel-connect-instructions.phone-migrate-confirmation.checkbox",
                                            "I understand and wish to continue with this number."
                                          )}
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </dd>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-0 flex items-start justify-start">
                <div className="shrink-0">
                  <GlobeAltIcon className="h-5 w-5 mr-3 pt-1 text-gray-500 font-normal" />
                </div>
                <div className="grow">
                  <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center justify-start">
                    <span>
                      {t(
                        "channel:channel-connect-instructions.website.title",
                        "Website"
                      )}
                    </span>
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-2">
                    <div className="-space-y-px w-full rounded-md bg-white border-gray-200 relative flex cursor-pointer border p-4 focus:outline-none">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="has_website"
                            aria-describedby="has_website-description"
                            name="has_website"
                            type="checkbox"
                            checked={fields["has_website"]}
                            onChange={(ev) =>
                              handleInputChange(
                                "has_website",
                                ev.target.checked
                              )
                            }
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="has_website"
                            className="text-gray-900"
                          >
                            {t(
                              "channel:channel-connect-instructions.website.checkbox",
                              "I have an active website or Facebook Fan Page for my business."
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </dd>
                </div>
              </div>
              <div className="px-4 py-3 sm:px-0 flex items-start justify-start">
                <img
                  className="w-5 mr-3 pt-1 flex-shrink-0"
                  src={cloudAPILogo}
                  alt=""
                />
                <div className="grow">
                  <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center justify-between">
                    <span>
                      {t(
                        "channel:channel-connect-instructions.business-manager-access.title",
                        "Meta Business Manager Admin Access"
                      )}
                    </span>
                    <a
                      href="https://hilos.io/docs/user/getting-started/prerequisites#requisites"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-center text-blue-500 font-normal"
                    >
                      <span>
                        {t(
                          "channel:channel-connect-instructions.business-manager-access.help",
                          "How do I get this?"
                        )}
                      </span>
                      <ExternalLinkIcon
                        className="h-4 w-4 ml-1"
                        aria-hidden="true"
                      />
                    </a>
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-2">
                    <div className="-space-y-px w-full rounded-md bg-white border-gray-200 relative flex cursor-pointer border p-4 focus:outline-none">
                      <div className="relative flex items-start">
                        <div className="flex h-6 items-center">
                          <input
                            id="has_business_manager_access"
                            aria-describedby="has_business_manager_access-description"
                            name="has_business_manager_access"
                            type="checkbox"
                            checked={fields["has_business_manager_access"]}
                            onChange={(ev) =>
                              handleInputChange(
                                "has_business_manager_access",
                                ev.target.checked
                              )
                            }
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                          />
                        </div>
                        <div className="ml-3 text-sm leading-6">
                          <label
                            htmlFor="has_business_manager_access"
                            className="text-gray-900"
                          >
                            {t(
                              "channel:channel-connect-instructions.business-manager-access.checkbox",
                              "I have admin access to my business' Meta Business Manager."
                            )}
                          </label>
                        </div>
                      </div>
                    </div>
                  </dd>
                </div>
              </div>
              <div className="px-4 py-4 sm:px-0 flex items-start justify-start">
                <div className="shrink-0">
                  <CreditCardIcon className="h-5 w-5 mr-3 pt-1 text-gray-500 font-normal" />
                </div>
                <div className="grow">
                  <dt className="text-sm font-medium leading-6 text-gray-900 flex items-center justify-start">
                    <span className="text-gray-500 font-normal mr-1">
                      (Optional)
                    </span>
                    <span>
                      {t(
                        "channel:channel-connect-instructions.credit-card.title",
                        "A debit/credit card"
                      )}
                    </span>
                  </dt>
                  <dd className="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-2">
                    <Trans i18nKey="channel:channel-connect-instructions.credit-card.text">
                      <span className="flex-grow space-y-1">
                        <p>
                          This card will be used by Meta to charge the WhatsApp
                          fees.
                        </p>
                        <p>
                          You can add it later but you won't be able to start
                          outbound conversations until you do.
                        </p>
                      </span>
                    </Trans>
                  </dd>
                </div>
              </div>
            </dl>
          </div>
          {canLogin && (
            <div className="rounded-md bg-blue-50 p-4">
              <div className="flex">
                <div className="flex-shrink-0">
                  <InformationCircleIcon
                    className="h-5 w-5 text-blue-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm text-blue-800">
                    {t(
                      "channel:channel-connect-instructions.popup",
                      "Make sure you don't have any Ad blockers active and your browser allows popups to be able to login with Meta."
                    )}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        <div>
          <img
            className="flex-shrink-0"
            src={connect}
            alt="Connect your business' WA number to Hilos"
          />
        </div>
      </div>

      <div className="px-10 mb-4 mx-auto">
        {!submitting ? (
          <button
            onClick={launchWhatsAppSignup}
            disabled={!canLogin}
            type="button"
            className={classNames(
              canLogin
                ? "bg-facebook ring-facebook ring-1 ring-inset"
                : "bg-gray-300",
              "rounded px-4 py-3 font-semibold text-white shadow-sm flex items-center"
            )}
          >
            <MetaIcon className="h-5 w-5 mr-2" />
            {t("login-meta", "Login with Meta")}
          </button>
        ) : (
          <button
            onClick={launchWhatsAppSignup}
            type="button"
            className="rounded bg-gray-300 px-4 py-3 font-semibold text-white shadow-sm"
          >
            {t("logging-in", "Logging in...")}
          </button>
        )}
      </div>
    </div>
  );
}
