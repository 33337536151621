import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChevronRightIcon,
  ClipboardIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { NEXT_STEPS } from "../DashboardMeta";

export interface SandboxSectionProps {
  QRCode: string | null;
  urlWACode: string;
  connectedNumbers: (string | null)[] | undefined;
}

export default function SandboxSection({
  QRCode,
  urlWACode,
  connectedNumbers,
}: SandboxSectionProps) {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = urlWACode;

      textarea.style.position = "absolute";
      textarea.style.left = "-9999px";

      document.body.appendChild(textarea);

      textarea.select();
      document.execCommand("copy");

      document.body.removeChild(textarea);

      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard", error);
    }
  };

  return (
    <div className=" bg-blue-gray-light rounded-lg p-4 overflow-x-scroll">
      <div className="flex items-center mb-4 pb-4 border-b">
        <div>
          <Trans i18nKey="dashboard:sandbox.next-steps">
            <h2 className="mt-2 flex items-center text-lg font-bold leading-7 text-neutral-dark  sm:truncate sm:text-xl">
              What's next?
            </h2>
            <p className="mt-1 text-sm text-neutral">
              We’ve lent you a company number that’s already connected to Hilos.
              This lets you try absolutely everything, except for creating
              templates.
            </p>
            <p className="mt-1 text-sm text-neutral">
              While in the Sandbox, you can only message numbers that you have
              connected to the sandbox.{" "}
              <span className="font-semibold">
                Connect additional test numbers below.
              </span>
            </p>
          </Trans>
        </div>
      </div>
      <div className="lg:flex mt-4  overflow-x-scroll">
        <div className="bg-white rounded-lg shrink-0 overflow-clip">
          <div className=" w-full bg-green-50 px-2 flex relative py-4 items-center justify-center">
            <div className="mr-4 flex text-sm text-green-600 absolute left-4">
              {" "}
              <span className=" mr-2 h-2 w-2 animate-pulse self-center rounded-full bg-green-500" />{" "}
              {t("")}
            </div>
            <div className=" text-xs flex items-center text-green-700 font-medium">
              <FontAwesomeIcon
                // @ts-ignore
                icon={faWhatsapp}
                aria-hidden="true"
                className="mr-4 h-4 w-4"
              />
              {/* t("dashboard:sandbox.numbers-count_one", "{{count}} number in sandbox") */}
              {/* t("dashboard:sandbox.numbers-count_other", "{{count}} numbers in sandbox") */}
              {t("dashboard:sandbox.numbers-count", {
                count: connectedNumbers?.length,
              })}
            </div>
          </div>
          <div className="px-6">
            <Trans i18nKey="dashboard:sandbox.invite">
              <h2 className="mt-4 flex items-center text-sm font-bold leading-7 text-neutral-dark  sm:truncate sm:text-md">
                Invite your team to try Hilos
              </h2>
              <p className="text-xs text-neutral">
                Add more numbers to the sandbox and try sending them flows,
                messages, and more.
              </p>
            </Trans>
          </div>
          <div className="flex mb-2 w-full justify-center gap-4 p-4">
            <div className="inline-flex mr-4 rounded-lg bg-white">
              {QRCode ? (
                <img
                  className="mx-auto rounded-lg h-28 p-2"
                  src={QRCode}
                  alt="qr-code"
                />
              ) : (
                <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                  {t("loading")}
                </div>
              )}
            </div>
            <div className="self-center">
              <div className="w-full flex justify-center">
                <a
                  href={urlWACode}
                  rel="noreferrer"
                  target="_blank"
                  className="spread-shadow-gree h-fit inline-block rounded-lg bg-green-600 py-2 px-3 text-xs font-semibold text-white self-center"
                >
                  <FontAwesomeIcon
                    // @ts-ignore
                    icon={faWhatsapp}
                    aria-hidden="true"
                    className="mr-2"
                  />
                  {t("send-whatsapp", "Send WhatsApp")}
                </a>
              </div>
              <div className="w-full flex justify-center mt-2">
                <button
                  onClick={handleCopyClick}
                  disabled={isCopied}
                  className=" border-dashed border-gray-600 border w-full justify-center rounded-md px-3 py-1 flex text-xs text-gray-600 font-medium"
                >
                  <ClipboardIcon className="w-4 h-4 mr-2" />
                  {isCopied
                    ? t("dashboard:sandbox.copied-link", "Link copied!")
                    : t("dashboard:sandbox.copy-link", "Copy link")}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full min-h-full lg:ml-8 mt-8 lg:mt-0 flex-row flex overflow-x-scroll no-scrollbar whitespace-nowrap gap-8 ">
          {NEXT_STEPS.map((step) => (
            <div className="rounded-md bg-white overflow-clip w-52 shrink-0 h-full">
              <div
                className={`bg-${step.baseColor}-300 p-4 w-auto inline-flex rounded-br-full`}
              >
                <step.icon
                  className={`h-6 w-6 mr-2 mb-2 text-white ${
                    step.module === "flows" && "rotate-90"
                  }`}
                />
              </div>
              <div className="px-6 mt-4 overflow-x-clip">
                <div className="text-md font-semibold leading-5 whitespace-normal break-words w-full">
                  {t(step.title)}
                </div>
              </div>
              <div className="px-6 mt-2 overflow-x-clip">
                <div className="text-xs text-gray-500 leading-5 whitespace-normal break-words w-full">
                  {t(step.description)}
                </div>
              </div>
              <div className="flex justify-end px-6 mt-4 mb-2  w-full ">
                <Link
                  to={step.href}
                  className=" inline-flex p-3 items-center rounded-full justify-center  bg-gray-100 text-sm font-medium text-gray-900 hover:text-gray-500"
                >
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
