import create from "zustand";

interface CurrentNodeDragging {
  id: string;
  type: string;
  isMovingPath: boolean;
  nextStepIds: (string | undefined)[];
}

interface FlowBuilderStore {
  hasPendingRefresh: boolean;
  nextSelectedNodeId: string | null;
  nextStepIdToDelete: string | null;
  currentNodeDragging: CurrentNodeDragging | null;
  onFlowRefresh: (nextSelectedNodeId?: string | null) => void;
  onCompleteFlowRefresh: () => void;
  onSetStepToDelete: (id?: string | null) => void;
  onNodeDragging: (currentNodeDragging: CurrentNodeDragging | null) => void;
}

const useFlowBuilderStore = create<FlowBuilderStore>((set) => ({
  hasPendingRefresh: false,
  nextSelectedNodeId: null,
  nextStepIdToDelete: null,
  currentNodeDragging: null,
  onSetStepToDelete: (nextStepIdToDelete = null) => set({ nextStepIdToDelete }),
  onFlowRefresh: (nextSelectedNodeId = null) =>
    set({ nextSelectedNodeId, hasPendingRefresh: true }),
  onCompleteFlowRefresh: () =>
    set({ hasPendingRefresh: false, nextSelectedNodeId: null }),
  onNodeDragging: (currentNodeDragging = null) => set({ currentNodeDragging }),
}));

export default useFlowBuilderStore;
