import SwitchField from "../../../../../components/Form/SwitchField";
import TextInputField from "../../../../../components/Form/TextInputField";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionNumber({ formik, index }) {
  return (
    <>
      <div>
        <SwitchField
          label={FlowStepQuestionMeta.FIELDS.answer_allow_decimals.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_allow_decimals.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_allow_decimals.help}
        />
      </div>
      <div>
        <SwitchField
          label={FlowStepQuestionMeta.FIELDS.answer_has_range.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_has_range.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_has_range.help}
        />
      </div>
      {formik.values.steps[index].answer_has_range && (
        <div className="grid grid-cols-2 items-start space-x-4">
          <div>
            <TextInputField
              label={FlowStepQuestionMeta.FIELDS.answer_range_min.label}
              name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_range_min.key}`}
              help={FlowStepQuestionMeta.FIELDS.answer_range_min.help}
              type="number"
              placeholder="0"
            />
          </div>
          <div>
            <TextInputField
              label={FlowStepQuestionMeta.FIELDS.answer_range_max.label}
              name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_range_max.key}`}
              help={FlowStepQuestionMeta.FIELDS.answer_range_max.help}
              type="number"
              placeholder="100"
            />
          </div>
        </div>
      )}

      <div>
        <TextInputField
          label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
          placeholder={
            FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
          }
          type="text"
        />
      </div>
    </>
  );
}
