import { useCallback, useRef } from "react";
import { UseMutateAsyncFunction, useMutation, useQuery } from "react-query";
import { useApolloClient } from "@apollo/client";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import {
  ConversationEdit,
  ConversationStatus,
  ConversationStatusEnum,
  InboxContactEdit,
  InboxContactRead,
  PatchedContactEdit,
} from "@hilos/types/private-schema";
import { GET_INBOX_CONTACTS_QUERY } from "src/helpers/queries";
import { queryClient } from "../HilosProvider";
import { API_ROUTES, buildAPIRoute } from "../router/router";

interface FetchConversationDetailsParams {
  signal?: AbortSignal;
}

interface UseConversationDetailsParams {
  inboxContactId?: string;
}

interface InboxContactPayload extends InboxContactEdit {}

export type UpdateConversationStatusFn = UseMutateAsyncFunction<
  // string,
  ConversationStatusEnum | null,
  unknown,
  ConversationStatusEnum,
  unknown
>;

export type UpdateInboxContactFn = UseMutateAsyncFunction<
  InboxContactEdit | null,
  unknown,
  Partial<InboxContactPayload>,
  unknown
>;

// interface UpdateConversationFnProps extends Partial<Conversation> {
//   assigned_team?: string;
// }

export type UpdateConversationFn = (
  conversation: Partial<ConversationEdit>
) => void;

export type UpdateContactFn = (contact: Partial<PatchedContactEdit>) => void;

function useInboxContactDetails({
  inboxContactId,
}: UseConversationDetailsParams) {
  const isLastMessageFocusedRef = useRef(false);
  const { cache } = useApolloClient();
  const posthog = usePostHog();

  const fetchConversationDetails = useCallback(
    async ({ signal }: FetchConversationDetailsParams) => {
      if (inboxContactId) {
        const { data } = await axios.get<InboxContactRead>(
          buildAPIRoute(API_ROUTES.INBOX_CONTACT_DETAIL, {
            ":id": inboxContactId,
          }),
          { signal }
        );
        if (data.id !== inboxContactId) {
          window.location.assign(`/inbox/conversation/${data.id}/details`);
        }
        return data;
      }
      return null;
    },
    [inboxContactId]
  );

  const { data, refetch, isError, isLoading, isFetching } = useQuery(
    ["inbox_contact_details", inboxContactId],
    fetchConversationDetails,
    {
      retry: false,
      refetchInterval: 20000,
    }
  );

  const updateConversationStatus = useCallback(
    async (status: ConversationStatusEnum) => {
      let lastConversationId = null as string | null;
      if (data && data.last_conversation && data.last_conversation) {
        lastConversationId = data.last_conversation.id;
      }
      if (lastConversationId) {
        const { data } = await axios.post<ConversationStatus>(
          buildAPIRoute(API_ROUTES.CONVERSATION_STATUS, {
            ":id": lastConversationId,
          }),
          {
            status,
          }
        );
        return data;
      }
      return null;
    },
    [data]
  );

  const {
    mutateAsync: handleUpdateConversationStatus,
    isLoading: isSubmittingStatus,
  } = useMutation(updateConversationStatus, {
    onSuccess: (nextStatus) => {
      const currentData = queryClient.getQueryData<InboxContactRead>([
        "inbox_contact_details",
        inboxContactId,
      ]);
      if (data) {
        const newData = { ...data };
        if (newData.last_conversation) {
          newData.last_conversation = {
            ...newData.last_conversation,
            status: nextStatus?.status || newData.last_conversation?.status,
          };
        }
        queryClient.setQueryData(["inbox_contact_details", inboxContactId], {
          ...currentData,
          ...newData,
        });
      }
    },
  });

  const updateInboxContactDetails = useCallback(
    async (params: Partial<InboxContactPayload>) => {
      if (inboxContactId) {
        const { data } = await axios.patch<InboxContactEdit>(
          API_ROUTES.INBOX_CONTACT_DETAIL.replace(":id", inboxContactId),
          params
        );
        return data;
      }
      return null;
    },
    [inboxContactId]
  );

  const { mutateAsync: handleUpdateInboxContact, isLoading: isSubmitting } =
    useMutation(updateInboxContactDetails, {
      onSuccess: (nextData, variables) => {
        queryClient.setQueryData(
          ["inbox_contact_details", inboxContactId],
          nextData
        );

        if (!variables.is_unread) {
          cache.updateQuery(
            {
              query: GET_INBOX_CONTACTS_QUERY,
            },
            (data) => ({
              api_inboxcontact:
                data.api_inboxcontact &&
                data.api_inboxcontact.map((inboxContact) =>
                  inboxContact.id === inboxContactId
                    ? { ...inboxContact, ...variables }
                    : inboxContact
                ),
            })
          );
        }
      },
    });

  const handleSwitchSilenced = useCallback(() => {
    if (data) {
      handleUpdateInboxContact({
        is_silenced: !data.is_silenced,
      });
    }
  }, [data, handleUpdateInboxContact]);

  const handleMarkAsRead = useCallback(() => {
    handleUpdateInboxContact({
      is_unread: false,
      unseen_messages: 0,
    });
  }, [handleUpdateInboxContact]);

  const setIsLastMessageFocused = useCallback(
    (isLastMessageFocused = false) => {
      const focusIsChanging =
        isLastMessageFocusedRef.current !== isLastMessageFocused;

      isLastMessageFocusedRef.current = isLastMessageFocused;

      if (focusIsChanging) {
        if (isLastMessageFocused) {
          handleMarkAsRead();
          refetch();
        }
      }
    },
    [refetch, handleMarkAsRead]
  );

  const handleSwitchArchived = useCallback(() => {
    if (data) {
      handleUpdateInboxContact({
        is_archived: !data.is_archived,
      });
    }
  }, [data, handleUpdateInboxContact]);

  const handleUpdateConversation = useCallback(
    async (nextConversationData: Partial<ConversationEdit>) => {
      if (data && data.last_conversation && data.last_conversation.id) {
        const result = await axios.patch<ConversationEdit>(
          API_ROUTES.CONVERSATION_UPDATE.replace(
            ":id",
            data.last_conversation.id
          ),
          {
            id: data.last_conversation.id,
            ...nextConversationData,
          }
        );

        if (result && result.data) {
          queryClient.setQueryData(["inbox_contact_details", inboxContactId], {
            ...data,
            last_conversation: result.data,
          });
        }
      }
    },
    [data, inboxContactId]
  );

  return {
    inboxContact: data,
    isError,
    isLoading,
    isFetching,
    isSubmitting,
    isSubmittingStatus,
    setIsLastMessageFocused,
    handleMarkAsRead,
    handleSwitchSilenced,
    handleSwitchArchived,
    handleUpdateInboxContact,
    handleUpdateConversation,
    handleUpdateConversationStatus,
  };
}

export default useInboxContactDetails;
