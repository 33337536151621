import { useTranslation } from "react-i18next";
import { FormikProps } from "formik";

interface FlowStepContactUpdateNavProps {
  index: number;
  currentStep: string;
  setCurrentStep: (x: string) => void;
  formik: FormikProps<any>;
}

export default function FlowStepContactUpdateNav({
  index,
  currentStep,
  setCurrentStep,
  formik,
}: FlowStepContactUpdateNavProps) {
  const { t } = useTranslation();
  const STEPS = [
    {
      // i18n.t("basic-info", "Basic info")
      name: "basic-info",
      stepName: "default",
      current: currentStep === "default",
      hasErrors: false,
    },
    {
      // i18n.t("additional-attributes", "Adittional attributes")
      name: "additional-attributes",
      stepName: "additional",
      current: currentStep === "additional",
      hasErrors: false,
    },
  ];

  return (
    <nav aria-label="Nav">
      <ol className="space-y-4 md:flex md:space-y-0 md:space-x-5">
        {STEPS.map((step) => (
          <li key={step.name} className="md:flex-1">
            <>
              {step.current ? (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className="group flex w-full flex-col border-l-4 border-blue-600 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0"
                  aria-current="step"
                >
                  <span className="flex items-center text-tiny font-semibold uppercase tracking-wide text-blue-600">
                    {t(step.name)} {step.hasErrors}
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={(_) => setCurrentStep(step.stepName)}
                  className={`group flex w-full flex-col border-l-4 pl-4 pb-2 md:border-l-0 md:border-t-4 md:pl-0 md:pt-2 md:pb-0 ${
                    step.hasErrors
                      ? "border-red-200 hover:border-red-300"
                      : "border-gray-200 hover:border-gray-300"
                  }`}
                >
                  <span
                    className={`flex items-center text-tiny font-semibold uppercase tracking-wide ${
                      step.hasErrors ? "text-red-600" : "text-gray-600"
                    }`}
                  >
                    {t(step.name)} {step.hasErrors}
                  </span>
                </button>
              )}
            </>
          </li>
        ))}
      </ol>
    </nav>
  );
}
