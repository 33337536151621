import { useCallback, useEffect, useMemo } from "react";
import { FormikProps } from "formik";
import { WhatsAppTemplate } from "@hilos/types/wa/templates";
import WhatsAppTemplatePreview from "src/containers/wa/WhatsAppTemplatePreview";
import WhatsAppTemplateSelect from "src/containers/wa/WhatsAppTemplateSelect";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";
import { getTemplateComponents } from "src/helpers/template";
import useWhatsAppTemplate from "src/hooks/useWhatsAppTemplate";
import DelayUntilComponent from "../components/DelayUntilComponent";
import MaxAnswerAttemptsComponent from "../components/MaxAnswerAttemptsComponent";
import FlowStepTemplateForm from "./FlowStepTemplateForm";
import { FlowStepTemplateMeta } from "./Meta";

interface FlowStepTemplateProps {
  formik: FormikProps<any>;
  index: number;
  availableGroupedSteps: (x: number) => { value: string; label: string }[];
  formatGroupLabel;
}

export default function FlowStepTemplate({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}: FlowStepTemplateProps) {
  const templateId =
    formik.values.steps[index][
      FlowStepTemplateMeta.FIELDS.whatsapp_template.key
    ];
  const templateSelected =
    formik.values.steps[index][
      FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key
    ];
  const template = useWhatsAppTemplate(templateId);

  const setSelectedTemplate = useCallback(
    (template: WhatsAppTemplate | undefined) => {
      formik.setFieldValue(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key}`,
        template
      );
      formik.setFieldTouched(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key}`,
        true
      );
      formik.setFieldValue(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key}`,
        {
          headerURL: "",
          headerVar: "",
          bodyVars: [],
          buttonVars: [],
        },
        true
      );
      formik.setFieldTouched(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key}`,
        true
      );
    },
    []
  );

  useEffect(() => {
    if (!template) {
      return;
    }
    // Check if saved template is still valid, maybe the selected channel changed
    if (
      templateId &&
      !template.channel.is_sandbox &&
      template.channel.id !== formik.values.channel
    ) {
      formik.setFieldValue(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template.key}`,
        ""
      );
      setSelectedTemplate(undefined);
    }

    if (template && !templateSelected) {
      // Setting the value directly to keep the existing template variables
      formik.setFieldValue(
        `steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key}`,
        template
      );
    }
  }, [
    formik,
    index,
    templateId,
    templateSelected,
    template,
    setSelectedTemplate,
  ]);

  const isTemplateSelected = useMemo(() => {
    return [
      formik.values.steps[index][
        FlowStepTemplateMeta.FIELDS.whatsapp_template.key
      ],
      formik.values.steps[index][
        FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key
      ],
    ].every((x) => x);
  }, [formik.values, index]);

  const hasQuickReplyButtonsSelectedTemplate = useMemo(() => {
    if (!isTemplateSelected) {
      return false;
    }
    const { buttons } = getTemplateComponents(
      formik.values.steps[index][
        FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key
      ]
    );
    const quickReplyButtons = buttons
      ? buttons.buttons.filter((b) => b.type === "QUICK_REPLY")
      : [];
    return quickReplyButtons.length > 0;
  }, [formik.values, index, isTemplateSelected]);

  return (
    <>
      <WhatsAppTemplateSelect
        setSelectedTemplate={setSelectedTemplate}
        fieldName={`steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template.key}`}
        fieldLabel={FlowStepTemplateMeta.FIELDS.whatsapp_template.label}
        fieldHelp={FlowStepTemplateMeta.FIELDS.whatsapp_template.help}
        channel={formik.values.channel}
      />

      {isTemplateSelected && (
        <div className="space-y-4">
          <WhatsAppTemplatePreview
            template={
              formik.values.steps[index][
                FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key
              ]
            }
            values={
              formik.values.steps[index][
                FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key
              ] || {}
            }
          />

          <div>
            <FlowStepTemplateForm
              template={
                formik.values.steps[index][
                  FlowStepTemplateMeta.FIELDS.whatsapp_template_selected.key
                ]
              }
              formik={formik}
              previewValues={
                formik.values.steps[index][
                  FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key
                ]
              }
              formPrefix={`steps.${index}.${FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key}`}
              index={index}
            />

            {formik.errors.steps &&
              formik.errors.steps[index] &&
              formik.errors.steps[index][
                FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key
              ] &&
              Object.keys(
                formik.errors.steps[index][
                  FlowStepTemplateMeta.FIELDS.whatsapp_template_variables.key
                ]
              ).length > 0 && (
                <div className="mt-1.5 text-xs text-red-500">
                  * All variables for this template must be provided.
                </div>
              )}
          </div>

          <SwitchField
            label={FlowStepTemplateMeta.FIELDS.save_contact_answer.label}
            name={`steps.${index}.${FlowStepTemplateMeta.FIELDS.save_contact_answer.key}`}
            help={FlowStepTemplateMeta.FIELDS.save_contact_answer.help}
          />

          {hasQuickReplyButtonsSelectedTemplate && (
            <>
              <SwitchField
                label={
                  FlowStepTemplateMeta.FIELDS.validate_answer_with_buttons.label
                }
                name={`steps.${index}.${FlowStepTemplateMeta.FIELDS.validate_answer_with_buttons.key}`}
                help={
                  FlowStepTemplateMeta.FIELDS.validate_answer_with_buttons.help
                }
              />
              {formik.values.steps[index][
                FlowStepTemplateMeta.FIELDS.validate_answer_with_buttons.key
              ] && (
                <TextInputField
                  label={
                    FlowStepTemplateMeta.FIELDS.answer_validation_message.label
                  }
                  name={`steps.${index}.${FlowStepTemplateMeta.FIELDS.answer_validation_message.key}`}
                  help={
                    FlowStepTemplateMeta.FIELDS.answer_validation_message.help
                  }
                  placeholder={
                    FlowStepTemplateMeta.FIELDS.answer_validation_message
                      .placeholder
                  }
                  type="text"
                />
              )}
            </>
          )}
        </div>
      )}

      <SelectorField
        label={FlowStepTemplateMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepTemplateMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepTemplateMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
        formatGroupLabel={formatGroupLabel}
      />

      {formik.values.steps[index][
        FlowStepTemplateMeta.FIELDS.validate_answer_with_buttons.key
      ] && (
        <MaxAnswerAttemptsComponent
          formik={formik}
          index={index}
          availableGroupedSteps={availableGroupedSteps}
          formatGroupLabel={formatGroupLabel}
        />
      )}

      <DelayUntilComponent
        formik={formik}
        index={index}
        availableGroupedSteps={availableGroupedSteps}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
}
