import {
  BOOLEAN_VARS,
  COMPARISON_OPTIONS,
  DATETIME_COMPARISON_OPTIONS,
  GLOBAL_VARS,
  WEEKDAY_OPTIONS,
} from "../../../ComparisonMeta";
import {
  CalendarIcon,
  CheckIcon,
  GlobeAltIcon,
  PlusSmIcon,
  ShareIcon,
  TrashIcon,
  UserCircleIcon,
  XIcon,
} from "@heroicons/react/outline";

import DatePickerField from "../../../../../components/Form/DatePickerField";
import { FieldArray } from "formik";
import { FlowStepConditionalMeta } from "./Meta";
import RadioButtonsField from "../../../../../components/Form/RadioButtonsField";
import Select from "react-select";
import SelectorField from "../../../../../components/Form/SelectorField";
import TextInputField from "../../../../../components/Form/TextInputField";
import { getContactVariables } from "../../../../../helpers/utils";
import { useCustomFields } from "src/hooks/useContactCustomField";
import { useTranslation } from "react-i18next";

export default function FlowStepConditionalList({ formik, index }) {
  const { t } = useTranslation();
  const contactCustomFields = useCustomFields();
  const normalizeVariableNamesRegex = RegExp(/\s|-+/, "g");
  const getAvailableFields = () => {
    const availableQuestionSteps = formik.values.steps.filter(
      (step, idx) =>
        idx < index && step && step.name && step.step_type === "QUESTION"
    );
    const availableTemplateQuestionSteps = formik.values.steps.filter(
      (step, idx) =>
        idx < index &&
        step &&
        step.name &&
        step.step_type === "TEMPLATE" &&
        step.save_contact_answer
    );
    const availableMenuSteps = formik.values.steps.filter(
      (step, idx) =>
        idx < index && step && step.name && step.step_type === "MENU"
    );
    const availableActionSteps = formik.values.steps.filter(
      (step, idx) =>
        idx < index && step && step.name && step.step_type === "ACTION"
    );
    const flowVars = [].concat(
      ...availableTemplateQuestionSteps.map((step) => ({
        label: `flow.${step.name.replaceAll(normalizeVariableNamesRegex, "_")}`,
        value: `flow.${step.name.replaceAll(normalizeVariableNamesRegex, "_")}`,
      })),
      ...availableMenuSteps.map((step) => ({
        label: `flow.${step.name.replaceAll(normalizeVariableNamesRegex, "_")}`,
        value: `flow.${step.name.replaceAll(normalizeVariableNamesRegex, "_")}`,
      })),
      ...availableQuestionSteps.map((step) =>
        step.answer_type === "LOCATION"
          ? [
              {
                label: `flow.${step.name.replaceAll(
                  normalizeVariableNamesRegex,
                  "_"
                )}.lat`,
                value: `flow.${step.name.replaceAll(
                  normalizeVariableNamesRegex,
                  "_"
                )}.lat`,
              },
              {
                label: `flow.${step.name.replaceAll(
                  normalizeVariableNamesRegex,
                  "_"
                )}.lng`,
                value: `flow.${step.name.replaceAll(
                  normalizeVariableNamesRegex,
                  "_"
                )}.lng`,
              },
            ]
          : {
              label: `flow.${step.name.replaceAll(
                normalizeVariableNamesRegex,
                "_"
              )}`,
              value: `flow.${step.name.replaceAll(
                normalizeVariableNamesRegex,
                "_"
              )}`,
            }
      )
    );
    flowVars.push(...getAvailableActionVars(availableActionSteps));

    const contactVars = getContactVariables(contactCustomFields);
    const formattedContactVars = contactVars.map((contactVar) => ({
      label: `contact.${contactVar}`,
      value: `contact.${contactVar}`,
    }));
    const formattedGlobalVars = GLOBAL_VARS.map((globalVar) => ({
      label: `${t(globalVar.label)}`,
      value: `${globalVar.value}`,
    }));

    const groupedVars = [
      {
        label: "Flow Variables",
        icon: ShareIcon,
        options: flowVars,
      },
      {
        label: "Contact Variables",
        icon: UserCircleIcon,
        options: formattedContactVars,
      },
      {
        label: "Global Variables",
        icon: GlobeAltIcon,
        options: formattedGlobalVars,
      },
    ];

    return groupedVars;
  };

  const getAvailableActionVars = (steps) => {
    const mutableActionVars = [];
    steps.forEach((step) => {
      if (!!step.action_test_response_data) {
        getJsonKeys(
          mutableActionVars,
          step.action_test_response_data,
          step.name.replaceAll(normalizeVariableNamesRegex, "_")
        );
      }
    });
    return mutableActionVars;
  };

  function getJsonKeys(mutableActionVars, obj, stepName, path = "") {
    if (!obj || typeof obj !== "object") {
      mutableActionVars.push({
        label: `flow.${stepName}.${path}`,
        value: `flow.${stepName}.${path}`,
      });
      return;
    }
    if (Array.isArray(obj)) {
      mutableActionVars.push({
        label: `flow.${stepName}.${path}|length`,
        value: `flow.${stepName}.${path}|length`,
      });
    }
    return Object.keys(obj).map((key) =>
      getJsonKeys(
        mutableActionVars,
        obj[key],
        stepName,
        path
          ? [path, key.replaceAll(normalizeVariableNamesRegex, "_")].join(".")
          : key.replaceAll(normalizeVariableNamesRegex, "_")
      )
    );
  }

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            data.icon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  const selectVariable = (value, cIdx) => {
    formik.setFieldValue(
      `steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.field.key}`,
      value
    );
  };

  const getComparisonOptions = (field) => {
    const isGlobal = GLOBAL_VARS.find((variable) => variable.value === field);
    const isBool = BOOLEAN_VARS.includes(field);
    if (isBool) {
      return [{ label: "Is (equals)", value: "exact" }];
    }
    if (isGlobal) {
      return DATETIME_COMPARISON_OPTIONS.map((comparison) => ({
        ...comparison,
        label: t(comparison.label),
      }));
    }
    return COMPARISON_OPTIONS.map((comparison) => ({
      ...comparison,
      label: t(comparison.label),
    }));
  };

  const getFormField = (condition, cIdx) => {
    if (condition.comparison === "isnull") {
      return (
        <RadioButtonsField
          options={[
            {
              value: "true",
              label: "Yes",
              disabled: false,
              icon: CheckIcon,
            },
            {
              value: "false",
              label: "No",
              disabled: false,
              icon: XIcon,
            },
          ]}
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
        />
      );
    }
    if (condition.field === "IS_IN_WORKING_HOURS") {
      return (
        <RadioButtonsField
          options={[
            {
              value: "true",
              label: "Yes",
              disabled: false,
              icon: CheckIcon,
            },
            {
              value: "false",
              label: "No",
              disabled: false,
              icon: XIcon,
            },
          ]}
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
        />
      );
    }
    if (condition.field === "TIME") {
      return (
        <TextInputField
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
          type="time"
        />
      );
    }
    if (condition.field === "DAY") {
      return (
        <TextInputField
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
          type="number"
        />
      );
    }
    if (condition.field === "DATE") {
      return (
        <DatePickerField
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
          type="text"
          dateFormat="dd/MM/yyyy"
          minDate={new Date()}
          icon={CalendarIcon}
        />
      );
    }
    if (condition.field === "WEEKDAY") {
      return (
        <SelectorField
          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
          options={WEEKDAY_OPTIONS.map((option) => ({
            ...option,
            label: t(option.label, option.label),
          }))}
        />
      );
    }
    return (
      <TextInputField
        name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`}
        help={FlowStepConditionalMeta.FIELDS.conditions.value.help}
        type="text"
        containerClassName="relative rounded-md shadow-sm mb-2"
      />
    );
  };

  return (
    <>
      <div>
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          Conditions
        </h6>
        <p className="text-sm text-gray-500">
          These conditions will be AND'ed together when evaluating.
        </p>
      </div>
      <FieldArray
        name={`steps.${index}.conditions`}
        render={(arrayHelpers) => (
          <>
            <ul className="mt-2">
              {formik.values.steps &&
                formik.values.steps[index].conditions &&
                formik.values.steps[index].conditions.map((condition, cIdx) => (
                  <div
                    key={cIdx}
                    className="mt-2 flex items-start border-t border-gray-200 pt-2"
                  >
                    <div className="grid grow grid-cols-3 grid-rows-2 gap-2">
                      <div className="col-span-2">
                        <Select
                          value={{
                            value:
                              formik.values.steps[index].conditions[cIdx][
                                FlowStepConditionalMeta.FIELDS.conditions.field
                                  .key
                              ],
                            label:
                              formik.values.steps[index].conditions[cIdx][
                                FlowStepConditionalMeta.FIELDS.conditions.field
                                  .key
                              ],
                          }}
                          onChange={(option) => {
                            selectVariable(option.value, cIdx);
                            if (
                              GLOBAL_VARS.findIndex(
                                (variable) => variable.value === option.value
                              ) >= 0
                            ) {
                              formik.setFieldValue(
                                `steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.value.key}`,
                                ""
                              );
                              formik.setFieldValue(
                                `steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.comparison.key}`,
                                ""
                              );
                            }
                          }}
                          options={getAvailableFields()}
                          formatGroupLabel={formatGroupLabel}
                          menuPortalTarget={document.body}
                          menuPosition="fixed"
                          menuPlacement="auto"
                          styles={{
                            control: (base) => ({
                              ...base,
                              borderColor: "#ddd",
                              "&:hover": {
                                borderColor: "#ddd",
                              },
                              boxShadow: "none",
                              "*": {
                                boxShadow: "none !important",
                              },
                            }),
                            option: (base, { isFocused, isSelected }) => {
                              return {
                                ...base,
                                wordBreak: "break-all",
                                backgroundColor: isSelected
                                  ? "#d54466"
                                  : isFocused
                                  ? "#d54466"
                                  : undefined,
                                color: isSelected
                                  ? "#fff"
                                  : isFocused
                                  ? "#fff"
                                  : undefined,
                                fontSize: "small",
                                paddingLeft: "30px",
                              };
                            },
                          }}
                        ></Select>
                      </div>
                      <div className="">
                        <SelectorField
                          name={`steps.${index}.conditions.${cIdx}.${FlowStepConditionalMeta.FIELDS.conditions.comparison.key}`}
                          options={getComparisonOptions(condition.field)}
                        />
                      </div>

                      <div className="col-span-3">
                        {getFormField(condition, cIdx)}
                      </div>
                    </div>
                    <div className="ml-4 grow-0">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={(_) => arrayHelpers.remove(cIdx)}
                      >
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                ))}
            </ul>

            {formik.errors &&
              formik.errors.steps &&
              formik.errors.steps[index] &&
              formik.errors.steps[index].conditions &&
              typeof formik.errors.steps[index].conditions === "string" && (
                <p className="text-xs text-red-500">
                  {formik.errors.steps[index].conditions}
                </p>
              )}

            <div className="mt-4">
              <button
                type="button"
                className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                onClick={(_) =>
                  arrayHelpers.push({ field: "", comparison: "", value: "" })
                }
              >
                <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Add condition
              </button>
            </div>
          </>
        )}
      ></FieldArray>
    </>
  );
}
