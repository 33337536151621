import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useParams,
} from "react-router-dom";
import { PlusSmIcon } from "@heroicons/react/outline";
import axios from "axios";
import { differenceInDays, isValid } from "date-fns";
import { CursorPageData } from "@hilos/types/hilos";
import {
  ConversationContent,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import Loading from "src/components/Loading";
import useConversationContent, {
  SendMessageFn,
} from "src/hooks/useConversationContent";
import useInboxContactDetails from "src/hooks/useInboxContactDetails";
import useInboxContactSync from "src/hooks/useInboxContactSync";
import useMediaDropzone from "src/hooks/useMediaDropzone";
import { API_ROUTES } from "src/router/router";
import NewConversationModal from "../NewConversationModal";
import ConversationBlocked from "./ConversationBlocked";
import ConversationContentList from "./ConversationContentList";
import ConversationFooter from "./ConversationFooter";
import ConversationHeader from "./ConversationHeader";
import ConversationSearch from "./ConversationTab/ConversationContentSearch";
import DropzoneContainer from "./DropzoneContainer";
import FilesPreview from "./FilesPreview";
import ForwardMessageModal from "./ForwardMessageModal";

export interface HandleMountConversationContentPayload {
  id: string;
  messageId?: string | null;
  onFocus: () => void;
}

export type HandleMountConversationContent = (
  payload: HandleMountConversationContentPayload
) => () => void;

function Conversation() {
  const isFocusedWithMessage = useMatch(
    "/inbox/conversation/:inboxContactId/message/:messageId"
  );
  const isFocused =
    useMatch("/inbox/conversation/:inboxContactId") || isFocusedWithMessage;
  const { inboxContactId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const lastOutboundMessageIdRef = useRef<string | null>(null);
  const conversationContentByIdRef = useRef(new Map());
  const [showNewConversation, setShowNewConversation] = useState(false);
  const [selectedContextContentMessage, setSelectedContextContentMessage] =
    useState<ConversationContent | undefined>(undefined);
  const [selectedForwardMessage, setSelectedForwardMessage] = useState<
    WhatsAppMessage | undefined
  >(undefined);
  const [showConversationSearch, setShowConversationSearch] = useState(false);
  const [focusedConversationContentId, setFocusedConversationContentId] =
    useState<string | null>(null);
  const [disableMessageMediaUpload, setDisableMessageMediaUpload] =
    useState(false);

  const {
    inboxContact,
    isLoading,
    isSubmittingStatus,
    setIsLastMessageFocused,
    handleUpdateInboxContact,
    handleUpdateConversationStatus,
    handleMarkAsRead,
  } = useInboxContactDetails({ inboxContactId });

  const {
    pages,
    isLoading: contentIsLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    isSubmitting,
    isUpdatingCursor,
    hasNextPage,
    hasPreviousPage,
    hasFirstPageLoaded,
    handleNextPage,
    handlePreviousPage,
    handleSendMessage,
    handleChangeInitialCursor,
  } = useConversationContent({ inboxContactId });

  useInboxContactSync({ id: inboxContactId });

  const mustSendTemplate = useMemo(() => {
    if (inboxContact && inboxContact.last_inbound_message_on) {
      const lastInboundMessageTimestamp = new Date(
        inboxContact.last_inbound_message_on
      );

      return (
        !isValid(lastInboundMessageTimestamp) ||
        differenceInDays(new Date(), lastInboundMessageTimestamp) >= 1
      );
    }
    return true;
  }, [inboxContact]);

  const {
    files,
    setFiles,
    uploading,
    getRootProps,
    getInputProps,
    isDragActive,
    handleFilePicker,
    handleMediaSubmit,
    handleCancelUpload,
  } = useMediaDropzone({
    disabled: mustSendTemplate || disableMessageMediaUpload,
    handleSendMessage,
  });

  const handleSendAndScrollToMessage = useCallback<SendMessageFn>(
    async (data) => {
      const result = await handleSendMessage(data);
      if (!focusedConversationContentId) {
        lastOutboundMessageIdRef.current = result.id;
      }
      setSelectedContextContentMessage(undefined);
      return result;
    },
    [handleSendMessage, focusedConversationContentId]
  );

  const handleCloseNewConversation = useCallback(() => {
    setShowNewConversation(false);
  }, []);

  const handleShowNewConversation = useCallback(() => {
    setShowNewConversation(true);
  }, []);

  const handleCloseConversationSearch = useCallback(() => {
    setShowConversationSearch(false);
    setFocusedConversationContentId(null);
  }, []);

  const handleFocusMessage = async (messageId: string) => {
    if (inboxContactId) {
      const { data } = await axios.get<CursorPageData<ConversationContent>>(
        API_ROUTES.CONVERSATION_CONTENT.replace(":id", inboxContactId),
        {
          params: {
            search: messageId,
          },
        }
      );
      if (data && data.results) {
        handleFocusConversationContent(data.results[0]);
      }
    }
  };

  const handleFocusConversationContent = useCallback(
    async (conversationContent: ConversationContent) => {
      if (!conversationContentByIdRef.current.has(conversationContent.id)) {
        if (conversationContent.timestamp) {
          setFocusedConversationContentId(null);
          await handleChangeInitialCursor(conversationContent.timestamp);
        }
      }

      setFocusedConversationContentId(conversationContent.id);
    },
    [handleChangeInitialCursor]
  );

  const handleDisableMessageMediaUpload = useCallback(
    (nextDisableMessageMediaUpload: boolean) => {
      setDisableMessageMediaUpload(nextDisableMessageMediaUpload);
    },
    []
  );

  const handleMountConversationContent =
    useCallback<HandleMountConversationContent>(
      ({ id, messageId, onFocus }) => {
        if (
          lastOutboundMessageIdRef.current &&
          lastOutboundMessageIdRef.current === messageId
        ) {
          onFocus();
          lastOutboundMessageIdRef.current = null;
        }
        conversationContentByIdRef.current.set(id, onFocus);
        return () => {
          conversationContentByIdRef.current.delete(id);
        };
      },
      []
    );

  useEffect(() => {
    if (inboxContactId) {
      handleMarkAsRead();
      setShowConversationSearch(false);
      setFocusedConversationContentId(null);
    }
  }, [inboxContactId]);

  useEffect(() => {
    if (
      inboxContact &&
      inboxContact.merged_inbox_contact &&
      !location.pathname.includes(inboxContact.merged_inbox_contact)
    ) {
      const mergedInboxContactPath = location.pathname.replace(
        inboxContact.id,
        inboxContact.merged_inbox_contact
      );
      navigate(mergedInboxContactPath);
    }
  }, [navigate, location, inboxContact]);

  useEffect(() => {
    if (!isUpdatingCursor) {
      if (focusedConversationContentId) {
        const onFocusConversationContent =
          conversationContentByIdRef.current.get(focusedConversationContentId);

        if (onFocusConversationContent) {
          onFocusConversationContent();
        }
      }
    }
  }, [isUpdatingCursor, focusedConversationContentId]);

  if (isLoading || contentIsLoading) {
    return <Loading />;
  }

  if (!inboxContact || !inboxContactId) {
    // ?: isError from useInboxContactDetails maybe has more details
    return (
      <div className="align-center flex h-full w-full flex-col justify-center">
        <div className="text-center align-middle">
          <h4 className="">Looks like this conversation does not exist.</h4>
          <p className="mb-4 text-sm text-gray-500">
            Why don't you create one?
          </p>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleShowNewConversation}
          >
            <PlusSmIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true" />
            New conversation
          </button>
        </div>
        <NewConversationModal
          show={showNewConversation}
          onClose={handleCloseNewConversation}
        />
      </div>
    );
  }

  return (
    <>
      <section
        onPaste={(e) => {
          const pastedFiles = [...e.clipboardData.files].map((file) => ({
            source: file,
            preview: URL.createObjectURL(file),
          }));
          setFiles(pastedFiles);
        }}
        className={`h-full flex-col border-gray-200 lg:border-r ${
          isFocused && !showConversationSearch
            ? "flex-1 shrink-0"
            : "hidden w-full lg:block"
        }`}
        data-tour="convo"
      >
        <div
          {...getRootProps({
            className: "flex-1 flex flex-col h-full cursor-default",
          })}
        >
          {isDragActive ? (
            <DropzoneContainer />
          ) : files.length > 0 ? (
            <FilesPreview
              files={files}
              uploading={uploading}
              isSubmitting={isSubmitting}
              handleCancel={handleCancelUpload}
              handleFilePicker={handleFilePicker}
              handleMediaSubmit={handleMediaSubmit}
            />
          ) : (
            <>
              <ConversationHeader
                inboxContact={inboxContact}
                showConversationSearch={showConversationSearch}
                handleUpdateInboxContact={handleUpdateInboxContact}
                // @ts-ignore
                onUpdateConversationStatus={handleUpdateConversationStatus}
                isSubmittingStatus={isSubmittingStatus}
                setShowConversation={setShowConversationSearch}
              />
              {inboxContact.is_blocked ? (
                <ConversationBlocked />
              ) : (
                <>
                  <ConversationContentList
                    inboxContact={inboxContact}
                    pages={pages}
                    isUpdatingCursor={isUpdatingCursor}
                    isFetchingNextPage={isFetchingNextPage}
                    isFetchingPreviousPage={isFetchingPreviousPage}
                    hasNextPage={hasNextPage}
                    hasPreviousPage={hasPreviousPage}
                    hasFirstPageLoaded={hasFirstPageLoaded}
                    setIsLastMessageFocused={setIsLastMessageFocused}
                    handleNextPage={handleNextPage}
                    handlePreviousPage={handlePreviousPage}
                    focusedConversationContentId={focusedConversationContentId}
                    onMountConversationContent={handleMountConversationContent}
                    handleSelectContextMessage={
                      setSelectedContextContentMessage
                    }
                    handleSelectForwardMessage={setSelectedForwardMessage}
                    onFocusMessage={handleFocusMessage}
                    onCloseConversationSearch={handleCloseConversationSearch}
                  />
                  <ConversationFooter
                    key={`conversation-footer-${inboxContactId}`}
                    inboxContact={inboxContact}
                    mustSendTemplate={mustSendTemplate}
                    getInputProps={getInputProps}
                    handleSendMessage={handleSendAndScrollToMessage}
                    handleUpdateInboxContact={handleUpdateInboxContact}
                    handleCancelContextContentMessage={() =>
                      setSelectedContextContentMessage(undefined)
                    }
                    onFocusConversationContent={handleFocusConversationContent}
                    onDisableMessageMediaUpload={
                      handleDisableMessageMediaUpload
                    }
                    contextContentMessage={selectedContextContentMessage}
                  />
                  {selectedForwardMessage && (
                    <ForwardMessageModal
                      message={selectedForwardMessage}
                      handleSelectedForwardMessage={setSelectedForwardMessage}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </section>
      {showConversationSearch ? (
        <ConversationSearch
          inboxContactId={inboxContactId}
          focusedConversationContentId={focusedConversationContentId}
          onClose={handleCloseConversationSearch}
          onFocusConversationContent={handleFocusConversationContent}
        />
      ) : (
        <Outlet />
      )}
    </>
  );
}

export default Conversation;
