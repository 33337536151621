import { ViewListIcon } from "@heroicons/react/outline";

export const getInteractiveMessageComponents = (message) => {
  let body = null;
  let type = null;
  let action = null;

  if (message) {
    for (const component in message.content) {
      switch (component) {
        case "body":
          body = message.content[component];
          break;
        case "type":
          type = message.content[component];
          break;
        case "action":
          action = message.content[component];
          break;
        default:
          break;
      }
    }
  }

  return { body, type, action };
};

function InteractiveMessage({ message, isPreview = false }) {
  if (message.direction === "INBOUND") {
    return (
      <>
        <button
          type="button"
          className="flex w-full items-center justify-center rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
        >
          {message.body}
        </button>
      </>
    );
  }

  const { body, type, action } = getInteractiveMessageComponents(message);

  if (isPreview) {
    return (
      <p className="whitespace-pre-wrap break-words text-left truncate line-clamp-2 text-gray-500">
        {body && body.text}
      </p>
    );
  }
  if (type === "list") {
    return (
      <>
        <div className="flex items-center justify-around">
          <div className="w-80">
            <div className="space-y-2 rounded-t-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
              <div className="whitespace-pre-line">{body && body.text}</div>
            </div>

            {action && (
              <>
                {action.button && (
                  <button
                    type="button"
                    className="flex w-full items-center justify-center rounded-b-lg border border-b border-gray-200 border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                  >
                    <ViewListIcon className="mr-1 h-4 w-4" />
                    {action.button}
                  </button>
                )}
                {action.sections &&
                  action.sections.map((section, sectionIdx) => (
                    <div
                      className="mt-2 rounded-lg bg-white divide-y divide-gray-200 border border-gray-200 overflow-hidden"
                      key={sectionIdx}
                    >
                      {section && section.title && (
                        <p className="px-2.5 py-1.5 font-medium text-black">
                          {section.title}
                        </p>
                      )}
                      {section.rows &&
                        section.rows.map((row, rowIdx) => (
                          <button
                            key={rowIdx}
                            type="button"
                            className="block w-full py-1.5 px-2.5 text-center text-sm text-blue-500 hover:bg-gray-100"
                          >
                            {row && row.title}
                          </button>
                        ))}
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  } else if (type === "button") {
    return (
      <>
        <div className="flex items-center justify-around">
          <div className="w-80 space-y-2">
            <div className="space-y-2 rounded-lg bg-whatsapp-outgoing py-2 px-3 text-left text-sm text-gray-900">
              <div className="whitespace-pre-line">{body.text}</div>

              {action && action.buttons && (
                <div className="space-y-0.5">
                  {action.buttons.map((button, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className="w-full rounded-lg border border-gray-200 bg-white py-1.5 px-2.5 text-center text-sm text-blue-500 shadow-sm hover:bg-gray-100"
                    >
                      {button.reply && button.reply.title}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
}

export default InteractiveMessage;
