import { useCallback, useState } from "react";
import {
  PlusSmIcon,
  QuestionMarkCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FieldArray } from "formik";
import listImg from "../../../../../assets/img/flow/list.webp";
import SelectorField from "../../../../../components/Form/SelectorField";
import TextInputField from "../../../../../components/Form/TextInputField";
import DelayUntilComponent from "../components/DelayUntilComponent";
import MaxAnswerAttemptsComponent from "../components/MaxAnswerAttemptsComponent";
import FlowStepMessageText from "../message/FlowStepMessageText";
import { FlowStepMenuMeta } from "./Meta";

export default function FlowStepMenu({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  const [showListExample, setShowListExample] = useState(false);

  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
    </div>
  );

  const toggleShowListExample = useCallback(() => {
    setShowListExample((s) => !s);
  }, [setShowListExample]);

  const maxOptionsLength = (answerType, answerOptionsRender) => {
    if (answerType === "BOOL") {
      return 2;
    }
    if (answerType === "SINGLE_OPTION") {
      if (answerOptionsRender === "BUTTONS") {
        return 3;
      }
      if (answerOptionsRender === "LIST") {
        return 10;
      } else {
        return 15;
      }
    }
  };

  const onAnswerOptionsRenderChange = (optionsRenderAs, index) => {
    const answerType = formik.values.steps[index].answer_type;
    const maxLength = maxOptionsLength(answerType, optionsRenderAs);
    const options = formik.values.steps[index].answer_options;
    if (options.length > maxLength) {
      const newOptions = options.slice(0, maxLength);
      formik.setFieldValue(`steps.${index}.answer_options`, newOptions);
    }
  };

  const addOption = () => {
    const answer_options = formik.values.steps[index].answer_options;
    const next_steps = formik.values.steps[index].next_steps_for_options_idx;

    if (!Array.isArray(answer_options) || answer_options.length === 0) {
      formik.setFieldValue(`steps.${index}.answer_options`, [""]);
    } else {
      answer_options.push("");
      formik.setFieldValue(`steps.${index}.answer_options`, answer_options);
    }

    if (!Array.isArray(next_steps) || next_steps.length === 0) {
      formik.setFieldValue(`steps.${index}.next_steps_for_options_idx`, [""]);
    } else {
      next_steps.push("");
      formik.setFieldValue(
        `steps.${index}.next_steps_for_options_idx`,
        next_steps
      );
    }
  };

  const removeOption = (optionIndex) => {
    const answer_options = formik.values.steps[index].answer_options;
    const next_steps = formik.values.steps[index].next_steps_for_options_idx;

    if (!Array.isArray(answer_options) || answer_options.length === 0) {
      formik.setFieldValue(`steps.${index}.answer_options`, [""]);
    } else {
      answer_options.splice(optionIndex, 1);
      formik.setFieldValue(`steps.${index}.answer_options`, answer_options);
    }

    if (!Array.isArray(next_steps) || next_steps.length === 0) {
      formik.setFieldValue(`steps.${index}.next_steps_for_options_idx`, [""]);
    } else {
      next_steps.splice(optionIndex, 1);
      formik.setFieldValue(
        `steps.${index}.next_steps_for_options_idx`,
        next_steps
      );
    }
  };

  return (
    <>
      <FlowStepMessageText formik={formik} index={index} />

      <div>
        <SelectorField
          label={FlowStepMenuMeta.FIELDS.answer_options_render.label}
          name={`steps.${index}.${FlowStepMenuMeta.FIELDS.answer_options_render.key}`}
          help={FlowStepMenuMeta.FIELDS.answer_options_render.help}
          options={FlowStepMenuMeta.ANSWER_OPTIONS_RENDER_TYPES}
          formatOptionLabel={formatOptionLabel}
          onSelect={(option) =>
            onAnswerOptionsRenderChange(option.value, index)
          }
        />
        {formik.values.steps[index].answer_options_render === "LIST" && (
          <button
            className="mt-1 flex items-center text-sm text-blue-500"
            type="button"
            onClick={toggleShowListExample}
          >
            <QuestionMarkCircleIcon className="mr-0.5 h-4 w-4" />
            {showListExample ? "Hide example" : "Show example"}
          </button>
        )}
      </div>

      {formik.values.steps[index].answer_options_render === "LIST" && (
        <div className="space-y-4">
          {showListExample && (
            <div className="text-center text-sm italic text-gray-500">
              <img src={listImg} alt="Message with list example" />
              <p>Example of a message with an interactive list</p>
            </div>
          )}
          <div>
            <TextInputField
              label={
                FlowStepMenuMeta.FIELDS.answer_options_render_list_button_title
                  .label
              }
              name={`steps.${index}.${FlowStepMenuMeta.FIELDS.answer_options_render_list_button_title.key}`}
              placeholder={
                FlowStepMenuMeta.FIELDS.answer_options_render_list_button_title
                  .placeholder
              }
              type="text"
              maxLength={20}
            />
          </div>
        </div>
      )}

      <div>
        <p className="block text-sm font-medium text-gray-700">
          Which options can the user choose from?
        </p>

        {formik.values.steps[index].answer_options_render === "EMOJIS" && (
          <p className="text-xs text-gray-500">
            We'll add these options to the question text along with emoji
            numbers (<code>1️⃣</code> , <code>2️⃣</code>, <code>...</code>) to let
            the customer know how to answer.
          </p>
        )}
        {formik.values.steps[index].answer_options_render === "NUMBERS" && (
          <p className="text-xs text-gray-500">
            We'll add these options to the question text along with numbers (
            <code>1</code>, <code>2</code>, <code>...</code>) to let the
            customer know how to answer.
          </p>
        )}
        <div className="mt-2">
          <FieldArray
            name={`steps.${index}.answer_options`}
            render={(_) => (
              <>
                <div className="list-group list-group-flush">
                  {formik.values.steps &&
                    formik.values.steps[index].answer_options &&
                    formik.values.steps[index].answer_options.map((_, oIdx) => (
                      <div key={oIdx}>
                        <div className="flex items-center py-1">
                          <div className="mr-4 grow-0">
                            <code>{oIdx + 1}</code>
                          </div>
                          <div className="grow">
                            <TextInputField
                              name={`steps.${index}.${FlowStepMenuMeta.FIELDS.answer_options.key}.${oIdx}`}
                              placeholder={
                                FlowStepMenuMeta.FIELDS.answer_options
                                  .placeholder
                              }
                              type="text"
                              maxLength={
                                formik.values.steps[index]
                                  .answer_options_render === "BUTTONS"
                                  ? 20
                                  : formik.values.steps[index]
                                      .answer_options_render === "LIST"
                                  ? 24
                                  : null
                              }
                            />
                          </div>

                          <div className="ml-4 grow-0">
                            <button
                              type="button"
                              className="inline-flex w-full items-center justify-center rounded-md border border-red-500 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-700 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                              onClick={(_) => removeOption(oIdx)}
                            >
                              <XIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                          </div>
                        </div>

                        <div className="mr-2 mt-2 pl-7 text-sm text-gray-600">
                          If chosen, go to...
                        </div>
                        <div className="mb-3  py-1 pl-7">
                          <SelectorField
                            name={`steps.${index}.${FlowStepMenuMeta.FIELDS.next_steps_for_options_idx.key}.${oIdx}`}
                            options={availableGroupedSteps(index)}
                            formatGroupLabel={formatGroupLabel}
                          />
                        </div>
                      </div>
                    ))}
                </div>

                {formik.errors &&
                  formik.errors.steps &&
                  formik.errors.steps[index] &&
                  formik.errors.steps[index].answer_options &&
                  typeof formik.errors.steps[index].answer_options ===
                    "string" && (
                    <p className="text-danger mb-2">
                      {formik.errors.steps[index].answer_options}
                    </p>
                  )}

                {formik.values.steps[index].answer_options &&
                  formik.values.steps[index].answer_options.length <
                    maxOptionsLength(
                      formik.values.steps[index].answer_type,
                      formik.values.steps[index].answer_options_render
                    ) && (
                    <button
                      type="button"
                      className="mt-2 inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                      onClick={(_) => addOption()}
                    >
                      <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      Add option
                    </button>
                  )}
              </>
            )}
          ></FieldArray>
        </div>
      </div>

      <TextInputField
        label="If the user didn't select a valid option, answer with"
        name={`steps.${index}.${FlowStepMenuMeta.FIELDS.answer_validation_message.key}`}
        placeholder="Please select one of the options above."
        type="text"
      />

      <MaxAnswerAttemptsComponent
        formik={formik}
        index={index}
        availableGroupedSteps={availableGroupedSteps}
        formatGroupLabel={formatGroupLabel}
      />

      <DelayUntilComponent
        formik={formik}
        index={index}
        availableGroupedSteps={availableGroupedSteps}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
}
