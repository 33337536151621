import {
  ExclamationIcon,
  ShareIcon,
  UserCircleIcon,
  VariableIcon,
  XIcon,
} from "@heroicons/react/outline";
import {
  filterByStepType,
  getAvailableActionVariables,
  getAvailableQuestionVariables,
  getAvailableStepVariables,
} from "src/helpers/flow";
import { getContactVariables, hasItems } from "../../helpers/utils";

import Select from "react-select";
import { useCustomFields } from "src/hooks/useContactCustomField";
import { useMemo } from "react";

function FlowAddVariableForm({
  formik,
  addVariable,
  currentStepIndex,
  setShowVariableSelect,
  listIndex,
}) {
  const { contactCustomFields } = useCustomFields();

  const hasMissingActionTest = useMemo(
    () =>
      formik.values.steps.some(
        filterByStepType(
          "ACTION",
          currentStepIndex,
          (step) => !hasItems(step.action_responses)
        )
      ),
    [formik.values, currentStepIndex]
  );

  const options = useMemo(() => {
    const availableQuestionSteps = formik.values.steps.filter(
      filterByStepType("QUESTION", currentStepIndex)
    );
    const availableTemplateQuestionSteps = formik.values.steps.filter(
      filterByStepType(
        "TEMPLATE",
        currentStepIndex,
        (step) => step.save_contact_answer
      )
    );
    const availableTemplateMenuQuestionSteps = formik.values.steps.filter(
      filterByStepType(
        "TEMPLATE_MENU",
        currentStepIndex,
        (step) => step.next_action !== "CONTINUE"
      )
    );
    const availableMenuSteps = formik.values.steps.filter(
      filterByStepType("MENU", currentStepIndex)
    );
    const availableActionSteps = formik.values.steps.filter(
      filterByStepType("ACTION", currentStepIndex)
    );

    const availableHubspotSteps = formik.values.steps.filter(
      filterByStepType("HUBSPOT_CONTACT_GET", currentStepIndex)
    );
    const flowVariables = [
      ...getAvailableStepVariables(availableTemplateQuestionSteps),
      ...getAvailableStepVariables(availableTemplateMenuQuestionSteps),
      ...getAvailableStepVariables(availableMenuSteps),
      ...getAvailableQuestionVariables(availableQuestionSteps),
      ...getAvailableActionVariables(availableActionSteps),
      ...getAvailableActionVariables(availableHubspotSteps),
    ];
    const contactVariables = getContactVariables(contactCustomFields);

    const flowExecutionVariables = formik.values.flow_execution_variables ?? [];

    return [
      {
        label: "Flow Variables",
        icon: ShareIcon,
        options: flowVariables.map((name) => ({
          label: `flow.${name}`,
          value: `{{flow.${name}}}`,
        })),
      },
      {
        label: "Contact Variables",
        icon: UserCircleIcon,
        options: contactVariables.map((name) => ({
          label: `contact.${name}`,
          value: `{{contact.${name}}}`,
        })),
      },
      {
        label: "Execution Variables",
        icon: VariableIcon,
        options: flowExecutionVariables.map((name) => ({
          label: `flow_execution_variables.${name}`,
          value: `{{flow_execution_variables.${name}}}`,
        })),
      },
    ];
  }, [formik.values, currentStepIndex, contactCustomFields]);

  const selectVariable = (value) => {
    setShowVariableSelect(false);
    addVariable(value, listIndex);
  };

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            data.icon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  return (
    <div>
      {formik.values.execution_type === "INBOUND" && (
        <div className="my-2 flex rounded-md bg-yellow-50 p-4">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-yellow-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              For Inbound flows, keep in mind that it's likely that people that
              join the flow won't be saved as a contact, so they won't have any{" "}
              <code>contact.*</code> variables set.
            </div>
          </div>
        </div>
      )}
      {hasMissingActionTest && (
        <div className="my-2 flex rounded-md bg-red-50 p-4">
          <div className="flex-shrink-0">
            <ExclamationIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <div className="text-xs text-red-700">
              Click{" "}
              <code className="bg-green-50 px-2 font-semibold text-green-500">
                Test Request
              </code>{" "}
              button to get all action step variables.
            </div>
          </div>
        </div>
      )}

      <p className="my-1 text-xs text-gray-500">
        You can insert variables from available contact fields, as well as from
        previous steps from this same flow.
      </p>

      <div className="flex items-center">
        <div className="grow">
          <Select
            options={options}
            formatGroupLabel={formatGroupLabel}
            onChange={(option) => selectVariable(option.value)}
            menuPortalTarget={document.body}
            menuPosition="fixed"
            menuPlacement="auto"
            styles={{
              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              control: (base) => ({
                ...base,
                borderColor: "#ddd",
                "&:hover": {
                  borderColor: "#ddd",
                },
                boxShadow: "none",
                "*": {
                  boxShadow: "none !important",
                },
              }),
              option: (base, { isFocused, isSelected }) => {
                return {
                  ...base,
                  wordBreak: "break-all",
                  backgroundColor: isSelected
                    ? "#d54466"
                    : isFocused
                    ? "#d54466"
                    : undefined,
                  color: isSelected ? "#fff" : isFocused ? "#fff" : undefined,
                  fontSize: "small",
                  paddingLeft: "30px",
                };
              },
            }}
          />
        </div>
        <div className="ml-2 grow-0">
          <button
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            type="button"
            onClick={(_) => setShowVariableSelect(false)}
          >
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default FlowAddVariableForm;
