import { useTranslation } from "react-i18next";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import { useConversationTags } from "src/hooks/useTags";

interface ConversationTagFilterProps {
  value: string[];
  onValueChange: (value: string[]) => void;
}

export default function ConversationTagFilter({
  value,
  onValueChange,
}: ConversationTagFilterProps) {
  const { tags } = useConversationTags();
  const { t } = useTranslation();
  return (
    <MultiSelect
      placeholder={t("conversation-tags")}
      value={value}
      onValueChange={onValueChange}
    >
      {tags.map((tag) => (
        <MultiSelectItem value={String(tag.id)} key={tag.id}>
          {tag.name}
        </MultiSelectItem>
      ))}
    </MultiSelect>
  );
}
