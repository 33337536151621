import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import { FilterIcon, PlusSmIcon, SearchIcon } from "@heroicons/react/outline";
import { InboxParams } from "@hilos/types/hilos";
import ChannelFilterField, {
  ChannelChoice,
} from "src/components/Form/ChannelFilterField";
import Loading from "src/components/Loading";
import { hasItems } from "src/helpers/utils";
import useHilosStore from "src/hooks/useHilosStore";
import { InboxParamsValues } from "./InboxParamsForm";

interface ConversationListHeaderProps {
  inboxParams: InboxParams;
  totalConversations: number;
  totalConversationsLoading: boolean;
  isPageVisible: boolean;
  allowQueryCountWithLimit: boolean;
  onChangeSearch: (value: string) => void;
  onSwitchShowFilters: () => void;
  onShowNewConversation: () => void;
  onChangeFilters: (params: InboxParamsValues) => void;
}

function ConversationListHeader({
  inboxParams,
  totalConversations,
  totalConversationsLoading,
  isPageVisible,
  allowQueryCountWithLimit,
  onChangeSearch,
  onSwitchShowFilters,
  onShowNewConversation,
  onChangeFilters,
}: ConversationListHeaderProps) {
  const { t } = useTranslation();
  const { session, updateSession } = useHilosStore();
  const [search, setSearch] = useState(() => inboxParams.search || "");

  const handleChangeSearch = useCallback(
    (event) => {
      const nextSearch = event.target.value || "";
      setSearch(nextSearch);
      onChangeSearch(nextSearch);
    },
    [onChangeSearch]
  );

  const handleSetAvailibilityForInbox = useCallback((nextVal) => {
    updateSession({
      is_available_for_inbox: nextVal,
    });
  }, []);

  const selectedChannelsIds = inboxParams.filters.find(
    (filter) => filter.field === "channel"
  )?.value as number[];

  const handleChannelChange = (
    selectedChannels: ChannelChoice[] | ChannelChoice
  ) => {
    const nextFilters = inboxParams.filters.filter(
      (filter) => filter.field !== "channel"
    );

    if (Array.isArray(selectedChannels)) {
      const selectedChannelIds = selectedChannels.map((ch) => ch.value);
      if (selectedChannelIds.length > 0) {
        nextFilters.push({
          field: "channel",
          operator: "_in",
          value: selectedChannels.map((ch) => ch.value),
        });
      }
    }
    onChangeFilters({
      view: null,
      ordering: inboxParams.ordering,
      filters: nextFilters,
    });
  };

  return (
    <>
      <div className="flex flex-col justify-center space-y-3 bg-white py-2 px-4 pt-3">
        <div className="flex items-center space-x-2">
          <div className="flex grow items-baseline space-x-2">
            <h2
              className="text-lg font-medium text-gray-900"
              data-tour="inbox-title"
            >
              {t("inbox", "Inbox")}
            </h2>
            {/* <HelpDocsLink
              href="https://hilos.io/docs/user/create-a-flow"
              label=""
              className="self-center"
            /> */}
            {totalConversationsLoading || !isPageVisible ? (
              <div className="w-5">
                <Loading className="h-3 w-3 text-gray-400" showText={false} />
              </div>
            ) : (
              <p className="text-sm font-medium text-gray-500">
                {/* t("inbox:conversation_one", "{{count}} chat") */}
                {/* t("inbox:conversation_other", "{{count}} chats") */}
                {/* t("inbox:conversation_interval", "(1000-inf)[+999 chats];") */}
                {allowQueryCountWithLimit
                  ? t("inbox:conversation_interval", {
                      count: totalConversations,
                      postProcess: "interval",
                    })
                  : t("inbox:conversation", { count: totalConversations })}
              </p>
            )}
          </div>
          <button
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 bg-gradient-to-r from-hilos to-red-400 px-3 py-1.5 text-sm font-medium text-white shadow-sm hover:bg-hilos focus:outline-none focus:ring-2 focus:ring-hilos focus:ring-offset-2"
            onClick={onShowNewConversation}
          >
            <PlusSmIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </div>
        {session?.account.feature_flags.includes(
          "require_agent_availability"
        ) && (
          <div className="group flex items-center justify-between">
            <p className="text-xs text-gray-500">
              {t("inbox:set-agent-availability", "Set yourself as available")}
            </p>
            <Switch
              checked={session?.is_available_for_inbox}
              onChange={handleSetAvailibilityForInbox}
              className={`${
                session?.is_available_for_inbox ? "bg-blue-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">
                {t("inbox:set-agent-availability", "Set yourself as available")}
              </span>
              <span
                className={`${
                  session?.is_available_for_inbox
                    ? "translate-x-6"
                    : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
        )}
        {session?.account.has_used_multiple_channels && (
          <div className="mx-auto mt-2 md:w-64 xl:w-72">
            <ChannelFilterField
              handleOptionChange={handleChannelChange}
              selected={selectedChannelsIds}
            />
          </div>
        )}
      </div>
      <form onSubmit={(ev) => ev.preventDefault()} className="bg-white pt-2">
        <div className="w-42 flex grow-0 flex-row items-center bg-white">
          <div className="pointer-events-none absolute left-0 pl-3">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            className="block w-full border-0 border-transparent pl-9 focus:ring-0 sm:text-sm"
            type="search"
            placeholder={t("search", "Search...")}
            value={search}
            onChange={handleChangeSearch}
          />
          <button
            onClick={onSwitchShowFilters}
            className="flex items-center px-3"
            data-tour="filter-button"
          >
            <FilterIcon
              className="h-5 w-5 cursor-pointer text-gray-400"
              aria-hidden="true"
            />
            {hasItems(inboxParams.filters) && (
              <div className="pointer-events-none absolute right-1 mb-5 flex h-4 w-4 min-w-min grow items-center justify-center rounded-full bg-indigo-500 px-1">
                <span className="text-xs font-medium text-indigo-100">
                  {inboxParams.filters.length > 9
                    ? "9+"
                    : inboxParams.filters.length}
                </span>
              </div>
            )}
          </button>
        </div>
      </form>
    </>
  );
}

export default ConversationListHeader;
