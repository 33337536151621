import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SingleValue } from "react-select";
import { getIn, useFormikContext } from "formik";
import MappedValuesField from "../MappedValuesField";
import TextAreaFieldWithVariables from "../TextAreaFieldWithVariables";
import HTTPFormBodySelect, { VariableOption } from "./HTTPFormBodySelect";

interface HTTPFormBodyProps {
  path: string;
  fieldBodyName: string;
  fieldHeadersName: string;
  currentStepIndex: number | null;
}

export const isStructuredContentType = (contentType) =>
  ["multipart/form-data", "application/x-www-form-urlencoded"].includes(
    contentType
  );

function HTTPFormBody({
  path,
  fieldBodyName,
  fieldHeadersName,
  currentStepIndex,
}: HTTPFormBodyProps) {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const [headers, contentType] = useMemo(() => {
    const nextHeaders = getIn(values, `${path}.${fieldHeadersName}`) || [];
    const nextHeaderContentType = nextHeaders.find(
      (header) =>
        header.key.localeCompare("Content-Type", undefined, {
          sensitivity: "accent",
        }) === 0
    );
    const nextContentType =
      (nextHeaderContentType && nextHeaderContentType.value) || null;
    return [nextHeaders, nextContentType];
  }, [values, path, fieldHeadersName]);

  const onChangeContentType = useCallback(
    (nextBodyType: SingleValue<VariableOption>) => {
      let prevContentType: string | null = null;
      const nextContentType = nextBodyType && nextBodyType.value;
      const nextHeaders: { key: string; value: string }[] = [];

      if (headers) {
        for (const header of headers) {
          if (
            header.key.localeCompare("Content-Type", undefined, {
              sensitivity: "accent",
            }) === 0
          ) {
            if (nextContentType && nextContentType !== "NONE") {
              nextHeaders.push({
                key: "Content-Type",
                value: nextContentType,
              });
            } else {
              nextHeaders.push(header);
            }
            prevContentType = header.value;
          } else {
            nextHeaders.push(header);
          }
        }
      }

      if (
        prevContentType === null &&
        nextContentType &&
        nextContentType !== "NONE"
      ) {
        nextHeaders.push({
          key: "Content-Type",
          value: nextContentType,
        });
      }

      if (
        isStructuredContentType(prevContentType) !==
          isStructuredContentType(nextContentType) ||
        (prevContentType !== null && nextContentType === "NONE")
      ) {
        setFieldValue(`${path}.${fieldBodyName}`, null);
      }

      setFieldValue(`${path}.${fieldHeadersName}`, nextHeaders);
    },
    [headers, path, fieldBodyName, fieldHeadersName, setFieldValue]
  );

  return (
    <div className="mt-4">
      <HTTPFormBodySelect
        contentType={contentType}
        onChangeContentType={onChangeContentType}
      />
      {contentType === "text/plain" && (
        <TextAreaFieldWithVariables
          path={path}
          name={fieldBodyName}
          label={t(
            "components:http-form.body.message-body.label",
            "Message body"
          )}
          placeholder={t(
            "components:http-form.body.message-body.placeholder",
            "Type request body"
          )}
          rows={8}
          currentStepIndex={currentStepIndex}
        />
      )}
      {["application/json", "application/xml", "text/yaml"].includes(
        contentType
      ) && (
        <TextAreaFieldWithVariables
          path={path}
          name={fieldBodyName}
          placeholder={t(
            "components:http-form.body.message-body.placeholder",
            "Type request body"
          )}
          rows={8}
          currentStepIndex={currentStepIndex}
        />
      )}
      {isStructuredContentType(contentType) && (
        <MappedValuesField
          path={path}
          name={fieldBodyName}
          objectName={t("components:http-form.body.message-body.item", "Item")}
          currentStepIndex={currentStepIndex}
          addButtonLabel={t(
            "components:http-form.body.message-body.add-item",
            "Add item"
          )}
        />
      )}
    </div>
  );
}

export default HTTPFormBody;
