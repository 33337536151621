import { useEffect, useState } from "react";
import { Formik, validateYupSchema, yupToFormErrors } from "formik";
import useHilosStore from "src/hooks/useHilosStore";
import APIErrors from "../../components/base/APIErrors";
import FlowAutosave from "./FlowAutosave";
import FlowFormStepAttrs from "./FlowFormStepAttrs";
import FlowFormStepNav from "./FlowFormStepNav";
import FlowFormStepSteps from "./FlowFormStepSteps";
import * as meta from "./FlowMeta";
import {
  getFormatterForSave,
  getSchemaForStep,
  getValidatorForStep,
} from "./FlowStepMeta";
import FlowTestModal from "./FlowTestModal";

export default function FlowForm({
  flow,
  formSubmit,
  success,
  submitted,
  formSaveAction,
  setFormSaveAction,
  isChatbot = false,
}) {
  const [submitCount, setSubmitCount] = useState(0);
  const [currentStep, setCurrentStep] = useState("attrs");
  const [showTestModal, setShowTestModal] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [showHasError, setShowHasError] = useState(false);
  const [showConfirmationPublishModal, setShowConfirmationPublishModal] =
    useState(false);
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const { session } = useHilosStore();

  const cleanValuesForSubmit = (values, isTest = false) => {
    const newVals = { ...values };
    // Step-specific formatting

    newVals.current_draft = {};
    newVals.current_draft.execution_type = newVals.execution_type;
    newVals.current_draft.deleted_files = newVals.deleted_files || [];
    newVals.current_draft.steps = newVals.steps.map((step) => {
      const formatter = getFormatterForSave(step.step_type);
      return formatter(step);
    });

    delete newVals.steps;
    delete newVals.execution_type;
    delete newVals.current_version;

    // Cleanup
    newVals.current_draft.steps.forEach((step) => {
      if (!step.next_step_default_idx && step.next_step_default_idx !== 0) {
        delete step.next_step_default_idx;
      }
      if (!step.next_step_alternate_idx && step.next_step_alternate_idx !== 0) {
        delete step.next_step_alternate_idx;
      }
      if (
        !step.answer_failed_next_step_idx &&
        step.answer_failed_next_step_idx !== 0
      ) {
        delete step.answer_failed_next_step_idx;
      }
      if (step.next_step_default_idx === "end-flow") {
        delete step.next_step_default_idx;
      }
      if (step.next_step_alternate_idx === "end-flow") {
        delete step.next_step_alternate_idx;
      }
      if (step.answer_failed_next_step_idx === "end-flow") {
        delete step.answer_failed_next_step_idx;
      }
      if (step.next_step_default_idx >= newVals.current_draft.steps.length) {
        // A step was deleted and this one was left hanging
        delete step.next_step_default_idx;
      }
      if (step.next_step_alternate_idx >= newVals.current_draft.steps.length) {
        // A step was deleted and this one was left hanging
        delete step.next_step_alternate_idx;
      }
      if (
        step.answer_failed_next_step_idx >= newVals.current_draft.steps.length
      ) {
        // A step was deleted and this one was left hanging
        delete step.answer_failed_next_step_idx;
      }
      if (
        step.next_steps_for_options_idx &&
        step.next_steps_for_options_idx.length > 0
      ) {
        step.next_steps_for_options_idx = step.next_steps_for_options_idx.map(
          (step_val) => (step_val !== "end-flow" ? step_val : null)
        );
      }
    });

    if (isTest) {
      newVals["is_test"] = true;
    }
    return newVals;
  };

  const onSubmit = (values, setSubmitting) => {
    formSubmit(
      cleanValuesForSubmit(values),
      setSubmitting,
      setBackendValidationErrors,
      setBackendError
    );
  };

  const handleTestFlow = (values, setSubmittingTest, setHasError) => {
    formSubmit(
      cleanValuesForSubmit(values, true),
      setSubmittingTest,
      setBackendValidationErrors,
      setBackendError,
      true
    ).then(() => {
      if (
        backendError ||
        Object.getOwnPropertyNames(backendValidationErrors).length > 0
      ) {
        setHasError(true);
      } else {
        setShowTestModal(true);
      }
    });
  };

  const validate = (values) => {
    setSubmitCount(submitCount + 1);
    let errors = { steps: [] };
    values.steps.forEach((step, index) => {
      // Validate step-specific schema errors
      try {
        const schema = getSchemaForStep(step.step_type);
        validateYupSchema(step, schema, true);
      } catch (err) {
        const stepErrors = errors.steps ? errors : { steps: [] };
        stepErrors.steps[index] = yupToFormErrors(err);
        errors = { ...errors, ...stepErrors };
      }

      // Validate step-specific field validations
      const stepValidationErrors = errors.steps ? errors : { steps: [] };
      const validator = getValidatorForStep(step.step_type);
      const validationResult = validator(values, step);

      if (validationResult) {
        stepValidationErrors.steps[index] = {
          ...validationResult,
          ...stepValidationErrors.steps[index],
        };
      }
      errors = { ...errors, ...stepValidationErrors };
    });

    // If no errors remain in all steps, delete steps attr from errors
    const remainingErrors = errors.steps.filter((err) => {
      if (typeof err === "object") {
        if (Object.keys(err).length === 0) return false;
      }
      return true;
    });
    if (remainingErrors.length === 0) {
      delete errors.steps;
    }
    return errors;
  };

  useEffect(() => {
    setTimeout(() => {
      setShowHasError(false);
    }, 1000 * 4);
  }, [showHasError, flow]);

  return (
    <Formik
      validationSchema={meta.schema}
      onSubmit={(values, { setSubmitting }) => {
        return onSubmit(
          values,
          setSubmitting,
          setBackendError,
          setBackendValidationErrors
        );
      }}
      enableReinitialize={true}
      initialValues={flow}
      validate={validate}
      validateOnChange={submitCount > 0}
      validateOnBlur={submitCount > 0}
    >
      {(formik) => (
        <form noValidate onSubmit={formik.handleSubmit}>
          {!isChatbot && (
            <div className="mb-4">
              <FlowFormStepNav
                formik={formik}
                submitted={submitted}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              ></FlowFormStepNav>
            </div>
          )}
          <div className="my-4">
            <APIErrors
              APIError={backendError}
              APIValidationErrors={backendValidationErrors}
              fieldTranslations={meta.FIELDS}
            />
          </div>

          <FlowAutosave formik={formik} />

          <>
            {currentStep === "attrs" && (
              <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto mt-6 max-w-2xl">
                  <FlowFormStepAttrs
                    formik={formik}
                    setCurrentStep={setCurrentStep}
                  />
                </div>
              </div>
            )}

            {currentStep === "steps" && (
              <FlowFormStepSteps
                formik={formik}
                setCurrentStep={setCurrentStep}
                submitted={submitted}
                success={success}
                formSaveAction={formSaveAction}
                setFormSaveAction={setFormSaveAction}
              />
            )}

            {/* {currentStep === "end" && (
                <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                  <div className="mx-auto mt-6 max-w-2xl">
                    <FlowFormStepEnd
                      formik={formik}
                      setCurrentStep={setCurrentStep}
                      submitted={submitted}
                      success={success}
                      formSaveAction={formSaveAction}
                      setFormSaveAction={setFormSaveAction}
                    ></FlowFormStepEnd>
                  </div>
                </div>
              )} */}
          </>
        </form>
      )}
    </Formik>
  );
}
