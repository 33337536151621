import { FormikProps } from "formik";
import TextAreaField from "../../../../../components/Form/TextAreaField";
import FlowFormStepPreview from "../../../FlowFormStepPreview";
import FlowStepAddVariable from "../../../FlowStepAddVariable";
import { FlowStepMessageMeta } from "./Meta";

interface FlowStepMessageTextProps {
  formik: FormikProps<any>;
  index: number;
}

export default function FlowStepMessageText({
  formik,
  index,
}: FlowStepMessageTextProps) {
  const addVariable = (variable) => {
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body.key}`,
      formik.values.steps[index].body
        ? formik.values.steps[index].body + ` ${variable}`
        : variable
    );
  };

  return (
    <div>
      <TextAreaField
        placeholder={FlowStepMessageMeta.FIELDS.body.placeholder}
        label={FlowStepMessageMeta.FIELDS.body.label}
        name={`steps.${index}.${FlowStepMessageMeta.FIELDS.body.key}`}
        help={FlowStepMessageMeta.FIELDS.body.help}
        rows={8}
      />

      <div className="mt-2">
        <FlowStepAddVariable
          formik={formik}
          addVariable={addVariable}
          currentStepIndex={index}
        />
      </div>
    </div>
  );
}
