import {
  ConversationContent,
  InboxContactRead,
  WhatsAppMessage,
} from "@hilos/types/private-schema";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { classNames, userToString } from "../../../Helpers";
import {
  faCheck,
  faCheckDouble,
  faClockRotateLeft,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

import { BanIcon } from "@heroicons/react/outline";
import ConversationMessageMenu from "./ConversationMessageMenu";
import DeleteMessage from "./ConversationMessageButton/DeleteMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ForwardMessage from "./ConversationMessageButton/ForwardMessage";
import { HandleMountConversationContent } from "./Conversation";
import MessageRenderPreview from "src/components/MessageRenderPreview";
import ReplyTo from "./ConversationMessageButton/ReplyTo";
import ResendMessage from "./ConversationMessageButton/ResendMessage";
import { WhatsAppMessageStatusEnum } from "src/types/conversation";
import { getTimeFromTimestamp } from "../../../helpers/date";
import { useTranslation } from "react-i18next";

interface ConversationMessageContainerProps {
  content: ConversationContent;
  message: WhatsAppMessage;
  inboxContact: InboxContactRead;
  isFocused: boolean;
  isSubmitting?: boolean;
  showDetails: boolean;
  onMountConversationContent: HandleMountConversationContent;
  handleSelectContextMessage: (content: ConversationContent) => void;
  handleSelectForwardMessage: (message: WhatsAppMessage) => void;
  onFocusMessage: (messageId: string) => Promise<void>;
}

function ConversationMessageContainer({
  content,
  inboxContact,
  children,
  message,
  isFocused,
  isSubmitting,
  showDetails,
  onMountConversationContent,
  handleSelectContextMessage,
  handleSelectForwardMessage,
  onFocusMessage,
}: PropsWithChildren<ConversationMessageContainerProps>) {
  const { t } = useTranslation();
  const isOutbound = message.direction === "OUTBOUND";
  const messageRef = useRef<HTMLDivElement>(null);

  const hasFailedMessage = useMemo(
    () =>
      !message.is_deleted &&
      (message.status === WhatsAppMessageStatusEnum.FAILED ||
        message.status === WhatsAppMessageStatusEnum.RETRY),
    [message]
  );

  const handleFocus = useCallback(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, []);

  useEffect(
    () =>
      onMountConversationContent({
        id: content.id,
        messageId: message.id,
        onFocus: handleFocus,
      }),
    [onMountConversationContent, content.id, message, handleFocus]
  );

  return (
    <>
      {showDetails && (
        <div
          className={classNames(
            "flex px-2 pb-0.5 w-full align-center items-center text-xs text-gray-500",
            isOutbound ? "justify-end text-right" : "justify-start text-left"
          )}
        >
          <div>
            {!isSubmitting && isOutbound && (
              <>
                {message.sent_by
                  ? ` ${userToString(message.sent_by)} @ `
                  : "🤖 @ "}
              </>
            )}
            <span className="">{getTimeFromTimestamp(message.timestamp)}</span>
          </div>

          {!isSubmitting && isOutbound && (
            <div className="ml-1 text-right text-xs">
              {message.status === WhatsAppMessageStatusEnum.RETRY && (
                <FontAwesomeIcon icon={faClockRotateLeft} />
              )}
              {message.status === WhatsAppMessageStatusEnum.SENT && (
                <FontAwesomeIcon icon={faCheck} />
              )}
              {message.status === WhatsAppMessageStatusEnum.DELIVERED && (
                <FontAwesomeIcon icon={faCheckDouble} />
              )}
              {message.status === WhatsAppMessageStatusEnum.READ && (
                <FontAwesomeIcon
                  icon={faCheckDouble}
                  className="text-blue-600"
                />
              )}
            </div>
          )}
        </div>
      )}
      {hasFailedMessage && (
        <div
          className={classNames(
            "flex flex-row items-center mx-2",
            isOutbound ? "justify-end" : "justify-start"
          )}
        >
          <div
            className={classNames(
              "flex max-w-[21rem] min-w-[2rem] space-x-1 align-center items-center text-xs text-red-500",
              isOutbound ? "justify-end text-right" : "justify-start text-left"
            )}
          >
            {message.status === "failed" && (
              <>
                <FontAwesomeIcon icon={faExclamationCircle} />
                <span className="whitespace-pre-wrap flex flex-col gap-1">
                  {message.provider_error_message || "Unknown Error"}
                  <a
                    href="https://hilos.io/docs/user/advanced/error-messages"
                    rel="noreferrer"
                    target="_blank"
                    className="text-blue-300 underline"
                  >{`${t("why-this-happens", "Why does this happen?")}`}</a>
                </span>
              </>
            )}
            {message.status === "retry" && (
              <>
                <FontAwesomeIcon icon={faClockRotateLeft} />
                <span className="whitespace-pre-wrap flex flex-col gap-1">
                  Retrying: {message.provider_error_message || "Unknown Error"}
                </span>
              </>
            )}
          </div>
        </div>
      )}
      <div
        ref={messageRef}
        className={classNames(
          "flex group mx-2 text-sm flex-row items-center",
          isOutbound ? "justify-end" : "justify-start"
        )}
      >
        <span
          className={classNames(
            "flex max-w-[21rem] min-w-[2rem] rounded-lg p-2 transition-colors delay-200 duration-500 ease-in-out overflow-hidden",
            isOutbound
              ? `${isFocused ? "bg-indigo-700" : "bg-indigo-400"} text-white`
              : isFocused
              ? "bg-gray-500 text-white"
              : "bg-gray-200 text-gray-600",
            isSubmitting && "inbox-msg-pulse"
          )}
        >
          {message.is_deleted ? (
            <span
              className={classNames(
                "flex flex-row italic",
                isOutbound ? "text-gray-200" : "text-gray-400"
              )}
            >
              <BanIcon className="mr-1 h-4 w-4 self-center" />
              {t("message-is-deleted", "Message has been deleted")}
            </span>
          ) : (
            <div className="flex-col w-full">
              {message.context_message && message.context_message && (
                <div className="flex mb-2">
                  <button
                    className={classNames(
                      "self-center text-xs rounded-md w-full py-1 px-2 border-l-4",
                      isOutbound
                        ? "border-indigo-500 bg-white bg-opacity-30"
                        : "bg-white bg-opacity-30 border-gray-400 "
                    )}
                    onClick={() =>
                      message.context_message
                        ? onFocusMessage(message.context_message.id)
                        : null
                    }
                  >
                    <MessageRenderPreview
                      message={message.context_message}
                      isPreviewInMessage
                    />
                  </button>
                </div>
              )}
              <div className="relative pr-6 sm:pr-0">
                {children}
                {["sent", "delivered", "read", "new", "received"].includes(
                  message.status
                ) && (
                  <ConversationMessageMenu
                    isFocused={isFocused}
                    isOutbound={isOutbound}
                    items={[
                      (active) => (
                        <ReplyTo
                          active={active}
                          inboxContact={inboxContact}
                          content={content}
                          handleSelectContextMessage={
                            handleSelectContextMessage
                          }
                        />
                      ),
                      (active) => (
                        <ForwardMessage
                          active={active}
                          message={message}
                          setSelectedForwardMessage={handleSelectForwardMessage}
                        />
                      ),
                      // (active) => (
                      //   <AutocompleteAI
                      //     active={active}
                      //     inboxContact={inboxContact}
                      //     content={content}
                      //     handleSelectContextMessage={
                      //       handleSelectContextMessage
                      //     }
                      //   />
                      // ),
                    ]}
                  />
                )}
                {hasFailedMessage && (
                  <ConversationMessageMenu
                    isFocused={isFocused}
                    isOutbound={isOutbound}
                    items={[
                      (active) => (
                        <ResendMessage
                          active={active}
                          messageId={message.id}
                          inboxContactId={inboxContact.id}
                        />
                      ),
                      (active) => (
                        <DeleteMessage
                          active={active}
                          messageId={message.id}
                          inboxContactId={inboxContact.id}
                        />
                      ),
                    ]}
                  />
                )}
              </div>
            </div>
          )}
        </span>
      </div>
    </>
  );
}

export default ConversationMessageContainer;
