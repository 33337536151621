import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import {
  FlowExecutionList,
  FlowExecutionStatusEnum,
} from "@hilos/types/private-schema";
import { PlayIcon, UserGroupIcon } from "@heroicons/react/outline";
import { Trans, useTranslation } from "react-i18next";

import { DateTime } from "luxon";
import FlowExecutionStatusBadge from "./FlowExecutionStatusBadge";
import { Link } from "react-router-dom";
import Table from "src/components/Table/Table";
import { useMemo } from "react";
import { userToString } from "../../../Helpers";

interface FlowExecutionListProps {
  flowId: string;
  flowVersionId?: string;
}
export default function FlowExecutionListComponent({
  flowId,
  flowVersionId,
}: FlowExecutionListProps) {
  const { t } = useTranslation();

  const initialParams = useMemo(() => {
    const nextInitialParams = { flow: flowId };
    if (flowVersionId) {
      nextInitialParams["flow_version"] = flowVersionId;
    }
    return nextInitialParams;
  }, [flowId, flowVersionId]);

  return (
    <div className="py-6 sm:px-6">
      <Table
        baseURL={buildAPIRoute(API_ROUTES.FLOW_EXECUTION_LIST)}
        initialSearchParams={initialParams}
        paginated={true}
        tableHead={
          <thead>
            <tr>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              ></th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("name", "Name")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("created-by", "Created by")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("status", "Status")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.num_contacts", "Contacts")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.ongoing", "Ongoing")}
              </th>
              <th
                scope="col"
                className="px-4 py-3 text-left text-tiny font-medium uppercase tracking-wider text-gray-500"
              >
                {t("flows:analytics.completed", "Completed")}
              </th>
            </tr>
          </thead>
        }
        tableRow={(flowExecution: FlowExecutionList) => (
          <>
            <td className="whitespace-nowrap py-3 pl-4 text-sm text-gray-900">
              <div className="h-8 w-8 rounded-full flex items-center justify-center bg-gray-100">
                <UserGroupIcon
                  className=" h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-900">
              <Link
                className="font-medium text-blue-600"
                to={buildRoute("flow-execution-detail", {
                  ":id": flowExecution.id,
                })}
              >
                {flowExecution.id}
              </Link>

              <p className="text-sm text-gray-500">
                {t("created-on", "Created on")}{" "}
                {DateTime.fromISO(
                  flowExecution.created_on as string
                ).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
              </p>
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-500">
              {userToString(flowExecution.created_by)}
            </td>
            <td className="whitespace-nowrap px-4 py-3 text-sm text-gray-500">
              <FlowExecutionStatusBadge
                status={flowExecution.status as FlowExecutionStatusEnum}
              />
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowExecution.num_contacts}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowExecution.running}
            </td>
            <td className="whitespace-nowrap py-3 px-4 text-sm text-gray-500">
              {flowExecution.completed}
            </td>
          </>
        )}
        dataName={t("flow-executions", "Executions")}
        noResults={
          <div className="my-4">
            <Trans i18nKey="flow-executions:no-results-with-filters">
              <h4>Couldn't find any runs for this flow.</h4>
              <p className="text-sm text-gray-500">
                Remove filters to try again.
              </p>
            </Trans>
          </div>
        }
        createObject={
          <div className="my-4">
            <Trans i18nKey="flow-executions:no-executions-yet">
              <h4>Looks like this flow hasn't been run.</h4>
              <p className="text-sm text-gray-500">Why don't you run it?</p>
              <Link
                to={buildRoute("flow-execution-create", { ":id": flowId })}
                className="mt-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <PlayIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Run flow
              </Link>
            </Trans>
          </div>
        }
      ></Table>
    </div>
  );
}
