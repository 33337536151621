import { Edge, Node, ReactFlowState, XYPosition } from "reactflow";
import { HierarchyPointNode, stratify, tree } from "d3-hierarchy";
import { FormikTouched } from "formik";
import { TFunction } from "i18next";
import { v4 as uuid } from "uuid";
import {
  FlowData,
  FlowNodeData,
  FlowStepData,
  StepOption,
} from "@hilos/types/flow";
import { HilosVariableData } from "@hilos/types/hilos";
import { hasItems, removeFalsyValuesFromEndArray } from "src/helpers/utils";
import { getUpdatedVariablesWithExtraSources } from "src/helpers/variables";
import { getTranslationPayload as t } from "src/i18n";
import { STEP_TYPES_WITHOUT_NEXT, StepTypes } from "./constants/steps";
import { getWhatsappTemplateData } from "./helpers/steps/template";

interface UpdateNodeByIdParams {
  id: string;
  data: FlowNodeData;
  nodes: Node<FlowNodeData>[];
}

interface GetChildNodesByStepParams {
  id: string;
  nextStepId: string | null;
  stepOptions: StepOption[] | null;
  position: XYPosition;
  selectFirstEdge?: boolean;
  allowAddStep?: boolean;
}

interface UpdateStepDataParams {
  step: Partial<FlowStepData> & Pick<FlowStepData, "id" | "step_type">;
  toSourceStep: Partial<FlowStepData> & Pick<FlowStepData, "id" | "step_type">;
  toOptionId?: string | null;
  toTargetStepId?: string | null;
  fromTargetStepId?: string | null;
}

type FlowNodeParams = Partial<Node<FlowNodeData> & { sourceHandle: string }>;

type RelatedStepsValue = { prev: string[]; next: string[] };

type FlowInitialValuesWithTouchedFields = {
  initialValues: {
    steps: FlowStepData[];
    variables: HilosVariableData[];
    unrelated_steps: FlowStepData[];
  };
  formikTouchedFields: FormikTouched<FlowData>;
};

const getRelatedStepsById = (
  id: string,
  relatedStepsByStepId: Map<string, RelatedStepsValue>,
  currentRelatedSteps: string[] = []
) => {
  if (currentRelatedSteps.includes(id)) {
    return [];
  }

  const nextRelatedSteps: string[] = [id];
  const step = relatedStepsByStepId.get(id);

  if (step) {
    if (step.next) {
      for (const nextStepId of step.next) {
        nextRelatedSteps.push(
          ...getRelatedStepsById(nextStepId, relatedStepsByStepId, [
            ...currentRelatedSteps,
            ...nextRelatedSteps,
          ])
        );
      }
    }
  }

  return nextRelatedSteps;
};

// initialize the tree layout (see https://observablehq.com/@d3/tree for examples)
const layout = tree<Node>()
  // the node size configures the spacing between the nodes ([width, height])
  .nodeSize([200, 160])
  // this is needed for creating equal space between all nodes
  .separation(() => 1.5);

export const isValidKeyValue = (item: any) =>
  item !== null && item !== undefined && item !== "";

export function getHierarchyPointNodes(
  nodes: Node[],
  edges: Edge[],
  id: string | null = null
): HierarchyPointNode<Node>[] {
  // convert nodes and edges into a hierarchical object for using it with the layout function
  const edgesByTargetId = edges.reduce((nextEdgesByTargetId, edge) => {
    nextEdgesByTargetId[edge.target] = edge;
    return nextEdgesByTargetId;
  }, {} as { [key: string]: Edge });

  // To make sure to preserve an order no matter how the nodes are added,
  // we will use sourceHandle which is an identifier of the option in
  // relation to the parent node.
  nodes.sort((a, b) => {
    if (!edgesByTargetId[a.id]?.sourceHandle) {
      return -1;
    }

    if (!edgesByTargetId[b.id]?.sourceHandle) {
      return 0;
    }

    return (edgesByTargetId[a.id]?.sourceHandle as string).localeCompare(
      edgesByTargetId[b.id]?.sourceHandle as string
    );
  });

  const hierarchy = stratify<Node>()
    .id((d) => d.id)
    // get the id of each node by searching through the edges
    // this only works if every node has one connection
    .parentId((d: Node) => edgesByTargetId[d.id]?.source)(nodes);

  // run the layout algorithm with the hierarchy data structure
  let root: HierarchyPointNode<Node> | undefined = layout(hierarchy);

  if (id) {
    root = root.find((node) => node.id === id);
  }

  if (!root) {
    return [];
  }

  return root.descendants();
}

// this is the store selector that is used for triggering the layout, this returns the number of nodes once they change
export const nodeCountSelector = (state: ReactFlowState) =>
  state.nodeInternals.size;
export const nodesInitializedSelector = (state: ReactFlowState) =>
  Array.from(state.nodeInternals.values()).every(
    (node) => node.width && node.height
  );

export function isEqualId(id: any, value: string) {
  if (id === null || id === undefined) {
    return false;
  }

  return `${id}` === `${value}`;
}

function getStepAlternateOption(step: FlowStepData) {
  const hasMaxWaitTime = step.has_max_wait_time;
  const hasOptionsFromVariable = step.has_options_from_variable;
  const nextStepAlternateId = `${step.next_step_alternate || ""}` || null;

  if (hasMaxWaitTime && hasOptionsFromVariable) {
    return {
      id: "timeout_or_empty_data",
      label: t("flows:edge-labels.timeout-or-empty", "TIMEOUT OR EMPTY"),
      stepId: nextStepAlternateId,
    };
  }

  if (hasOptionsFromVariable) {
    return {
      id: "empty_data",
      label: t("flows:edge-labels.empty-data", "EMPTY DATA"),
      stepId: nextStepAlternateId,
    };
  }

  if (hasMaxWaitTime) {
    return {
      id: "timeout",
      label: t("flows:edge-labels.timeout", "TIMEOUT"),
      stepId: nextStepAlternateId,
    };
  }

  return null;
}

function getOptionsFromConditionalStep(step: FlowStepData): StepOption[] {
  const nextStepOptions: StepOption[] = [];
  const nextStepOptionIds = step.next_steps_for_options || [];
  const largestArrayLength = Math.max(
    nextStepOptionIds.length,
    step.paths?.length ?? 0
  );

  const nextStepOptionIdsConditional = step.next_steps_for_options || [];

  for (let index = 0; index < largestArrayLength; index++) {
    const answerOptionLabel: string | string[] =
      step?.answer_options?.[index] ??
      t("flows:edge-labels.unreachable-step", "UNREACHABLE STEP");

    const answerOptionStepId = nextStepOptionIdsConditional[index] || null;
    nextStepOptions.push({
      id: `${index}`,
      label: answerOptionLabel,
      stepId: answerOptionStepId,
    });
  }

  nextStepOptions.push({
    id: "else",
    stepId: `${step.next_step_alternate || ""}` || null,
    label: t("flows:edge-labels.else", "ELSE"),
  });
  return nextStepOptions;
}

function getOptionsFromTemplateMenuStep(step: FlowStepData): StepOption[] {
  const nextStepOptions: StepOption[] = [];
  const nextStepOptionIds = removeFalsyValuesFromEndArray(
    step.next_steps_for_options || []
  );
  const largestArrayLength = Math.max(
    nextStepOptionIds.length,
    step.answer_options?.length ?? 0
  );

  for (let index = 0; index < largestArrayLength; index++) {
    const isUnreachable = !Boolean(step.answer_options?.[index]);
    const answerOptionLabel =
      step.answer_options?.[index] ||
      t("flows:edge-labels.unreachable-step", "UNREACHABLE STEP");
    const answerOptionStepId = nextStepOptionIds?.[index] || null;
    nextStepOptions.push({
      id: `${index}`,
      label: answerOptionLabel,
      isUnreachable,
      stepId: answerOptionStepId,
    });
  }
  if (step.next_action === "BUTTON_RESPONSE" && step.next_step_default) {
    nextStepOptions.push({
      id: "answer",
      stepId: step.next_step_default,
      isUnreachable: true,
      label: t("flows:edge-labels.unreachable-step", "UNREACHABLE STEP"),
    });
  }
  return nextStepOptions;
}

export function getOptionsFromStep(step: FlowStepData): StepOption[] | null {
  const nextStepOptions: StepOption[] = [];

  switch (step.step_type) {
    case "ACTION":
      nextStepOptions.push(
        {
          id: "response",
          stepId: `${step.next_step_default || ""}` || null,
          label: t("flows:edge-labels.response", "RESPONSE"),
        },
        {
          id: "error",
          stepId: `${step.next_step_alternate || ""}` || null,
          label: t("flows:edge-labels.error", "ERROR"),
        }
      );
      break;
    case "TEMPLATE_MENU":
      const stepAlternateOptionTemplate = getStepAlternateOption(step);

      if (stepAlternateOptionTemplate) {
        nextStepOptions.push(stepAlternateOptionTemplate);
      }

      if (step.has_max_answer_attempts) {
        nextStepOptions.push({
          id: "failed_attempts",
          stepId: `${step.answer_failed_next_step || ""}` || null,
          label: t("flows:edge-labels.failed-attempts", "FAILED ATTEMPTS"),
        });
      }

      if (step.has_msg_fail_path) {
        nextStepOptions.push({
          id: "step_failed",
          stepId: `${step.next_step_failed || ""}` || null,
          label: t("flows:edge-labels.msg-failed", "FAIL TO SEND"),
        });
      }

      nextStepOptions.unshift(...getOptionsFromTemplateMenuStep(step));
      if (
        step.next_action !== "BUTTON_RESPONSE" &&
        (nextStepOptions.length > 0 ||
          step.next_step_alternate ||
          step.next_step_failed)
      ) {
        nextStepOptions.push({
          id: "answer",
          stepId: `${step.next_step_default || ""}` || null,
          label: t("flows:edge-labels.answer", "ANSWER"),
        });
      }
      break;
    case "MESSAGE":
      if (step.has_msg_fail_path) {
        nextStepOptions.push({
          id: "step_failed",
          stepId: `${step.next_step_failed || ""}` || null,
          label: t("flows:edge-labels.msg-failed", "FAIL TO SEND"),
        });
      }

      if (nextStepOptions.length > 0) {
        nextStepOptions.unshift({
          id: "sent",
          stepId: `${step.next_step_default || ""}` || null,
          label: t("flows:edge-labels.sent", "SENT"),
        });
      }
      break;
    case "TEMPLATE":
    case "QUESTION":
      const stepAlternateOption = getStepAlternateOption(step);

      if (stepAlternateOption) {
        nextStepOptions.push(stepAlternateOption);
      }

      if (step.has_max_answer_attempts) {
        nextStepOptions.push({
          id: "failed_attempts",
          stepId: `${step.answer_failed_next_step || ""}` || null,
          label: t("flows:edge-labels.failed-attempts", "FAILED ATTEMPTS"),
        });
      }

      if (step.has_msg_fail_path) {
        nextStepOptions.push({
          id: "step_failed",
          stepId: `${step.next_step_failed || ""}` || null,
          label: t("flows:edge-labels.msg-failed", "FAIL TO SEND"),
        });
      }

      if (nextStepOptions.length > 0) {
        nextStepOptions.unshift({
          id: "answer",
          stepId: `${step.next_step_default || ""}` || null,
          label: t("flows:edge-labels.answer", "ANSWER"),
        });
      }
      break;
    case "CONDITIONAL":
      nextStepOptions.push(
        {
          id: "then",
          stepId: `${step.next_step_default || ""}` || null,
          label: t("flows:edge-labels.then", "THEN"),
        },
        {
          id: "otherwise",
          stepId: `${step.next_step_alternate || ""}` || null,
          label: t("flows:edge-labels.otherwise", "OTHERWISE"),
        }
      );
      break;
    case "CONDITIONAL_MULTIPLE":
      nextStepOptions.push(...getOptionsFromConditionalStep(step));
      break;
    case "MENU":
      const nextStepOptionIds = step.next_steps_for_options || [];

      if (hasItems(step.answer_options)) {
        for (const answerOptionIndex in step.answer_options) {
          const answerOptionLabel =
            step.answer_options[answerOptionIndex] || " ... ";
          const answerOptionStepId =
            nextStepOptionIds[answerOptionIndex] || null;
          nextStepOptions.push({
            id: `${answerOptionIndex}`,
            label: answerOptionLabel,
            stepId: answerOptionStepId,
          });
        }
      }

      if (step.has_max_wait_time) {
        nextStepOptions.push({
          id: "timeout",
          stepId: `${step.next_step_alternate || ""}` || null,
          label: t("flows:edge-labels.timeout", "TIMEOUT"),
        });
      }

      if (step.has_max_answer_attempts) {
        nextStepOptions.push({
          id: "failed_attempts",
          stepId: `${step.answer_failed_next_step || ""}` || null,
          label: t("flows:edge-labels.failed-attempts", "FAILED ATTEMPTS"),
        });
      }

      if (step.has_msg_fail_path) {
        nextStepOptions.push({
          id: "step_failed",
          stepId: `${step.next_step_failed || ""}` || null,
          label: t("flows:edge-labels.msg-failed", "FAIL TO SEND"),
        });
      }

      break;
    default:
      break;
  }

  if (nextStepOptions.length) {
    return nextStepOptions;
  }

  return null;
}

export const getChildNodesByStep = ({
  id,
  nextStepId,
  stepOptions,
  position,
  selectFirstEdge = false,
  allowAddStep = true,
}: GetChildNodesByStepParams) => {
  const childEdges: Edge[] = [];
  const childNodes: (Node<FlowNodeData> | string)[] = [];
  const childStepsToBeAssigned: string[] = [];

  if (hasItems(stepOptions)) {
    let isSelectedFirstEdge = false;
    for (const stepOption of stepOptions as StepOption[]) {
      const nextStepId = stepOption.stepId;
      const data = {
        label: stepOption.label,
        optionId: stepOption.id,
        isUnreachable: stepOption.isUnreachable,
      };

      if (nextStepId) {
        childEdges.push(
          createForkEdge({
            data,
            source: id,
            target: nextStepId,
            sourceHandle: stepOption.id,
          })
        );
        childNodes.push(nextStepId);
        childStepsToBeAssigned.push(nextStepId);
      } else if (stepOption.isUnreachable) {
        const addForkStepId = uuid();
        const selected = !isSelectedFirstEdge && selectFirstEdge;
        const childEdge = createEdge(
          "unreachable_fork_step_edge",
          id,
          addForkStepId,
          {
            data,
            selected,
            sourceHandle: stepOption.id,
          }
        );
        const childNode = createNode(
          "unreachable_step_node",
          addForkStepId,
          position
        );

        childEdges.push(childEdge);
        childNodes.push(childNode);
      } else if (allowAddStep) {
        const addForkStepId = uuid();
        const selected = !isSelectedFirstEdge && selectFirstEdge;
        const childEdge = createEdge("add_fork_step_edge", id, addForkStepId, {
          data,
          selected,
          sourceHandle: stepOption.id,
        });
        const childNode = createNode(
          "add_fork_step_node",
          addForkStepId,
          position
        );

        childEdges.push(childEdge);
        childNodes.push(childNode);
      }

      isSelectedFirstEdge = true;
    }
  } else {
    if (nextStepId) {
      childEdges.push(createEdge("step_edge", id, nextStepId));
      childNodes.push(nextStepId);
      childStepsToBeAssigned.push(nextStepId);
    } else if (allowAddStep) {
      const [addStepNode, addStepEdge] = createAddStepNode(
        id,
        position,
        selectFirstEdge
      );

      childEdges.push(addStepEdge);
      childNodes.push(addStepNode);
    }
  }

  return { childEdges, childNodes, childStepsToBeAssigned };
};

const createNode = (
  type: string,
  id: string,
  position: XYPosition,
  node: FlowNodeParams = {}
): Node<FlowNodeData> => ({
  id,
  type,
  position,
  data: {},
  deletable: false,
  selectable: true,
  selected: false,
  ...node,
});

const createEdge = (
  type: string,
  source: string,
  target: string,
  edge: Partial<Edge> = {}
) => ({
  id: `${source}=>${target}`,
  source: `${source}`,
  target: `${target}`,
  type,
  data: {},
  deletable: false,
  selected: false,
  ...edge,
});

const createForkEdge = ({
  data,
  source,
  target,
  sourceHandle,
}: {
  data: any;
  source: string;
  target: string;
  sourceHandle: string;
}) => ({
  id: `${source}=>${target}`,
  source: `${source}`,
  target: `${target}`,
  type: "fork_step_edge",
  sourceHandle,
  data,
});

const createAddStepNode = (
  parentId: string,
  position: XYPosition = { x: 0, y: 0 },
  selected: boolean = false
): [Node<FlowNodeData>, Edge] => {
  const id = uuid();
  return [
    createNode("add_step_node", id, position),
    createEdge("add_step_edge", parentId, id, { selected }),
  ];
};

export const getTouchedData = (data: any) => {
  const touched = {};
  for (const key in data) {
    if (typeof data[key] === "object") {
      if (Array.isArray(data[key])) {
        touched[key] = data[key].map((item) => getTouchedData(item));
      } else if (data[key] !== null) {
        touched[key] = getTouchedData(data[key]);
      } else {
        touched[key] = true;
      }
    } else {
      touched[key] = true;
    }
  }
  return touched;
};

export const getNodesFromData = ({
  steps,
  executionType,
  firstStepId = null,
  nextSelectedNodeId = null,
  allowAddStep = true,
  extraNodeDataByStepId = {},
}: {
  steps: FlowStepData[];
  executionType: string;
  firstStepId?: string | null;
  nextSelectedNodeId?: string | null;
  allowAddStep?: boolean;
  extraNodeDataByStepId?: { [key: string]: Partial<FlowNodeData> };
}) => {
  const triggerId = uuid();
  let nodesToBeAssigned = 0;
  let edges: Edge[] = [];
  let nodes: (Node<FlowNodeData> | string)[] = [
    {
      id: triggerId,
      type: "trigger",
      position: { x: 0, y: 0 },
      // @ts-ignore
      data: { type: executionType || "INBOUND" },
      deletable: false,
      selectable: true,
      selected: false,
    },
  ];
  const stepIdsWithMissingRoot: string[] = [];
  const relatedStepsByStepId = new Map<string, RelatedStepsValue>();

  if (steps.length !== 0) {
    if (firstStepId) {
      edges.push(createEdge("step_edge", triggerId, `${firstStepId}`));
    }
  } else if (allowAddStep) {
    const [addStepNode, addStepEdge] = createAddStepNode(triggerId);
    nodes.push(addStepNode);
    edges.push({ ...addStepEdge, selected: true });
  }

  for (const index in steps) {
    const step = steps[index];

    if (step) {
      const id = `${step.id}`;
      const stepOptions = getOptionsFromStep(step);
      const position = { x: 0, y: 160 * Number(index) + 200 };

      const nodeIndexPosition = nodes.findIndex((node) => node === id);
      const node = createNode("step", id, position, {
        selected: nextSelectedNodeId === id,
        data: {
          ...(extraNodeDataByStepId[id] || {}),
          index: Number(index),
          type: step.step_type,
          label: step.name || "",
          options: stepOptions,
          next_step_default: step.next_step_default,
          next_step_alternate: step.next_step_alternate,
          answer_failed_next_step: step.answer_failed_next_step,
          next_step_failed: step.next_step_failed,
        },
      });

      if (nodeIndexPosition !== -1) {
        nodes[nodeIndexPosition] = node;
        --nodesToBeAssigned;
      } else {
        nodes.push(node);
      }

      const currentRelatedStep = relatedStepsByStepId.get(id) || {
        prev: [],
        next: [],
      };

      if (STEP_TYPES_WITHOUT_NEXT.includes(step.step_type)) {
        relatedStepsByStepId.set(id, currentRelatedStep);
      } else {
        const { childEdges, childNodes, childStepsToBeAssigned } =
          getChildNodesByStep({
            id,
            nextStepId: step.next_step_default,
            stepOptions,
            position,
            allowAddStep,
          });

        nodesToBeAssigned += childStepsToBeAssigned.length;

        relatedStepsByStepId.set(id, {
          ...currentRelatedStep,
          next: [...currentRelatedStep.next, ...childStepsToBeAssigned],
        });

        for (const childStepToBeAssigned of childStepsToBeAssigned) {
          const currentChildRelatedStep = relatedStepsByStepId.get(
            childStepToBeAssigned
          ) || {
            prev: [],
            next: [],
          };
          relatedStepsByStepId.set(childStepToBeAssigned, {
            ...currentChildRelatedStep,
            prev: [...currentChildRelatedStep.prev, id],
          });
        }

        nodes.push(...childNodes);
        edges.push(...childEdges);
      }
    }
  }

  for (const [currentStepId, childStepIds] of relatedStepsByStepId.entries()) {
    if (!hasItems(childStepIds.prev) && firstStepId !== currentStepId) {
      stepIdsWithMissingRoot.push(
        ...getRelatedStepsById(
          currentStepId,
          relatedStepsByStepId,
          stepIdsWithMissingRoot
        )
      );
    }
  }

  const deleteNodeIfAddStep: string[] = [];
  const hasStepsWithMissingRoot = stepIdsWithMissingRoot.length > 0;

  if (hasStepsWithMissingRoot) {
    const nextEdges: Edge[] = [];

    for (const edge of edges) {
      if (stepIdsWithMissingRoot.includes(edge.source)) {
        deleteNodeIfAddStep.push(edge.target);
        continue;
      }

      if (stepIdsWithMissingRoot.includes(edge.target)) {
        continue;
      }

      nextEdges.push(edge);
    }

    edges = nextEdges;
  }

  if (nodesToBeAssigned !== 0 || hasStepsWithMissingRoot) {
    nodes = nodes.filter(
      (node) =>
        !(
          typeof node === "string" ||
          stepIdsWithMissingRoot.includes(node.id) ||
          (deleteNodeIfAddStep.includes(node.id) &&
            node.type &&
            node.type.includes("add_"))
        )
    );
  }

  return {
    edges,
    nodes: nodes as Node<FlowNodeData>[],
  };
};

const getStepIdIfExist = (
  id: string | null | undefined,
  stepsById: string[]
) => {
  if (id && stepsById.includes(id)) {
    return id;
  }
  return null;
};

export const hasNestedTrueValue = (data) => {
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = data[key];

      if (value) {
        return true;
      }

      if (typeof value === "object" && !Array.isArray(value)) {
        if (hasNestedTrueValue(value)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        for (const item of value) {
          if (typeof item === "object" && !Array.isArray(item)) {
            if (hasNestedTrueValue(item)) {
              return true;
            }
          }
        }
      }
    }
  }

  return false;
};

export const getFlowErrorFromResponse = (data) => {
  const error = { message: "", data: {} };
  if (
    data &&
    data["response"] &&
    data["response"]["status"] === 400 &&
    data["response"]["data"]
  ) {
    error["data"] = data["response"]["data"];
  } else {
    error["message"] =
      "An error occurred while updating your flow. Please try again.";
  }

  return error;
};

export const getDictFromKeyValueList = <V = string>(
  data: any,
  validate = (value: V) => typeof value === "string"
): { [key: string]: V } =>
  data.reduce((nextData, param) => {
    if (
      isValidKeyValue(param.key) &&
      isValidKeyValue(param.value) &&
      validate(param.value)
    ) {
      return { ...nextData, [param.key]: param.value };
    }
    return nextData;
  }, {});

export const getKeyValueListFromDict = <V = string>(
  data: any,
  validate = (value: V) => typeof value === "string"
) =>
  Object.entries<V>(data || {}).reduce((nextData, [key, value]) => {
    if (validate(value)) {
      nextData.push({ key, value });
    }
    return nextData;
  }, [] as { key: string; value: V }[]);

const getNextStepsByStep = (step: FlowStepData) => {
  const nextSteps = [
    step.next_step_default,
    step.next_step_alternate,
    step.answer_failed_next_step,
    step.next_step_failed,
  ];

  if (hasItems(step.next_steps_for_options)) {
    nextSteps.push(...step.next_steps_for_options);
  }

  return nextSteps.filter(Boolean) as string[];
};

function getRelatedNextSteps(
  firstStepId: string,
  nextStepsByStepId: { [key: string]: string[] }
) {
  const relatedNextSteps = new Set<string>();

  function addRelatedNextSteps(stepId) {
    const nextSteps = nextStepsByStepId[stepId];

    if (!nextSteps || relatedNextSteps.has(stepId)) {
      return;
    }

    relatedNextSteps.add(stepId);

    for (const nextStepId of nextSteps) {
      addRelatedNextSteps(nextStepId);
    }
  }

  addRelatedNextSteps(firstStepId);

  return relatedNextSteps;
}

function getFilteredRelatedSteps<T extends FlowStepData>(
  originalSteps: T[],
  relatedNextSteps: Set<string>
) {
  const steps: T[] = [];
  const unrelatedSteps: T[] = [];

  for (const step of originalSteps) {
    if (relatedNextSteps.has(step.id)) {
      steps.push(step);
    } else {
      unrelatedSteps.push(step);
    }
  }

  return { steps, unrelatedSteps };
}

export function getUpdatedStepsFromDraft(
  firstStepId: string,
  steps: FlowStepData[] = []
) {
  const nextStepsByStepId = {};
  // This in case it is a flow is in draft and uses the old format to save action responses
  if (hasItems(steps)) {
    for (const index in steps) {
      try {
        if (
          steps[index].action_test_response_data &&
          !steps[index].action_responses
        ) {
          steps[index].action_responses = [
            {
              created_on: new Date(),
              data: steps[index].action_test_response_data,
            },
          ];
          delete steps[index].action_test_response_data;
        }
        if (steps[index].action_request_body) {
          const isDictTypeHeader = steps[index].action_request_headers?.find(
            (el) =>
              el.key.toLowerCase() === "content-type" &&
              [
                "application/x-www-form-urlencoded",
                "multipart/form-data",
              ].includes(el.value.toLowerCase())
          );
          if (isDictTypeHeader !== undefined) {
            steps[index].action_request_body = JSON.parse(
              steps[index].action_request_body as string
            );
          }
        }

        nextStepsByStepId[steps[index].id] = getNextStepsByStep(steps[index]);
      } catch {}
    }
  }

  const relatedNextSteps = getRelatedNextSteps(firstStepId, nextStepsByStepId);

  return getFilteredRelatedSteps(steps, relatedNextSteps);
}

export async function getUpdatedSteps(
  firstStepId: string,
  steps: FlowStepData[] = []
) {
  // To prevent a next step from being added if it does not exist we map all steps by id.
  const stepsById = steps.map((step) => step.id);
  const nextStepsByStepId = {};

  for (const index in steps) {
    const step = steps[index];
    if (step) {
      steps[index].next_step_default = getStepIdIfExist(
        step.next_step_default,
        stepsById
      );
      steps[index].next_step_alternate = getStepIdIfExist(
        step.next_step_alternate,
        stepsById
      );
      steps[index].answer_failed_next_step = getStepIdIfExist(
        step.answer_failed_next_step,
        stepsById
      );
      steps[index].next_step_failed = getStepIdIfExist(
        step.next_step_failed,
        stepsById
      );

      switch (step.step_type) {
        case "MENU":
          if (hasItems(step.next_steps_for_options)) {
            steps[index].next_steps_for_options =
              step.next_steps_for_options.map((optionStepId) =>
                getStepIdIfExist(optionStepId, stepsById)
              );

            if (step.next_step_default) {
              for (const nextStepOptionIndex in steps[index]
                .next_steps_for_options) {
                const nextStepOptionId =
                  steps[index].next_steps_for_options[nextStepOptionIndex];
                if (nextStepOptionId) {
                  const nextStepGoTo = steps.find(
                    (currentStep) =>
                      currentStep.id === nextStepOptionId &&
                      currentStep.step_type === "GO_TO"
                  );

                  if (
                    nextStepGoTo &&
                    step.next_step_default === nextStepGoTo.next_step_default
                  ) {
                    steps[index].next_steps_for_options[nextStepOptionIndex] =
                      step.next_step_default;
                    steps[index].next_step_default = null;
                  }
                }
              }
            }
          }
          break;
        case "ACTION":
          if (step.action_request_headers) {
            steps[index].action_request_headers = getKeyValueListFromDict(
              step.action_request_headers
            );
          }
          if (step.action_request_params) {
            steps[index].action_request_params = getKeyValueListFromDict(
              step.action_request_params
            );
          }
          if (step.action_request_body) {
            const isDictTypeHeader = step.action_request_headers?.find(
              (el) =>
                el.key.toLowerCase() === "content-type" &&
                [
                  "application/x-www-form-urlencoded",
                  "multipart/form-data",
                ].includes(el.value.toLowerCase())
            );
            if (isDictTypeHeader !== undefined) {
              steps[index].action_request_body = JSON.parse(
                step.action_request_body as string
              );
            }
          }
          break;
        case "TEMPLATE":
          if (step.whatsapp_template) {
            const templateSelected = await getWhatsappTemplateData(
              step.whatsapp_template
            );
            steps[index].whatsapp_template_selected = templateSelected;
            // TODO: Get answer options to keep update validations
          }
          break;
        case "TEMPLATE_MENU":
          if (step.whatsapp_template) {
            const templateSelected = await getWhatsappTemplateData(
              step.whatsapp_template
            );
            steps[index].whatsapp_template_selected = templateSelected;
            // Avoid undefineds for flows with drafts that contain these old fields
            if (!steps[index].next_action) {
              if (step.turn_into_menu) {
                steps[index].next_action = "BUTTON_RESPONSE";
              } else if (step.save_contact_answer) {
                steps[index].next_action = "ANY_RESPONSE";
              } else {
                steps[index].next_action = "CONTINUE";
              }
            }
          }
          break;
        default:
          if (step.step_type.includes("WRAPPED")) {
            if (step.action_request_headers) {
              steps[index].action_request_headers = getKeyValueListFromDict(
                step.action_request_headers
              );
            }
            if (step.action_request_params) {
              steps[index].action_request_params = getKeyValueListFromDict(
                step.action_request_params
              );
            }
          }
          break;
      }

      nextStepsByStepId[steps[index].id] = getNextStepsByStep(steps[index]);
    }
  }

  const relatedNextSteps = getRelatedNextSteps(firstStepId, nextStepsByStepId);

  return getFilteredRelatedSteps(steps, relatedNextSteps);
}

export const getInitialValues = async ({
  data,
  flowVariableKeys,
  triggerVariables,
  isDraft = false,
  allFieldsWereTouched = false,
}: {
  data: any;
  flowVariableKeys?: string[];
  triggerVariables?: {
    [key: string]: Partial<HilosVariableData> &
      Pick<HilosVariableData, "data_type">;
  };
  isDraft?: boolean;
  allFieldsWereTouched?: boolean;
}): Promise<FlowInitialValuesWithTouchedFields> => {
  const firstStepId = data.first_step;

  if (isDraft) {
    const variables = getUpdatedVariablesWithExtraSources({
      flowVariableKeys,
      triggerVariables,
      currentVariables: [...(data.variables || [])],
    });

    const { steps, unrelatedSteps } = getUpdatedStepsFromDraft(
      firstStepId,
      data.steps
    );

    return {
      initialValues: {
      steps,
      variables,
        unrelated_steps: [...(data.unrelated_steps || []), ...unrelatedSteps],
      },
      formikTouchedFields:
        data.formikTouchedFields ||
        // Mark all steps as touched to validate them directly.
        (allFieldsWereTouched && getTouchedData({ steps })) ||
        {},
    };
  }

  const variables = getUpdatedVariablesWithExtraSources({
    flowVariableKeys,
    triggerVariables,
    currentVariables: [
      ...(data.variables || []).map((variable) => ({
        ...variable,
        id: variable.initial_id || variable.id,
        step_id: variable.step,
        flow_id: variable.flow_version,
        account_id: variable.account,
      })),
    ],
  });

  const { steps, unrelatedSteps } = await getUpdatedSteps(
    firstStepId,
    data.steps
  );

  return {
    initialValues: {
    steps,
    variables,
      unrelated_steps: unrelatedSteps,
    },
    formikTouchedFields:
      // Mark all steps as touched to validate them directly.
      (allFieldsWereTouched && getTouchedData({ steps })) || {},
  };
};

export const updateNodeById = ({ id, data, nodes }: UpdateNodeByIdParams) => {
  for (const nodeIndex in nodes) {
    if (nodes[nodeIndex] && nodes[nodeIndex].id === id) {
      const prevNodeData = nodes[nodeIndex].data;
      nodes[nodeIndex].data = {
        ...prevNodeData,
        ...data,
      };
      break;
    }
  }

  return nodes;
};

export const getNextStepId = (step: FlowStepData) => {
  if (step.step_type === "GO_TO") {
    return null;
  }

  if (hasItems(step.next_steps_for_options)) {
    return step.next_steps_for_options.find(Boolean) || null;
  }

  return (
    step.next_step_default ||
    step.next_step_alternate ||
    step.answer_failed_next_step ||
    step.next_step_failed ||
    null
  );
};

export const getOptionsCount = (options: any = null) => {
  if (!options) {
    return 0;
  }

  return options.filter(Boolean).length || 0;
};

export const hasMultipleNextSteps = (step: FlowStepData) => {
  const nextStepOptionsCount = getOptionsCount(
    step && step.next_steps_for_options
  );

  if (nextStepOptionsCount > 1) {
    return true;
  }

  return (
    getOptionsCount([
      step.next_step_default,
      step.next_step_alternate,
      step.answer_failed_next_step,
      step.next_step_failed,
      nextStepOptionsCount,
    ]) > 1
  );
};

export const updateNextStepId = (
  step,
  toNextStepId: string | null = null,
  fromNextStepId: string | null = null
) => {
  let isUpdated = false;
  const nextStep = { ...step };

  // If a next step currently exists, replace the value with the new one
  if (fromNextStepId) {
    if (nextStep.next_step_default === fromNextStepId) {
      isUpdated = true;
      nextStep.next_step_default = toNextStepId;
    } else if (nextStep.next_step_alternate === fromNextStepId) {
      isUpdated = true;
      nextStep.next_step_alternate = toNextStepId;
    } else if (nextStep.answer_failed_next_step === fromNextStepId) {
      isUpdated = true;
      nextStep.answer_failed_next_step = toNextStepId;
    } else if (nextStep.next_step_failed === fromNextStepId) {
      isUpdated = true;
      nextStep.next_step_failed = toNextStepId;
    }
  }

  if (!isUpdated) {
    // If the step has answer options
    if (
      (nextStep.step_type === "MENU" ||
        nextStep.step_type === "CONDITIONAL_MULTIPLE" ||
        nextStep.step_type === "TEMPLATE_MENU") &&
      (hasItems(nextStep.answer_options) ||
        hasItems(nextStep.next_steps_for_options))
    ) {
      const nextStepOptions: (string | null)[] = [];

      if (hasItems(nextStep.next_steps_for_options)) {
        for (const nextStepOptionId of nextStep.next_steps_for_options) {
          // If there is currently an option with a next step, replace it with the new value
          if (fromNextStepId && nextStepOptionId === fromNextStepId) {
            isUpdated = true;
            nextStepOptions.push(toNextStepId);
          } else {
            nextStepOptions.push(nextStepOptionId);
          }
        }

        if (!isUpdated) {
          // If no next step was found, assign the first one that is null.
          for (const nextStepOptionIndex in nextStepOptions) {
            if (!nextStepOptions[nextStepOptionIndex]) {
              isUpdated = true;
              nextStepOptions[nextStepOptionIndex] = toNextStepId;
              break;
            }
          }
        }
      } else {
        isUpdated = true;
        nextStepOptions.push(toNextStepId);
      }

      if (!isUpdated) {
        if (
          nextStep.has_options_from_variable &&
          !nextStep.next_step_alternate
        ) {
          isUpdated = true;
          nextStep.next_step_alternate = toNextStepId;
        } else if (
          nextStep.has_max_answer_attempts &&
          !nextStep.answer_failed_next_step
        ) {
          isUpdated = true;
          nextStep.answer_failed_next_step = toNextStepId;
        } else if (nextStep.has_msg_fail_path && !nextStep.next_step_failed) {
          isUpdated = true;
          nextStep.next_step_failed = toNextStepId;
        }
      }

      nextStep.next_steps_for_options = nextStepOptions;
    } else {
      isUpdated = true;

      if (nextStep.next_step_default) {
        if (
          nextStep.has_max_answer_attempts &&
          !nextStep.answer_failed_next_step
        ) {
          nextStep.answer_failed_next_step = toNextStepId;
        } else if (!nextStep.next_step_alternate) {
          // TODO: Add as alternate step only if is allowed in the step
          nextStep.next_step_alternate = toNextStepId;
        }
      } else {
        nextStep.next_step_default = toNextStepId;
      }
    }
  }

  return nextStep;
};

export const NEXT_DEFAULT_OPTION_TYPES = [
  "then",
  "answer",
  "response",
  "message",
  "sent",
];

export const NEXT_ALTERNATE_OPTION_TYPES = [
  "otherwise",
  "timeout",
  "error",
  "empty_data",
  "timeout_or_empty_data",
  "else",
];

export const NEXT_FAILED_OPTION_TYPES = ["failed_attempts"];
export const NEXT_MSG_FAILED_OPTION_TYPES = ["step_failed"];

export const NEXT_OPTION_TYPES = [
  ...NEXT_DEFAULT_OPTION_TYPES,
  ...NEXT_ALTERNATE_OPTION_TYPES,
  ...NEXT_FAILED_OPTION_TYPES,
  ...NEXT_MSG_FAILED_OPTION_TYPES,
];

export const KEYS_OF_STEP_WITH_VARIABLES = [
  "body",
  "body_file_url",
  "options_from_variable",
  "option_from_variable_value",
  "option_from_variable_title",
  "conditions",
  "paths",
  "answer_options",
  "whatsapp_template_variables",
  "contact_first_name",
  "contact_last_name",
  "contact_email",
  "contact_external_url",
  "contact_custom_properties",
  "firstname",
  "lastname",
  "email",
  "action_request_body",
  "action_request_headers",
  "action_request_url",
  "action_request_params",
];

export const updateStepData = ({
  step,
  toSourceStep,
  toOptionId = null,
  toTargetStepId = null,
  fromTargetStepId = null,
}: UpdateStepDataParams) => {
  if (toOptionId === null) {
    return {
      toSourceStep: updateNextStepId(toSourceStep, step.id, toTargetStepId),
      nextStep: updateNextStepId(step, toTargetStepId, fromTargetStepId),
    };
  }

  let toOptionStepId: string | null = null;

  if (NEXT_DEFAULT_OPTION_TYPES.includes(toOptionId)) {
    toOptionStepId = toSourceStep.next_step_default || null;
    toSourceStep.next_step_default = step.id;
  } else if (NEXT_ALTERNATE_OPTION_TYPES.includes(toOptionId)) {
    toOptionStepId = toSourceStep.next_step_alternate || null;
    toSourceStep.next_step_alternate = step.id;
  } else if (NEXT_FAILED_OPTION_TYPES.includes(toOptionId)) {
    toOptionStepId = toSourceStep.answer_failed_next_step || null;
    toSourceStep.answer_failed_next_step = step.id;
  } else if (NEXT_MSG_FAILED_OPTION_TYPES.includes(toOptionId)) {
    toOptionStepId = toSourceStep.next_step_failed || null;
    toSourceStep.next_step_failed = step.id;
  } else if (
    (toSourceStep.step_type === "MENU" ||
      toSourceStep.step_type === "CONDITIONAL_MULTIPLE" ||
      toSourceStep.step_type === "TEMPLATE_MENU") &&
    toSourceStep.answer_options &&
    hasItems(toSourceStep.answer_options)
  ) {
    const nextStepOptions: (string | null)[] = [];

    for (const stepOptionIndex in toSourceStep.answer_options) {
      const stepOptionStepId =
        (toSourceStep.next_steps_for_options &&
          toSourceStep.next_steps_for_options[stepOptionIndex]) ||
        null;
      if (isEqualId(stepOptionIndex, toOptionId)) {
        toOptionStepId = stepOptionStepId;
        nextStepOptions.push(step.id);
      } else {
        nextStepOptions.push(stepOptionStepId);
      }
    }
    toSourceStep.next_steps_for_options = nextStepOptions;
  }

  return {
    toSourceStep,
    nextStep: updateNextStepId(step, toOptionStepId, fromTargetStepId),
  };
};

export const getInitialStepValues = (
  id: string,
  type: StepTypes,
  t: TFunction
) => {
  const nextStep: Partial<FlowStepData> &
    Pick<FlowStepData, "id" | "step_type"> = {
    id,
    step_type: type,
  };

  if (type === "MENU") {
    nextStep.answer_options_render = "BUTTONS";
    nextStep.answer_options = ["Option 1"];
    nextStep.has_max_wait_time = false;
    nextStep.max_wait_time_amount = 24;
    nextStep.max_wait_time_unit = "HOUR";
  }
  if (type === "QUESTION") {
    nextStep.answer_options_render = "BUTTONS";
    nextStep.answer_options = [];
    nextStep.has_max_wait_time = false;
    nextStep.max_wait_time_amount = 24;
    nextStep.max_wait_time_unit = "HOUR";
  }
  if (type === "TEMPLATE") {
    nextStep.has_max_wait_time = false;
    nextStep.max_wait_time_amount = 24;
    nextStep.max_wait_time_unit = "HOUR";
  }
  if (type === "TEMPLATE_MENU") {
    nextStep.has_max_wait_time = false;
    nextStep.max_wait_time_amount = 24;
    nextStep.max_wait_time_unit = "HOUR";
    nextStep.next_action = "CONTINUE";
  }
  if (type === "CONDITIONAL_MULTIPLE") {
    nextStep.answer_options = [
      t("flows:steps.conditional-multiple.path", "Path {{number}}", {
        number: 1,
      }),
    ];
    nextStep.next_steps_for_options = [];
    nextStep.paths = [
      {
        conditions: [
          {
            comparison: "icontains",
            field: "contact.phone",
            value: "+52",
          },
        ],
      },
    ];
  }
  if (type === "DELAY") {
    nextStep.has_max_wait_time = true;
    nextStep.max_wait_time_amount = 24;
    nextStep.max_wait_time_unit = "HOUR";
  }
  if (type === "ACTION") {
    nextStep.action_request_headers = [];
    nextStep.action_request_params = [];
  }
  if (type.includes("WRAPPED")) {
    nextStep.action_request_headers = [];
    nextStep.action_request_params = [];
  }
  return nextStep;
};
