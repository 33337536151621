import { HilosVariableData } from "./hilos";
import {  InboxSettings,  PricingModelVersionEnum, PricingPlanSelectedEnum, StripePrice, StripeSubscriptionSimple } from "./private-schema";

interface NotificationSettings {
  notify_with_toast: boolean;
  require_confirmation_for_mass_send: boolean;
  confirmation_min: number;
  confirmation_phones: string[];
}
export interface AccountData {
  id: number;
  name: string;
  status: AccountStatus;
  utm_source: string;
  utm_term: string;
  utm_campaign: string;
  utm_medium: string;
  user_agent: string | null;
  referrer: string | null;
  agents_can_see_all_conversations?: boolean | null;
  // ? Maybe create a column or relation where you get all the contact and global variables.
  required_contact_variables: HilosVariableData[];
  rate_of_current_tier_to_use: number;
  continue_sending_next_day_on: string;
  default_timezone: string;
  current_wa_tier: string;
  tier_limit_hit_for_current_day: boolean;
  unique_conversations_opened_last_n_days: number;
  onboarding_company_domain: string;
  onboarding_company_size: string;
  onboarding_company_role: string;
  onboarding_use_case: string;
  sandbox_numbers?: Array<string | null>;
  generated_code: string | null;
  onboarding_use_case_other: string | null;
  onboarding_user_source: string;
  onboarding_user_source_other: string | null;
  onboarding_has_waba_provider: string | null;
  onboarding_waba_provider: string;
  measure_customer_satisfaction_per_conversation: boolean;
  feature_flags: string[];
  current_subscription: StripeSubscriptionSimple | null;
  subscription_should_start_on: string | null;
  country: string | null;
  payment_currency: string;
  billing_email: string | null;
  remaining_free_trial_days: number;
  default_channel: number;
  has_multiple_active_channels: boolean;
  has_used_multiple_channels: boolean;
  notification_settings: NotificationSettings;
  inbox_settings: InboxSettings;
  pricing_model_version?: PricingModelVersionEnum;
  pricing_plan_selected?: PricingPlanSelectedEnum;
  readonly current_billed_users: number;
  plan_price: StripePrice;
  custom_plan_description?: string | null;
  users_price: StripePrice;
  channels_price: StripePrice;
  macs_price: StripePrice;
}

export interface AccountDialog360Settings {
  d360_channel_id: string | null;
  d360_channel_name: string | null;
  api_key_ro: string | null;
  webhook_token: string | null;
  webhook_set: boolean;
}

export interface AccountMetaCloudAPISettings {
  access_token?: string;
  app_token?: string;
  access_token_ro?: string;
  app_token_ro?: string;
  whatsapp_business_account_id?: string;
  phone_number_id?: string;
  app_id?: string;
  webhook_set: boolean;
  webhook_verify_token: string;
}

export enum AccountStatus {
  NEW = "NEW",
  IN_SANDBOX = "IN_SANDBOX",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum WABAProvider {
  META_CLOUD_API = "META_CLOUD_API",
  D360_CLOUD_API = "D360_CLOUD_API",
  DIALOG360 = "360DIALOG",
}
