import { TimeoutMeta } from "src/containers/flow/Helpers";
import TimeWindows from "src/containers/flow/form/steps/components/TimeWindows";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import TextInputField from "src/components/Form/TextInputField";

export const FIELDS = {
  has_max_wait_time: {
    key: "has_max_wait_time",
    label: "Set max wait time?",
    help: "Set a maximum time to wait for a response before continuing.",
  },
  max_wait_time_amount: {
    key: "max_wait_time_amount",
    label: "Wait for a response for",
    placeholder: "For example, Available Options",
  },
  max_wait_time_unit: {
    key: "max_wait_time_unit",
  },
  next_step_alternate_idx: {
    key: "next_step_alternate_idx",
    label: "If user didn't answer after waiting, go to this step",
  },
  set_time_window: {
    key: "set_time_window",
    label: "Set a time window to continue?",
    help: "Useful if you'd like to wait a day to continue but only if it's between Mon-Fri.",
  },
  time_window_type: {
    key: "time_window_type",
    label: "Choose the time window type",
    help: "Use your account's working hours or set a custom time window.",
  },
  time_windows: {
    key: "time_windows",
    weekday: {
      key: "weekday",
      label: "Day of the week",
    },
    is_available: {
      key: "is_available",
      label: "Is available?",
    },
    start_at: {
      key: "start_at",
      label: "From",
    },
    end_at: {
      key: "end_at",
      label: "Until",
    },
  },
};

export default function DelayUntilComponent({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  return (
    <>
      <hr />

      <SwitchField
        name={`steps.${index}.${FIELDS.has_max_wait_time.key}`}
        label={FIELDS.has_max_wait_time.label}
        help={FIELDS.has_max_wait_time.help}
      />

      {formik.values.steps[index][FIELDS.has_max_wait_time.key] && (
        <>
          <div>
            <p className="block text-sm font-medium text-gray-700">
              {FIELDS.max_wait_time_amount.label}
            </p>
            <div className="grid grid-cols-3 items-center">
              <div className="col-span-2 w-full">
                <TextInputField
                  name={`steps.${index}.${FIELDS.max_wait_time_amount.key}`}
                  placeholder="24"
                  type="number"
                />
              </div>
              <div className="ml-2 w-full">
                <SelectorField
                  name={`steps.${index}.${FIELDS.max_wait_time_unit.key}`}
                  options={TimeoutMeta.MAX_WAIT_TIME_OPTIONS}
                />
              </div>
            </div>
          </div>

          <SelectorField
            label={FIELDS.next_step_alternate_idx.label}
            name={`steps.${index}.${FIELDS.next_step_alternate_idx.key}`}
            options={availableGroupedSteps(index)}
            formatGroupLabel={formatGroupLabel}
          />

          <TimeWindows formik={formik} index={index} />
        </>
      )}
    </>
  );
}
