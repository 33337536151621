import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationCircleIcon,
  ExclamationIcon,
} from "@heroicons/react/outline";
import { BeakerIcon } from "@heroicons/react/solid";
import { captureException } from "@sentry/react";
import QRCode from "qrcode";
import ConnectToSandboxCard from "src/containers/config/ConnectToSandboxCard";
import ConnectToSandboxMenu from "src/containers/home/ConnectToSandboxMenu";
import Loading from "src/components/Loading";
import { useChannel } from "src/hooks/useChannel";
import { AccountData, AccountStatus } from "src/types/account";

interface FlowTestModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  flow: any;
  account: AccountData;
}

export default function FlowTestModal({
  show,
  setShow,
  flow,
  account,
}: FlowTestModalProps) {
  const { t } = useTranslation();
  const { channel } = useChannel(flow.channel.id);
  const [QR, setQR] = useState("");
  const [url, setUrl] = useState<string | undefined>(undefined);

  const generateQRCode = useCallback(() => {
    if (channel && flow.test_execution_message) {
      const url = `https://api.whatsapp.com/send?phone=${channel.channel_id.replaceAll(
        " ",
        ""
      )}&text=${flow.test_execution_message}`;

      setUrl(url);
      QRCode.toDataURL(
        url,
        {
          width: 120,
          margin: 2,
          color: {
            dark: "#486581",
            light: "#ffffff",
          },
        },
        (err, url) => {
          if (err) return captureException(err);
          setQR(url);
        }
      );
    }
  }, [channel, flow]);

  useEffect(() => {
    generateQRCode();
  }, [channel, flow, generateQRCode]);

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={setShow}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-blue-500 mr-4 bg-blue-100">
                    <BeakerIcon className="h-6 w-6" />
                  </div>
                  <div className="text-lg">
                    {t("flows:test-modal.title", "Test your flow!")}
                  </div>
                </div>
                {account.status === AccountStatus.NEW ? (
                  <div className="mx-auto mt-6 block max-w-[500px]">
                    <div className="">
                      <div className="flex justify-center p-4 bg-yellow-100 text-yellow-700 items-center">
                        <ExclamationIcon className="w-6 h-6 self-center mr-4" />
                        <div className="text-sm font-semibold text-center  justify-self-center">
                          {t(
                            "flows:test.connect-sandbox.title",
                            "Connect your number to Sandbox to test this Flow!"
                          )}
                        </div>
                      </div>

                      <div className="text-xs text-gray-700 mt-4 px-2 font-semibold  justify-self-center">
                        {t(
                          "flows:test.connect-sandbox.description",
                          "Connect your number to the Sandbox by scanning the code from your phone or from your computer clicking the button."
                        )}
                      </div>

                      <div>
                        <ConnectToSandboxCard />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto mt-6 block max-w-[500px]">
                    {account.status === AccountStatus.IN_SANDBOX && (
                      <div>
                        <div className="flex justify-center p-4 mb-4 bg-blue-100 text-blue-700 items-center">
                          <ExclamationCircleIcon className="w-4 h-4 self-center mr-4" />
                          <div className="text-xs font-medium text-center  justify-self-center">
                            {t(
                              "flows:test.sandbox-advice",
                              "You can only test your flows with the following connected numbers to sandbox:"
                            )}
                          </div>
                        </div>
                        <div className="flex overflow-x-scroll gap-2 no-scrollbar justify-center mb-4">
                          {account.sandbox_numbers?.map((number) => (
                            <div className="rounded-md shrink-0 items-center flex bg-green-50 py-2 px-4 text-xs text-green-700">
                              <FontAwesomeIcon
                                // @ts-ignore
                                icon={faWhatsapp}
                                aria-hidden="true"
                                className="mr-2"
                              />
                              {number}
                            </div>
                          ))}
                        </div>
                        <div className="mb-8">
                          <ConnectToSandboxMenu />
                        </div>
                      </div>
                    )}
                    <div className="text-sm text-gray-800 mx-4">
                      {t(
                        "flows:test-modal.description",
                        "Test your flow before publishing it by scanning the QR code from your phone or clicking the button."
                      )}
                    </div>

                    {url ? (
                      <div>
                        <div className="py-6 text-center grid grid-cols-1 md:grid-cols-5 justify-center align-middle">
                          <div className="md:col-span-2">
                            <h3 className="text-sm font-bold text-center text-gray-500 justify-self-center">
                              {t(
                                "flows:test.test-on-your-computer",
                                "Test on your computer"
                              )}
                            </h3>
                          </div>
                          <div className="md:col-span-1"></div>
                          <div className="md:col-span-2">
                            <h3 className="text-sm font-bold text-gray-500">
                              {t(
                                "flows:test.test-on-your-phone",
                                "Test on your phone"
                              )}
                            </h3>
                          </div>
                          <div className="col-span-2 flex items-center justify-center">
                            <a
                              href={url}
                              rel="noreferrer"
                              target="_blank"
                              className="spread-shadow-gree h-fit mt-4 mb-4 inline-block rounded-lg bg-green-600 py-3 px-4 text-xs font-semibold text-white justify-self-center"
                            >
                              <FontAwesomeIcon
                                icon={faWhatsapp as IconProp}
                                aria-hidden="true"
                                className="mr-2"
                              />
                              {t("send-whatsapp", "Send WhatsApp")}
                            </a>
                          </div>
                          <div className="col-span-1 text-gray-400 font-bold self-center">
                            {t("or", "or")}
                          </div>
                          <div className="col-span-2 self-start">
                            {QR ? (
                              <img
                                className="mx-auto rounded-lg p-3"
                                src={QR}
                                alt="qr-code"
                              />
                            ) : (
                              <div className="m-2 flex h-[170px] min-h-[170px] min-w-[170px] animate-pulse flex-col items-center rounded-lg p-3 text-center">
                                {t("loading")}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="flex mt-4 items-center mx-0 text-gray-500 text-xs">
                          <ExclamationCircleIcon
                            className="h-4 w-4 ml-1 mr-1"
                            aria-hidden="true"
                          />

                          {t(
                            "flows:test-modal.info",
                            "This flow won't be triggered if your conversation is not CLOSED."
                          )}
                        </div>
                      </div>
                    ) : (
                      <Loading />
                    )}
                  </div>
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
