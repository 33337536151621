import { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ArrowSmLeftIcon, ArrowSmRightIcon } from "@heroicons/react/outline";
import FlowStepContactUpdate from "src/containers/flow/form/steps/contact_update/FlowStepContactUpdate";
import FlowStepDelay from "src/containers/flow/form/steps/delay/FlowStepDelay";
import SelectorField from "../../components/Form/SelectorField";
import FlowFormStepToggle from "./FlowFormStepToggle";
import { STEP_TYPES, getInitialValuesForStep } from "./FlowStepMeta";
import FlowStepAction from "./form/steps/action/FlowStepAction";
import FlowStepConditional from "./form/steps/conditional/FlowStepConditional";
import FlowStepConversation from "./form/steps/conversation/FlowStepConversation";
import FlowStepMenu from "./form/steps/menu/FlowStepMenu";
import FlowStepMessage from "./form/steps/message/FlowStepMessage";
import FlowStepQuestionContainer from "./form/steps/question/FlowStepQuestionContainer";
import FlowStepTag from "./form/steps/tag/FlowStepTag";
import FlowStepTeamConversation from "./form/steps/team/FlowStepTeamConversation";
import FlowStepTemplate from "./form/steps/template/FlowStepTemplate";
import FlowFromStepOutboundNotice from "./FlowFormStepOutboundNotice";
import FlowStepTriggerFlow from "./form/steps/trigger_flow/FlowStepTriggerFlow";

export default function FlowFormStep({ formik, arrayHelpers, index, step }) {
  const [open, setOpen] = useState();

  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
      {props.soon && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
            Coming soon!
          </span>
        </div>
      )}
      {props.beta && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-yellow-100 px-2.5 py-0.5 text-xs font-medium text-yellow-700">
            Beta
          </span>
        </div>
      )}
    </div>
  );

  const getAvailableNextSteps = (index) => {
    const availableSteps = formik.values.steps
      .map((step, idx) => {
        if (idx > index) {
          return {
            value: idx,
            label: `Step ${idx + 1}: ${step.name ? step.name : "Unnamed step"}`,
          };
        }
        return undefined;
      })
      .filter((x) => x);
    const endStep = { value: "end-flow", label: "-- None or end of flow --" };
    return availableSteps ? [...availableSteps, endStep] : [endStep];
  };
  const getAvailablePreviousSteps = (index) => {
    const availableSteps = formik.values.steps
      .map((step, idx) => {
        if (idx < index) {
          return {
            value: idx,
            label: `Step ${idx + 1}: ${step.name ? step.name : "Unnamed step"}`,
          };
        }
        return undefined;
      })
      .filter((x) => x);
    return availableSteps;
  };

  const availableGroupedSteps = (index) => {
    const groupedSteps = [];

    groupedSteps.push({
      label: "Previous Steps",
      icon: ArrowSmLeftIcon,
      options: getAvailablePreviousSteps(index),
    });
    groupedSteps.push({
      label: "Next Steps",
      icon: ArrowSmRightIcon,
      options: getAvailableNextSteps(index),
    });

    return groupedSteps;
  };

  const formatGroupLabel = (data) => (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <data.icon className="aria-hidden h-4 w-4 text-hilos" />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {data.label}
      </div>
    </div>
  );

  const handleStepChange = (step, option) => {
    if (formik.values.steps[index].step_type !== option.value) {
      formik.setFieldValue(
        `steps.${index}`,
        getInitialValuesForStep(step, option.value)
      );
    }
  };

  return (
    <Disclosure>
      {/* Purposely not using Disclosure.Button because it hijacks Space/Enter 
          functionality. We're trading off those for being able to write on 
          the Disclosure button */}
      <FlowFormStepToggle
        eventKey={index.toString()}
        formik={formik}
        index={index}
        step={step}
        open={open}
        setOpen={setOpen}
      />

      <Transition
        show={open}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Disclosure.Panel static className="bg-white p-4">
          <div className="mb-4">
            <SelectorField
              label="What kind of step will this be?"
              name={`steps.${index}.step_type`}
              formatOptionLabel={formatOptionLabel}
              isOptionDisabled={(option) => option.soon}
              options={STEP_TYPES}
              onSelect={(option) => handleStepChange(step, option)}
              // helpDocsLink="https://hilos.io/docs/user/flow-steps"
            />
          </div>

          <FlowFromStepOutboundNotice formik={formik} index={index} />

          <div className="mb-4 space-y-4">
            {formik.values.steps[index].step_type === "MESSAGE" && (
              <FlowStepMessage
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
              ></FlowStepMessage>
            )}
            {formik.values.steps[index].step_type === "QUESTION" && (
              <FlowStepQuestionContainer
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              ></FlowStepQuestionContainer>
            )}
            {formik.values.steps[index].step_type === "MENU" && (
              <FlowStepMenu
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              ></FlowStepMenu>
            )}
            {formik.values.steps[index].step_type === "CONDITIONAL" && (
              <FlowStepConditional
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              ></FlowStepConditional>
            )}
            {formik.values.steps[index].step_type === "ACTION" && (
              <FlowStepAction
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
              ></FlowStepAction>
            )}
            {formik.values.steps[index].step_type === "CONVERSATION" && (
              <FlowStepConversation formik={formik} index={index} />
            )}
            {formik.values.steps[index].step_type === "TEAM" && (
              <FlowStepTeamConversation formik={formik} index={index} />
            )}
            {formik.values.steps[index].step_type === "TAG" && (
              <FlowStepTag
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
              />
            )}
            {formik.values.steps[index].step_type === "TEMPLATE" && (
              <FlowStepTemplate
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              />
            )}
            {formik.values.steps[index].step_type === "DELAY" && (
              <FlowStepDelay
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              />
            )}
            {formik.values.steps[index].step_type === "CONTACT_UPDATE" && (
              <FlowStepContactUpdate
                formik={formik}
                index={index}
                availableGroupedSteps={availableGroupedSteps}
                formatGroupLabel={formatGroupLabel}
              />
            )}
            {formik.values.steps[index].step_type === "TRIGGER_FLOW" && (
              <FlowStepTriggerFlow
                formik={formik}
                index={index}
              />
            )}
          </div>
        </Disclosure.Panel>
      </Transition>
    </Disclosure>
  );
}
