import SelectorField from "../../../../../components/Form/SelectorField";
import FlowStepConditionalList from "./FlowStepConditionalList";
import { FlowStepConditionalMeta } from "./Meta";

export default function FlowStepConditional({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  return (
    <>
      <FlowStepConditionalList
        formik={formik}
        index={index}
      ></FlowStepConditionalList>

      <hr className="my-4" />
      <SelectorField
        label={FlowStepConditionalMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepConditionalMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepConditionalMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
        formatGroupLabel={formatGroupLabel}
      />

      <SelectorField
        label={FlowStepConditionalMeta.FIELDS.next_step_alternate_idx.label}
        name={`steps.${index}.${FlowStepConditionalMeta.FIELDS.next_step_alternate_idx.key}`}
        help={FlowStepConditionalMeta.FIELDS.next_step_alternate_idx.help}
        options={availableGroupedSteps(index)}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
}
