import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";
import { Card, DonutChart, Legend, Text } from "@tremor/react";
import { ConversationsSummaryByAgentInPeriodData } from "@hilos/types/analytics";
import LocalTable from "src/components/LocalTable";
import Tooltip from "src/components/Tooltip";
import {
  useInboxUsageSummaryAnalytics,
  useInboxUsageSummaryConversations,
  useInboxUsageSummaryMessages,
} from "src/hooks/useInboxUsageSummaryByAgentAnalytics";
import { API_ROUTES } from "src/router/router";
import { paramsToQueryString } from "../FiltersMeta";
import InboxUsageDownloadCSVButton from "../InboxUsageDownloadCSVButton";
import { InboxReportProps } from "../summary/InboxSummary";

export default function InboxByAgent({ params }: InboxReportProps) {
  const { t } = useTranslation();
  const paramsQS = paramsToQueryString(params);
  const { inboxUsageSummary, columnsTable: inboxSummaryColumnsTable } =
    useInboxUsageSummaryAnalytics(paramsQS + "&period_type_type=WEEK");
  const { inboxUsageSummaryConversations } =
    useInboxUsageSummaryConversations(paramsQS);
  const { inboxUsageSummaryMessages } = useInboxUsageSummaryMessages(paramsQS);

  return (
    <div className="space-y-6">
      <div className="flex justify-end">
        <InboxUsageDownloadCSVButton
          url={API_ROUTES.ANALYTICS_INBOX_USAGE_AGENT_SUMMARY}
          params={paramsQS}
          report_type="Summary By Agent"
        />
      </div>
      <div className="grid grid-cols-3 gap-x-6">
        <div className="space-y-6">
          {inboxUsageSummaryConversations && (
            <Card className="max-w-lg px-4 py-3 sm:px-6 sm:py-4">
              <Text className="flex items-center">
                {t(
                  "reports:by-agent.conversations-by-agent",
                  "Conversations by Agent"
                )}
                <Tooltip
                  content={
                    <div className="min-w-[170px] px-2 py-3 text-sm text-gray-500 sm:p-3 ">
                      {t(
                        "reports:by-agent.conversations-by-agent-tooltip",
                        "If a conversation is assigned to multiple agents, we counted once per agent"
                      )}
                    </div>
                  }
                />
              </Text>
              <DonutChart
                className="mt-6"
                data={
                  inboxUsageSummaryConversations.period &&
                  inboxUsageSummaryConversations.period.conversations_per_agent.map(
                    (data) => ({
                      source: data[0],
                      count: data[1],
                    })
                  )
                }
                category="count"
                index="source"
                showLabel
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
              <Legend
                className="mt-3"
                categories={inboxUsageSummaryConversations.period.conversations_per_agent.map(
                  (data) => data[0]
                )}
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
            </Card>
          )}
          {inboxUsageSummaryMessages && (
            <Card className="max-w-lg px-4 py-3 sm:px-6 sm:py-4">
              <Text>
                {t("reports:by-agent.messages-by-agent", "Messages by agent")}
              </Text>
              <DonutChart
                className="mt-6"
                data={
                  inboxUsageSummaryMessages.period &&
                  inboxUsageSummaryMessages.period.messages_per_agent.map(
                    (data) => ({
                      source: data[0],
                      count: data[1],
                    })
                  )
                }
                category="count"
                index="source"
                showLabel
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
              <Legend
                className="mt-3"
                categories={inboxUsageSummaryMessages.period.messages_per_agent.map(
                  (data) => data[0]
                )}
                colors={["slate", "violet", "indigo", "rose", "cyan", "amber"]}
              />
            </Card>
          )}
        </div>
        <div className="col-span-2">
          {inboxUsageSummary && inboxUsageSummary.period && (
            <div>
              <LocalTable<ConversationsSummaryByAgentInPeriodData>
                tableTitle={
                  <>
                    {t(
                      "reports:by-agent.summary-per-agent",
                      "Conversations summary (per agent)"
                    )}
                  </>
                }
                columns={
                  inboxSummaryColumnsTable as unknown as ColumnDef<ConversationsSummaryByAgentInPeriodData>[]
                }
                data={inboxUsageSummary.period.conversations_summary_in_period}
                columnVisibility={{
                  should_be_included: false,
                }}
                enableColumnSorting={true}
                disableSearch={true}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
