import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExclamationIcon } from "@heroicons/react/outline";
import { FormikProps } from "formik";
import { FlowStepDelayMeta } from "./Meta";

interface FlowStepDelayNoticeProps {
  formik: FormikProps<any>;
  index: number;
}

export default function FlowStepDelayNotice({
  formik,
  index,
}: FlowStepDelayNoticeProps) {
  const { t } = useTranslation();
  const showNotice = useMemo(() => {
    const timeUnit =
      formik.values.steps[index][
        FlowStepDelayMeta.FIELDS.max_wait_time_unit.key
      ];
    const timeAmount =
      formik.values.steps[index][
        FlowStepDelayMeta.FIELDS.max_wait_time_amount.key
      ];

    switch (timeUnit) {
      case "MINUTE":
        // 60 minutes * 24 hours
        if (timeAmount >= 60 * 24) {
          return true;
        }
        return false;
      case "HOUR":
        // 24 hours
        if (timeAmount >= 24) {
          return true;
        }
        return false;
      case "DAY":
        // 1 day
        if (timeAmount >= 1) {
          return true;
        }
        return false;
      default:
        console.warn(`Unknown timeUnit: ${timeUnit} at FlowStepDelayNotice`);
    }
    return true;
  }, [formik.values, index]);
  return (
    <>
      {showNotice && (
        <div className="rounded-md bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationIcon
                className="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <div className="text-sm text-yellow-700">
                <p>
                  {t(
                    "flows:steps.delay.notice",
                    "Since you're waiting more than 24 hours, use a TEMPLATE step to continue the conversation."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
