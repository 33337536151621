import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import useAccount from "src/hooks/useAccount";
import { useContactTags } from "src/hooks/useTags";
import { useConversationTags } from "src/hooks/useTags";
import { userAvatar, userToString } from "src/Helpers";
import { FlowStepConversationMeta } from "./Meta";

export default function FlowStepConversation({ formik, index }) {
  const { t } = useTranslation();
  const { members } = useAccount();
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();

  const formatOptionLabel = ({ value, label, user }) => (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <div className="mr-2">{userAvatar(user, "h-8 w-8")}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <SelectorField
        isMulti
        isCreatable={false}
        label={FlowStepConversationMeta.FIELDS.assign_to_users.label}
        name={`steps.${index}.${FlowStepConversationMeta.FIELDS.assign_to_users.key}`}
        help={FlowStepConversationMeta.FIELDS.assign_to_users.help}
        // @ts-ignore
        formatOptionLabel={formatOptionLabel}
        options={members.map((user) => ({
          label: userToString(user),
          value: user.id,
          user: user,
        }))}
      />
      <FlowTag
        title={t(
          "flows:steps.tag.conversation-tags.description",
          "Add conversation tags"
        )}
        availableTags={availableConversationTags}
        name={`steps.${index}.${FlowStepConversationMeta.FIELDS.conversation_tags.key}`}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        title={t(
          "flows:steps.tag.contact-tags.description",
          "Add contact tags"
        )}
        availableTags={availableContactTags}
        name={`steps.${index}.${FlowStepConversationMeta.FIELDS.contact_tags.key}`}
        {...contactTagsColorClasses}
      />
      <SwitchField
        name={`steps.${index}.${FlowStepConversationMeta.FIELDS.append_tags.key}`}
        label={FlowStepConversationMeta.FIELDS.append_tags.label}
        help={FlowStepConversationMeta.FIELDS.append_tags.help}
      />
    </>
  );
}
