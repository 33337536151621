import { useMemo } from "react";
import { gql, useSubscription } from "@apollo/client";
import { InboxIcon } from "@heroicons/react/outline";

const INBOX_CONTACTS_UNREAD_SUBSCRIPTION = gql`
  subscription OnInboxContactsUnread {
    api_inboxcontact(
      where: {
        _or: [{ is_unread: { _eq: true } }, { unseen_messages: { _neq: 0 } }]
      }
      limit: 1
    ) {
      id
    }
  }
`;

function InboxIconWithIndicator() {
  const { data } = useSubscription(INBOX_CONTACTS_UNREAD_SUBSCRIPTION);

  const hasUnseenConversations = useMemo(
    () => data?.api_inboxcontact?.length > 0,
    [data]
  );

  return (
    <div className="relative mr-2 h-5 w-5">
      {hasUnseenConversations && (
        <div className="absolute mr-0 flex h-2 w-2 self-end">
          <span className="flex h-2 w-2">
            <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-hilos opacity-75" />
            <span className="relative inline-flex h-2 w-2 rounded-full bg-hilos" />
          </span>
        </div>
      )}
      <InboxIcon className="h-5 w-5" aria-hidden="true" />
    </div>
  );
}

export default InboxIconWithIndicator;
