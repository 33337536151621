import { ReactNode, useMemo } from "react";
import { GroupBase } from "react-select";
import { ExclamationIcon, ShareIcon } from "@heroicons/react/outline";
import { get } from "lodash";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import {
  filterByStepType,
  getArrayVariableByName,
  getAvailableActionVariables,
  getOptionsFromArrayVariable,
} from "src/helpers/flow";
import { hasItems } from "src/helpers/utils";
import { FlowStepQuestionMeta } from "./form/steps/question/Meta";

function formatGroupLabel<Option>({
  // @ts-ignore
  icon: RenderIcon,
  label,
}: GroupBase<Option>): ReactNode {
  return (
    <div className="flex flex-nowrap py-1">
      <div className="rounded-full bg-hilos-light p-1">
        <RenderIcon
          className={`aria-hidden="true" h-4 w-4 text-hilos ${
            RenderIcon === ShareIcon ? "rotate-90" : ""
          }`}
        />
      </div>
      <div className="ml-2 self-center text-sm capitalize text-neutral-light">
        {label}
      </div>
    </div>
  );
}

function FlowOptionsFromVariable({
  steps,
  currentStepIndex,
  maxOptionsLength,
  availableGroupedSteps,
}) {
  const hasMissingActionTest = useMemo(
    () =>
      steps.some(
        filterByStepType(
          "ACTION",
          currentStepIndex,
          (step) => !hasItems(step.action_responses)
        )
      ),
    [steps, currentStepIndex]
  );

  const optionsFromArrayVariables = useMemo(() => {
    const availableActionSteps =
      steps.filter(filterByStepType("ACTION", currentStepIndex)) +
      steps.filter(filterByStepType("HUBSPOT_CONTACT_GET", currentStepIndex));

    const flowListVariables = getAvailableActionVariables(
      availableActionSteps,
      ["array"]
    );

    return [
      {
        label: "Flow List Variables",
        icon: ShareIcon,
        options: flowListVariables.map((key) => ({
          label: `flow.${key}`,
          value: `flow.${key}`,
        })),
      },
    ];
  }, [steps, currentStepIndex]);

  const optionsFromSelectedVariable = useMemo(() => {
    const selectedVariableName = get(
      steps,
      `${currentStepIndex}.options_from_variable`,
      null
    );

    const selectedVariable = getArrayVariableByName(
      steps,
      selectedVariableName
    );

    const keysFromSelectedVariable =
      getOptionsFromArrayVariable(selectedVariable);

    return keysFromSelectedVariable.map((key) => ({
      label: key,
      value: key,
    }));
  }, [steps, currentStepIndex]);

  const optionsFromAvailableSteps = useMemo(
    () => availableGroupedSteps(currentStepIndex),
    [currentStepIndex, availableGroupedSteps]
  );

  return (
    <div>
      {hasMissingActionTest && (
        <div className="my-2 flex items-center rounded-md bg-yellow-50 p-4">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              To use results of an action step, a test request is required to
              map available variables.
            </div>
          </div>
        </div>
      )}
      {maxOptionsLength && (
        <div className="my-2 flex items-center rounded-md bg-yellow-50 p-4">
          <ExclamationIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
          <div className="ml-3">
            <div className="text-xs text-yellow-700">
              Only the first {maxOptionsLength} results of the variable will be
              used.
            </div>
          </div>
        </div>
      )}

      <div className="flex w-full flex-col space-y-4 py-2">
        <SelectorField
          name={`steps.${currentStepIndex}.${FlowStepQuestionMeta.FIELDS.options_from_variable.key}`}
          label={FlowStepQuestionMeta.FIELDS.options_from_variable.label}
          placeholder={
            FlowStepQuestionMeta.FIELDS.options_from_variable.placeholder
          }
          options={optionsFromArrayVariables}
          formatGroupLabel={formatGroupLabel}
        />
        <SelectorField
          name={`steps.${currentStepIndex}.${FlowStepQuestionMeta.FIELDS.option_from_variable_value.key}`}
          label={FlowStepQuestionMeta.FIELDS.option_from_variable_value.label}
          placeholder={
            FlowStepQuestionMeta.FIELDS.option_from_variable_value.placeholder
          }
          options={optionsFromSelectedVariable}
          formatGroupLabel={formatGroupLabel}
        />
        <SelectorField
          name={`steps.${currentStepIndex}.${FlowStepQuestionMeta.FIELDS.option_from_variable_title.key}`}
          label={FlowStepQuestionMeta.FIELDS.option_from_variable_title.label}
          placeholder={
            FlowStepQuestionMeta.FIELDS.option_from_variable_title.placeholder
          }
          options={optionsFromSelectedVariable}
          formatGroupLabel={formatGroupLabel}
        />
        <TextInputField
          type="text"
          label={FlowStepQuestionMeta.FIELDS.missing_options_message.label}
          name={`steps.${currentStepIndex}.${FlowStepQuestionMeta.FIELDS.missing_options_message.key}`}
          placeholder={
            FlowStepQuestionMeta.FIELDS.missing_options_message.placeholder
          }
        />
        <SelectorField
          label={FlowStepQuestionMeta.FIELDS.next_step_alternate_idx.label}
          name={`steps.${currentStepIndex}.${FlowStepQuestionMeta.FIELDS.next_step_alternate_idx.key}`}
          placeholder={
            FlowStepQuestionMeta.FIELDS.next_step_alternate_idx.placeholder
          }
          options={optionsFromAvailableSteps}
        />
      </div>
    </div>
  );
}

export default FlowOptionsFromVariable;
