import SelectorField from "src/components/Form/SelectorField";
import DelayUntilComponent from "../components/DelayUntilComponent";
import MaxAnswerAttemptsComponent from "../components/MaxAnswerAttemptsComponent";
import FlowStepMessageText from "../message/FlowStepMessageText";
import FlowStepQuestionBoolean from "./FlowStepQuestionBoolean";
import FlowStepQuestionDate from "./FlowStepQuestionDate";
import FlowStepQuestionEmail from "./FlowStepQuestionEmail";
import FlowStepQuestionFile from "./FlowStepQuestionFile";
import FlowStepQuestionFreeText from "./FlowStepQuestionFreeText";
import FlowStepQuestionImage from "./FlowStepQuestionImage";
import FlowStepQuestionLocation from "./FlowStepQuestionLocation";
import FlowStepQuestionNumber from "./FlowStepQuestionNumber";
import FlowStepQuestionPhone from "./FlowStepQuestionPhone";
import FlowStepQuestionSingleOption from "./FlowStepQuestionSingleOption";
import FlowStepQuestionURL from "./FlowStepQuestionURL";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionContainer({
  formik,
  index,
  availableGroupedSteps,
  formatGroupLabel,
}) {
  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
      {props.soon && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
            Coming soon!
          </span>
        </div>
      )}
    </div>
  );

  const onAnswerTypeChange = (answerType, index) => {
    // Set answer_type
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_type.key}`,
      answerType
    );

    // Set instructions, if any
    const answerTypeMeta = FlowStepQuestionMeta.ANSWER_TYPES.find(
      (at) => at.value === answerType
    );
    const instructions = answerTypeMeta ? answerTypeMeta.instructions : "";
    formik.setFieldValue(
      `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_instructions.key}`,
      instructions
    );
    // Set options, if answer_type requires it and it doesn't already have ones
    if (answerType === "SINGLE_OPTION") {
      const answer_options =
        formik.values.steps[index][
          FlowStepQuestionMeta.FIELDS.answer_options.key
        ];
      if (!Array.isArray(answer_options) || answer_options.length === 0) {
        formik.setFieldValue(
          `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options.key}`,
          [""]
        );
      }
      formik.setFieldValue(
        `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options_render.key}`,
        answerType === "SINGLE_OPTION" ? "BUTTONS" : "EMOJIS"
      );
    }
    if (["BOOL"].includes(answerType)) {
      formik.setFieldValue(
        `steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options.key}`,
        ["Yes", "No"]
      );
    }
  };

  return (
    <>
      <FlowStepMessageText formik={formik} index={index} />

      <SelectorField
        label={FlowStepQuestionMeta.FIELDS.answer_type.label}
        name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_type.key}`}
        help={FlowStepQuestionMeta.FIELDS.answer_type.help}
        options={FlowStepQuestionMeta.ANSWER_TYPES}
        formatOptionLabel={formatOptionLabel}
        onSelect={(option) => {
          onAnswerTypeChange(option.value, index);
        }}
        isOptionDisabled={(option) => option.soon}
      />

      {formik.values.steps[index].answer_type === "BOOLEAN" && (
        <FlowStepQuestionBoolean formik={formik} index={index} />
      )}

      {formik.values.steps[index].answer_type === "DATE" && (
        <FlowStepQuestionDate formik={formik} index={index} />
      )}

      {formik.values.steps[index].answer_type === "EMAIL" && (
        <FlowStepQuestionEmail index={index} />
      )}

      {formik.values.steps[index].answer_type === "FILE" && (
        <FlowStepQuestionFile index={index} />
      )}

      {formik.values.steps[index].answer_type === "FREE_TEXT" && (
        <FlowStepQuestionFreeText />
      )}

      {formik.values.steps[index].answer_type === "IMAGE" && (
        <FlowStepQuestionImage index={index} />
      )}

      {formik.values.steps[index].answer_type === "NUMBER" && (
        <FlowStepQuestionNumber formik={formik} index={index} />
      )}

      {formik.values.steps[index].answer_type === "LOCATION" && (
        <FlowStepQuestionLocation formik={formik} index={index} />
      )}

      {formik.values.steps[index].answer_type === "PHONE" && (
        <FlowStepQuestionPhone formik={formik} index={index} />
      )}

      {formik.values.steps[index].answer_type === "SINGLE_OPTION" && (
        <FlowStepQuestionSingleOption
          formik={formik}
          index={index}
          availableGroupedSteps={availableGroupedSteps}
        />
      )}

      {formik.values.steps[index].answer_type === "URL" && (
        <FlowStepQuestionURL index={index} />
      )}

      <MaxAnswerAttemptsComponent
        formik={formik}
        index={index}
        availableGroupedSteps={availableGroupedSteps}
        formatGroupLabel={formatGroupLabel}
      />

      <SelectorField
        label={FlowStepQuestionMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepQuestionMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
        formatGroupLabel={formatGroupLabel}
      />

      <DelayUntilComponent
        formik={formik}
        index={index}
        availableGroupedSteps={availableGroupedSteps}
        formatGroupLabel={formatGroupLabel}
      />
    </>
  );
}
