import { useMemo } from "react";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import ChannelSelectField from "src/components/Form/ChannelSelectField";
import SelectorField from "src/components/Form/SelectorField";
import TextInputField from "src/components/Form/TextInputField";
import useHilosStore from "src/hooks/useHilosStore";
import * as meta from "./FlowMeta";
import { FlowStepTemplateMeta } from "./form/steps/template/Meta";

export default function FlowFormStepAttrs({ formik, setCurrentStep }) {
  const { session } = useHilosStore();

  const formatOptionLabel = ({ value, label, icon, help }) => (
    <div className="flex items-center py-3">
      <div className="mx-4">{icon}</div>
      <div>
        <h4 className="font-medium">{label}</h4>
        {help && <p className="mb-0 text-xs">{help}</p>}
      </div>
    </div>
  );

  const onExecutionTypeChange = (executionType) => {
    formik.setFieldValue(meta.FIELDS.execution_type.key, executionType);

    if (executionType === "OUTBOUND") {
      if (
        formik.values.current_draft &&
        Array.isArray(formik.values.current_draft.steps) &&
        ["MESSAGE", "QUESTION", "MENU"].includes(
          formik.values.current_draft.steps[0].step_type
        )
      ) {
        // Only a template can be the first step for an outbound flow
        const newSteps = [...formik.values.current_draft.steps];
        newSteps.splice(0, 1, FlowStepTemplateMeta.getInitialValues());
        formik.setFieldValue("steps", newSteps);
      }
    }
  };

  const availableFlowExecutionVariables = useMemo(
    () =>
      formik.values.flow_execution_variables?.map((variable) => ({
        label: variable,
        value: variable,
      })) ?? [],
    [formik.values.flow_execution_variables]
  );

  return (
    <div className="space-y-14">
      <div className="row mt-10 text-center">
        <h6 className="text-tiny uppercase tracking-wider text-gray-500">
          Step 1 of 2
        </h6>
        <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
          Let's start with the basics.
        </h1>
        <p className="mb-5 text-sm text-gray-500">
          Give this flow a name and choose how it starts.
        </p>
      </div>

      <div className="space-y-4">
        <TextInputField
          placeholder={meta.FIELDS.name.placeholder}
          label={meta.FIELDS.name.label}
          name={meta.FIELDS.name.key}
          help={meta.FIELDS.name.help}
          type="text"
        />

        {session?.account.has_multiple_active_channels && (
          <ChannelSelectField
            name={meta.FIELDS.channel.key}
            label={meta.FIELDS.channel.label}
            help={meta.FIELDS.channel.help}
          />
        )}

        <SelectorField
          label={meta.FIELDS.execution_type.label}
          name={meta.FIELDS.execution_type.key}
          formatOptionLabel={formatOptionLabel}
          options={meta.FLOW_EXECUTION_TYPES}
          onSelect={(option) => onExecutionTypeChange(option.value)}
          // helpDocsLink="https://hilos.io/docs/user/create-a-flow"
        />
        {session?.account.feature_flags.includes(
          "flow_execution_variables"
        ) && (
          <SelectorField
            isMulti
            isCreatable
            name={meta.FIELDS.flow_execution_variables.key}
            label={meta.FIELDS.flow_execution_variables.label}
            help={meta.FIELDS.flow_execution_variables.help}
            options={availableFlowExecutionVariables}
          />
        )}
      </div>

      <div className="my-14">
        <hr className="my-5" />
        <div className="nav grid grid-cols-3 grid-rows-1 items-center gap-4">
          <div className=""></div>
          <div className="col text-center">
            <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
              Step 1 of 2
            </h6>
          </div>
          <div className="justify-self-end">
            <button
              className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              type="button"
              onClick={(_) => setCurrentStep("steps")}
            >
              Steps
              <ArrowSmRightIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
