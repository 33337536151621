import React from "react";
import {
  ArrowSmLeftIcon,
  PlayIcon,
  PlusSmIcon,
  SaveIcon,
} from "@heroicons/react/outline";
import { FieldArray } from "formik";
import StateButton from "src/components/StateButton";
import FlowFormMissingFieldsNotice from "./FlowFormMissingFieldsNotice";
import FlowFormStep from "./FlowFormStep";
import FlowFormStepStepsDiagram from "./FlowFormStepStepsDiagram";
import { FlowStepTemplateMeta } from "./form/steps/template/Meta";

export default function FlowFormStepSteps({
  formik,
  setCurrentStep,
  isChatbot = false,
  submitted,
  success,
  formSaveAction,
  setFormSaveAction,
}) {
  return (
    <div>
      {isChatbot ? (
        <div className="row mt-10 text-center">
          <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
            What should we do in this chatbot?
          </h1>
          <p className="mb-5 text-sm text-gray-500">
            Set unlimited steps, choosing from a variety of options.
          </p>
        </div>
      ) : (
        <div className="row mt-10 text-center">
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Step 2 of 2
          </h6>
          <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
            What should we do in this flow?
          </h1>
          <p className="mb-5 text-sm text-gray-500">
            Set unlimited steps, choosing from a variety of options.
          </p>
        </div>
      )}

      <div className="mt-10 grid grid-cols-2 grid-rows-1 gap-6">
        <div className="col-span-2 md:col-span-1">
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            Steps
          </h6>

          <div className="my-2">
            <FlowFormMissingFieldsNotice formik={formik} />
          </div>

          <FieldArray
            name="steps"
            render={(arrayHelpers) => (
              <>
                <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-md">
                  <ul className="divide-y divide-gray-200">
                    {formik.values.steps &&
                      formik.values.steps.map((step, index) => (
                        <React.Fragment key={index}>
                          <FlowFormStep
                            formik={formik}
                            arrayHelpers={arrayHelpers}
                            index={index}
                            step={step}
                          ></FlowFormStep>
                        </React.Fragment>
                      ))}
                  </ul>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                    onClick={(_) =>
                      arrayHelpers.push({
                        ...{
                          step_type: "TEMPLATE",
                          name: "",
                        },
                        ...FlowStepTemplateMeta.getInitialValues(),
                      })
                    }
                  >
                    <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    Add step
                  </button>
                </div>
              </>
            )}
          ></FieldArray>
        </div>
        <div>
          <h6 className="text-tiny uppercase tracking-wider text-gray-500">
            {isChatbot ? "Chatbot preview" : "Flow preview"}
          </h6>
          <div className="mt-2 overflow-hidden rounded-md bg-white shadow">
            <FlowFormStepStepsDiagram formik={formik} />
          </div>
        </div>
      </div>

      {!isChatbot && (
        <div>
          <hr className="my-5" />
          <div className="nav flex items-center gap-4">
            <div>
              <button
                className="inline-flex items-center rounded-md border border-gray-300 bg-blue-500 px-4 py-3 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
                type="button"
                onClick={(_) => setCurrentStep("steps")}
              >
                <ArrowSmLeftIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                Steps
              </button>
            </div>
            <div className="grow text-center">
              <h6 className="mb-0 text-tiny uppercase tracking-wider text-gray-500">
                Step 2 of 2
              </h6>
            </div>
            <div className="flex items-center justify-self-end">
              <StateButton
                isSubmitting={formik.isSubmitting && formSaveAction === "save"}
                submitted={submitted && formSaveAction === "save"}
                success={success && formSaveAction === "save"}
                submittingText="Saving..."
                successText="Flow saved!"
                initialText={
                  <>
                    <SaveIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {!formik.values.flow_executions ||
                    formik.values.flow_executions.length < 1
                      ? "Publish without running"
                      : "Publish"}
                  </>
                }
                onClick={(_) => setFormSaveAction("save")}
                btnClasses="mr-2 inline-flex items-center px-4 py-3 border border-blue-600 shadow-sm text-sm leading-4 font-medium rounded-md text-blue-600 bg-white hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                btnClassesSuccess="mr-2 inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                btnClassesError="mr-2 inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
              />

              {(!formik.values.flow_executions ||
                formik.values.flow_executions.length < 1) && (
                <StateButton
                  isSubmitting={
                    formik.isSubmitting && formSaveAction === "save&run"
                  }
                  submitted={submitted && formSaveAction === "save&run"}
                  success={success && formSaveAction === "save&run"}
                  submittingText="Saving..."
                  successText="Flow saved!"
                  initialText={
                    <>
                      <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                      Publish & run
                    </>
                  }
                  btnClasses="inline-flex items-center px-4 py-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                  onClick={(_) => setFormSaveAction("save&run")}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
