import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { faBell, faBellSlash } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArchiveIcon, ExternalLinkIcon } from "@heroicons/react/outline";
import { InboxContactRead } from "@hilos/types/private-schema";
import CopyPhoneToClipboardButton from "src/components/Form/CopyPhoneToClipboardButton";
import { contactToString, formatPhone } from "../../../Helpers";
import { getDistanceFromNow } from "../../../helpers/date";
import { UpdateConversationStatusFn } from "../../../hooks/useInboxContactDetails";
import { buildRoute } from "../../../router/router";
import ConversationStatusIndicator from "../ConversationStatusIndicator";
import { getConnectionColor } from "../Helpers";

interface ConversationDetailsHeaderProps {
  inboxContact: InboxContactRead;
  onSwitchSilenced: () => void;
  onSwitchArchived: () => void;
  onUpdateConversationStatus: UpdateConversationStatusFn;
}

function ConversationDetailsHeader({
  inboxContact,
  onSwitchSilenced,
  onSwitchArchived,
}: ConversationDetailsHeaderProps) {
  const { t } = useTranslation();
  const externalURL = useMemo(
    () => inboxContact?.contact?.external_url || null,
    [inboxContact]
  );

  const statusColor = useMemo(
    () => getConnectionColor(inboxContact.last_inbound_message_on),
    [inboxContact]
  );

  return (
    <div className="px-3 py-3 text-center sm:px-4">
      <div className="mt-1">
        <div className="flex-rows mr-2 items-center justify-center font-medium text-gray-800">
          {!inboxContact.contact.first_name &&
          !inboxContact.contact.last_name ? (
            <div className="flex items-center justify-center">
              <span className="ml-2">
                <CopyPhoneToClipboardButton
                  value={inboxContact.contact.canonical_phone}
                  text={formatPhone(inboxContact.contact.phone, true, true)}
                />
              </span>
              <ConversationStatusIndicator color={statusColor} />
            </div>
          ) : (
            <>
              <div className="flex items-center justify-center">
                <span className="ml-2">
                  {contactToString(inboxContact.contact)}
                </span>
                <ConversationStatusIndicator color={statusColor} />
              </div>
              <div className="text-sm text-gray-500">
                {/* {formatPhone(inboxContact.contact.phone, true, true)} */}
                <CopyPhoneToClipboardButton
                  value={inboxContact.contact.canonical_phone}
                  text={formatPhone(inboxContact.contact.phone, true, true)}
                />
              </div>
            </>
          )}

          {inboxContact.last_inbound_message_on && statusColor !== "green" && (
            <div className="text-xs text-gray-400">
              ({getDistanceFromNow(inboxContact.last_inbound_message_on)})
            </div>
          )}

          {inboxContact.is_archived && (
            <span className="ml-1 mb-1 text-xs text-gray-400">(Archived)</span>
          )}

          <Link
            className="flex items-center justify-center text-sm text-blue-500"
            to={buildRoute("contact-update", {
              ":id": inboxContact.contact.id,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <ExternalLinkIcon className="mr-1 h-4 w-4" />
            <span>{t("update-contact", "Update contact")}</span>
          </Link>
          {externalURL !== null && (
            <a
              className="flex items-center justify-center text-sm text-blue-500"
              href={externalURL}
              target="_blank"
              rel="noreferrer"
            >
              <ExternalLinkIcon className="mr-1 h-4 w-4" />
              <span>{t("see-in-crm", "See in CRM")}</span>
            </a>
          )}

          <button
            type="button"
            className="hidden h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none sm:inline-flex"
            onClick={onSwitchSilenced}
          >
            <FontAwesomeIcon
              // @ts-ignore
              icon={inboxContact.is_silenced ? faBellSlash : faBell}
              className="h-5 w-5"
            />
          </button>
          <button
            type="button"
            className="hidden h-8 w-8 items-center justify-center rounded-full text-gray-500 transition duration-150 ease-in-out hover:bg-gray-300 focus:outline-none sm:inline-flex"
            onClick={onSwitchArchived}
          >
            <ArchiveIcon
              className={`h-5 w-5 ${
                inboxContact.is_archived ? "text-gray-800" : ""
              }`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ConversationDetailsHeader;
