import { useCallback, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/components/prism-json";
import "prismjs/components/prism-markup";
import "prismjs/components/prism-yaml";
import "prismjs/themes/prism.css";
import TextAreaField from "../../../../../components/Form/TextAreaField";
import FlowStepAddVariable from "../../../FlowStepAddVariable";
import FlowStepActionForm from "./FlowStepActionForm";
import FlowStepActionSelectBody from "./FlowStepActionSelectBody";
import { FlowStepActionMeta } from "./Meta";

export default function FlowStepActionBody({ formik, index }) {
  const headersContentType = useCallback(() => {
    return formik.values.steps[index].action_request_body
      ? formik.values.steps[index].action_request_headers.find(
          (header) =>
            !header.key.localeCompare("Content-Type", undefined, {
              sensitivity: "accent",
            }) && header.value !== ""
        )
      : { value: "NONE" };
  }, [formik.values.steps, index]);

  const getHeaderContentType = useCallback(() => {
    const bodyTypeHeader = FlowStepActionMeta.BODY_TYPES.find(
      (type) => type.value === headersContentType().value
    );
    return bodyTypeHeader ? bodyTypeHeader : { value: "NONE" };
  }, [headersContentType]);

  const getBodyTypeForHeader = useCallback(() => {
    return headersContentType() ? getHeaderContentType() : { value: "NONE" };
  }, [getHeaderContentType, headersContentType]);

  const [bodyType, setBodyType] = useState(getBodyTypeForHeader());

  const setContentType = (variable) => {
    // Here we add/update content-type header and update bodyType
    const contentTypeIndex = formik.values.steps[index].action_request_headers
      ? formik.values.steps[index].action_request_headers.findIndex(
          (header) =>
            !header.key.localeCompare("Content-Type", undefined, {
              sensitivity: "accent",
            })
        )
      : -1;
    if (contentTypeIndex < 0 && variable !== "NONE") {
      formik.values.steps[index].action_request_headers.push({
        key: "Content-Type",
        value: variable,
      });
    } else {
      if (variable !== "NONE") {
        formik.setFieldValue(
          `steps.${index}.action_request_headers.${contentTypeIndex}`,
          {
            key: "Content-Type",
            value: variable,
          }
        );
      } else {
        formik.values.steps[index].action_request_headers.splice(
          contentTypeIndex,
          1
        );
      }
    }
    let newBodyType = FlowStepActionMeta.BODY_TYPES.find(
      (type) => type.value === variable
    );
    setBodyType(newBodyType);
    if (newBodyType.type === "STRUCTURED") {
      if (!Array.isArray(formik.values.steps[index].action_request_body)) {
        formik.setFieldValue(`steps.${index}.action_request_body`, []);
      }
    } else if (newBodyType.type === "TEXT") {
      if (typeof formik.values.steps[index].action_request_body !== "string") {
        formik.setFieldValue(`steps.${index}.action_request_body`, "");
      }
    } else if (newBodyType.type === "OTHER") {
      formik.setFieldValue(`steps.${index}.action_request_body`, "");
    }
  };

  const [cursorPosition, setCursorPosition] = useState(0);

  const addVariable = (variable) => {
    const currentBody = formik.values.steps[index].action_request_body;
    const newBody = [
      currentBody.slice(0, cursorPosition),
      variable,
      currentBody.slice(cursorPosition),
    ].join("");
    formik.setFieldValue(
      `steps.${index}.${FlowStepActionMeta.FIELDS.action_request_body.key}`,
      newBody
    );
  };
  const onChangeRequestBody = (body) => {
    formik.setFieldValue(
      `steps.${index}.${FlowStepActionMeta.FIELDS.action_request_body.key}`,
      body
    );
  };

  const getEditorLanguague = () => {
    if (bodyType.value.includes("yaml")) {
      return "yaml";
    } else if (bodyType.value.includes("xml")) {
      return "xml";
    } else {
      return "json";
    }
  };

  const getEditorGrammar = () => {
    if (bodyType.value.includes("yaml")) {
      return languages.json;
    } else if (bodyType.value.includes("xml")) {
      return languages.xml;
    } else {
      return languages.json;
    }
  };

  return (
    <div className="mt-4">
      <FlowStepActionSelectBody
        setContentType={setContentType}
        bodyType={bodyType}
      />
      {bodyType.value === "text/plain" && (
        <div className="mt-2">
          <TextAreaField
            label={FlowStepActionMeta.FIELDS.action_request_body.label}
            name={`steps.${index}.${FlowStepActionMeta.FIELDS.action_request_body.key}`}
            help={FlowStepActionMeta.FIELDS.action_request_body.help}
            placeholder={
              FlowStepActionMeta.FIELDS.action_request_body.placeholder
            }
            onKeyDown={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onKeyUp={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onClick={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            rows={4}
          />

          <div className="mt-2 flex items-start justify-between">
            <div className="mr-4">
              <FlowStepAddVariable
                formik={formik}
                addVariable={addVariable}
                currentStepIndex={index}
              />
            </div>
          </div>
        </div>
      )}
      {(bodyType.value === "application/json" ||
        bodyType.value === "application/xml" ||
        bodyType.value === "text/yaml") && (
        <div className="container">
          <Editor
            placeholder="Type request body"
            value={formik.values.steps[index].action_request_body}
            onValueChange={(body) => onChangeRequestBody(body)}
            className="my-4 bg-slate-50"
            highlight={(body) =>
              highlight(body, getEditorGrammar(), getEditorLanguague())
            }
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 12,
              minHeight: 100,
            }}
            onKeyDown={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onKeyUp={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
            onClick={(e) => {
              setCursorPosition(e.target.selectionStart);
            }}
          />
          <div className="mt-2 flex items-start justify-between">
            <div className="mr-4">
              <FlowStepAddVariable
                formik={formik}
                addVariable={addVariable}
                currentStepIndex={index}
              />
            </div>
          </div>
        </div>
      )}
      {bodyType.type === "STRUCTURED" && (
        <FlowStepActionForm formik={formik} index={index} />
      )}
    </div>
  );
}
