import TextInputField from "../../../../../components/Form/TextInputField";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionFile({ index }) {
  return (
    <div>
      <TextInputField
        label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
        name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
        help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
        placeholder={
          FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
        }
        type="text"
      />
    </div>
  );
}
