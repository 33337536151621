import { ReactNode, useState } from "react";
import { FormikProps } from "formik";
import FlowAddVariableForm from "./FlowAddVariableForm";

interface FlowStepInputAddVariableProps {
  formik: FormikProps<any>;
  fieldName: string;
  currentValue?: string;
  currentStepIndex: number;
  children: (setShowVariableSelect: (boolean) => void) => ReactNode;
}
export default function FlowStepInputAddVariable({
  formik,
  fieldName,
  currentValue = undefined,
  currentStepIndex,
  children,
}: FlowStepInputAddVariableProps) {
  const [showVariableSelect, setShowVariableSelect] = useState(false);

  const addVariable = (value) => {
    const nextValue = currentValue ? `${currentValue} ${value}` : value;
    formik.setFieldValue(fieldName, nextValue);
    formik.setFieldTouched(fieldName);
  };

  return (
    <div className="space-y-3">
      {children(setShowVariableSelect)}
      {showVariableSelect && (
        <FlowAddVariableForm
          formik={formik}
          addVariable={addVariable}
          currentStepIndex={currentStepIndex}
          setShowVariableSelect={setShowVariableSelect}
          listIndex={null}
        />
      )}
    </div>
  );
}
