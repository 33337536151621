import { UserData } from "./hilos";
import {
  ContactListRead,
  ContactTag,
  ConversationReadSimple,
  ConversationStatusEnum,
  ConversationTag,
  FlowExecutionTypeEnum,
  InboxContactRead,
  MessageStatusEnum,
  MessageTypeEnum,
  UserSimple,
} from "./private-schema";
import { TeamData } from "./team";

export enum WhatsAppMessageStatusEnum {
  NEW = "new",
  RETRY = "retry",
  ACCEPTED = "accepted",
  SENDING = "sending",
  SENT = "sent",
  RECEIVING = "receiving",
  RECEIVED = "received",
  DELIVERED = "delivered",
  UNDELIVERED = "undelivered",
  FAILED = "failed",
  READ = "read",
  DELETED = "deleted",
}

export type WhatsAppMessageType =
  | "text"
  | "image"
  | "sticker"
  | "document"
  | "voice"
  | "video"
  | "audio"
  | "location"
  | "contacts"
  | "template"
  | "button"
  | "interactive"
  | "unknown"
  | "unsupported"
  | "ephemeral"
  | "system"
  | "request_welcome";

export interface BlastData {
  id: string;
  name: string;
  recipient?: string;
}

export interface FlowData {
  id: string;
  name: string;
  is_chatbot: boolean;
  is_csat: boolean;
  execution_type?: FlowExecutionTypeEnum;
}

export interface TagData {
  id: number;
  name: string;
}

export interface NoteData {
  id: number;
  notes: string;
  created_by: UserData;
  created_on: string;
}

export interface EventData {
  user?: UserData;
  blast?: BlastData;
  flow?: FlowData;
  flow_execution?: string;
  flow_version?: string;
  flow_execution_contact?: string;
  note?: NoteData;
  by?: UserData;
  tag?: TagData;
  status?: ConversationStatusEnum;
  reason?: string;
  team?: TeamData;
}

export interface ConversationEventData {
  id: string;
  event_type: string;
  type: string;
  content: string;
  event_data: EventData;
  timestamp: string;
  created_by: string;
}

export interface PaginatedConversationReadSimple {
  count: number;
  results: ConversationReadSimple[];
}

export interface InboxContactReadV2 extends InboxContactRead {
  contact: ContactListRead & {
    tags: { tag: ContactTag }[];
  };
  last_conversation: ConversationReadSimple & {
    assigned: { user: UserSimple }[];
    tags?: { tag: ConversationTag }[];
  };
  conversation_status: ConversationStatusEnum | null;
  channel_id: string;

  // InboxContactData Fields
  contact_tags: { tag: ContactTag }[];
  conversation_tags: { tag: ConversationTag }[];
  conversation_assigned: { user: UserSimple }[];
  contact__phone: string | null;
  contact__first_name: string | null;
  contact__last_name: string | null;
  contact__email: string | null;
  last_message__status: MessageStatusEnum;
  last_message__direction: string;
  last_message__is_deleted: boolean;
  last_message__msg_type: MessageTypeEnum;
  last_message__body?: string | null;
  last_message__content: any;
  last_message__timestamp: string;
  last_conversation__status: ConversationStatusEnum | null;
}

export interface InboxContactReadV2Query {
  api_inboxcontact: InboxContactReadV2[];
}
