import SelectorField from "../../../../../components/Form/SelectorField";
import TextInputField from "../../../../../components/Form/TextInputField";
import * as countries from "../../../../../constants/Country";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionPhone({ index }) {
  return (
    <>
      <div>
        <SelectorField
          label={FlowStepQuestionMeta.FIELDS.answer_phone_default_country.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_phone_default_country.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_phone_default_country.help}
          options={countries.COUNTRIES_WITH_FLAGS}
          formatOptionLabel={countries.formatCountryOptionLabel}
        />
      </div>
      <div>
        <TextInputField
          label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
          placeholder={
            FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
          }
          type="text"
        />
      </div>
    </>
  );
}
