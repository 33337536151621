import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  PlayIcon,
  ShareIcon,
  UserGroupIcon,
  UserIcon,
  ViewGridIcon,
} from "@heroicons/react/outline";
import { DateTime } from "luxon";
import ChannelBadge from "src/containers/config/channel/ChannelBadge";
import HeaderNavigation from "src/components/HeaderNavigation";
import Tabs from "src/components/Tabs";
import useFlowVersion from "src/hooks/useFlowVersion";
import useHilosStore from "src/hooks/useHilosStore";
import { userToString } from "src/Helpers";
import { buildRoute } from "src/router/router";
import FlowAnalyticsSummary from "../FlowAnalyticsSummary";
import FlowDetail from "../builder/FlowDetail";
import FlowExecutionList from "../flow_execution/FlowExecutionList";
import FlowExecutionContactList from "../flow_execution_contact/FlowExecutionContactList";
import FlowVersionResultDownload from "./FlowVersionResultsDownload";

export default function FlowVersionDetail() {
  const { t } = useTranslation();
  const params = useParams();
  const flowVersionId = params.id as string;
  const { session } = useHilosStore();

  const {
    data: flowVersion,
    refetch,
    setFlowVersionAsCurrent,
  } = useFlowVersion(flowVersionId);

  const handleSetFlowVersionAsCurrent = useCallback(async () => {
    try {
      setFlowVersionAsCurrent();
      refetch();
    } catch (err) {
      console.log("error", err);
    }
  }, [refetch, setFlowVersionAsCurrent]);

  if (!flowVersion) {
    return null;
  }

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      {/* Header */}
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: t("flows:list-header", "Flow list"),
              url: buildRoute("flow-list"),
            },
            {
              name: flowVersion.flow.name,
              url: buildRoute("flow-detail", { ":id": flowVersion.flow.id }),
            },
            {
              name: `${t("version", "Version")} ${flowVersion.version_name}`,
              url: buildRoute("flow-version-detail", {
                ":id": flowVersion.id,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ShareIcon
                className="mr-4 h-7 w-7 rotate-90"
                aria-hidden="true"
              />
              {t("version", "Version")} {flowVersion.version_name}{" "}
            </h2>
          </div>
        </div>
      </div>

      <div className="my-4 px-6">
        <div className="rounded-md border border-gray-200 bg-white px-4 py-5 shadow-sm sm:px-6">
          <h3 className="text-tiny font-medium uppercase tracking-wider text-gray-500">
            {t("summary", "Summary")}
          </h3>
          <div className="flex items-center justify-between text-sm">
            <div className="mr-4 w-full grow">
              <p className="text-base font-medium">
                {flowVersion.version_name}{" "}
              </p>
              <p>
                {flowVersion.is_current_version && (
                  <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
                    {t("flows:current-version", "Current")}
                  </span>
                )}
              </p>
              {session?.account.has_used_multiple_channels && (
                <p className="whitespace-nowrap text-sm text-gray-600">
                  <ChannelBadge channel={flowVersion.flow.channel} />
                </p>
              )}
              <p className="text-gray-400">
                {t("published-by", "Published by")}{" "}
                <span className="font-medium text-gray-700">
                  {userToString(flowVersion.published_by)}
                </span>
              </p>
              <p className="text-gray-400">
                {t("published-on", "Published on")}{" "}
                <span className="font-medium text-gray-700">
                  {flowVersion.created_on &&
                    DateTime.fromISO(flowVersion.created_on).toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS
                    )}
                </span>
              </p>
            </div>
            <div className="grow-0 space-y-2">
              {!flowVersion.is_current_version && (
                <button
                  className="focus:ring-hilos-500 mr-2 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
                  type="button"
                  onClick={(_) => handleSetFlowVersionAsCurrent()}
                >
                  <PlayIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  {t("flows:set-version-as-current", "Set as current version")}
                </button>
              )}
              <FlowVersionResultDownload flowVersion={flowVersion} />
            </div>
          </div>
        </div>
      </div>

      <FlowAnalyticsSummary flowObject={flowVersion} />

      <Tabs
        tabs={[
          {
            label: t("flow-viewer", "Flow Viewer"),
            icon: ViewGridIcon,
          },
          {
            label: t("contacts", "Contacts"),
            icon: UserIcon,
          },
          {
            label: t("flow-executions", "Flow Executions"),
            icon: UserGroupIcon,
          },
        ]}
        className="px-4 sm:px-6"
      >
        <FlowDetail flowVersion={flowVersion} />
        <FlowExecutionContactList
          flowId={flowVersion.flow.id}
          flowVersionId={flowVersionId}
        />
        <FlowExecutionList
          flowId={flowVersion.flow.id}
          flowVersionId={flowVersionId}
        />
      </Tabs>
    </div>
  );
}
