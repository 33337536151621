import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { CalendarIcon, PencilAltIcon } from "@heroicons/react/outline";
import { DateTime } from "luxon";
import { Flow } from "@hilos/types/flows/Flow";
import { FlowExecutionStatusPayload } from "@hilos/types/flows/FlowExecution";
import {
  FlowDetailRead,
  FlowExecutionList,
  InboundTriggerWithEnum,
  PaginatedFlowExecutionListList,
} from "@hilos/types/private-schema";
import { queryClient } from "src/HilosProvider";
import { buildRoute } from "../../../router/router";
import FlowExecutionControlsButtons from "../flow_execution_contact/FlowExecutionControlsButtons";
import FlowExecutionInboundTriggerWith from "./FlowExecutionInboundTriggerWith";

interface FlowExecutionSummaryInboundProps {
  flow: FlowDetailRead;
  flowExecution?: FlowExecutionList;
}

export default function FlowExecutionSummaryInbound({
  flow,
  flowExecution,
}: FlowExecutionSummaryInboundProps) {
  const { t } = useTranslation();

  const onFlowExecutionStatusUpdateSuccess = useCallback(
    (nextStatusFlowExecution: FlowExecutionStatusPayload) => {
      const currentFlowExecutions =
        queryClient.getQueryData<PaginatedFlowExecutionListList>([
          "flow_executions",
          flow.id,
        ]);
      const updatedFlowExecutions = currentFlowExecutions?.results?.map(
        (flowExecutionMap) => {
          if (flowExecutionMap.id === flowExecution?.id) {
            return {
              ...flowExecutionMap,
              ...nextStatusFlowExecution,
            };
          }
          return flowExecutionMap;
        }
      );
      queryClient.setQueryData(["flow_executions", flow.id], {
        ...currentFlowExecutions,
        results: updatedFlowExecutions,
      });
    },
    [flow, flowExecution?.id]
  );

  if (!flowExecution) {
    return null;
  }

  return (
    <>
      <div className="mt-3 flex items-center">
        <div>
          <p className="text-gray-900">
            <FlowExecutionInboundTriggerWith
              inbound_trigger_with={
                flowExecution.inbound_trigger_with as InboundTriggerWithEnum
              }
              inbound_start_message_match_exact={
                flowExecution.inbound_start_message_match_exact as boolean
              }
              inbound_start_message={
                flowExecution.inbound_start_message as string
              }
            />
          </p>
          <div className="mt-2 flex items-center text-sm text-gray-500 group-hover:text-gray-600">
            {flowExecution.start_on ? (
              <p>
                <CalendarIcon
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                {t("flow-executions:start-on-set", "Set to start on")}{" "}
                <time dateTime={flowExecution.start_on}>
                  {DateTime.fromISO(flowExecution.start_on).toLocaleString(
                    DateTime.DATETIME_SHORT_WITH_SECONDS
                  )}
                </time>
              </p>
            ) : (
              <span className="inline-flex items-center rounded-full bg-green-100 px-3 py-0.5 text-xs font-medium uppercase text-green-800">
                {t("flows:active", "Active")}
              </span>
            )}
          </div>
          <Link
            className="focus:ring-hilos-500 my-2 inline-flex items-center justify-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100"
            to={buildRoute("flow-execution-update", {
              ":id": flowExecution.id,
            })}
          >
            <PencilAltIcon
              className="-ml-0.5 mr-2 h-4 w-4"
              aria-hidden="true"
            />
            {t("update", "Update")}
          </Link>
        </div>

        {/* <FlowExecutionResultsDownload
              flowVersion={flowVersion}
              flowExecution={flowExecution}
            /> */}
        <div className="py-2 px-2">
          <FlowExecutionControlsButtons
            flowExecutionId={flowExecution.id ?? ""}
            flowExecutionStatus={flowExecution.status}
            onFlowExecutionStatusUpdateSuccess={
              onFlowExecutionStatusUpdateSuccess
            }
          />
        </div>
      </div>
    </>
  );
}
