import { useCallback, useState } from "react";
import { captureException } from "@sentry/react";
import axios from "axios";
import { UploadedFile } from "@hilos/types/uploaded_file";
import SelectorField from "../../../../../components/Form/SelectorField";
import { API_ROUTES, buildAPIRoute } from "../../../../../router/router";
import FlowStepMessageMedia from "./FlowStepMessageMedia";
import FlowStepMessageText from "./FlowStepMessageText";
import { FlowStepMessageMeta } from "./Meta";

export default function FlowStepMessage({
  formik,
  index,
  availableGroupedSteps,
}) {
  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
      {props.soon && (
        <div className="ml-2 text-right">
          <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
            Coming soon!
          </span>
        </div>
      )}
    </div>
  );

  const deleteUploadedFile = useCallback(async () => {
    if (!uploadedFile) {
      return;
    }
    try {
      await axios.delete(
        buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, {
          ":id": uploadedFile.id,
        })
      );
    } catch (e) {
      console.log(e);
      captureException(e);
    }
  }, [uploadedFile]);

  const handleBodyTypeChange = (selectedStepBodyType) => {
    deleteUploadedFile();
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file.key}`,
      ""
    );
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file_type.key}`,
      ""
    );
    if (selectedStepBodyType.value !== "text") {
      formik.setFieldValue(
        `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file_type.key}`,
        "URL"
      );
    }
    setUploadedFile(null);
  };

  return (
    <>
      <SelectorField
        label={FlowStepMessageMeta.FIELDS.body_type.label}
        name={`steps.${index}.${FlowStepMessageMeta.FIELDS.body_type.key}`}
        help={FlowStepMessageMeta.FIELDS.body_type.help}
        options={FlowStepMessageMeta.BODY_TYPES}
        // @ts-ignore
        formatOptionLabel={formatOptionLabel}
        isOptionDisabled={(option) => option.soon}
        onSelect={handleBodyTypeChange}
      />
      {formik.values.steps[index].body_type === "text" ? (
        <FlowStepMessageText formik={formik} index={index} />
      ) : (
        <FlowStepMessageMedia
          formik={formik}
          index={index}
          deleteUploadedFile={deleteUploadedFile}
          uploadedFile={uploadedFile}
          setUploadedFile={setUploadedFile}
        />
      )}
      <SelectorField
        label={FlowStepMessageMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepMessageMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepMessageMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
      />
    </>
  );
}
