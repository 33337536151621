import { Trans } from "react-i18next";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

export default function InactiveFlowNotice() {
  return (
    <div className="rounded-lg border-yellow-400 bg-yellow-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <Trans i18nKey="flows:inactive-flow-notice">
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              This flow is currently inactive, so it won't be triggered. Active
              it to select how to run this flow.
            </p>
          </div>
        </Trans>
      </div>
    </div>
  );
}
