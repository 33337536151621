import { FieldArray } from "formik";
import TextInputField from "../../../../../components/Form/TextInputField";
import { FlowStepQuestionMeta } from "./Meta";

export default function FlowStepQuestionBoolean({ formik, index }) {
  return (
    <>
      <div>
        <p className="block text-sm font-medium text-gray-700">
          Which options can the user choose from?
        </p>
        <p className="text-xs text-gray-500">
          We'll save the first option as <code>True</code>, the second as{" "}
          <code>False</code>. Provide user-friendly descriptions of both for
          your customers.
        </p>
        <div className="mt-2">
          <FieldArray
            name={`steps.${index}.answer_options`}
            render={(_) => (
              <>
                <div className="list-group list-group-flush">
                  {formik.values.steps &&
                    formik.values.steps[index].answer_options &&
                    formik.values.steps[index].answer_options.map((_, oIdx) => (
                      <div className="flex items-center py-1" key={oIdx}>
                        <div className="mr-4 grow-0">
                          <code>{oIdx + 1}</code>
                        </div>
                        <div className="grow">
                          <TextInputField
                            name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_options.key}.${oIdx}`}
                            placeholder={
                              FlowStepQuestionMeta.FIELDS.answer_options
                                .placeholder
                            }
                            type="text"
                          />
                        </div>
                      </div>
                    ))}
                </div>

                {formik.errors &&
                  formik.errors.steps &&
                  formik.errors.steps[index] &&
                  formik.errors.steps[index].answer_options &&
                  typeof formik.errors.steps[index].answer_options ===
                    "string" && (
                    <p className="text-danger mb-2">
                      {formik.errors.steps[index].answer_options}
                    </p>
                  )}
              </>
            )}
          ></FieldArray>
        </div>
      </div>

      <div className="mt-4">
        <TextInputField
          label={FlowStepQuestionMeta.FIELDS.answer_validation_message.label}
          name={`steps.${index}.${FlowStepQuestionMeta.FIELDS.answer_validation_message.key}`}
          help={FlowStepQuestionMeta.FIELDS.answer_validation_message.help}
          placeholder={
            FlowStepQuestionMeta.FIELDS.answer_validation_message.placeholder
          }
          type="text"
        />
      </div>
    </>
  );
}
