import { useTranslation } from "react-i18next";
import FlowTag from "src/components/FlowTag";
import SelectorField from "src/components/Form/SelectorField";
import SwitchField from "src/components/Form/SwitchField";
import {
  contactTagsColorClasses,
  conversationTagsColorClasses,
} from "src/components/Tags";
import { useContactTags } from "src/hooks/useTags";
import { useConversationTags } from "src/hooks/useTags";
import { FlowStepTagMeta } from "./Meta";

export default function FlowStepTag({ formik, index, availableGroupedSteps }) {
  const { tags: availableContactTags } = useContactTags();
  const { tags: availableConversationTags } = useConversationTags();
  const { t } = useTranslation();

  return (
    <>
      <FlowTag
        availableTags={availableConversationTags}
        name={`steps.${index}.${FlowStepTagMeta.FIELDS.conversation_tags.key}`}
        title={t(
          "flows:steps.tag.conversation-tags.description",
          "Add conversation tags"
        )}
        {...conversationTagsColorClasses}
      />
      <FlowTag
        availableTags={availableContactTags}
        name={`steps.${index}.${FlowStepTagMeta.FIELDS.contact_tags.key}`}
        title={t(
          "flows:steps.tag.contact-tags.description",
          "Add contact tags"
        )}
        {...contactTagsColorClasses}
      />
      <SwitchField
        name={`steps.${index}.${FlowStepTagMeta.FIELDS.append_tags.key}`}
        label={FlowStepTagMeta.FIELDS.append_tags.label}
        help={FlowStepTagMeta.FIELDS.append_tags.help}
      />
      <SelectorField
        label={FlowStepTagMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepTagMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepTagMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
      />
    </>
  );
}
