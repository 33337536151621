import { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, Transition } from "@headlessui/react";
import {
  ArrowSmRightIcon,
  EmojiSadIcon,
  XIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import { Formik } from "formik";
import { DateTime } from "luxon";
import * as yup from "yup";
import { axiosErr } from "@hilos/types/axios";
import SelectorField from "src/components/Form/SelectorField";
import TextAreaField from "src/components/Form/TextAreaField";
import StateButton from "src/components/StateButton";
import APIErrors from "src/components/base/APIErrors";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useHilosStore from "src/hooks/useHilosStore";
import { ERROR_MESSAGES } from "src/constants/errors";
import { API_ROUTES } from "src/router/router";

export default function SubscriptionV2Cancel() {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [backendError, setBackendError] = useState("");
  const [backendValidationErrors, setBackendValidationErrors] = useState({});
  const timeout = useCleanTimeout();
  const cancelButtonRef = useRef(null);
  const { session } = useHilosStore();

  const initialValues = {
    feedback: "",
    comment: "",
  };

  const FIELDS = {
    feedback: {
      key: "feedback",
      label: t(
        "settings:billing.cancel.feedback.label",
        "Can you share why you're canceling?"
      ),
    },
    comment: {
      key: "comment",
      label: t(
        "settings:billing.cancel.comment.label",
        "How can we do better?"
      ),
    },
  };

  const FEEDBACK_OPTIONS = [
    {
      value: "customer_service",
      label: t(
        "settings:billing.cancel.customer_service.label",
        "Customer service was less than expected"
      ),
    },
    {
      value: "low_quality",
      label: t(
        "settings:billing.cancel.low_quality.label",
        "Quality was less than expected"
      ),
    },
    {
      value: "missing_features",
      label: t(
        "settings:billing.cancel.missing_features.label",
        "Some features are missing"
      ),
    },
    {
      value: "switched_service",
      label: t(
        "settings:billing.cancel.switched_service.label",
        "I'm switching to a different service"
      ),
    },
    {
      value: "too_complex",
      label: t(
        "settings:billing.cancel.too_complex.label",
        "Ease of use was less than expected"
      ),
    },
    {
      value: "too_expensive",
      label: t(
        "settings:billing.cancel.too_expensive.label",
        "It's too expensive"
      ),
    },
    {
      value: "unused",
      label: t(
        "settings:billing.cancel.unused.label",
        "I don't use the service enough"
      ),
    },
    {
      value: "other",
      label: t("settings:billing.cancel.other.label", "Other reason"),
    },
  ];

  const onSubmit = async (formData, setSubmitting) => {
    setBackendValidationErrors({});
    setBackendError("");
    setSubmitting(true);

    try {
      await axios.post(API_ROUTES.STRIPE_SUBSCRIPTION_CANCEL, formData);
      setSubmitting(false);
      setSuccess(true);
      window.location.reload();
      setShow(false);
    } catch (err) {
      const errorAxios: axiosErr = err as axiosErr;
      setSuccess(false);
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          t(
            "settings:billing.cancel.error",
            "There was an error while canceling your subscription, please try again later."
          )
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
        setSuccess(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => setShow(false)}
        >
          <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block transform rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
                <div className="flex text-gray-800 items-center font-bold">
                  <div className="h-10 w-10 rounded-full flex items-center justify-center text-hilos mr-4 bg-red-100">
                    <EmojiSadIcon className="h-6 w-6" />
                  </div>
                  <div>
                    <div className="text-lg">
                      {t(
                        "settings:billing.v2.cancel.title",
                        "We're sorry to see you go!"
                      )}
                    </div>
                    <div className="text-sm text-gray-500 font-normal">
                      {t(
                        "settings:billing.v2.cancel.description",
                        "You'll still be able to use your account normally until {{periodEnd}} after canceling.",
                        {
                          periodEnd: DateTime.fromISO(
                            session?.account.current_subscription
                              ?.current_period_end as string
                          ).toLocaleString(DateTime.DATE_SHORT),
                        }
                      )}
                    </div>
                  </div>
                </div>

                <Formik
                  validationSchema={yup.object().shape({
                    feedback: yup.string().required(t(ERROR_MESSAGES.REQUIRED)),
                    comment: yup.string(),
                  })}
                  onSubmit={(values, { setSubmitting }) =>
                    onSubmit(values, setSubmitting)
                  }
                  initialValues={initialValues}
                >
                  {(formik) => (
                    <form
                      noValidate
                      onSubmit={formik.handleSubmit}
                      className="space-y-4 mt-8"
                    >
                      <APIErrors
                        APIError={backendError}
                        APIValidationErrors={backendValidationErrors}
                        fieldTranslations={FIELDS}
                      />

                      <SelectorField
                        label={t(FIELDS.feedback.label)}
                        name={FIELDS.feedback.key}
                        options={FEEDBACK_OPTIONS}
                      />

                      <TextAreaField
                        label={t(FIELDS.comment.label)}
                        name={FIELDS.comment.key}
                        optional
                      />

                      <div className="py-3 sm:flex sm:flex-row-reverse">
                        <StateButton
                          isSubmitting={formik.isSubmitting}
                          submitted={submitted}
                          success={success}
                          disabled={formik.isSubmitting}
                          submittingText={t("processing")}
                          successText={t("success", "Success!")}
                          btnClasses="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                          btnClassesSuccess="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-5 py-3 text-base font-medium text-green-800 shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                          btnClassesError="w-full inline-flex items-center justify-center rounded-md border border-transparent bg-yellow-100 px-5 py-3 text-base font-medium text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                          initialText={
                            <>
                              {t(
                                "settings:billing.cancel-subscription-button",
                                "Cancel my subscription"
                              )}
                              <ArrowSmRightIcon
                                className="ml-1 h-5 w-5"
                                aria-hidden="true"
                              />
                            </>
                          }
                        />

                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={() => setShow(false)}
                          ref={cancelButtonRef}
                        >
                          {t("cancel", "Cancel")}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <button
        type="button"
        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-hilos shadow-sm ring-inset ring-hilos ring-1 hover:bg-red-50 sm:mt-0 sm:w-auto"
        onClick={() => setShow(true)}
      >
        <XIcon className="h-5 w-5 mr-1" />
        {t("settings:billing.cancel-plan", "Cancel subscription")}
      </button>
    </>
  );
}
