import { useField } from "formik";

export interface StepInputFieldProps extends React.HTMLProps<HTMLInputElement> {
  name: string;
}

function StepInputField({ ...props }: StepInputFieldProps) {
  const [field] = useField(props);
  return (
    <div className="flex w-full">
      <input
        className="block w-full rounded-none border-0 border-b border-transparent bg-white shadow-none focus:border-indigo-600 focus:ring-0 sm:text-sm"
        {...field}
        {...props}
      />
    </div>
  );
}

export default StepInputField;
