import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { PlayIcon, ShareIcon } from "@heroicons/react/outline";
import axios from "axios";
import { usePostHog } from "posthog-js/react";
import {
  FlowExecutionReadDetail,
  FlowSimpleRead,
  FlowVersionSimpleRead,
} from "@hilos/types/private-schema";
import ChannelBadge from "src/containers/config/channel/ChannelBadge";
import useCleanTimeout from "src/hooks/useCleanTimeout";
import useFlow from "src/hooks/useFlow";
import { axiosErr } from "src/types/axios";
import { FlowExecutionSimple } from "src/types/flows/FlowExecution";
import HeaderNavigation from "../../../components/HeaderNavigation";
import { API_ROUTES, buildAPIRoute, buildRoute } from "../../../router/router";
import FlowExecutionForm from "./FlowExecutionForm";
import * as meta from "./FlowExecutionMeta";

export type FlowExecutionCreateFormSubmit = (
  formData: FlowExecutionSimple,
  setSubmitting: (boolean) => void,
  setBackendValidationErrors: React.Dispatch<
    React.SetStateAction<Record<string, unknown>>
  >,
  setBackendError: React.Dispatch<React.SetStateAction<string>>
) => Promise<void>;

export default function FlowExecutionCreate() {
  const { t } = useTranslation();
  const params = useParams();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const { flow } = useFlow(params.id!);

  const timeout = useCleanTimeout();

  const formSubmit: FlowExecutionCreateFormSubmit = async (
    formData,
    setSubmitting,
    setBackendValidationErrors,
    setBackendError
  ) => {
    setBackendValidationErrors({});
    setBackendError("");
    try {
      const response = await axios.post<FlowExecutionReadDetail>(
        buildAPIRoute(API_ROUTES.FLOW_EXECUTION_RUN, {
          ":id": params.id,
        }),
        formData
      );
      setSubmitting(false);
      setSuccess(true);
      posthog?.capture("flow_execution created", {
        flow_name: response.data.flow_version.flow.name,
        flow_execution_id: response.data.id,
        flow_execution_execution_type: response.data.execution_type,
        created_by: response.data.created_by,
      });
      timeout.current = setTimeout(() => {
        navigate(buildRoute("flow-detail", { ":id": params.id }));
      }, 1000 * 2);
    } catch (err) {
      setSuccess(false);
      posthog?.capture("flow_execution creation failed", {
        formData: formData,
        error: JSON.stringify(err),
      });
      const errorAxios: axiosErr = err as axiosErr;
      if (errorAxios?.response?.status === 400) {
        console.log("error", errorAxios);
        setBackendValidationErrors(errorAxios.response.data);
      } else {
        setBackendError(
          "An error occurred while running your flow. Please try again."
        );
      }
      timeout.current = setTimeout(() => {
        setSubmitted(false);
      }, 1000 * 2);
    } finally {
      setSubmitting(false);
      setSubmitted(true);
    }
  };

  return (
    <div className="h-screen overflow-y-auto bg-gray-50">
      <div className="py-4 px-4 sm:border-b sm:border-gray-200 sm:px-6 lg:px-8">
        <HeaderNavigation
          navPages={[
            {
              name: "Home",
              url: buildRoute("dashboard"),
            },
            {
              name: `Flow ${flow?.name}`,
              url: buildRoute("flow-detail", { ":id": params.id }),
            },
            {
              name: "New execution",
              url: buildRoute("flow-execution-create", {
                ":id": params.id,
              }),
            },
          ]}
        />
        <div className="lg:flex lg:items-center lg:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="mt-2 flex items-center text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl">
              <ShareIcon
                className="mr-4 h-7 w-7 rotate-90"
                aria-hidden="true"
              />
              {t("flow-executions:outbound.run.basic")} {flow?.name}
            </h2>
          </div>
        </div>
      </div>

      {flow && (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-5xl">
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto mt-6 max-w-2xl">
                <div className="space-y-14">
                  <div className="row mt-10 text-center">
                    <h1 className="mt-5 mb-1 text-2xl font-bold leading-7 text-gray-900">
                      {t(
                        "flow-executions:create.title",
                        "Let's configure this {{flowType}} flow to run",
                        {
                          flowType:
                            flow.current_version.execution_type.toLowerCase(),
                        }
                      )}
                    </h1>
                    <p className="text-sm text-gray-500">
                      {flow.current_version.execution_type === "INBOUND"
                        ? t(
                            "flow-executions:create.subtitle.inbound",
                            "Set the trigger message for this flow to start."
                          )
                        : t(
                            "flow-executions:create.subtitle.outbound",
                            "Filter or upload contacts and schedule it."
                          )}
                    </p>
                    <p className="mt-2 mb-5 text-sm text-gray-500">
                      {t(
                        "flow-executions:create.subtitle.channel",
                        "This flow will run on channel"
                      )}{" "}
                      <ChannelBadge channel={flow.channel} />
                    </p>
                  </div>
                  <FlowExecutionForm
                    flow={flow as unknown as FlowSimpleRead}
                    flowVersion={
                      flow.current_version as unknown as FlowVersionSimpleRead
                    }
                    submittingText={t(
                      "flow-executions:run-on-button-running",
                      "Running..."
                    )}
                    successText={t(
                      "flow-executions:run-on-button-scheduled",
                      "Run scheduled!"
                    )}
                    initialText={
                      <>
                        <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                        {t("flow-executions:run-now-button", "Run now")}
                      </>
                    }
                    flowExecution={meta.getInitialValues(flow)}
                    formSubmit={formSubmit}
                    success={success}
                    submitted={submitted}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
