import { useState } from "react";
import { ExclamationIcon, PlayIcon } from "@heroicons/react/outline";
import axios from "axios";
import { API_ROUTES, buildAPIRoute } from "../../../../../router/router";
import { FlowStepActionMeta } from "./Meta";

export default function FlowFormStepActionTest({ step, formik, index }) {
  const [loading, setLoading] = useState(false);

  const testAction = async () => {
    setLoading(true);
    let stepTemp = { ...step };
    if (stepTemp.name === "") stepTemp.name = "Action_Step";
    if (Array.isArray(stepTemp.action_request_body)) {
      stepTemp.action_request_body?.forEach((obj, idx) => {
        if (!obj.key || !obj.value) {
          stepTemp.action_request_body.splice(idx, 1);
        }
      });
      let objBody = stepTemp.action_request_body
        ? JSON.stringify(
            stepTemp.action_request_body.reduce(
              (obj, item) => Object.assign(obj, { [item.key]: item.value }),
              {}
            )
          )
        : "";
      stepTemp.action_request_body = objBody;
    }
    stepTemp.action_request_headers?.forEach((obj, idx) => {
      if (!obj.key || !obj.value) {
        stepTemp.action_request_headers.splice(idx, 1);
      }
    });
    let objHeaders = stepTemp.action_request_headers
      ? stepTemp.action_request_headers.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        )
      : {};
    stepTemp.action_request_headers = objHeaders;
    stepTemp.action_request_params?.forEach((obj, idx) => {
      if (!obj.key || !obj.value) {
        stepTemp.action_request_params.splice(idx, 1);
      }
    });
    let objParams = stepTemp.action_request_params
      ? stepTemp.action_request_params.reduce(
          (obj, item) => Object.assign(obj, { [item.key]: item.value }),
          {}
        )
      : {};
    stepTemp.action_request_params = objParams;
    try {
      const response = await axios.post(
        buildAPIRoute(API_ROUTES.FLOW_STEP_ACTION_TEST),
        stepTemp
      );
      if (typeof response.data !== "object") {
        formik.setFieldValue(
          `steps.${index}.${FlowStepActionMeta.FIELDS.action_test_response_data.key}`,
          {
            response: response.data,
          }
        );
      } else {
        formik.setFieldValue(
          `steps.${index}.${FlowStepActionMeta.FIELDS.action_test_response_data.key}`,
          {
            response: response.data.response,
            "content-type": response.data["content-type"],
            status: response.data["status"],
            ...(response.data["redirect_data"] && {
              redirect_data: response.data["redirect_data"],
            }),
          }
        );
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getResponseEntries = () => {
    let type =
      formik.values.steps[index].action_test_response_data?.["content-type"];
    if (type && type.includes("json")) {
      return (
        <>
          {Object.entries(
            formik.values.steps[index].action_test_response_data.response
          ).map(([key, value]) => {
            let resolved_value =
              typeof value === "object"
                ? JSON.stringify(value, 2, null)
                : value;
            return (
              <p key={key}>
                <span className="font-semibold">{key}</span>{" "}
                {": " + resolved_value}
              </p>
            );
          })}
        </>
      );
    }
    return formik.values.steps[index].action_test_response_data?.response;
  };

  return (
    <>
      <div className="my-6">
        <button
          type="button"
          className="inline-flex w-full items-center justify-center rounded-md border border-green-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-green-500 shadow-sm hover:bg-green-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-green-500"
          onClick={() => testAction()}
        >
          <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
          {loading ? "Requesting..." : "Test Request"}
        </button>
        {formik.values.steps[index].action_test_response_data?.redirect_data !==
          undefined && (
          <>
            <div className="my-2 flex rounded-md bg-yellow-50 p-4">
              <div className="flex-shrink-0 self-center">
                <ExclamationIcon
                  className="h-5 w-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <div className="text-sm font-bold text-yellow-400">
                  This request had at least 1 redirection
                </div>
                <div className="mt-1 text-xs font-medium text-yellow-400">
                  Initial url:{" "}
                  <code className="ml-2 bg-white  text-yellow-500">
                    {
                      formik.values.steps[index].action_test_response_data
                        .redirect_data["initial_url"]
                    }
                  </code>
                </div>
                <div className="text-xs text-yellow-400">
                  Final url:{" "}
                  <code className="ml-2 bg-white  text-yellow-500">
                    {
                      formik.values.steps[index].action_test_response_data
                        .redirect_data["final_url"]
                    }
                  </code>
                </div>
                <div className="mt-2 border-t border-yellow-400 pt-2 text-xs text-yellow-400">
                  If you are having problems with the expected behavior, please
                  consider using the final url.
                </div>
              </div>
            </div>
          </>
        )}
        {formik.values.steps[index].action_test_response_data?.status !==
          undefined &&
          formik.values.steps[index].action_test_response_data?.status !==
            "" && (
            <>
              <div className="mt-4 block text-sm font-medium text-gray-700">
                Status
              </div>
              <div className="mt-2 block rounded-md bg-gray-50 px-2 py-2 text-sm">
                {formik.values.steps[index].action_test_response_data.status}
              </div>
            </>
          )}
        {formik.values.steps[index].action_test_response_data?.[
          "content-type"
        ] !== undefined &&
          formik.values.steps[index].action_test_response_data?.[
            "content-type"
          ] !== "" && (
            <>
              <div className="mt-4 block text-sm font-medium text-gray-700">
                Content Type
              </div>
              <div className="mt-2 block rounded-md bg-blue-50 px-2 py-2 text-sm">
                {
                  formik.values.steps[index].action_test_response_data[
                    "content-type"
                  ]
                }
              </div>
            </>
          )}
        {formik.values.steps[index].action_test_response_data?.response !==
          undefined &&
          formik.values.steps[index].action_test_response_data?.response !==
            "" && (
            <>
              <div className="mt-4 block text-sm font-medium text-gray-700">
                Response
              </div>
              <div className="mt-2 block rounded-md bg-green-50 px-2 py-2 text-sm">
                {getResponseEntries()}
              </div>
            </>
          )}
      </div>
    </>
  );
}
