import { useCallback, useState } from "react";
import FlowStepAddVariable from "src/containers/flow/FlowStepAddVariable";
import SelectorField from "../../../../../components/Form/SelectorField";
import TextInputField from "../../../../../components/Form/TextInputField";
import FlowFormStepActionTest from "./FlowFormStepActionTest";
import FlowStepActionBody from "./FlowStepActionBody";
import FlowStepActionHeadersList from "./FlowStepActionHeadersList";
import FlowStepActionNav from "./FlowStepActionNav";
import FlowStepActionParamsList from "./FlowStepActionParamsList";
import { FlowStepActionMeta } from "./Meta";

export default function FlowStepAction({
  formik,
  index,
  availableGroupedSteps,
}) {
  const [currentStep, setCurrentStep] = useState("body");

  const formatOptionLabel = ({ value, label, icon, help, ...props }) => (
    <div className="flex items-center justify-between py-3">
      <div className="flex items-center">
        <div className="mx-4">{icon}</div>
        <div>
          <h4 className="font-medium">{label}</h4>
          {help && <p className="text-xs">{help}</p>}
        </div>
      </div>
    </div>
  );

  const [cursorPosition, setCursorPosition] = useState(0);

  const addVariable = useCallback(
    (variable) => {
      const currentURL = formik.values.steps[index].action_request_url;
      const newURL = [
        currentURL.slice(0, cursorPosition),
        variable,
        currentURL.slice(cursorPosition),
      ].join("");
      formik.setFieldValue(
        `steps.${index}.${FlowStepActionMeta.FIELDS.action_request_url.key}`,
        newURL
      );
    },
    [cursorPosition, formik, index]
  );

  return (
    <>
      <SelectorField
        label={FlowStepActionMeta.FIELDS.action_request_method.label}
        name={`steps.${index}.${FlowStepActionMeta.FIELDS.action_request_method.key}`}
        help={FlowStepActionMeta.FIELDS.action_request_method.help}
        options={FlowStepActionMeta.METHOD_TYPES}
        formatOptionLabel={formatOptionLabel}
      />
      <TextInputField
        label={FlowStepActionMeta.FIELDS.action_request_url.label}
        name={`steps.${index}.${FlowStepActionMeta.FIELDS.action_request_url.key}`}
        key={`steps.${index}.${FlowStepActionMeta.FIELDS.action_request_url.key}`}
        placeholder={FlowStepActionMeta.FIELDS.action_request_url.placeholder}
        type="text"
        onKeyDown={(e) => {
          setCursorPosition(e.target.selectionStart);
        }}
        onKeyUp={(e) => {
          setCursorPosition(e.target.selectionStart);
        }}
        onClick={(e) => {
          setCursorPosition(e.target.selectionStart);
        }}
      />
      <div className="mt-2 flex items-start justify-between">
        <div className="mr-4">
          <FlowStepAddVariable
            formik={formik}
            addVariable={addVariable}
            currentStepIndex={index}
          />
        </div>
      </div>
      <FlowStepActionNav
        formik={formik}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
      />
      {currentStep === "headers" && (
        <FlowStepActionHeadersList formik={formik} index={index} />
      )}
      {currentStep === "query" && (
        <FlowStepActionParamsList formik={formik} index={index} />
      )}
      {currentStep === "body" && (
        <FlowStepActionBody formik={formik} index={index} />
      )}
      <FlowFormStepActionTest
        formik={formik}
        index={index}
        step={formik.values.steps[index]}
      />
      <SelectorField
        label={FlowStepActionMeta.FIELDS.next_step_default_idx.label}
        name={`steps.${index}.${FlowStepActionMeta.FIELDS.next_step_default_idx.key}`}
        help={FlowStepActionMeta.FIELDS.next_step_default_idx.help}
        options={availableGroupedSteps(index)}
      />
    </>
  );
}
