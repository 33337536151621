import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Handle, NodeProps, Position } from "reactflow";
import { TRIGGER_TYPES } from "../../constants/flow";

const TriggerNode = ({ id, data }: NodeProps) => {
  const [t] = useTranslation();
  const {
    title,
    description,
    icon: TriggerIcon,
  } = useMemo(() => TRIGGER_TYPES[data.type], [data.type]);

  return (
    <div key={id} className="nodrag w-60 rounded-lg p-2 shadow-md bg-teal-500">
      <div className="flex flex-row items-center">
        <TriggerIcon className="h-4 w-4 text-white" />
        <h1 className="ml-1 text-sm font-bold text-white">{t(title)}</h1>
      </div>
      <p className="font-small text-xs text-white">{t(description)}</p>
      <Handle type="source" position={Position.Bottom} isConnectable={false} />
    </div>
  );
};

export default memo(TriggerNode);
