import { useTranslation } from "react-i18next";
import { MultiSelect, MultiSelectItem } from "@tremor/react";
import { useContactTags } from "src/hooks/useTags";

interface ContactTagFilterProps {
  value: string[];
  onValueChange: (value: string[]) => void;
}

export default function ContactTagFilter({
  value,
  onValueChange,
}: ContactTagFilterProps) {
  const { tags } = useContactTags();
  const { t } = useTranslation();
  return (
    <MultiSelect
      placeholder={t("contact-tags")}
      value={value}
      onValueChange={onValueChange}
    >
      {tags.map((tag) => (
        <MultiSelectItem value={String(tag.id)} key={tag.id}>
          {tag.name}
        </MultiSelectItem>
      ))}
    </MultiSelect>
  );
}
