import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlayIcon } from "@heroicons/react/outline";
import { FormikTouched } from "formik";
import { FlowData } from "@hilos/types/flow";
import HeaderNavigation from "src/components/HeaderNavigation";
import LoaderSpinner from "src/components/LoaderSpinner";
import StateButton from "src/components/StateButton";
import { classNames } from "src/Helpers";
import { buildRoute } from "src/router/router";
import UpdatedAt from "./UpdatedAt";
import { FLOW_TABS } from "./constants/flow";
import { hasNestedTrueValue } from "./utils";

interface FlowHeaderProps {
  flowId: string;
  flowName: string;
  isSubmitting: boolean;
  isTestSubmitting?: boolean;
  handleTest: () => void;
  touched: FormikTouched<FlowData>;
  updating: boolean;
  lastUpdatedAt: number | null;
  currentTab: string;
  setCurrentTab: (id: string) => void;
}

function FlowHeader({
  flowId,
  flowName,
  isSubmitting,
  isTestSubmitting,
  touched,
  updating,
  lastUpdatedAt,
  currentTab,
  setCurrentTab,
  handleTest,
}: FlowHeaderProps) {
  const { t } = useTranslation();

  const hasTouchedFields = useMemo(
    () => hasNestedTrueValue(touched),
    [touched]
  );

  return (
    <div className="border-b border-gray-200 bg-gray-50">
      <div className="py-4 px-4 sm:px-6 lg:px-8 w-full ">
        <HeaderNavigation
          navPages={[
            {
              name: t("flows:header.home", "Home"),
              url: buildRoute("dashboard"),
            },
            {
              name: t("flows:header.flows", "Flows"),
              url: buildRoute("flow-list"),
            },
            {
              name: flowName
                ? t("flows:header.update-flow", "Update flow {{flowName}}", {
                    flowName,
                  })
                : t("flows:header.update-flow-without-name", "Update flow"),
              url: buildRoute("flow-detail", { ":id": flowId }),
            },
          ]}
        />
      </div>
      <div className="flex flex-row justify-between items-center border-b border-gray-200 px-4 sm:px-6 lg:px-8">
        <div className="flex h-12 justify-between -my-px">
          {FLOW_TABS.map(({ id, name }) => (
            <button
              key={id}
              type="button"
              className={classNames(
                currentTab === id
                  ? "border-indigo-500 text-indigo-500"
                  : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                "inline-flex items-center border-b-2 w-24 pl-1 pt-2 text-sm font-medium"
              )}
              onClick={(event) => {
                event.preventDefault();
                setCurrentTab(id);
              }}
            >
              {t(name)}
            </button>
          ))}
        </div>
        <div className="flex flex-row items-center space-x-2">
          {hasTouchedFields ? (
            <UpdatedAt
              updating={!isSubmitting && updating}
              lastUpdatedAt={lastUpdatedAt}
            />
          ) : (
            <span className="italic text-xs text-gray-400">
              {t("flows:no-changes-to-save", "No changes to save")}
            </span>
          )}
          {currentTab === "steps" ? (
            <>
              <StateButton
                isSubmitting={isTestSubmitting}
                submitted={false}
                disabled={isSubmitting}
                success={false}
                submittingText="Loading..."
                initialText={
                  <>
                    <PlayIcon className="mr-2 h-5 w-5" aria-hidden="true" />
                    {t("flows:test.title", "Test")}
                  </>
                }
                btnClasses={
                  "inline-flex items-center rounded-md border bg-white px-8 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-offset-green-500 focus:ring-green-500 hover:bg-green-50 text-green-600 border-green-300 disabled:cursor-default disabled:hover:bg-white disabled:text-gray-300 disabled:border-gray-300"
                }
                btnClassesSuccess="inline-flex items-center rounded-md border border-transparent bg-green-100 px-4 py-3 text-sm font-medium leading-4 text-green-800 text-white shadow-sm hover:bg-green-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                btnClassesError="inline-flex items-center rounded-md border border-transparent bg-yellow-50 px-4 py-3 text-sm font-medium leading-4 text-yellow-800 shadow-sm hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-400 focus:ring-offset-2"
                onClick={(ev) => {
                  ev.preventDefault();
                  handleTest();
                }}
                buttonType="button"
              />
              <button
                type="submit"
                disabled={isSubmitting || !hasTouchedFields}
                className="inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-100 transition-all"
              >
                {isSubmitting ? (
                  <>
                    <LoaderSpinner className="text-white" />
                    {t("flows:header.publishing", "Publishing")}
                  </>
                ) : (
                  t("flows:header.publish", "Publish")
                )}
              </button>
            </>
          ) : (
            <button
              type="button"
              className="inline-flex rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-300 disabled:text-gray-100"
              onClick={(event) => {
                event.preventDefault();
                setCurrentTab("steps");
              }}
            >
              {t("flows:header.next", "Next")}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FlowHeader;
