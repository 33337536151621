import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import {
  Edge,
  Node,
  NodeMouseHandler,
  useOnSelectionChange,
  useStoreApi,
} from "reactflow";
import axios from "axios";
import { FlowNodeData, FlowStepData } from "@hilos/types/flow";
import { FlowVersionDetailRead } from "@hilos/types/private-schema";
import { API_ROUTES, buildAPIRoute } from "src/router/router";
import { getNodesFromData, getUpdatedSteps } from "../utils";
import { SelectedItemState } from "./useFlowLayout";

interface UseFlowViewerParams {
  flowVersion: FlowVersionDetailRead;
  flowExecutionId?: string;
  flowExecutionContactId?: string;
  selectedItem: SelectedItemState;
  onSelectItem: (selectedItem: SelectedItemState) => void;
  onUpdateTransitions: (nodes: Node<FlowNodeData>[], edges: Edge[]) => void;
}

async function fetchFlowAnalyticsData({ queryKey }) {
  const [_, id, status, flowExecutionId, flowExecutionContactId] = queryKey;
  const { data } = await axios.get(
    buildAPIRoute(API_ROUTES.FLOW_VERSION_ANALYTICS, {
      ":id": id,
    }),
    {
      params: {
        status,
        flow_execution: flowExecutionId,
        flow_execution_contact: flowExecutionContactId,
      },
    }
  );

  return data;
}

function useFlowViewer({
  flowVersion,
  flowExecutionId,
  flowExecutionContactId,
  onSelectItem,
  onUpdateTransitions,
}: UseFlowViewerParams) {
  const store = useStoreApi();
  const [status, setStatus] = useState<string | undefined>();
  const hasNodesInitializedRef = useRef(false);
  const stepsRef = useRef<FlowStepData[]>([]);

  const { data, isLoading } = useQuery(
    [
      "flow_viewer",
      flowVersion.id,
      status,
      flowExecutionId,
      flowExecutionContactId,
    ],
    fetchFlowAnalyticsData,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  useOnSelectionChange({
    onChange: ({ nodes: selectedNodes, edges: selectedEdges }) => {
      onSelectItem(selectedNodes[0] || selectedEdges[0] || null);
    },
  });

  const onNodeClick = useCallback<NodeMouseHandler>(
    (_, node) => {
      const { addSelectedNodes } = store.getState();
      if (!node.selected && addSelectedNodes) {
        addSelectedNodes([node.id]);
      }
    },
    [store]
  );

  useEffect(() => {
    const loadInitialValues = async () => {
      if (isLoading) {
        return;
      }
      if (!hasNodesInitializedRef.current) {
        hasNodesInitializedRef.current = true;
        const { steps: nextSteps } = await getUpdatedSteps(
          flowVersion.first_step,
          flowVersion.steps as unknown as FlowStepData[]
        );

        stepsRef.current = nextSteps;
      }

      const extraNodeDataByStepId = {};

      if (data) {
        const maxExecutionsByStatus = {};
        const hasMultipleContactExecutions = !(
          flowExecutionId && flowExecutionContactId
        );

        for (const item of data) {
          if (item.step) {
            if (item.status && item.execution_steps_count) {
              if (!extraNodeDataByStepId[item.step]) {
                extraNodeDataByStepId[item.step] = { executions_by_status: {} };
              }
              if (
                !maxExecutionsByStatus[item.status] ||
                item.execution_steps_count > maxExecutionsByStatus[item.status]
              ) {
                maxExecutionsByStatus[item.status] = item.execution_steps_count;
              }

              extraNodeDataByStepId[item.step]["executions_by_status"][
                item.status
              ] = item.execution_steps_count;

              extraNodeDataByStepId[item.step]["execution_steps"] =
                item.execution_steps || 0;
            }
          }
          extraNodeDataByStepId[item.step]["has_multiple_contact_executions"] =
            hasMultipleContactExecutions;
        }

        for (const stepId in extraNodeDataByStepId) {
          if (extraNodeDataByStepId[stepId]) {
            extraNodeDataByStepId[stepId]["max_executions_by_status"] =
              maxExecutionsByStatus;
          }
        }
      }

      const { nodes: nextNodes, edges: nextEdges } = getNodesFromData({
        steps: stepsRef.current,
        executionType: flowVersion.execution_type,
        firstStepId: flowVersion.first_step,
        allowAddStep: false,
        extraNodeDataByStepId,
      });

      onUpdateTransitions(nextNodes, nextEdges);
    };

    loadInitialValues();
  }, [
    flowVersion,
    data,
    onUpdateTransitions,
    isLoading,
    flowExecutionId,
    flowExecutionContactId,
  ]);

  useEffect(() => {
    if (!status && !flowExecutionContactId) {
      setStatus("COMPLETED");
    }
  }, [status, flowExecutionContactId]);

  return {
    status,
    setStatus,
    stepsRef,
    onNodeClick,
  };
}

export default useFlowViewer;
