import { PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { FieldArray } from "formik";
import TextInputField from "../../../../../components/Form/TextInputField";
import FlowStepAddVariable from "../../../FlowStepAddVariable";
import { FlowStepActionMeta } from "./Meta";

export default function FlowStepActionForm({ formik, index }) {
  const addVariable = (variable, cIdx) => {
    formik.setFieldValue(
      `steps.${index}.action_request_body.${cIdx}.${FlowStepActionMeta.FIELDS.action_request_body.form_value.key}`,
      formik.values.steps[index].action_request_body[cIdx].value
        ? formik.values.steps[index].action_request_body[cIdx].value +
            ` ${variable}`
        : variable
    );
  };

  return (
    <FieldArray
      name={`steps.${index}.action_request_body`}
      render={(arrayHelpers) => (
        <>
          <ul className="mt-2">
            {formik.values.steps &&
              formik.values.steps[index].action_request_body &&
              formik.values.steps[index].action_request_body.length > 0 &&
              formik.values.steps[index].action_request_body.map(
                (item, cIdx) => (
                  <div key={cIdx} className="my-2 flex items-start">
                    <div className="grid grow grid-cols-3 gap-2">
                      <div className="">
                        <TextInputField
                          name={`steps.${index}.action_request_body.${cIdx}.${FlowStepActionMeta.FIELDS.action_request_body.form_key.key}`}
                          placeholder={
                            FlowStepActionMeta.FIELDS.action_request_body
                              .form_key.placeholder
                          }
                          type="text"
                        />
                      </div>
                      <div className="col-span-2">
                        <TextInputField
                          name={`steps.${index}.action_request_body.${cIdx}.${FlowStepActionMeta.FIELDS.action_request_body.form_value.key}`}
                          placeholder={
                            FlowStepActionMeta.FIELDS.action_request_body
                              .form_value.placeholder
                          }
                          type="text"
                        />
                        <div className="mt-2 flex items-start justify-between">
                          <div className="mr-4">
                            <FlowStepAddVariable
                              formik={formik}
                              addVariable={addVariable}
                              listIndex={cIdx}
                              currentStepIndex={index}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ml-4 grow-0">
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={(_) => arrayHelpers.remove(cIdx)}
                      >
                        <TrashIcon className="h-4 w-4" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                )
              )}
          </ul>

          {formik.errors &&
            formik.errors.steps &&
            formik.errors.steps[index] &&
            formik.errors.steps[index].body &&
            typeof formik.errors.steps[index].body === "string" && (
              <p className="text-xs text-red-500">
                {formik.errors.steps[index].body}
              </p>
            )}

          <div className="mt-2">
            <button
              type="button"
              className="inline-flex w-full items-center justify-center rounded-md border border-blue-300 bg-gray-50 px-3 py-2 text-sm font-medium leading-4 text-blue-500 shadow-sm hover:bg-blue-600 hover:text-white focus:outline-none focus:ring-1 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-500"
              onClick={(_) => {
                arrayHelpers.push({ key: "", value: "" });
              }}
            >
              <PlusSmIcon className="mr-2 h-5 w-5" aria-hidden="true" />
              Add Item
            </button>
          </div>
        </>
      )}
    ></FieldArray>
  );
}
