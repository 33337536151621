import {
  ArrowSmDownIcon,
  ArrowSmUpIcon,
  DocumentTextIcon,
  PhotographIcon,
  VideoCameraIcon,
} from "@heroicons/react/outline";
import { getTranslationPayload as t } from "src/i18n";

export const TRIGGER_TYPES = {
  INBOUND: {
    icon: ArrowSmDownIcon,
    title: t("flows:trigger-types.inbound.title", "Inbound message"),
    description: t(
      "flows:trigger-types.inbound.description",
      "Triggers when we get a text containing a specific message from a customer."
    ),
  },
  OUTBOUND: {
    icon: ArrowSmUpIcon,
    title: t("flows:trigger-types.outbound.title", "Outbound message"),
    description: t(
      "flows:trigger-types.outbound.description",
      "Can be triggered with existing contacts, a .csv upload, or via our API."
    ),
  },
};

export const FLOW_TABS = [
  { name: t("flows:header.details", "Details"), id: "details" },
  { name: t("flows:header.steps", "Steps"), id: "steps" },
];

export const BASE_STEP_NAMES = {
  TEMPLATE: t("flows:base-step-names.template", "Send template"),
  TEMPLATE_MENU: t("flows:base-step-names.template-menu", "Send template"),
  MENU: t("flows:base-step-names.menu", "Show menu"),
  QUESTION: t("flows:base-step-names.question", "Ask a question"),
  ACTION: t("flows:base-step-names.action", "API Call"),
  MESSAGE: t("flows:base-step-names.message", "Send message"),
  CONDITIONAL: t("flows:base-step-names.conditional", "Validate conditional"),
  CONDITIONAL_MULTIPLE: t(
    "flows:base-step-names.conditional-multiple",
    "Validate conditional"
  ),
  CONVERSATION: t("flows:base-step-names.conversation", "Assign conversation"),
  TEAM: t("flows:base-step-names.team", "Add team"),
  TAG: t("flows:base-step-names.tag", "Add tag"),
  DELAY: t("flows:base-step-names.delay", "Perform delay"),
  CONTACT_UPDATE: t("flows:base-step-names.contact-update", "Update contact"),
  TRIGGER_FLOW: t("flows:base-step-names.trigger-flow", "Run another flow"),
  GO_TO: t("flows:base-step-names.go-to", "Go to another step"),
  HUBSPOT_CONTACT_UPSERT: t(
    "flows:base-step-names.hubspot-contact-upsert",
    "Create HS contact"
  ),
  HUBSPOT_CONTACT_GET: t(
    "flows:base-step-names.hubspot-contact-get",
    "Get HS contact"
  ),
};

export const EXECUTION_STATUS_TYPES = [
  { id: "RUNNING", label: t("flows:execution-status.running", "RUNNING") },
  {
    id: "COMPLETED",
    label: t("flows:execution-status.completed", "COMPLETED"),
  },
  { id: "CANCELED", label: t("flows:execution-status.canceled", "CANCELED") },
  { id: "FAILED", label: t("flows:execution-status.failed", "FAILED") },
  { id: "EXPIRED", label: t("flows:execution-status.expired", "EXPIRED") },
];

export const EXECUTION_FREQUENCY_UNITS = [
  {
    value: "MINUTE",
    label: t(
      "flows:contact_execution_max_frequency_quantity.minute",
      "Minute(s)"
    ),
  },
  {
    value: "HOUR",
    label: t("flows:contact_execution_max_frequency_quantity.hour", "Hour(s)"),
  },
  {
    value: "DAY",
    label: t("flows:contact_execution_max_frequency_quantity.day", "Day(s)"),
  },
];

export const COMPLETED_COLORS_PALETTE = [
  { base: "#d1fae5", tint: "#16a34a" },
  { base: "#c2f6de", tint: "#169c47" },
  { base: "#b5f3d7", tint: "#169545" },
  { base: "#a8f0d1", tint: "#168e43" },
  { base: "#9aedca", tint: "#168740" },
  { base: "#8de9c4", tint: "#16803e" },
  { base: "#7fe6bd", tint: "#16793b" },
  { base: "#72e3b7", tint: "#167339" },
  { base: "#64dfb0", tint: "#166c36" },
  { base: "#57dcaa", tint: "#166534" },
];

export const EXPIRED_COLORS_PALETTE = [
  { base: "#fff7ed", tint: "#ea580c" },
  { base: "#fff0de", tint: "#e0540d" },
  { base: "#ffe9d1", tint: "#d8500d" },
  { base: "#fee2c4", tint: "#cf4c0e" },
  { base: "#fedcb7", tint: "#c6480f" },
  { base: "#fed5a9", tint: "#bd440f" },
  { base: "#fece9c", tint: "#b44010" },
  { base: "#fdc78f", tint: "#ac3c11" },
  { base: "#fdc181", tint: "#a33811" },
  { base: "#fdba74", tint: "#9a3412" },
];

export const CANCELED_COLORS_PALETTE = [
  { base: "#fef2f2", tint: "#dc2626" },
  { base: "#fee9e9", tint: "#d42525" },
  { base: "#fee0e0", tint: "#cd2323" },
  { base: "#fdd8d8", tint: "#c52222" },
  { base: "#fdcfcf", tint: "#be2121" },
  { base: "#fdc7c7", tint: "#b62020" },
  { base: "#fdbebe", tint: "#af1f1f" },
  { base: "#fcb6b6", tint: "#a81d1d" },
  { base: "#fcadad", tint: "#a01c1c" },
  { base: "#fca5a5", tint: "#991b1b" },
];

export const FAILED_COLORS_PALETTE = [
  { base: "#fff1f2", tint: "#e11d48" },
  { base: "#ffe8ea", tint: "#d91c46" },
  { base: "#ffdfe3", tint: "#d21a45" },
  { base: "#fed7db", tint: "#cb1943" },
  { base: "#feced4", tint: "#c31841" },
  { base: "#fec6cc", tint: "#bc1740" },
  { base: "#febdc5", tint: "#b5163e" },
  { base: "#fdb5be", tint: "#ae143c" },
  { base: "#fdacb6", tint: "#a6133b" },
  { base: "#fda4af", tint: "#9f1239" },
];

export const RUNNING_COLORS_PALETTE = [
  { base: "#f0fdfa", tint: "#0d9488" },
  { base: "#defbf5", tint: "#0d8e82" },
  { base: "#cef9f1", tint: "#0e887d" },
  { base: "#bef7ed", tint: "#0e8278" },
  { base: "#aef4e9", tint: "#0f7c73" },
  { base: "#9ef2e5", tint: "#0f766e" },
  { base: "#8ef0e1", tint: "#107069" },
  { base: "#7eeedc", tint: "#106a63" },
  { base: "#6eecd8", tint: "#11645e" },
  { base: "#5eead4", tint: "#115e59" },
];

export const STEP_FIELDS_BY_KEY = {
  name: t("flows:flow-step-errors.fields.name", "Name"),
  variables: t("flows:flow-step-errors.fields.variables", "Variables"),
  required_variables: t(
    "flows:flow-step-errors.fields.required-variables",
    "Required variables"
  ),
  weekday: t("flows:flow-step-errors.fields.weekday", "Weekday"),
  is_available: t("flows:flow-step-errors.fields.is-available", "Is available"),
  start_at: t("flows:flow-step-errors.fields.start-at", "Start at"),
  end_at: t("flows:flow-step-errors.fields.end-at", "End at"),
  max_answer_attempts: t(
    "flows:flow-step-errors.fields.max-answer-attempts",
    "Max answer attempts"
  ),
  answer_failed_next_step: t(
    "flows:flow-step-errors.fields.answer-failed-next-step",
    "Next step if the answer is failed"
  ),
  max_wait_time_amount: t(
    "flows:flow-step-errors.fields.max-wait-time-amount",
    "Waiting time"
  ),
  max_wait_time_unit: t(
    "flows:flow-step-errors.fields.max-wait-time-unit",
    "Unit of waiting time"
  ),
  time_window_type: t(
    "flows:flow-step-errors.fields.time-window-type",
    "Time window type"
  ),
  time_windows: t("flows:flow-step-errors.fields.time-windows", "Time windows"),
  action_request_method: t(
    "flows:flow-step-errors.fields.action-request-method",
    "Request method"
  ),
  action_request_url: t(
    "flows:flow-step-errors.fields.action-request-url",
    "Request url"
  ),
  action_request_body: t(
    "flows:flow-step-errors.fields.action-request-body",
    "Request body"
  ),
  conditions: t("flows:flow-step-errors.fields.conditions", "Conditionals"),
  field: t("flows:flow-step-errors.fields.field", "Field"),
  comparison: t("flows:flow-step-errors.fields.comparison", "Comparison"),
  value: t("flows:flow-step-errors.fields.value", "Value"),
  assign_to_users: t(
    "flows:flow-step-errors.fields.assign-to-users",
    "Assign to users"
  ),
  contact_tags: t("flows:flow-step-errors.fields.contact-tags", "Contact tags"),
  conversation_tags: t(
    "flows:flow-step-errors.fields.conversation-tags",
    "Conversation tags"
  ),
  answer_validation_message: t(
    "flows:flow-step-errors.fields.answer-validation-message",
    "Answer validation message"
  ),
  answer_options: t(
    "flows:flow-step-errors.fields.answer-options",
    "Answer options"
  ),
  next_steps_for_options: t(
    "flows:flow-step-errors.fields.next-steps-for-options",
    "Next steps for options"
  ),
  body: t("flows:flow-step-errors.fields.body", "Message body"),
  answer_options_render: t(
    "flows:flow-step-errors.fields.answer-options-render",
    "How would you like to show these options?"
  ),
  answer_options_render_list_button_title: t(
    "flows:flow-step-errors.fields.answer-options-render-list-button-title",
    "Open list button text"
  ),
  body_type: t("flows:flow-step-errors.fields.body-type", "Message type"),
  body_file_type: t(
    "flows:flow-step-errors.fields.body-file-type",
    "Media file type"
  ),
  body_file_url: t(
    "flows:flow-step-errors.fields.body-file-url",
    "Media file URL"
  ),
  body_file: t("flows:flow-step-errors.fields.body-file", "Media file"),
  missing_options_message: t(
    "flows:flow-step-errors.fields.missing-options-message",
    "Message to display when no options were found"
  ),
  answer_type: t("flows:flow-step-errors.fields.answer-type", "Answer type"),
  options_from_variable: t(
    "flows:flow-step-errors.fields.options-from-variable",
    "Data source for options"
  ),
  option_from_variable_value: t(
    "flows:flow-step-errors.fields.option-from-variable-value",
    "Value of each option"
  ),
  option_from_variable_title: t(
    "flows:flow-step-errors.fields.option-from-variable-title",
    "Title of each option"
  ),
  answer_range_min: t(
    "flows:flow-step-errors.fields.answer-range-min",
    "Minimum value expected (included)"
  ),
  answer_range_max: t(
    "flows:flow-step-errors.fields.answer-range-max",
    "Maximum value expected (included)"
  ),
};

export const UPLOAD_MEDIA_FILE_TYPES = {
  IMAGE: {
    icon: PhotographIcon,
    accept: "image/png,image/jpg,image/jpeg",
    label: t(
      "flows:upload-media-file.image.label",
      "Click here to select a image or drag & drop it."
    ),
    help: t(
      "flows:upload-media-file.image.help",
      "Make sure it's .png or .jpg file and no larger than 15MB."
    ),
  },
  DOCUMENT: {
    icon: DocumentTextIcon,
    accept: "application/pdf",
    label: t(
      "flows:upload-media-file.document.label",
      "Click here to select a document or drag & drop it."
    ),
    help: t(
      "flows:upload-media-file.document.help",
      "Make sure it's a .pdf file and no larger than 15MB."
    ),
  },
  VIDEO: {
    icon: VideoCameraIcon,
    accept: "video/mp4",
    label: t(
      "flows:upload-media-file.video.label",
      "Click here to select a video or drag & drop it."
    ),
    help: t(
      "flows:upload-media-file.video.help",
      "Make sure .mp4 file and no larger than 15MB."
    ),
  },
};

export type TriggerTypes = keyof typeof TRIGGER_TYPES;
