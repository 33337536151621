import { useEffect, useMemo, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { TrashIcon } from "@heroicons/react/outline";
import { captureException } from "@sentry/react";
import axios from "axios";
import { FormikProps } from "formik";
import { UploadedFile } from "@hilos/types/uploaded_file";
import { DropzoneStyles } from "../../../../../components/base/Form";
import { API_ROUTES, buildAPIRoute } from "../../../../../router/router";
import { FlowStepMessageMeta } from "./Meta";

interface FlowStepMessageMediaFileUploadProps {
  formik: FormikProps<any>;
  index: number;
  mediaType: MediaType;
  deleteUploadedFile: () => void;
  uploadedFile: UploadedFile | null;
  setUploadedFile: (x: UploadedFile | null) => void;
}

interface MediaType {
  label: string;
  value: string;
  icon: JSX.Element;
  help: string;
  accept?: string;
  inputHelp?: string;
}

export default function FlowStepMessageMediaFileUpload({
  formik,
  index,
  mediaType,
  deleteUploadedFile,
  uploadedFile,
  setUploadedFile,
}: FlowStepMessageMediaFileUploadProps) {
  const [error, setError] = useState("");
  const [uploadingFile, setUploadingFile] = useState(false);

  useEffect(() => {
    const getUploadedFile = async () => {
      const uploadedFileId =
        formik.values.steps[index][FlowStepMessageMeta.FIELDS.body_file.key];
      if (!uploadedFileId) {
        return null;
      }
      const { data } = await axios.get(
        buildAPIRoute(API_ROUTES.PUBLIC_FILE_DETAIL, { ":id": uploadedFileId })
      );
      setUploadedFile(data);
    };
    getUploadedFile();
  }, []);

  const handleOnDrop = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    setError("");
    fileRejections.forEach((file) => {
      setError(`${file.file.name} is not a valid format.`);
    });

    acceptedFiles.map(async (file) => {
      if (file.size > 1024 * 15000) {
        setError("Please upload a smaller file no larger than 15MB.");
      }

      const formData = new FormData();
      formData.append("uploaded_file", file);

      setUploadingFile(true);
      try {
        const { data } = await axios.post(
          API_ROUTES.PUBLIC_FILE_UPLOAD,
          formData
        );

        setUploadedFile(data);
        formik.setFieldValue(
          `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file.key}`,
          data.id
        );
      } catch (e) {
        captureException(e);
      } finally {
        setUploadingFile(false);
      }
    });
  };

  const handleCleanFile = () => {
    const uploadedFileId =
      formik.values.steps[index][FlowStepMessageMeta.FIELDS.body_file.key];
    if (uploadedFileId) {
      const deletedFiles = formik.values.deleted_files || [];
      deletedFiles.push(uploadedFileId);

      formik.setFieldValue("deleted_files", deletedFiles);
    }
    formik.setFieldValue(
      `steps.${index}.${FlowStepMessageMeta.FIELDS.body_file.key}`,
      ""
    );
    setUploadedFile(null);
  };

  const { getRootProps, getInputProps, isDragActive, isDragAccept } =
    useDropzone({
      // @ts-ignore
      accept: mediaType.accept,
      onDrop: handleOnDrop,
      maxFiles: 1,
    });

  const style = useMemo(
    () => ({
      ...DropzoneStyles.baseStyle,
      ...(isDragActive ? DropzoneStyles.activeStyle : {}),
      ...(isDragAccept ? DropzoneStyles.acceptStyle : {}),
    }),
    [isDragActive, isDragAccept]
  );

  if (!mediaType) {
    return null;
  }

  return (
    <div>
      {uploadedFile ? (
        <div className="flex items-stretch rounded-lg border border-gray-200 p-3 shadow-sm">
          {mediaType.value === "image" && (
            <img
              alt={formik.values.steps[index].name}
              src={uploadedFile.url}
              className="mr-2 w-14 self-center"
            />
          )}
          {mediaType.value === "video" && (
            <video loop className="mr-2 w-20 self-center">
              <source src={uploadedFile.url} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          )}

          <div className="grow self-center truncate text-sm">
            {uploadedFile.original_name}
          </div>
          <div className="self-center text-right">
            <button
              className="inline-flex items-center rounded-md border border-red-600 bg-white px-3 py-2 text-sm font-medium leading-4 text-red-600 shadow-sm hover:bg-red-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              type="button"
              onClick={handleCleanFile}
            >
              <TrashIcon className="mr-2 h-4 w-4" aria-hidden="true" />
              Remove
            </button>
          </div>
        </div>
      ) : (
        <div
          {...getRootProps({
            className: "dropzone",
            // @ts-ignore
            style: style,
          })}
        >
          <input {...getInputProps()} />
          <div className="mb-0 flex items-center pb-0">
            {mediaType.icon}
            {uploadingFile ? (
              <span className="ml-3">Uploading file...</span>
            ) : (
              <div className="ml-3">
                <div>
                  Click here to select a {mediaType.label.toLowerCase()} or drag
                  & drop it.
                </div>
                <div className="text-xs text-gray-500">
                  {mediaType.inputHelp}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <p>
        {error && <span className="mt-1.5 text-xs text-red-500">{error}</span>}
      </p>
    </div>
  );
}
