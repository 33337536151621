import { eachMinuteOfInterval, endOfDay, format, startOfDay } from "date-fns";
import { useCallback, useMemo } from "react";

import { FormikProps } from "formik";
import { TimeWindow } from "@hilos/types/flows/Flow";

interface useTimeWindowsHelpersProps {
  formik: FormikProps<any>;
  index: number;
}

export const FIELDS = {
  time_windows: {
    key: "time_windows",
    weekday: {
      key: "weekday",
      label: "Day of the week",
    },
    is_available: {
      key: "is_available",
      label: "Is available?",
    },
    start_at: {
      key: "start_at",
      label: "From",
    },
    end_at: {
      key: "end_at",
      label: "Until",
    },
  },
};


export default function useTimeWindowsHelpers({formik, index}: useTimeWindowsHelpersProps) {
  const getOrCreateTimeWindows = useCallback(() => {
    // Checking that we have a time window object for each weekday
    const timeWindowsResult: TimeWindow[] = [];
    for (let weekday = 0; weekday <= 6; weekday++) {
      const timeWindows = formik.values.steps[index].time_windows || [];
      let existingElem = timeWindows.find((tw) => tw.weekday === weekday);
      if (!existingElem) {
        existingElem = {
          weekday: weekday,
          start_at: null,
          end_at: null,
          is_available: false,
        };
      }
      timeWindowsResult.push(existingElem);
    }
    formik.setFieldValue(`steps.${index}.time_windows`, timeWindowsResult);
    formik.setFieldTouched(`steps.${index}.time_windows`);
  }, [formik, index]);

  const unsetTimeWindows = useCallback(() => {
    formik.setFieldValue(`steps.${index}.time_windows`, []);
    formik.setFieldTouched(`steps.${index}.time_windows`);
  }, [formik, index]);

  const toggleTimeWindows = useCallback(
    (val: boolean) => {
      val ? getOrCreateTimeWindows() : unsetTimeWindows();
    },
    [getOrCreateTimeWindows, unsetTimeWindows]
  );

  const hourChoices = useMemo(() => {
    const today = new Date();
    const hoursFNS = eachMinuteOfInterval(
      {
        start: startOfDay(today),
        end: endOfDay(today),
      },
      { step: 30 }
    );
    return hoursFNS.map((h) => ({
      label: format(h, "HH:mm"),
      value: format(h, "HH:mm:ss"),
    }));
  }, []);

    const onAvailableDayChange = (ev, idx) => {
    formik.setFieldValue(
      `steps.${index}.${FIELDS.time_windows.key}.${idx}.is_available`,
      ev.target.checked
    );
    formik.setFieldTouched(
      `steps.${index}.${FIELDS.time_windows.key}.${idx}.is_available`
    );
    if (!ev.target.checked) {
      formik.setFieldValue(
        `steps.${index}.${FIELDS.time_windows.key}.${idx}.start_at`,
        null
      );
      formik.setFieldValue(
        `steps.${index}.${FIELDS.time_windows.key}.${idx}.end_at`,
        null
      );
    }
  };

  return {getOrCreateTimeWindows, unsetTimeWindows,toggleTimeWindows, hourChoices, onAvailableDayChange }
}